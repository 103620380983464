import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@components/arrangement/Stack/Stack";

import "./ClaimingUsersEmptyState.scss";

const ClaimingUsersEmptyState = () => {
  const { t } = useTranslation("eventsViews");

  return (
    <div className="claiming-users-empty-state">
      <img
        src="/images/claiming-users-empty-bg.png"
        alt="claiming users empty state"
        className="claiming-users-empty-state-bg"
      />

      <Stack rowGap={22} alignItems="center" fullSize className="claiming-users-empty-state-content">
        <img src="/images/icon-claiming-users-empty.svg" alt="claiming users empty state icon" width={46} height={60} />
        <p className="p0 text-center">{t("claimingUsers.emptyState")}</p>
      </Stack>
    </div>
  );
};

export default ClaimingUsersEmptyState;
