import React, { memo } from "react";
import classNames from "classnames";

import Group from "@components/arrangement/Group/Group";

import { ButtonProps } from "./Button.types";
import "./Button.scss";

const Button = ({
  type = "button",
  children,
  variant = "primary",
  size = "normal",
  disabled = false,
  loading = false,
  leftIcon,
  rightIcon,
  className = "",
  onClick = () => {},
  onBlur = () => {}
}: ButtonProps) => {
  const classes = classNames(`button button-${variant} button-${size}`, { "button-loading": loading }, className);

  return (
    <button type={type} className={classes} onClick={onClick} onBlur={onBlur} disabled={disabled || loading}>
      <Group colGap={15} justifyContent="center">
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </Group>
    </button>
  );
};

export default memo(Button);
