import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const Facebook = ({ width = 31, height = 30, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
      className={className}
    >
      <path
        d="M30.5452 15.133C30.5452 7.1765 23.8071 0.730469 15.5026 0.730469C7.19248 0.730469 0.459961 7.1765 0.459961 15.133C0.459961 22.3198 5.95898 28.2789 13.1522 29.3582V19.2971H9.3323V15.1339H13.1522V11.959C13.1522 8.3503 15.3945 6.35735 18.8317 6.35735C20.4779 6.35735 22.2013 6.6382 22.2013 6.6382V10.1821H20.3012C18.4359 10.1821 17.853 11.2938 17.853 12.4325V15.133H22.0245L21.3542 19.2962H17.853V29.3573C25.0406 28.278 30.5452 22.3189 30.5452 15.1321V15.133Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
