import React from "react";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { EventStatus, PublicEventDto } from "@services/Api/api.dto";
import { usePublicApiClient } from "@services/Api/PublicApiClientContext";
import { publicEventMock } from "../../../../mocks/publicEvent.mock";

export interface EventPageData {
  status: FetchStatus;
  event: PublicEventDto;
  error: Error | AxiosError | null;
}

interface ContextValue extends EventPageData {
  updateEventStatus: (status: EventStatus) => void;
}

const initialEventInfoData = (): EventPageData => ({
  status: "loading",
  event: publicEventMock,
  error: null
});

const EventPageContext = React.createContext(null as any);

export const EventPageProvider = ({ children }: IContext) => {
  const [eventPageData, setEventPageData] = React.useState<EventPageData>(initialEventInfoData());

  const { eventId } = useParams();
  const { getEventInfo } = usePublicApiClient();

  React.useEffect(() => {
    if (eventId) fetchEventInfoData(eventId);
  }, [eventId]);

  const fetchEventInfoData = async (eventId: string) => {
    initialEventInfoData();

    try {
      const { data } = await getEventInfo(eventId);
      return setEventPageData((prevState) => ({ ...prevState, event: data, status: "success" }));
    } catch (e: any) {
      setEventPageData((prevState) => {
        return { ...prevState, status: "failed", error: e };
      });
    }
  };

  const updateEventStatus = (status: EventStatus) => {
    setEventPageData((prevState) => ({
      ...prevState,
      event: { ...prevState.event, status: status }
    }));
  };

  const contextValue: ContextValue = {
    ...eventPageData,
    updateEventStatus
  };

  return <EventPageContext.Provider value={contextValue}>{children}</EventPageContext.Provider>;
};

export const useEventPageData = (): ContextValue => React.useContext(EventPageContext);
