import React from "react";
import { useTranslation } from "react-i18next";

import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";

import EventsNavListSkeleton from "../../skeletons/EventsNavListSkeleton/EventsNavListSkeleton";
import EventNavItem from "./EventNavItem/EventNavItem";
import "./EventsNavList.scss";

const EventsNavList = () => {
  const { t } = useTranslation("eventsViews");
  const { events, status } = useEvents();
  const { openModal } = useEventsModals();

  const handleOpenModal = () => openModal({ type: "createEvent" });

  if (status === "loading") {
    return <EventsNavListSkeleton />;
  }

  return (
    <Stack rowGap={10} className="events-nav-list">
      <button onClick={handleOpenModal} className="events-nav-list-create-event">
        <Group colGap={10} fullWidth>
          <img src="/images/icon-add-item.svg" alt="create event icon" className="craete-event-icon" />
          <p className="p0">{t("createEventButton")}</p>
        </Group>
      </button>

      {events.map(({ id, name, start, timezone, ticketsBurned, ticketsMinted, status, submissionsCount, page }) => {
        return (
          <EventNavItem
            key={id}
            eventId={id}
            name={name}
            startDate={start}
            timezone={timezone}
            ticketsBurned={ticketsBurned}
            ticketsMinted={ticketsMinted}
            eventStatus={status}
            submissionsCount={submissionsCount}
            occasionalNFT={page.occasionalNFT}
          />
        );
      })}
    </Stack>
  );
};

export default EventsNavList;
