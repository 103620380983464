import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./EventPageSkeleton.scss";

const EventPageSkeleton = () => {
  return (
    <div className="event-page-skeleton desktop-md">
      <ContentLoader width={1100} height={700} viewBox="0 0 1100 700">
        {/* Content section */}
        <rect x="0" y="0" rx="5" ry="5" width="720" height="60" />
        <rect x="0" y="95" rx="5" ry="5" width="350" height="28" />
        <rect x="0" y="158" rx="5" ry="5" width="720" height="1" />
        <rect x="0" y="179" rx="5" ry="5" width="350" height="28" />
        <rect x="0" y="242" rx="5" ry="5" width="720" height="480" />

        {/* Sidebar section */}
        <rect x="760" y="0" rx="5" ry="5" width="340" height="340" />
        <rect x="760" y="375" rx="5" ry="5" width="340" height="40.5" />

        <rect x="760" y="450.5" rx="5" ry="5" width="170" height="22.5" />
        <rect x="760" y="483" rx="5" ry="5" width="340" height="22.5" />
        <rect x="760" y="515.5" rx="5" ry="5" width="340" height="22.5" />

        <rect x="760" y="573" rx="5" ry="5" width="170" height="22.5" />
        <rect x="760" y="605.5" rx="5" ry="5" width="340" height="22.5" />
        <rect x="760" y="638" rx="5" ry="5" width="340" height="22.5" />
      </ContentLoader>
    </div>
  );
};

export default EventPageSkeleton;
