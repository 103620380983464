import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useEventPageData } from "@app/public/EventPage/contexts/EventPage.context";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./EventPageError.scss";

const EventPageError = () => {
  const { error } = useEventPageData();
  const { t } = useTranslation("public");

  const handleReload = () => window.location.reload();

  if (axios.isAxiosError(error) && error.response) {
    const {
      response: {
        data: { statusCode }
      }
    } = error;

    if (statusCode === 400 || statusCode === 404) {
      return (
        <div className="event-page-error">
          <img
            src="/images/eventWeb/event-page-error-bg.png"
            alt="event not found"
            className="event-page-error-background"
          />

          <Stack rowGap={40} alignItems="center" fullSize className="event-page-error-content">
            <img src="/images/eventWeb/icon-event-page-not-found.svg" alt="not found event icon" />

            <Stack rowGap={23} alignItems="center">
              <h2>{t("eventPage.errors.notFound.title")}</h2>
              <p className="p2 text-center text-regular">{t("eventPage.errors.notFound.description")}</p>
            </Stack>
          </Stack>
        </div>
      );
    }
  }

  return (
    <div className="event-page-error">
      <img
        src="/images/eventWeb/event-page-error-bg.png"
        alt="event not found"
        className="event-page-error-background desktop-md"
      />

      <Stack rowGap={40} alignItems="center" fullSize className="event-page-error-content">
        <img src="/images/eventWeb/icon-event-page-info.svg" alt="get event info error" />

        <Stack rowGap={15} alignItems="center">
          <h2>{t("eventPage.errors.baseError.title")}</h2>
          <p className="p2 text-center text-regular">{t("eventPage.errors.baseError.description")}</p>
        </Stack>

        <Button variant="primary" onClick={handleReload}>
          {t("eventPage.errors.baseError.button")}
        </Button>
      </Stack>
    </div>
  );
};

export default EventPageError;
