import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { Pagination } from "@src/types/api/Pagination.types";
import { TicketDto } from "@services/Api/api.dto";

import { useApiClient } from "@services/Api/ApiClientContext";
import { SocketEvents, TicketUsedData } from "@services/Websocket/Socket.types";
import { useEvents } from "./EventsData.context";
import useSocketSubscribe from "@services/Websocket/useSocketSubscribe";

export interface TicketsData {
  status: FetchStatus;
  tickets: {
    data: TicketDto[];
    meta: Pagination;
  };
  error: Error | AxiosError | null;
}

interface ContextValue extends TicketsData {
  updateTicket: (ticketId: string, updatedTicket: TicketDto) => void;
  refetchTicketsData: (eventId: string, page: number, perPage?: number) => Promise<void>;
}

const initialTicketsData = (): TicketsData => ({
  status: "loading",
  tickets: { data: [], meta: { page: 1, perPage: 50, total: 50, totalPages: 1 } },
  error: null
});

const TicketsDataContext = React.createContext(null as any);

export const TicketsDataProvider = ({ children }: IContext) => {
  const [ticketsData, setTicketsData] = useState<TicketsData>(initialTicketsData());
  const { eventId } = useParams();

  const { getTicketsForEvent } = useApiClient();
  const { incrementTicketsBurned } = useEvents();

  useEffect(() => {
    if (!eventId) return;

    fetchTicketsData(eventId, 1, 20);
  }, [eventId]);

  useSocketSubscribe(
    SocketEvents.TICKET_USED,
    ({ ticket }: TicketUsedData) => {
      if (!eventId) return;

      updateTicket(ticket.id, ticket);
      incrementTicketsBurned(eventId);
    },
    [eventId]
  );

  useSocketSubscribe(
    SocketEvents.TICKETS_MINTED,
    () => {
      if (!eventId) return;

      refetchTicketsData(eventId, 1, 20);
    },
    [eventId]
  );

  const fetchTicketsData = async (eventId: string, page: number, perPage: number = 20) => {
    setTicketsData(initialTicketsData());

    try {
      const { data } = await getTicketsForEvent(eventId, page, perPage);
      return setTicketsData((prevState) => ({ ...prevState, tickets: data, status: "success" }));
    } catch (e: any) {
      setTicketsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const updateTicket = React.useCallback((ticketId: string, updatedTicket: TicketDto) => {
    setTicketsData((prevState) => ({
      ...prevState,
      tickets: {
        ...prevState.tickets,
        data: prevState.tickets.data.map((ticket) => {
          if (ticket.id === ticketId) {
            return { ...ticket, ...updatedTicket };
          }

          return ticket;
        })
      }
    }));
  }, []);

  const refetchTicketsData = async (eventId: string, page: number, perPage: number = 20) => {
    try {
      const { data } = await getTicketsForEvent(eventId, page, perPage);
      return setTicketsData((prevState) => ({ ...prevState, tickets: data, status: "success" }));
    } catch (e: any) {
      setTicketsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const contextValue: ContextValue = {
    ...ticketsData,
    updateTicket,
    refetchTicketsData
  };

  return <TicketsDataContext.Provider value={contextValue}>{children}</TicketsDataContext.Provider>;
};

export const useTickets = (): ContextValue => useContext(TicketsDataContext);
