export type ApiKeyAccess = "read" | "write";
export type SubmissionsOrderBy = "id" | "lastName" | "createdAt";
export type ClaimingUsersOrderBy = "userName" | "email";
export type EventStatus = "draft" | "started" | "ended";
export type TicketStatusType = "created" | "used" | "canceled" | "expired";
export type OccasionalNFTRule = "everyone" | "ticket_used";

export interface SessionDto {
  id: string;
  ip: string;
  exp: string;
  createdAt: string;
  updatedAt: string;
}

export interface LoginDto {
  user: UserDto;
  csrf: string;
}

export interface OrganizationDto {
  id: string;
  name: string;
  email: string;
  url: string;
  description: string;
  blockchainWallet: string;
  imageUrl: string | null;
}

export interface UserDto {
  id: string;
  email: string;
  organizations?: OrganizationDto[];
}

export interface ApiKeyDto {
  id: string;
  label: string;
  exp: string;
  access: ApiKeyAccess;
  hash: string;
}

export interface WorkerTokenDto {
  id: string;
  hash: string;
  label: string;
  controller: string;
  eventId: string;
  createdAt: string;
}

export interface EventLocationDto {
  name: string;
  address: string;
  url: string;
}

export interface EventPageDto {
  content: string;
  imageUrl: string;
  enableSubmissions: boolean;
  uniqueSubmission: boolean;
  automaticTicketSending: boolean;
  submissionsButtonDisabled: boolean;
  submissionEnd: string;
  occasionalNFT: boolean;
}

export interface SubmissionDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  note?: string;
  acceptedAgreements: string[];
  ticket?: TicketDto;
  ticketLastSentAt?: string;
  registeredAt: string;
}

export interface AgreementDto {
  id: string;
  index: number;
  name: string;
  content: string;
  url?: string | null;
  required?: boolean;
}

export interface OccasionalNftDto {
  name: string;
  description: string;
  occasionalNFTRule: OccasionalNFTRule;
  imageUrl: string;
  ipfsHash: string;
}

export interface UpdateOccasionalNftDto {
  name?: string;
  occasionalNFTRule?: OccasionalNFTRule;
  image?: File;
}

export interface EventDto {
  id: string;
  email: string;
  name: string;
  ticketsGoal: number;
  ticketsMinted: number;
  ticketsBurned: number;
  url: string;
  start: string;
  end: string;
  expired: boolean;
  offset: number;
  timezone: string;
  agreementsLocked: boolean;
  status: EventStatus;
  submissionsCount: number;
  logoUrl: string;
  location: EventLocationDto;
  page: EventPageDto;
  agreements: AgreementDto[];
  occasionalNFT: OccasionalNftDto | null;
  occasionalNFTSent: boolean;
}

export interface PublicEventDto {
  id: string;
  name: string;
  url: string;
  start: string;
  end: string;
  expired: boolean;
  offset: number;
  timezone: string;
  status: EventStatus;
  logoUrl: string;
  location: EventLocationDto;
  page: EventPageDto;
  agreements: AgreementDto[];
  organization: OrganizationDto;
}

interface HistoryCommon {
  timestamp: number;
}

export enum TicketStatus {
  CREATED = "created",
  USED = "used",
  CANCELED = "canceled",
  EXPIRED = "expired"
}

interface ITicketCreated extends HistoryCommon {
  status: TicketStatus.CREATED;
}

interface ITicketUsed extends HistoryCommon {
  status: TicketStatus.USED;
  burnTxHash: string;
  workerToken: {
    id: string;
    label: string;
    controller: string;
  };
}

interface ITicketCanceled extends HistoryCommon {
  status: TicketStatus.CANCELED;
  userId?: string;
  apiKeyId?: string;
}

interface ITicketExpired extends HistoryCommon {
  status: TicketStatus.EXPIRED;
}

export type TicketHistoryObject = ITicketCreated | ITicketUsed | ITicketCanceled | ITicketExpired;

export interface TicketDto {
  id: string;
  address: string;
  globalId: number;
  status: TicketStatusType;
  explorerUrl: string;
  history: TicketHistoryObject[];
  submission?: string;
}

export interface TicketWithSubmissionDto {
  id: string;
  address: string;
  globalId: number;
  status: TicketStatusType;
  explorerUrl: string;
  history: TicketHistoryObject[];
  submission?: SubmissionDto;
}

export interface ClaimingUserDto {
  id: string;
  email: string;
  blockchainAddress: string;
  explorerUrl: string;
  occasionalNftAssetId: string;
  claimed: boolean;
  nftLastSentAt: string;
  claimingAt: string;
  ticket: TicketWithSubmissionDto;
}

export interface ClaimingOccasionalNftResponseDto {
  seed: string;
  occasionalNftAssetUrl: string;
}
