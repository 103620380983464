import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./TicketSkeleton.scss";

const TicketSkeleton = () => {
  return (
    <div className="ticket-skeleton">
      <ContentLoader width={340} height={599} viewBox="0 0 340 599">
        <path d="M 24.41 87.317 h 291.174" />
        <rect x="266" y="19" rx="5" ry="5" width="49" height="49" />
        <rect x="74" y="319" rx="5" ry="5" width="194" height="194" />
        <rect x="24" y="43" rx="5" ry="5" width="229" height="26" />
        <rect x="24" y="19" rx="5" ry="5" width="56" height="10" />
        <rect x="24" y="548" rx="5" ry="5" width="56" height="10" />
        <rect x="260" y="548" rx="5" ry="5" width="56" height="10" />
        <rect x="24" y="130" rx="5" ry="5" width="291" height="10" />
        <rect x="24" y="154" rx="5" ry="5" width="229" height="10" />
        <rect x="24" y="107" rx="5" ry="5" width="229" height="10" />
        <rect x="24" y="190" rx="5" ry="5" width="140" height="10" />
        <rect x="24" y="227" rx="5" ry="5" width="18" height="10" />
        <rect x="53" y="226" rx="5" ry="5" width="111" height="10" />
        <rect x="53" y="272" rx="5" ry="5" width="111" height="10" />
        <rect x="24" y="250" rx="5" ry="5" width="18" height="10" />
        <rect x="53" y="249" rx="5" ry="5" width="263" height="10" />
      </ContentLoader>
    </div>
  );
};

export default TicketSkeleton;
