import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { ItemsWithPaginations } from "@src/types/api/ItemsWithPaginations.types";
import { OrderType } from "@src/types/api/OrderType.types";
import { ClaimingUserDto, ClaimingUsersOrderBy } from "@services/Api/api.dto";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "./SelectedEvent.context";
import { ClaimingUserData, OccasionalNftsSentData, SocketEvents } from "@services/Websocket/Socket.types";
import useSocketSubscribe from "@services/Websocket/useSocketSubscribe";
import { useEvents } from "./EventsData.context";

export interface ClaimingUsersData {
  status: FetchStatus;
  claimingUsers: ItemsWithPaginations<ClaimingUserDto>;
  error: Error | AxiosError | null;
}

interface ContextValue extends ClaimingUsersData {
  fetchClaimingUsersData: (eventId: string, page: number, perPage: number) => Promise<void>;
  refetchClaimingUsersData: (
    eventId: string,
    page: number,
    perPage?: number,
    order?: OrderType,
    orderBy?: ClaimingUsersOrderBy
  ) => Promise<void>;
  addClaimingUser: (newUser: ClaimingUserDto) => void;
  updateClaimingUser: (claimingUserId: string, updatedClaimingUser: ClaimingUserDto) => void;
  getClaimingUser: (claimingUserId: string) => ClaimingUserDto | undefined;
}

const initialClaimingUsersData = (): ClaimingUsersData => ({
  status: "loading",
  claimingUsers: { data: [], meta: { page: 1, perPage: 50, total: 50, totalPages: 1 } },
  error: null
});

const ClaimingUsersDataContext = React.createContext(null as any);

export const ClaimingUsersDataProvider = ({ children }: IContext) => {
  const [claimingUsersData, setClaimingUsersData] = useState<ClaimingUsersData>(initialClaimingUsersData());
  const { event } = useSelectedEvent();
  const { updateEvent } = useEvents();
  const { eventId } = useParams();

  const { getClaimingUsersByEventId } = useApiClient();

  useEffect(() => {
    if (event.id !== eventId || !eventId || !event.occasionalNFTSent) return;
    fetchClaimingUsersData(eventId, 1, 20);
  }, [event, eventId, event.occasionalNFTSent]);

  useSocketSubscribe(SocketEvents.OCCASIONAL_NFTS_SENT, ({ eventId }: OccasionalNftsSentData) => {
    if (eventId === event.id) {
      updateEvent(eventId, { occasionalNFTSent: true });
      fetchClaimingUsersData(eventId, 1, 20);
    }
  });

  useSocketSubscribe(SocketEvents.OCCASIONAL_NFTS_CLAIM_EVENT, ({ claimingUser }: ClaimingUserData) => {
    updateClaimingUser(claimingUser.id, claimingUser);
  });

  useSocketSubscribe(SocketEvents.OCCASIONAL_NFTS_SENT_TO_CLAIMING_USER, ({ claimingUser }: ClaimingUserData) => {
    updateClaimingUser(claimingUser.id, claimingUser);
  });

  const fetchClaimingUsersData = async (eventId: string, page: number, perPage: number) => {
    setClaimingUsersData(initialClaimingUsersData());

    try {
      const { data } = await getClaimingUsersByEventId(eventId, page, perPage);
      return setClaimingUsersData((prevState) => ({ ...prevState, claimingUsers: data, status: "success" }));
    } catch (e: any) {
      setClaimingUsersData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const refetchClaimingUsersData = async (
    eventId: string,
    page: number,
    perPage: number = 20,
    order?: OrderType,
    orderBy?: ClaimingUsersOrderBy
  ) => {
    try {
      const { data } = await getClaimingUsersByEventId(eventId, page, perPage, order, orderBy);
      return setClaimingUsersData((prevState) => ({ ...prevState, claimingUsers: data, status: "success" }));
    } catch (e: any) {
      setClaimingUsersData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const addClaimingUser = useCallback((newUser: ClaimingUserDto) => {
    setClaimingUsersData((prevState) => ({
      ...prevState,
      claimingUsers: {
        ...prevState.claimingUsers,
        data: [...prevState.claimingUsers.data, newUser]
      }
    }));
  }, []);

  const updateClaimingUser = useCallback(
    (claimingUserId: string, updatedClaimingUser: ClaimingUserDto) => {
      const foundClaimingUser = claimingUsersData.claimingUsers.data.find((user) => user.id === claimingUserId);

      if (!foundClaimingUser) return;

      setClaimingUsersData((prevState) => ({
        ...prevState,
        claimingUsers: {
          ...prevState.claimingUsers,
          data: prevState.claimingUsers.data.map((user) => {
            if (user.id === claimingUserId) {
              return { ...user, ...updatedClaimingUser };
            }

            return user;
          })
        }
      }));
    },
    [claimingUsersData]
  );

  const getClaimingUser = (claimingUserId: string) => {
    return claimingUsersData.claimingUsers.data.find((claimingUser) => claimingUser.id === claimingUserId);
  };

  const contextValue: ContextValue = {
    ...claimingUsersData,
    fetchClaimingUsersData,
    refetchClaimingUsersData,
    addClaimingUser,
    updateClaimingUser,
    getClaimingUser
  };

  return <ClaimingUsersDataContext.Provider value={contextValue}>{children}</ClaimingUsersDataContext.Provider>;
};

export const useClaimingUsers = (): ContextValue => useContext(ClaimingUsersDataContext);
