import React from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { useNotification } from "@contexts/Notification.context";
import { TESTNET_PROXY_URL } from "@config/config";
import { IContext } from "src/types/IContext.types";
import { ContextValue } from "./TestnetProxyContext.types";

const TestnetProxyClientContext = React.createContext(null as any);

export const TestnetProxyClientProvider = ({ children }: IContext) => {
  const { notify } = useNotification();
  const { t: responseT } = useTranslation("apiResponse");

  const testnetProxyClient = axios.create({
    baseURL: TESTNET_PROXY_URL,
    headers: {
      "Content-Type": "application/json"
    }
  });

  const _request = async <T,>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return await testnetProxyClient.request<T>(config);
  };

  const mintTickets = async (eventId: string, ticketsCount: number) => {
    try {
      const res = await _request({
        method: "POST",
        url: `/event/${eventId}/mintTickets`,
        data: {
          goal: ticketsCount
        }
      });

      if (res.status === 204) {
        notify("success", responseT("success.testnetGeneratedTickets"));
      }

      return res;
    } catch (e: any) {
      notify("error", responseT("errors.base"));

      throw e;
    }
  };

  const contextValue: ContextValue = {
    mintTickets
  };

  return <TestnetProxyClientContext.Provider value={contextValue}>{children}</TestnetProxyClientContext.Provider>;
};

export const useTestnetProxyClient = (): ContextValue => React.useContext(TestnetProxyClientContext);
