import React from "react";

import { useEvents } from "../../contexts/EventsData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";

import EventNftSkeleton from "../../skeletons/EventNftSkeleton/EventNftSkeleton";
import EventNftAccessDenied from "../../components/EventNftAccessDenied/EventNftAccessDenied";
import EventNftEmptyState from "@app/panel/Events/components/EventNftEmptyState/EventNftEmptyState";

import EditNft from "./sections/EditNft/EditNft";
import NftSettings from "./sections/NftSettings/NftSettings";
import ClaimingUsers from "./sections/ClaimingUsers/ClaimingUsers";

import "./EventNft.scss";

const EventNft = () => {
  const { status: fetchEventsStatus } = useEvents();
  const { event } = useSelectedEvent();

  const hasAccessNftModule = event.page.occasionalNFT;
  const hasGeneratedNft = event.occasionalNFT;

  if (fetchEventsStatus === "loading") return <EventNftSkeleton />;
  if (!hasAccessNftModule) return <EventNftAccessDenied />;
  if (!hasGeneratedNft) return <EventNftEmptyState />;

  return (
    <div className="event-nft">
      <EditNft />
      <NftSettings />
      <ClaimingUsers />
    </div>
  );
};

export default EventNft;
