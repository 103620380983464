import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { ApiKeyAccess } from "@services/Api/api.dto";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useUserData } from "@contexts/UserData.context";
import { useApiKeys } from "@panel/Settings/contexts/ApiKeysContext";
import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Select from "@components/dataEntry/Select/Select";
import DateTimePicker from "@components/dataEntry/DateTimePicker/DateTimePicker";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { accessPrivilegesOptions, validityTypeOptions } from "./utils/selectOptions";
import { createApiKeyValidationSchema } from "./createApiKey.schema";
import "./CreateApiKey.scss";

interface CreateApiKeyForm {
  name: string;
  accessPrivileges: ApiKeyAccess;
  termOfValidity: string;
}

const CreateApiKey = () => {
  const [validityType, setValidityType] = useState<"indefinite" | "definite">("indefinite");
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const { createApiKey } = useApiClient();
  const { organization } = useUserData();
  const { addApiKey } = useApiKeys();
  const { closeModal } = useSettingsModals();

  const { t } = useTranslation(["settingsPage", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const optionsAccessPriviliges = accessPrivilegesOptions(t);
  const optionsValidityType = validityTypeOptions(t);
  const validationSchema = createApiKeyValidationSchema(errorsT, validityType === "definite");

  const handleSetValidityType = (value: string) => {
    switch (value) {
      case "indefinite":
        return setValidityType("indefinite");
      case "definite":
        return setValidityType("definite");
      default:
        return setValidityType("definite");
    }
  };

  const handleCreateApiKey = ({ name, accessPrivileges, termOfValidity }: CreateApiKeyForm) => {
    setSubmitting(true);

    createApiKey(organization?.id || "", name, termOfValidity && dayjs(termOfValidity).format(), accessPrivileges)
      .then((res) => {
        if (res?.status === 201) {
          addApiKey(res.data);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched } =
    useFormik<CreateApiKeyForm>({
      initialValues: {
        name: "",
        accessPrivileges: "" as any,
        termOfValidity: ""
      },
      validationSchema,
      onSubmit: handleCreateApiKey
    });

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack rowGap={25} className="create-api-key-modal">
            <h2>{t("settingsPage:createApiKeyModal.title")}</h2>
            <p className="p0">{t("settingsPage:createApiKeyModal.description")}</p>

            <Group colGap={20} alignItems="flex-start" fullWidth className="mb-0">
              <Input
                label={t("settingsPage:createApiKeyModal.inputs.nameKey.label")}
                placeholder={t("settingsPage:createApiKeyModal.inputs.nameKey.placeholder")}
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name ? errors.name : ""}
                maxLength={20}
                required
              />
              <Select
                label={t("settingsPage:createApiKeyModal.inputs.accessPrivileges.label")}
                placeholder={t("settingsPage:createApiKeyModal.inputs.accessPrivileges.placeholder")}
                options={optionsAccessPriviliges}
                name="accessPrivileges"
                onChange={(value: string) => {
                  setFieldValue("accessPrivileges", value);
                }}
                onBlur={handleBlur}
                onTouched={setFieldTouched}
                error={touched.accessPrivileges && errors.accessPrivileges ? errors.accessPrivileges : ""}
                required
              />
            </Group>
            <Group colGap={20} alignItems="flex-start" fullWidth>
              <Select
                label={t("settingsPage:createApiKeyModal.inputs.validityType.label")}
                placeholder={t("settingsPage:createApiKeyModal.inputs.validityType.placeholder")}
                className="create-api-key-modal-input"
                options={optionsValidityType}
                value={optionsValidityType[0]}
                onChange={handleSetValidityType}
              />
              {validityType === "definite" && (
                <DateTimePicker
                  label={t("settingsPage:createApiKeyModal.inputs.termOfValidity.label")}
                  placeholder={t("settingsPage:createApiKeyModal.inputs.termOfValidity.placeholder")}
                  name="termOfValidity"
                  value={dayjs(values.termOfValidity)}
                  onChange={(value) => {
                    setFieldValue("termOfValidity", value);
                  }}
                  onBlur={handleBlur}
                  error={errors.termOfValidity && touched.termOfValidity ? errors.termOfValidity : ""}
                  required
                />
              )}
            </Group>

            <Group colGap={20} className="mt-3">
              <Button variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("common:create")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:close")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default CreateApiKey;
