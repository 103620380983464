import React from "react";

const RecoveryIcon: React.FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      className={className}
    >
      <path
        d="M9.61538 6.92308V4.23077C9.61538 3.37392 9.275 2.55216 8.66911 1.94627C8.06323 1.34038 7.24147 1 6.38462 1C5.52776 1 4.706 1.34038 4.10012 1.94627C3.49423 2.55216 3.15385 3.37392 3.15385 4.23077V6.92308M2.61538 15H10.1538C10.5823 15 10.9932 14.8298 11.2961 14.5269C11.599 14.2239 11.7692 13.813 11.7692 13.3846V8.53846C11.7692 8.11004 11.599 7.69916 11.2961 7.39621C10.9932 7.09327 10.5823 6.92308 10.1538 6.92308H2.61538C2.18696 6.92308 1.77608 7.09327 1.47314 7.39621C1.17019 7.69916 1 8.11004 1 8.53846V13.3846C1 13.813 1.17019 14.2239 1.47314 14.5269C1.77608 14.8298 2.18696 15 2.61538 15Z"
        stroke="#5C44EC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecoveryIcon;
