import { PINATA_GATEWAY_MAINNET_URL, PINATA_GATEWAY_TESTNET_URL } from "@config/config";
import { SkeyTixPoapV1Meta, parse } from "@skeynetwork/nft-meta-parser";

export const extractIPFSHash = (inputString: string) => {
  const metadata = parse(inputString) as SkeyTixPoapV1Meta;
  if (metadata?.data?.cid) return metadata.data.cid;
  return null;
};

export const parseNftImageUrl = (network: "mainnet" | "testnet" | string, description: string) => {
  const ipfsHash = extractIPFSHash(description);
  if (!ipfsHash) return;

  if (network === "mainnet") {
    return `${PINATA_GATEWAY_MAINNET_URL}/ipfs/${ipfsHash}`;
  }

  return `${PINATA_GATEWAY_TESTNET_URL}/ipfs/${ipfsHash}`;
};
