import React from "react";
import { Navigate } from "react-router-dom";

import { IChildren } from "@src/types/IChildren.types";
import { PATHS } from "src/router/paths";

import { STORAGE_KEYS } from "@config/helpers";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";

const AuthGuard = ({ children }: IChildren) => {
  const isUserLogged = BrowserStorageManager.readSessionStorage(STORAGE_KEYS.USER_LOGGED);

  if (isUserLogged !== "true") {
    return <Navigate to={PATHS.login.path} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
