import Discord from "src/assets/socials/Discord";
import Facebook from "src/assets/socials/Facebook";
import KakaoTalk from "src/assets/socials/KakaoTalk";
import Medium from "src/assets/socials/Medium";
import Reddit from "src/assets/socials/Reddit";
import Telegram from "src/assets/socials/Telegram";
import Twitter from "src/assets/socials/Twitter";
import YouTube from "src/assets/socials/YouTube";

export const TWITTER = "https://twitter.com/skeynetwork";
export const YOUTUBE = "https://www.youtube.com/channel/UC6oK-S1xW9yysYFAXBou6sA";
export const REDDIT = "https://www.reddit.com/r/skeynetwork/";
export const FACEBOOK = "https://www.facebook.com/SkeyNetwork";
export const DISCORD = "https://discord.com/invite/en7YQU4EBR";
export const KAKAO_TALK = "https://open.kakao.com/o/g9fgzwPc";
export const MEDIUM = "https://skeynetwork.medium.com/";
export const TELEGRAM = "https://t.me/SkeyNetworkChat";

export const SOCIALS = [
  {
    title: "Twitter",
    icon: <Twitter />,
    href: TWITTER
  },
  {
    title: "YouTube",
    icon: <YouTube />,
    href: YOUTUBE
  },
  {
    title: "Reddit",
    icon: <Reddit />,
    href: REDDIT
  },
  {
    title: "Facebook",
    icon: <Facebook />,
    href: FACEBOOK
  },
  {
    title: "Discord",
    icon: <Discord />,
    href: DISCORD
  },
  {
    title: "Kakao talk",
    icon: <KakaoTalk />,
    href: KAKAO_TALK
  },
  {
    title: "Medium",
    icon: <Medium />,
    href: MEDIUM
  },
  {
    title: "Telegram",
    icon: <Telegram />,
    href: TELEGRAM
  }
];
