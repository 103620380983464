import React from "react";
import { ShowPasswordButtonProps } from "../PasswordInput.types";
import "./ShowPasswordButton.scss";

const ShowPasswordButton = ({ isVisible, onClick }: ShowPasswordButtonProps) => {
  return (
    <button type="button" onClick={onClick} className="password-button">
      {isVisible ? (
        <img src="/images/icon-eye-off.svg" alt="hide password" />
      ) : (
        <img src="/images/icon-eye.svg" alt="show password" />
      )}
    </button>
  );
};

export default ShowPasswordButton;
