import React from "react";
import { useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Stack from "@components/arrangement/Stack/Stack";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Link from "@components/navigation/Link/Link";

import "./EventAgreements.scss";

const EventAgreements = () => {
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation(["eventsViews", "common"]);

  const handleOpenModal = () => openModal({ type: "editAgreements" });

  const editButtonTip = eventStatus === "started" ? t("common:eventStartedDisabled") : t("common:eventEndedDisabled");
  const editButtonDisabled = eventStatus === "started" || eventStatus === "ended";

  return (
    <div className="event-agreements">
      <h3 className="event-agreements-title">{t("eventsViews:eventAgreements.title")}</h3>

      <div className="event-agreements-content">
        {event.agreements.length > 0 && (
          <Stack rowGap={36} className="event-agreement">
            {event.agreements.map((agreement) => (
              <Stack key={agreement.id} rowGap={5}>
                <p className="p4 black-2">{agreement.name}</p>

                {agreement.url ? (
                  <Link href={agreement.url} target="_blank" withImage={false} className="event-agreement-link">
                    <p>{agreement.url}</p>
                  </Link>
                ) : (
                  <p className="p4">-</p>
                )}
              </Stack>
            ))}
          </Stack>
        )}

        {event.agreements.length <= 0 && <p className="p0 mb-4">{t("eventsViews:eventAgreements.emptyState")}</p>}

        <Tooltip tip={editButtonTip} hide={!editButtonDisabled} width={200} textCenter>
          <Button variant="primary" disabled={editButtonDisabled} onClick={handleOpenModal}>
            {t("common:edit")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default EventAgreements;
