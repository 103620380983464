import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ACCEPT_IMAGE_EXTENSIONS, MAX_SIZE_IMAGE_FILE } from "@config/config";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./ImageDragAndDrop.scss";

interface ImageDragAndDropProps {
  onUpload?: (file: File | null) => void;
  acceptFormats?: string;
  maxSize?: number;
}

const ImageDragAndDrop = ({ onUpload, acceptFormats, maxSize }: ImageDragAndDropProps) => {
  const [dragActive, setDragActive] = React.useState(false);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { t: commonT } = useTranslation("common");
  const { t: formErrorsT } = useTranslation("formErrors");

  const acceptExtensions = acceptFormats || ACCEPT_IMAGE_EXTENSIONS;
  const maxImageSize = maxSize || MAX_SIZE_IMAGE_FILE;
  const maxImageSizeMB = `${maxImageSize / 1024 / 1024}MB`;

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    setErrorMessage("");
    setDragActive(false);

    const files = [...e.dataTransfer.files];

    if (files.length > 1) {
      setErrorMessage(formErrorsT("file.fileCountLimit", { count: 1 }));
      return;
    }

    const file = files[0];
    const formats = acceptExtensions.split(",");

    if (!formats.includes(file.type)) {
      const extensions = acceptExtensions.replaceAll("image/", "").replaceAll(",", ", ");
      setErrorMessage(formErrorsT("file.acceptFormats", { extensions }));
    }

    if (file.size > maxImageSize) {
      setErrorMessage(formErrorsT("file.toBig", { property: maxImageSizeMB }));
    }

    if (onUpload) {
      onUpload(file);
    }
  };

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setErrorMessage("");

    const { files } = e.target;
    if (!files?.length) return;

    const file = files[0];

    if (file.size > maxImageSize) {
      setErrorMessage(formErrorsT("file.toBig", { property: maxImageSizeMB }));
    }

    if (onUpload) {
      onUpload(file);
    }
  };

  const onButtonClick = () => {
    inputRef?.click();
  };

  const labelClasses = classNames("label-file-upload", { "drag-active": dragActive });

  return (
    <div onDragEnter={handleDrag} className="image-drag-and-drop">
      <input
        id="input-file-upload"
        ref={setInputRef}
        type="file"
        accept={acceptExtensions}
        onChange={handleChange}
        className="input-file-upload"
      />

      <label htmlFor="input-file-upload" className={labelClasses}>
        <Stack fullWidth alignItems="center">
          <img src="/images/icon-drag-and-drop.svg" alt="image placeholder" className="mb-2" />
          <p>{commonT("imageDragAndDrop.description")}</p>

          <Button variant="subtle" className="upload-button" onClick={onButtonClick}>
            {commonT("imageDragAndDrop.button")}
          </Button>
        </Stack>

        {errorMessage && <p className="mt-1 p0 danger-1 text-center">{errorMessage}</p>}
      </label>

      {dragActive && (
        <div
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
};

export default ImageDragAndDrop;
