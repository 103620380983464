import React from "react";
import Lottie from "react-lottie-player";
import { useTranslation } from "react-i18next";

import * as nftEmptyList from "../../../../../public/animations/nft-empty-list.json";
import "./ExploreNftsEmptyState.scss";

const ExploreNftsEmptyState = () => {
  const { t } = useTranslation("poapNft");

  return (
    <div className="explore-nfts-emptyState">
      <Lottie play speed={2} loop={false} animationData={nftEmptyList} className="emptyState-animation" />

      <div className="emptyState-info">
        <h2>{t("exploreNfts.emptySatate.title")}</h2>
      </div>
    </div>
  );
};

export default ExploreNftsEmptyState;
