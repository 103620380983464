import { Outlet, RouteObject } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { PATHS } from "../paths";

import { WvsProvider } from "@services/blockchain/Waves.context";
import { BrowseNftsProvider } from "@app/poap-nft/contexts/BrowseNfts.context";
import { PoapNftModalsProvider } from "src/app/poap-nft/contexts/EventPageModals.context";

import PoapBaseLayout from "@components/layouts/PoapBaseLayout/PoapBaseLayout";
import PoapExploreLayout from "@components/layouts/PoapExploreLayout/PoapExploreLayout";
import PoapNftModals from "@app/poap-nft/modals/PoapNftModals";

import PoapNft from "@app/poap-nft/views/PoapNft/PoapNft";
import SearchWallet from "@app/poap-nft/views/SearchWallet/SearchWallet";
import ExploreNfts from "@app/poap-nft/views/ExploreNfts/ExploreNfts";

export const poapnftRoutes: RouteObject = {
  element: (
    <>
      <Helmet>
        <title>SkeyTix Poap NFT</title>
      </Helmet>

      <WvsProvider connectOnLoad>
        <BrowseNftsProvider>
          <PoapNftModalsProvider>
            <Outlet />
            <PoapNftModals />
          </PoapNftModalsProvider>
        </BrowseNftsProvider>
      </WvsProvider>
    </>
  ),
  children: [
    {
      path: PATHS.poapNft.path,
      element: (
        <PoapBaseLayout>
          <PoapNft />
        </PoapBaseLayout>
      )
    },
    {
      path: PATHS.poapNftSearch.path,
      element: (
        <PoapBaseLayout>
          <SearchWallet />
        </PoapBaseLayout>
      )
    },
    {
      path: PATHS.poapNftExplore.path,
      element: (
        <PoapExploreLayout>
          <ExploreNfts />
        </PoapExploreLayout>
      )
    }
  ]
};
