import { ClaimingUserDto } from "@services/Api/api.dto";
import React, { useState } from "react";

import { IContext } from "src/types/IContext.types";

interface ContextValue {
  selectedClaimingUsers: ClaimingUserDto[];
  addClaimingUser: (user: ClaimingUserDto) => void;
  removeClaimingUser: (userId: string) => void;
  checkIsSelectedMember: (userId: string) => boolean;
  clearSelectedClaimingUsers: () => void;
}

const SelectedClaimingUsersContext = React.createContext(null as any);

export const SelectedClaimingUsersProvider = ({ children }: IContext) => {
  const [selectedClaimingUsers, setSelectedClaimingUsers] = useState<ClaimingUserDto[]>([]);

  const addClaimingUser = (user: ClaimingUserDto) => setSelectedClaimingUsers((prevState) => [...prevState, user]);

  const removeClaimingUser = (userId: string) =>
    setSelectedClaimingUsers((prevState) => prevState.filter((user) => user.id !== userId));

  const checkIsSelectedMember = (userId: string) => {
    return !!selectedClaimingUsers.find((user) => user.id === userId);
  };

  const clearSelectedClaimingUsers = () => setSelectedClaimingUsers([]);

  const contextValue: ContextValue = {
    selectedClaimingUsers,
    addClaimingUser,
    removeClaimingUser,
    checkIsSelectedMember,
    clearSelectedClaimingUsers
  };

  return <SelectedClaimingUsersContext.Provider value={contextValue}>{children}</SelectedClaimingUsersContext.Provider>;
};

export const useSelectedClaimingUsers = (): ContextValue => React.useContext(SelectedClaimingUsersContext);
