import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { copyToClipboard } from "@utils/copyToClipboard";

import { ButtonProps } from "../Button/Button.types";
import Button from "../Button/Button";

import "./CopyButton.scss";

export interface CopyButtonProps extends Omit<ButtonProps, "type" | "children"> {
  value: string;
}

const COPY_DELAY = 1000; // 1s

const CopyButton = ({
  value,
  variant = "secondary",
  size = "normal",
  disabled = false,
  loading = false,
  leftIcon,
  rightIcon,
  className = "",
  onClick = () => {},
  onBlur = () => {}
}: CopyButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (!isCopied) return;

    setTimeout(() => {
      setIsCopied(false);
    }, COPY_DELAY);
  }, [isCopied]);

  const handleClick = () => {
    if (isCopied) return;

    onClick && onClick();
    copyToClipboard(value);
    setIsCopied(true);
  };

  const classes = classNames(`copy-button`, className);

  return (
    <Button
      type="button"
      variant={variant}
      size={size}
      disabled={disabled || loading}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      className={classes}
      onClick={handleClick}
      onBlur={onBlur}
    >
      {isCopied ? t("copied") : t("copyToClipboard")}
    </Button>
  );
};

export default CopyButton;
