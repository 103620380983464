import React from "react";
import { useTranslation } from "react-i18next";

import { useEventsModals } from "../../contexts/EventsModals.context";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./NoEvents.scss";

const NoEvents = () => {
  const { t } = useTranslation("eventsViews");
  const { openModal } = useEventsModals();

  const handleOpenModal = () => openModal({ type: "createEvent" });

  return (
    <div className="no-events">
      <img src="/images/no-events-skeleton.png" alt="no events" className="no-events-background" />

      <Stack rowGap={40} alignItems="center" fullSize className="no-events-content">
        <Group colGap={40}>
          <img src="/images/icon-gradient-calendar.svg" alt="calendar icon" />
          <img src="/images/icon-gradient-localization.svg" alt="localization tag icon" />
          <img src="/images/icon-gradient-ticket.svg" alt="ticket icon" />
        </Group>

        <p className="p0 text-center">{t("noEvents.description")}</p>

        <Button variant="primary" onClick={handleOpenModal}>
          {t("noEvents.button")}
        </Button>
      </Stack>
    </div>
  );
};

export default NoEvents;
