import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import QRCodeStyling from "qr-code-styling";

import { TICKET_PREVIEW_QR_URL } from "@config/config";
import { formatDateTime } from "@utils/formatDateTime";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Link from "@components/navigation/Link/Link";

import { TicketProps } from "./Ticket.types";
import "./Ticket.scss";

const qrCode = new QRCodeStyling({
  width: 201,
  height: 201,
  data: TICKET_PREVIEW_QR_URL,
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 6,
    imageSize: 0.4
  },
  type: "svg"
});

const Ticket = ({
  organizationName,
  eventName,
  eventUrl,
  start,
  eventTimezone,
  locationName,
  locationUrl,
  logoUrl,
  demo = false
}: TicketProps) => {
  const qrRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  useEffect(() => {
    if (!qrRef.current) return;

    while (qrRef.current.firstChild) {
      qrRef.current.removeChild(qrRef.current.firstChild);
    }

    qrCode.append(qrRef.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      image: logoUrl
    });
  }, [logoUrl]);

  return (
    <div className="ticket">
      {demo && (
        <div className="ticket-background-demo">
          {[...Array(20)].map((_, index) => (
            <p key={index}>{commonT("demo")}</p>
          ))}
        </div>
      )}
      <div className="ticket-content">
        <Group colGap={32} alignItems="flex-start" justifyContent="space-between" className="ticket-organization">
          <Stack rowGap={7}>
            <p className="organization-header">{t("ticket.organization")}</p>
            <p className="organization-name">{organizationName || "-"}</p>
          </Stack>
          {logoUrl && <img src={logoUrl} alt="organizer logo" className="organization-logo" />}
        </Group>

        <hr className="ticket-horizontal-rule" />

        <Stack rowGap={6} className="ticket-event-name">
          <p className="event-ticket-type">{t("ticket.ticketType")}</p>
          <h1 className="event-title">{eventName || "-"}</h1>
          <Link href={eventUrl || ""} target="_blank" withImage={false} className="event-link">
            {t("ticket.moreInfo")}
          </Link>
        </Stack>

        <Stack rowGap={11} className="ticket-date-location">
          <Group colGap={10} alignItems="flex-start">
            <img src="/images/icon-ticket-calender.svg" alt="event start date" />
            <p>{formatDateTime(start, eventTimezone)}</p>
          </Group>
          <Group colGap={10} alignItems="flex-start">
            <img src="/images/icon-ticket-location.svg" alt="event location" />
            {locationUrl ? (
              <Link href={locationUrl} target="_blank" withImage={false}>
                <p className="event-location-name">{locationName}</p>
              </Link>
            ) : (
              <p className="event-location-name">-</p>
            )}
          </Group>
        </Stack>

        <Group fullWidth justifyContent="center" className="ticket-qr-code">
          <div ref={qrRef} />
        </Group>

        <Group justifyContent="space-between" className="ticket-footer" fullWidth>
          <img src="/images/skey-network-logo-small-gray.svg" alt="skey tix logo" />

          {demo ? (
            <div className="ticket-demo-mark">
              <p>{commonT("demoVersion")}</p>
            </div>
          ) : (
            <p className="footer-skey-branding">skey.network</p>
          )}
        </Group>
      </div>
    </div>
  );
};

export default Ticket;
