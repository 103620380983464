import React from "react";
import { useTranslation } from "react-i18next";

import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./ApiKeysEmptyState.scss";

const ApiKeysEmptyState = () => {
  const { t } = useTranslation("settingsPage");
  const { openModal } = useSettingsModals();

  const handleCreateApiKey = () => openModal({ type: "createApiKey" });

  return (
    <Stack rowGap={35} alignItems="center" className="api-keys-empty-state">
      <img src="/images/api-keys-empty-state.svg" alt="empty state" />
      <p className="p0 description">{t("emptyState.description")}</p>
      <Button variant="primary" onClick={handleCreateApiKey}>
        {t("emptyState.button")}
      </Button>
    </Stack>
  );
};

export default ApiKeysEmptyState;
