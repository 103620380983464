import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useUserData } from "@contexts/UserData.context";
import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";
import UploadImage from "@components/dataEntry/UploadImage/UploadImage";

import { editOrganizationSchema } from "./editOrganization.schema";
import "./EditOrganizationData.scss";

interface EditOrganizationForm {
  name: string;
  email: string;
}

const EditOrganizationData = () => {
  const [organizationLogo, setOrganizationLogo] = useState<File | null>(null);
  const [uploadImageError, setUploadImageError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { organization, updateOrganizationData } = useUserData();
  const { updateOrganization: fetchUpdateOrganization } = useApiClient();
  const { closeModal } = useSettingsModals();

  const { t } = useTranslation(["settingsPage", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const validationSchema = editOrganizationSchema(errorsT);

  const handleUpdateOrganization = ({ name, email }: EditOrganizationForm) => {
    if (uploadImageError) return;

    setSubmitting(true);

    fetchUpdateOrganization(organization!.id, name, email, organizationLogo)
      .then((res) => {
        if (res?.status === 200) {
          updateOrganizationData(res.data);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<EditOrganizationForm>({
    initialValues: {
      name: organization?.name || "",
      email: organization?.email || ""
    },
    validationSchema,
    onSubmit: handleUpdateOrganization
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;

  const handleChangeLogo = (file: File | null) => setOrganizationLogo(file);
  const handleOnUploadError = (error: string) => setUploadImageError(error);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-organization-data-modal">
            <h2>{t("settingsPage:editOrganizationData.title")}</h2>

            <Stack rowGap={30} fullWidth className="mt-6">
              <Group colGap={40} equalWidth fullWidth>
                <Input
                  label={t("settingsPage:editOrganizationData.inputs.name.label")}
                  placeholder={t("settingsPage:editOrganizationData.inputs.name.placeholder")}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name ? errors.name : ""}
                  required
                />
                <Input
                  label={t("settingsPage:editOrganizationData.inputs.email.label")}
                  placeholder={t("settingsPage:editOrganizationData.inputs.email.placeholder")}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email ? errors.email : ""}
                  required
                />
              </Group>

              <Stack rowGap={12}>
                <UploadImage
                  imageUrl={organization?.imageUrl}
                  onChange={handleChangeLogo}
                  onError={handleOnUploadError}
                />

                <p className="p0 danger-1">{uploadImageError}</p>
              </Stack>
            </Stack>

            <Group colGap={20} className="mt-6">
              <Button variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditOrganizationData;
