import React, { useCallback, useMemo } from "react";

import { TicketDto } from "@services/Api/api.dto";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useUserData } from "@contexts/UserData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useTickets } from "@app/panel/Events/contexts/TicketsData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import {
  downloadQrCodeAsPdf,
  downloadQrCodeAsPng,
  downloadTicketAsPdf,
  downloadTicketAsPng
} from "@app/panel/Events/utils/ticketsDownload";

import TicketsTableRender from "./TicketsTableRender/TicketsTableRender";

const TICKETS_PER_PAGE = 20;

const TicketsTable = () => {
  const { organization } = useUserData();
  const { event } = useSelectedEvent();
  const { tickets, refetchTicketsData } = useTickets();
  const { getTicketFile } = useApiClient();
  const { openModal } = useEventsModals();

  const organizerName = useMemo(() => organization?.name || "", []);

  const handleSaveTicketAsPdf = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;
        downloadTicketAsPdf(ticketId, globalId, res.data, event, organizerName);
      });
    },
    []
  );

  const handleSaveTicketAsPng = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;

        downloadTicketAsPng(ticketId, globalId, res.data, event, organizerName);
      });
    },
    []
  );

  const handleSaveQrCodeAsPdf = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;

        downloadQrCodeAsPdf(globalId, res.data, event);
      });
    },
    []
  );

  const handleSaveQrCodeAsPng = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;

        downloadQrCodeAsPng(globalId, res.data, event);
      });
    },
    []
  );

  const handleOpenPreviewTicket = useCallback(
    (ticket: TicketDto) => () => {
      openModal({ type: "ticketPreview", data: ticket });
    },
    []
  );

  const handlePageChange = useCallback((page: number) => {
    refetchTicketsData(event.id, page, TICKETS_PER_PAGE);
  }, []);

  return (
    <TicketsTableRender
      event={event}
      tickets={tickets}
      saveTicketAsPdf={handleSaveTicketAsPdf}
      saveTicketAsPng={handleSaveTicketAsPng}
      saveQrCodeAsPdf={handleSaveQrCodeAsPdf}
      saveQrCodeAsPng={handleSaveQrCodeAsPng}
      openPreviewTicket={handleOpenPreviewTicket}
      onPageChange={handlePageChange}
    />
  );
};

export default TicketsTable;
