import React from "react";

const HorizontalLine: React.FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M1 1H17" stroke="#120E2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HorizontalLine;
