import { PublicEventDto } from "@services/Api/api.dto";
import { organizationMock } from "./organization.mock";

export const publicEventMock: PublicEventDto = {
  id: "",
  name: "",
  start: "",
  end: "",
  expired: false,
  url: "",
  logoUrl: "",
  location: {
    name: "",
    address: "",
    url: ""
  },
  page: {
    content: "",
    imageUrl: "",
    enableSubmissions: false,
    uniqueSubmission: false,
    automaticTicketSending: false,
    submissionsButtonDisabled: false,
    submissionEnd: "",
    occasionalNFT: false
  },
  status: "draft",
  offset: 3600000,
  timezone: "Europe/Warsaw",
  agreements: [],
  organization: organizationMock
};
