import { TFunction } from "i18next";
import * as yup from "yup";
import { TESTNET_MAX_TICKET_GOAL_ENV } from "@config/config";

export const generatedTicketsSchema = (errorsT: TFunction) =>
  yup.object({
    ticketsCount: yup
      .number()
      .min(0, errorsT("number.min", { min: 0 }))
      .max(TESTNET_MAX_TICKET_GOAL_ENV, errorsT("number.max", { max: TESTNET_MAX_TICKET_GOAL_ENV }))
      .required(errorsT("required"))
  });
