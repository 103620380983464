import React from "react";
import { useTranslation } from "react-i18next";

import { IS_DEMO_MODE } from "src/config/helpers";
import { useUserData } from "@contexts/UserData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import Button from "@components/dataEntry/Buttons/Button/Button";

import Ticket from "@app/panel/Events/components/Ticket/Ticket";
import "./TicketSettings.scss";

const TicketSettings = () => {
  const { organization } = useUserData();
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation(["eventsViews", "common"]);

  const handleOpenModal = () => openModal({ type: "editTicketSettings" });

  const editButtonTip = eventStatus === "started" ? t("common:eventStartedDisabled") : t("common:eventEndedDisabled");
  const editButtonDisabled = eventStatus === "started" || eventStatus === "ended";

  const imageSource = event.logoUrl ? event.logoUrl : "/images/event-image-placeholder.svg";
  const imageClass = `${event.logoUrl ? "logo-image" : "logo-placeholder"}`;

  return (
    <div className="ticket-settings">
      <h3 className="ticket-settings-title">{t("eventsViews:ticketSettings.title")}</h3>
      <Group colGap={20} alignItems="flex-start" className="ticket-settings-content">
        <div className="ticket-settings-edit">
          <p className="p0">{t("eventsViews:ticketSettings.description")}</p>

          <Stack rowGap={20} className="ticket-settings-logo mt-3 mb-5">
            <p className="p4 black-2 ">{t("eventsViews:ticketSettings.logoHeader")}</p>
            <img src={imageSource} alt="event image" className={imageClass} />
          </Stack>

          <Tooltip tip={editButtonTip} hide={eventStatus === "draft"} width={200} textCenter>
            <Button variant="primary" disabled={editButtonDisabled} onClick={handleOpenModal}>
              {t("common:edit")}
            </Button>
          </Tooltip>
        </div>

        <Ticket
          organizationName={organization?.name}
          eventName={event.name}
          eventUrl={event.url}
          start={event.start}
          eventTimezone={event.timezone}
          locationName={event.location.name}
          locationUrl={event.location.url}
          logoUrl={event.logoUrl}
          demo={IS_DEMO_MODE}
        />
      </Group>
    </div>
  );
};

export default TicketSettings;
