import React from "react";
import { useDropdownDataContext } from "../Dropdown";

interface DropdownMenuItemProps {
  children: string;
  disabled?: boolean;
  onClick?: () => void;
}

const DropdownMenuItem = ({ children, disabled = false, onClick = () => {} }: DropdownMenuItemProps) => {
  const { handleCloseMenu } = useDropdownDataContext();

  const handleClick = () => {
    onClick();
    handleCloseMenu();
  };

  return (
    <button type="button" className="dropdown-menu-item" onClick={handleClick} disabled={disabled}>
      <p className="p4">{children}</p>
    </button>
  );
};

export default DropdownMenuItem;
