import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./SendAllTicekts.scss";

const SendAllTicekts = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { event } = useSelectedEvent();
  const { closeModal } = useEventsModals();
  const { sendTickets } = useApiClient();

  const { t } = useTranslation(["eventsModals", "common"]);

  const handleSendGroupTickets = () => {
    setSubmitting(true);

    sendTickets(event.id)
      .then((res) => {
        if (res.status === 204) {
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="send-all-tickets-modal">
            <p className="p0 mt-3">{t("eventsModals:sendAllTickets.description")}</p>

            <Group colGap={10} className="mt-3" alignItems="flex-start">
              <img src="/images/icon-notification-info.svg" alt="send group tickets info" />
              <p className="p0">{t("eventsModals:sendAllTickets.info")}</p>
            </Group>

            <Group colGap={20} className="mt-5">
              <Button type="submit" variant="primary" onClick={handleSendGroupTickets} disabled={submitting}>
                {t("common:send")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SendAllTicekts;
