import * as ics from "ics";
import dayjs from "dayjs";
import { Buffer } from "buffer";

export type CalendarLinkType = "google" | "icalendar" | "outlook";

export interface CalendarLink<T extends CalendarLinkType = CalendarLinkType> {
  type: T;
  url: string;
}

export interface CalendarEventData {
  title: string;
  description: string;
  location: string;
  url: string;
  startUTC: number;
  endUTC: number;
  timezone: string;
  organizerName: string;
  organizerEmail: string;
}

export const createCalendarLinks = (data: CalendarEventData): CalendarLink[] => {
  return [createGoogleCloudLink(data), createICalendarLink(data), createOutlookLink(data)];
};

export const createICalendarLink = (data: CalendarEventData): CalendarLink<"icalendar"> => {
  const event = ics.createEvent({
    title: data.title ?? "Unknown title",
    description: data.description ?? "Empty",
    location: data.location ?? "Unknown location",
    start: ics.convertTimestampToArray(data.startUTC, "utc"),
    startInputType: "utc",
    end: ics.convertTimestampToArray(data.endUTC, "utc"),
    endInputType: "utc",
    url: data.url ?? "",
    organizer: { name: data.organizerName ?? "", email: data.organizerEmail ?? "" },
    busyStatus: "BUSY"
  });

  if (event.error) {
    // eslint-disable-next-line no-console
    console.error(event.error);
  }

  const base64 = Buffer.from(event.value ?? "", "utf8").toString("base64");

  return {
    type: "icalendar",
    url: `data:text/calendar;base64,${base64}`
  };
};

export const createGoogleCloudLink = (data: CalendarEventData): CalendarLink<"google"> => {
  const base = "https://calendar.google.com/calendar/u/0/r/eventedit";

  const dates = [data.startUTC, data.endUTC].map((timestamp) => dayjs(timestamp).format("YYYYMMDDTHHmmss")).join("/");

  const params = new URLSearchParams({
    text: data.title,
    dates,
    ctz: data.timezone,
    details: data.description,
    location: data.location ?? "Unknown location",
    crm: "BUSY",
    pli: "1",
    uid: `${Date.now().toString()}tix`,
    sf: "true",
    output: "xml"
  });

  return {
    type: "google",
    url: `${base}?${params.toString()}`
  };
};

export const createOutlookLink = (data: CalendarEventData): CalendarLink<"outlook"> => {
  const base = "https://outlook.live.com/calendar/0/action/compose";

  const params = new URLSearchParams({
    subject: data.title,
    body: data.description,
    startdt: new Date(data.startUTC).toISOString(),
    enddt: new Date(data.endUTC).toISOString(),
    location: data.location ?? "Unknown location",
    path: "/calendar/action/compose",
    rru: "addevent"
  });

  return {
    type: "outlook",
    url: `${base}?${params.toString()}`
  };
};
