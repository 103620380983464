import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./TicketsTableSkeleton.scss";

const TicketsTableSkeleton = () => {
  return (
    <div className="tickets-table-skeleton">
      <ContentLoader width={720} height={300} viewBox="0 0 720 300">
        <rect x="0" y="0" rx="5" ry="5" width="720" height="30" />

        <rect x="0" y="50" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="50" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="50" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="50" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="100" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="100" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="100" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="100" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="150" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="150" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="150" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="150" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="200" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="200" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="200" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="200" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="250" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="250" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="250" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="250" rx="5" ry="5" width="30" height="30" />
      </ContentLoader>
    </div>
  );
};

export default TicketsTableSkeleton;
