import { SKEY_TIX_API_URL } from "@config/config";

export const SKEY_NETWORK = "https://skey.network/";

export const SKEY_TIX_MAIL = "tix@skey.network";
export const SKEY_TIX_TELEGRAM_NAME = "SkeyTix_Team";
export const SKEY_TIX_TELEGRAM = "https://t.me/SkeyTix_Team";

export const LOG_IN_READ_MORE_URL = "https://skey.network/products/skey-tix";

export const DOCUMENTATION_API_URL = SKEY_TIX_API_URL.slice(0, -2);
export const TIX_APP_ANDROID = "https://play.google.com/store/apps/details?id=network.skey.nftticket.app&pli=1";
export const TIX_APP_IOS = "https://apps.apple.com/us/app/skeytix/id1662473117";

export const SKEY_KEEPER_CHROME =
  "https://chrome.google.com/webstore/detail/skey-keeper/dcigkldapeipokagfmcjaijmngcagkcp";
