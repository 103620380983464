import { RouteObject } from "react-router-dom";

import { PATHS } from "../paths";

import NotFoundLayout from "@components/layouts/NotFoundLayout/NotFoundLayout";
import NotFound from "@app/common/NotFound/NotFound";

export const commonRoutes: RouteObject = {
  path: PATHS.notFound.path,
  element: (
    <NotFoundLayout>
      <NotFound />
    </NotFoundLayout>
  )
};
