import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CropperRef } from "react-advanced-cropper";
import { useFormik } from "formik";
import axios from "axios";

import { parseDataURLtoFile } from "@utils/parseDataURLtoFile";
import { captureCroppedImage } from "../../components/ImageCropper/utils/captureCroppedImage";

import { EventDto } from "@services/Api/api.dto";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useEvents } from "../../contexts/EventsData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedEvent } from "../../contexts/SelectedEvent.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";
import ImageCropper from "../../components/ImageCropper/ImageCropper";

import { editEventNftSchema } from "./editEventNft.schema";
import "./EditEventNft.scss";

interface EditEventNftForm {
  name: string;
}

const EditEventNft = () => {
  const cropperRef = useRef<CropperRef>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [imageInfo, setImageInfo] = useState<{ name: string; type: string }>({ name: "", type: "" });
  const [imageError, setImageError] = useState("");

  const { event } = useSelectedEvent();
  const { updateOccasionalNft } = useApiClient();
  const { updateEvent, getEvent } = useEvents();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const handleGetCroppedImage = async () => {
    const current = cropperRef?.current;

    if (!current) return;

    const imageDataUrl = captureCroppedImage(cropperRef);
    return await parseDataURLtoFile(imageDataUrl || "", imageInfo.name, imageInfo.type);
  };

  const handleGetImageInfo = (file: File) => {
    setImageInfo({ name: file.name, type: file.type });
  };

  useEffect(() => {
    let filename = "";
    let extension = "";

    const imageUrl = event.occasionalNFT?.imageUrl;

    if (!imageUrl) return;

    axios.get<Blob>(imageUrl, { responseType: "blob" }).then((res) => {
      switch (res.status) {
        case 200:
          // eslint-disable-next-line no-case-declarations
          const header = res.headers["content-disposition"];
          // eslint-disable-next-line no-case-declarations
          const encodedFilename = header!.split(/"/)[1];

          filename = decodeURI(encodedFilename);
          extension = `image/${filename.split(".")[1]}`;
          setImageInfo({ name: filename, type: extension });

          return res.data;
        default:
          return undefined;
      }
    });
  }, [event]);

  const validationSchema = editEventNftSchema(t, errorsT);
  const handleSetImageError = (error: string) => setImageError(error);

  const handleEditNft = async ({ name }: EditEventNftForm) => {
    setSubmitting(true);
    const croppedImage = await handleGetCroppedImage();

    if (!event.id || !croppedImage || imageError) {
      setSubmitting(false);
      return;
    }
    updateOccasionalNft(event.id || "", { name, image: croppedImage })
      .then((res) => {
        if (res?.status === 200) {
          const eventToUpdate = getEvent(event.id);
          const updatedEvent = { ...eventToUpdate, occasionalNFT: res.data } as EventDto;
          updateEvent(event.id, updatedEvent);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<EditEventNftForm>({
    initialValues: {
      name: event.occasionalNFT?.name || ""
    },
    validationSchema,
    onSubmit: handleEditNft
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;
  const disableSubmit = imageError.length > 0;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-event-nft-modal">
            <ScrollShadowWrapper contentClassName="create-event-nft-modal-content">
              <h2>{t("eventsModals:editEventlNft.title")}</h2>
              <p className="p0 mt-2">{t("eventsModals:editEventlNft.description")}</p>

              <Group colGap={10} alignItems="flex-start" className="mt-3 mb-4">
                <img src="/images/icon-notification-warning.svg" alt="create nft warning info" />
                <p className="p0">{t("eventsModals:editEventlNft.warningInfo")}</p>
              </Group>

              <Stack rowGap={15} fullWidth>
                <Stack fullWidth>
                  <h3 className="mb-3">
                    {t("eventsModals:editEventlNft.image")}
                    <span className="danger-1">*</span>
                  </h3>
                  <ImageCropper
                    cropperRef={cropperRef}
                    imageFile={event.occasionalNFT?.imageUrl}
                    getOriginalImage={handleGetImageInfo}
                    onError={handleSetImageError}
                  />
                </Stack>

                <Input
                  label={t("eventsModals:editEventlNft.inputs.name.label")}
                  placeholder={t("eventsModals:editEventlNft.inputs.name.placeholder")}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name ? errors.name : ""}
                  maxBytes={16}
                  required
                />
              </Stack>
            </ScrollShadowWrapper>

            <Group colGap={20} className="mt-4">
              <Button
                type="submit"
                variant="primary"
                onClick={handleSubmit}
                loading={submitting}
                disabled={disableSubmit}
              >
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditEventNft;
