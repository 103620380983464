import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const YouTube = ({ width = 32, height = 22, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 22"
      fill="none"
      className={className}
    >
      <path
        d="M30.7296 3.51869C30.3687 2.17847 29.312 1.12176 27.9718 0.760929C25.5233 0.0908203 15.7294 0.0908203 15.7294 0.0908203C15.7294 0.0908203 5.93553 0.0908203 3.48705 0.735156C2.17261 1.09598 1.09012 2.17847 0.729296 3.51869C0.0849609 5.96716 0.0849609 11.0445 0.0849609 11.0445C0.0849609 11.0445 0.0849609 16.1477 0.729296 18.5704C1.09012 19.9106 2.14683 20.9673 3.48705 21.3281C5.9613 21.9982 15.7294 21.9982 15.7294 21.9982C15.7294 21.9982 25.5233 21.9982 27.9718 21.3539C29.312 20.9931 30.3687 19.9364 30.7296 18.5961C31.3739 16.1477 31.3739 11.0703 31.3739 11.0703C31.3739 11.0703 31.3997 5.96716 30.7296 3.51869ZM12.6108 15.7353V6.35376L20.7552 11.0445L12.6108 15.7353Z"
        fill={color}
      />
    </svg>
  );
};

export default YouTube;
