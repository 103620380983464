import React from "react";
import { useTranslation } from "react-i18next";

import { SKEY_TIX_MAIL, SKEY_TIX_TELEGRAM, SKEY_TIX_TELEGRAM_NAME } from "src/constants/constants";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Link from "@components/navigation/Link/Link";

import HelpdeskModal from "../../modals/HelpdeskModal/HelpdeskModal";
import "./Helpdesk.scss";

const Helpdesk = () => {
  const [visibleHelpdeskModal, setHelpdeskModal] = React.useState<boolean>(false);
  const { t } = useTranslation("helpPage");

  const showHelpdeskModal = () => setHelpdeskModal(true);
  const closeHelpdeskModal = () => setHelpdeskModal(false);

  return (
    <>
      <Group alignItems="flex-start" colGap={40} className="helpdesk-section">
        <h2 className="title">{t("helpdesk.title")}</h2>
        <div className="content">
          <hr />
          <p className="p0 mt-3">{t("helpdesk.description")}</p>
          <Group colGap={50} className="mt-5">
            <Button variant="primary" onClick={showHelpdeskModal}>
              {t("helpdesk.sendButton")}
            </Button>
            <Link href={`mailto:${SKEY_TIX_MAIL}`} title="Email tix@skey.network" target="_blank" imageType="email">
              {SKEY_TIX_MAIL}
            </Link>
            <Link href={SKEY_TIX_TELEGRAM} title="Telegram SkeyTix_team" target="_blank" imageType="telegram">
              {SKEY_TIX_TELEGRAM_NAME}
            </Link>
          </Group>
        </div>
      </Group>

      {visibleHelpdeskModal && <HelpdeskModal onClose={closeHelpdeskModal} />}
    </>
  );
};

export default Helpdesk;
