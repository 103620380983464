import React from "react";
import classNames from "classnames";

import CloseImagePreview from "src/assets/CloseImagePreview";
import { useImageViewer } from "./useImageViewer";

import "./ImageViewer.scss";

interface ImageViewerProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageViewer = ({ src, alt, className }: ImageViewerProps) => {
  const { isPreviewOpen, showPreview, dontClosePreview, closePreview } = useImageViewer();

  const classes = classNames("image-viewer-image-body", className);

  return (
    <>
      <div onClick={showPreview} className={classes}>
        <img src={src} alt={alt} className="image-box" />
        <div className="image-overlay">
          <img src="/images/icon-image-loupe.svg" alt="" className="overlay-icon" />
        </div>
      </div>

      {isPreviewOpen && (
        <div onClick={closePreview} className="image-viewer-preview">
          <div onClick={closePreview} className="image-viewer-closeBtn">
            <CloseImagePreview />
          </div>
          <div onClick={dontClosePreview}>
            <img src={src} alt={alt} />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageViewer;
