import React from "react";
import classNames from "classnames";

import { TextareaProps } from "./Textarea.types";
import "./Textarea.scss";

const Textarea = ({
  id,
  name,
  value,
  label = "",
  placeholder = "",
  description = "",
  error = "",
  disabled = false,
  required = false,
  maxLength,
  rows = 3,
  onChange,
  onBlur,
  className = ""
}: TextareaProps) => {
  const textareaId = React.useId();
  const classes = classNames("textarea", className);

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id || name || textareaId} className="textarea-label">
          {label}
          {required && <span className="label-asterisk">*</span>}
        </label>
      )}
      {description && <p className="textarea-description">{description}</p>}

      <div className="relative">
        <textarea
          id={id}
          name={name || textareaId}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          className={error && "textarea-error"}
        />

        {maxLength && (
          <span className="textarea-char-counter">
            {value?.length}/{maxLength}
          </span>
        )}
      </div>

      {error && <p className="textarea-error-text">{error}</p>}
    </div>
  );
};

export default Textarea;
