import React, { memo } from "react";
import classNames from "classnames";

import { SwitchInputProps } from "./SwitchInput.types";
import "./SwitchInput.scss";

const SwitchInput = ({
  id,
  name,
  value,
  label,
  checked,
  defaultChecked,
  disabled = false,
  required = false,
  readonly = false,
  error = "",
  onChange,
  className = "",
  labelTextSize = "normal"
}: SwitchInputProps) => {
  const inputId = React.useId();

  const switchWrapperClasses = classNames("switch-input-wrapper", className);
  const switchInputClasses = classNames(
    "switch-input",
    { "switch-input-disabled": disabled, "switch-input-error": error },
    className
  );
  const labelClasses = classNames(`label-text-${labelTextSize}`, { "label-disabled": disabled });

  return (
    <div className={switchWrapperClasses}>
      <label htmlFor={id || name || inputId} className={switchInputClasses}>
        <input
          type="checkbox"
          id={id || name || inputId}
          name={name || inputId}
          value={value}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          readOnly={readonly}
          className="switch-input-switch"
        />

        {label && (
          <div className={labelClasses}>
            {label}
            {required && <span className="label-asterisk">*</span>}
          </div>
        )}
      </label>

      {error && <p className="switch-input-error-text">{error}</p>}
    </div>
  );
};

export default memo(SwitchInput);
