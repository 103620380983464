import React, { useState } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PATHS } from "@router/paths";
import { HttpError } from "@services/httpErrors.enum";
import { FetchStatus } from "@src/types/api/FetchStatus.types";

import { useApiClient } from "@services/Api/ApiClientContext";
import { sleep } from "@utils/sleep";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Spinner from "@components/ux/Spinner/Spinner";
import Dots from "@components/common/Dots/Dots";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./ActivateAccount.scss";

interface ActivateAccountData {
  status?: FetchStatus;
  error: string | null;
}

const ACTIVATION_TOKEN = "token";

const initialActivateAccountData = () => ({
  status: undefined,
  error: null
});

const ActivateAccount = () => {
  const [activateAccountData, setActivateAccountData] = useState<ActivateAccountData>(initialActivateAccountData());
  const [resendEmailLoading, setResendEmailLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { activateUser, resendActivationMail } = useApiClient();
  const activationToken = searchParams.get(ACTIVATION_TOKEN);

  const { t } = useTranslation(["authViews", "common"]);
  const { t: responseT } = useTranslation("apiResponse");

  const goToLogin = () => navigate(PATHS.login.path);

  const handleSetStatus = (status: FetchStatus | undefined) => {
    setActivateAccountData((prevState) => ({ ...prevState, status }));
  };

  const handleSetError = (error: string | null) => {
    setActivateAccountData((prevState) => ({ ...prevState, error }));
  };

  const handleActivate = async () => {
    try {
      setActivateAccountData(initialActivateAccountData());
      handleSetStatus("loading");
      await sleep(300);

      if (!activationToken) {
        handleSetStatus("failed");
        handleSetError(responseT("errors.activateUserBase"));
        return;
      }

      const res = await activateUser(activationToken);

      if (res.status === 204) {
        handleSetStatus("success");
      }
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      handleSetStatus("failed");
      if (e.response?.status === 400 && e.response?.data?.message === HttpError.USER_ALREADY_ACTIVATED) {
        handleSetError(responseT("errors.activateUserAlreadyActivated"));
      } else if (e.response?.status === 400 && e.response?.data?.message === HttpError.JWT_EXPIRED) {
        handleSetError(responseT("errors.activateUserJwtExpired"));
      } else {
        handleSetError(responseT("errors.activateUserBase"));
      }
    }
  };

  const handleResendEmail = () => {
    setResendEmailLoading(true);

    resendActivationMail(activationToken || "").finally(() => setResendEmailLoading(false));
  };

  const beforeActivation = activateAccountData.status === "idle" || activateAccountData.status === undefined;
  const loading = activateAccountData.status === "loading";
  const successActivation = activateAccountData.status === "success";
  const errorActivation = activateAccountData.error;

  return (
    <div className="activate-account-page">
      <Stack rowGap={30} fullSize>
        <h2>{t("authViews:activateAccount.title")}</h2>

        {beforeActivation && (
          <Stack rowGap={48} fullSize>
            <p className="p0">{t("authViews:activateAccount.description")}</p>

            <Button variant="primary" size="big" loading={loading} onClick={handleActivate}>
              {t("common:activate")}
            </Button>
          </Stack>
        )}

        {loading && (
          <Group colGap={20}>
            <Spinner sizePx={36} />
            <p className="p0 relative">
              {t("authViews:activateAccount.loadingInfo")} <Dots />
            </p>
          </Group>
        )}

        {successActivation && (
          <Stack rowGap={48} fullSize>
            <Group colGap={20} className="info-message">
              <img src="/images/icon-success.svg" alt="activation account success" width={36} height={36} />
              <p className="p0">{t("authViews:activateAccount.successInfo")}</p>
            </Group>

            <Button variant="primary" size="big" loading={loading} onClick={goToLogin}>
              {t("common:goToLogin")}
            </Button>
          </Stack>
        )}

        {errorActivation && (
          <Stack rowGap={48} fullSize>
            <Group colGap={20} className="info-message">
              <img src="/images/icon-error.svg" alt="activation account error" width={36} height={36} />
              <p className="p0">{errorActivation}</p>
            </Group>

            <Group colGap={20}>
              <Button variant="primary" size="big" loading={loading} onClick={handleActivate}>
                {t("common:tryAgain")}
              </Button>

              <Button variant="secondary" size="big" loading={resendEmailLoading} onClick={handleResendEmail}>
                {t("common:resend")}
              </Button>
            </Group>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default ActivateAccount;
