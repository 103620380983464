import React from "react";
import { useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useUserData } from "@contexts/UserData.context";

import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Dots from "@components/common/Dots/Dots";
import DemoMark from "@components/common/DemoMark/DemoMark";

import "./Header.scss";

const Header = () => {
  const { logout } = useApiClient();
  const { user, status } = useUserData();

  const { t } = useTranslation("common");

  const loading = () => (
    <p className="p4 relative">
      {t("loading")} <Dots />
    </p>
  );

  return (
    <div className="header">
      <Group justifyContent="space-between" className="header-content">
        <DemoMark>
          <img src="/images/skey-tix-logo.svg" alt="skey tix logo" />
        </DemoMark>

        <Group colGap={30}>
          <Group colGap={10} className="logged-user">
            <img src="/images/icon-logged-user.svg" alt="logged user" />
            {status === "loading" ? loading() : <p className="p4">{user.email}</p>}
          </Group>
          <Button variant="secondary" onClick={logout}>
            {t("logout")}
          </Button>
        </Group>
      </Group>
    </div>
  );
};

export default Header;
