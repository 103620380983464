import React from "react";

import { Router } from "src/router/router";

import "src/translations/i18n";

import "@scss/utils.scss";
import "@scss/base.scss";
import "@scss/lib.scss";
import "@scss/variables.scss";
import "@scss/mixins.scss";
import "@scss/responsive.scss";

const App = () => {
  return <Router />;
};

export default App;
