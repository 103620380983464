import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const Medium = ({ width = 31, height = 18, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 18"
      fill="none"
      className={className}
    >
      <path
        d="M17.4071 9.04419C17.4166 4.35672 13.6647 0.566643 9.02498 0.57619C4.37569 0.585737 0.614258 4.35672 0.614258 9.03464C0.614258 13.703 4.36615 17.5026 9.01543 17.5122C13.6456 17.5217 17.4071 13.7317 17.4071 9.04419ZM26.8488 9.05374C26.7152 8.03223 26.6483 7.00118 26.4383 5.98922C26.1137 4.40445 25.5409 2.90561 24.2807 1.77909C23.2783 0.881687 21.8845 0.824406 20.9107 1.75044C20.3952 2.24688 19.8892 2.82923 19.5933 3.46887C17.9894 6.86752 17.9799 10.3426 19.2305 13.8462C19.5742 14.8009 20.0993 15.641 20.8725 16.3188C21.8559 17.1876 23.2211 17.2544 24.1662 16.3761C24.6913 15.8892 25.1686 15.2973 25.5123 14.6768C26.467 12.9393 26.7534 11.0299 26.8488 9.05374ZM27.6317 9.01555C27.7558 10.4094 27.8226 11.8032 28.0135 13.1875C28.1376 14.0563 28.3954 14.925 28.7009 15.746C28.9205 16.3475 29.4074 16.3284 29.6938 15.7556C29.9038 15.3164 30.0852 14.8486 30.1807 14.3713C30.868 10.8199 30.868 7.26849 30.1807 3.71708C30.0852 3.2111 29.8752 2.71467 29.6365 2.24688C29.4074 1.79818 29.0064 1.79818 28.7582 2.23733C28.5482 2.6192 28.3763 3.04881 28.2713 3.46887C27.8417 5.28276 27.7176 7.14438 27.6317 9.01555Z"
        fill={color}
      />
      <path
        d="M17.4071 9.04419C17.4071 13.7317 13.6456 17.5217 9.01543 17.5122C4.36615 17.5026 0.614258 13.7126 0.614258 9.03464C0.614258 4.35672 4.37569 0.585737 9.02498 0.57619C13.6647 0.566643 17.4166 4.35672 17.4071 9.04419Z"
        fill={color}
      />
      <path
        d="M26.8486 9.0542C26.7531 11.0304 26.4667 12.9397 25.512 14.6773C25.1683 15.3073 24.691 15.8897 24.1659 16.3766C23.2112 17.2549 21.8556 17.1881 20.8723 16.3193C20.099 15.6415 19.5644 14.8014 19.2302 13.8467C17.9891 10.343 17.9987 6.85843 19.593 3.46932C19.8985 2.82969 20.3949 2.23779 20.9105 1.7509C21.8842 0.824864 23.2781 0.882145 24.2805 1.77954C25.5407 2.90606 26.1039 4.39536 26.4381 5.98968C26.6481 6.99209 26.7149 8.03269 26.8486 9.0542Z"
        fill={color}
      />
      <path
        d="M27.6309 9.01546C27.7168 7.14429 27.8409 5.29221 28.2705 3.47833C28.3755 3.04872 28.5473 2.62866 28.7574 2.24679C29.0056 1.80764 29.397 1.79809 29.6357 2.25634C29.8744 2.71458 30.0748 3.22056 30.1799 3.72654C30.8768 7.27795 30.8768 10.8294 30.1799 14.3808C30.0844 14.8581 29.9125 15.3354 29.693 15.765C29.4066 16.3474 28.9292 16.3569 28.7001 15.7555C28.3946 14.9345 28.1368 14.0657 28.0127 13.197C27.8218 11.8031 27.755 10.3997 27.6309 9.01546Z"
        fill={color}
      />
    </svg>
  );
};

export default Medium;
