import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./EventViewSkeleton.scss";

const EventViewSkeleton = () => {
  return (
    <div className="event-view-skeleton">
      <ContentLoader width={720} height={730} viewBox="0 0 720 730">
        <rect x="0" y="20" rx="5" ry="5" width="570" height="40" />
        <rect x="600" y="20" rx="5" ry="5" width="120" height="40" />

        <rect x="0" y="80" rx="5" ry="5" width="570" height="40" />
        <rect x="600" y="80" rx="5" ry="5" width="120" height="40" />

        <rect x="0" y="140" rx="5" ry="5" width="720" height="140" />
        <rect x="0" y="290" rx="5" ry="5" width="720" height="60" />

        {/* Tickets table */}
        <rect x="0" y="390" rx="5" ry="5" width="720" height="30" />

        <rect x="0" y="440" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="440" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="440" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="440" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="490" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="490" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="490" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="490" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="540" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="540" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="540" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="540" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="590" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="590" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="590" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="590" rx="5" ry="5" width="30" height="30" />

        <rect x="0" y="640" rx="5" ry="5" width="570" height="30" />
        <rect x="590" y="640" rx="5" ry="5" width="30" height="30" />
        <rect x="640" y="640" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="640" rx="5" ry="5" width="30" height="30" />
      </ContentLoader>
    </div>
  );
};

export default EventViewSkeleton;
