import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { PATHS } from "src/router/paths";
import { LOG_IN_READ_MORE_URL } from "src/constants/constants";
import { useApiClient } from "@services/Api/ApiClientContext";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";
import PasswordInput from "@components/dataEntry/PasswordInput/PasswordInput";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { loginValidationSchema } from "./LoginForm.schema";
import "./LoginPage.scss";

interface LoginForm {
  email: string;
  password: string;
}

const LoginPage = () => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { t } = useTranslation("authViews");
  const { t: errorsT } = useTranslation("formErrors");
  const navigate = useNavigate();
  const { login } = useApiClient();

  const initialValues = {
    email: "",
    password: ""
  };

  const validationSchema = loginValidationSchema(errorsT);

  const onFormSubmit = (props: LoginForm) => {
    setSubmitting(true);

    // eslint-disable-next-line react/prop-types
    const { email, password } = props;
    login(email, password).finally(() => setSubmitting(false));
  };

  const { values, touched, errors, setFieldTouched, handleChange, handleBlur, handleSubmit } = useFormik<LoginForm>({
    initialValues,
    validationSchema,
    onSubmit: onFormSubmit
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name, false);
    handleChange(e);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  const goToRegister = () => navigate(PATHS.register.path);

  return (
    <div className="login-page">
      <h1 className="mb-2 login-page-title">{t("loginPage.title")}</h1>

      <Link href={LOG_IN_READ_MORE_URL} target="_blank" title={t("loginPage.link")} className="mb-6">
        {t("loginPage.link")}
      </Link>

      <form onSubmit={onSubmit}>
        <Stack rowGap={20} className="login-page-inputs">
          <Input
            name="email"
            label={t("loginPage.labels.email")}
            placeholder={t("loginPage.placeholders.email")}
            autoComplete="email"
            className="login-page-input"
            value={values.email}
            onChange={onChange}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : ""}
          />
          <PasswordInput
            name="password"
            label={t("loginPage.labels.password")}
            placeholder={t("loginPage.placeholders.password")}
            className="login-page-input mb-4"
            value={values.password}
            onChange={onChange}
            onBlur={handleBlur}
            error={errors.password && touched.password ? errors.password : ""}
          />
          <Group colGap={20}>
            <Button type="submit" variant="primary" size="big" loading={submitting}>
              {t("loginPage.submit")}
            </Button>
            <Button variant="secondary" size="big" onClick={goToRegister}>
              {t("loginPage.createAccount")}
            </Button>
          </Group>

          <Link href={PATHS.resetPassword.path} target="_parent" imageType="recovery" className="mt-2">
            {t("loginPage.resetPassword")}
          </Link>
        </Stack>
      </form>
    </div>
  );
};

export default LoginPage;
