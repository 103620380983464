import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SubmissionDto } from "@services/Api/api.dto";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";

import { formatDateTime } from "@utils/formatDateTime";
import { formatTicketGlobalId } from "@app/panel/Events/utils/formatTicketGlobalId";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Button from "@components/dataEntry/Buttons/Button/Button";

import AgreementCheckbox from "@app/panel/Events/components/AgreementCheckbox/AgreementCheckbox";
import "./SubmissionDetails.scss";

interface SubmissionDetailsProps {
  submission: SubmissionDto;
}

const SubmissionDetails = ({ submission }: SubmissionDetailsProps) => {
  const { firstName, lastName, email, note, acceptedAgreements, ticket, ticketLastSentAt, registeredAt } = submission;

  const { event } = useSelectedEvent();
  const { closeModal } = useEventsModals();

  const eventAgreements = useMemo(() => {
    return event.agreements.sort((a, b) => a.index - b.index);
  }, [event.agreements]);

  const { t } = useTranslation(["eventsModals", "common"]);

  const formattedRegisteredDate = formatDateTime(registeredAt, event.timezone);
  const formattedSentTicketDate = ticketLastSentAt
    ? formatDateTime(ticketLastSentAt, event.timezone)
    : t("eventsModals:submissionDetails.notSent");

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="submission-details-modal">
            <ScrollShadowWrapper contentClassName="submission-details-modal-content">
              <h2>{t("eventsModals:submissionDetails.title")}</h2>

              <Stack rowGap={30} className="mt-3 mb-4">
                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <Stack rowGap={10}>
                    <p className="p4 black-2">{t("eventsModals:submissionDetails.firstName")}</p>
                    <p className="p4">{firstName}</p>
                  </Stack>

                  <Stack rowGap={10}>
                    <p className="p4 black-2">{t("eventsModals:submissionDetails.lastName")}</p>
                    <p className="p4">{lastName}</p>
                  </Stack>
                </Group>

                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <Stack rowGap={10}>
                    <p className="p4 black-2">{t("eventsModals:submissionDetails.email")}</p>
                    <p className="p4">{email}</p>
                  </Stack>

                  <Stack rowGap={10}>
                    <p className="p4 black-2">{t("eventsModals:submissionDetails.note")}</p>
                    <p className="p4">{note || "-"}</p>
                  </Stack>
                </Group>
              </Stack>

              <hr />

              <Group colGap={20} alignItems="flex-start" fullWidth className="mt-4 mb-4">
                <Stack rowGap={10}>
                  <p className="p4 black-2">{t("eventsModals:submissionDetails.registeredDate")}</p>
                  <p className="p4">{formattedRegisteredDate}</p>
                </Stack>

                <Stack rowGap={10}>
                  <p className="p4 black-2">{t("eventsModals:submissionDetails.sentTicketDate")}</p>
                  <p className="p4">{formattedSentTicketDate}</p>
                </Stack>

                <Stack rowGap={10}>
                  <p className="p4 black-2">{t("eventsModals:submissionDetails.ticketNumber")}</p>
                  <p className="p4">{ticket?.globalId ? formatTicketGlobalId(ticket.globalId) : "-"}</p>
                </Stack>
              </Group>

              {eventAgreements.length > 0 && (
                <>
                  <hr />
                  <Stack rowGap={15} className="mt-5">
                    <p className="p4 black-2">{t("eventsModals:submissionDetails.agreements")}</p>

                    {eventAgreements.map(({ id, name, content, url, required }) => {
                      const isChecked = acceptedAgreements.includes(id);

                      return (
                        <AgreementCheckbox
                          key={id}
                          name={`${name}-${id}`}
                          content={content}
                          url={url}
                          defaultChecked={isChecked}
                          required={required}
                          disabled
                        />
                      );
                    })}
                  </Stack>
                </>
              )}
            </ScrollShadowWrapper>

            <Group colGap={20} className="mt-3">
              <Button variant="primary" onClick={closeModal} className="mt-6">
                {t("common:close")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SubmissionDetails;
