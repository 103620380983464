export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};

export const bufferToDataUrl = (buffer: Buffer, mimetype: string) => {
  return `data:${mimetype};base64,${buffer.toString("base64")}`;
};
