import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./RegisterConfirm.scss";

const RegisterConfirm = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["authViews", "common"]);

  const goToLogin = () => navigate(PATHS.login.path);

  return (
    <div className="register-confirm-page">
      <Stack rowGap={20} fullSize>
        <h2>{t("authViews:registerConfirm.title")}</h2>
        <p className="p0">{t("authViews:registerConfirm.description")}</p>

        <Group colGap={20} className="mt-3">
          <Button variant="secondary" size="big" onClick={goToLogin}>
            {t("common:goToLogin")}
          </Button>
        </Group>
      </Stack>
    </div>
  );
};

export default RegisterConfirm;
