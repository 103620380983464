import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { AssetData } from "@services/blockchain/blockchain.types";

import { sleep } from "@utils/sleep";
import { useWvs } from "@services/blockchain/Waves.context";

export interface NftsData {
  status: FetchStatus;
  nfts: { year: number; data: AssetData[] }[];
  error: Error | null;
}

interface ContextValue extends NftsData {
  walletAddress: string;
  nftManageEnable: boolean;
  updateWalletAddress: (address: string) => void;
  getAccountNfts: (address: string) => Promise<any>;
  removeAssetData: (assetId: string) => void;
}

const WALLET_ADDR_PARAM = "addr";

const BrowseNftsContext = React.createContext<ContextValue>(null as any);

const initialBrowseNftsData = (status: FetchStatus = "loading"): NftsData => ({
  status,
  nfts: [],
  error: null
});

export const BrowseNftsProvider = ({ children }: IContext) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [nftManageEnable, setNftManageEnable] = useState(false);
  const [nftsData, setNftsData] = useState<NftsData>(initialBrowseNftsData());

  const { publicState, status: keeperStatus, services } = useWvs();
  const [searchParams] = useSearchParams();
  const walletAddrFromParam = searchParams.get(WALLET_ADDR_PARAM);

  useEffect(() => {
    const itsMyWallet = keeperStatus === "connected" && walletAddress === publicState?.account?.address;

    setNftManageEnable(itsMyWallet);
  }, [publicState?.account?.address, keeperStatus, walletAddress]);

  useEffect(() => {
    if (walletAddrFromParam) {
      setWalletAddress(walletAddrFromParam);
    } else if (keeperStatus === "connected") {
      setWalletAddress(publicState?.account?.address || "");
    }
  }, [keeperStatus, publicState?.account?.address, walletAddrFromParam]);

  useEffect(() => {
    getAccountNfts(walletAddress);
  }, [walletAddress]);

  const updateWalletAddress = (address: string) => {
    setWalletAddress(address);
  };

  const getAccountNfts = async (address: string) => {
    try {
      if (!walletAddress) {
        await sleep(200);
        setNftsData(initialBrowseNftsData("idle"));
        return;
      }

      setNftsData(initialBrowseNftsData());
      await sleep(200);

      const data = await services.readService.getAccountNfts(address);
      setNftsData((prevState) => ({ ...prevState, status: "success", nfts: _groupAssetsByYear(data) }));

      return data;
    } catch (e: any) {
      setNftsData((prevState) => ({ ...prevState, status: "failed", error: e }));

      return e;
    }
  };

  const removeAssetData = (assetId: string) => {
    const assetIdToRemove = _findAssetData(assetId);
    if (!assetIdToRemove) return;

    setNftsData((prevData) => {
      const updatedNfts = prevData.nfts
        .map((nft) => ({
          year: nft.year,
          data: nft.data.filter((asset) => asset.assetId !== assetId)
        }))
        .filter((nft) => {
          return nft.data.length > 0;
        });

      return { ...prevData, nfts: updatedNfts };
    });
  };

  const _findAssetData = (assetId: string) => {
    for (const collection of nftsData.nfts) {
      for (const asset of collection.data) {
        if (asset.assetId === assetId) {
          return asset;
        }
      }
    }
    return null;
  };

  const _groupAssetsByYear = (assets: AssetData[]): { year: number; data: AssetData[] }[] => {
    const groupedAssets: { year: number; data: AssetData[] }[] = [];

    for (const asset of assets) {
      const year = new Date(asset.issueTimestamp).getFullYear();

      let existingGroup = groupedAssets.find((group) => group.year === year);

      if (!existingGroup) {
        existingGroup = { year, data: [] };
        groupedAssets.push(existingGroup);
      }

      existingGroup.data.push(asset);
    }

    return groupedAssets.sort((a, b) => b.year - a.year);
  };

  const contextValue: ContextValue = {
    ...nftsData,
    walletAddress,
    nftManageEnable,
    updateWalletAddress,
    getAccountNfts,
    removeAssetData
  };

  return <BrowseNftsContext.Provider value={contextValue}>{children}</BrowseNftsContext.Provider>;
};

export const useBrowseNfts = (): ContextValue => React.useContext(BrowseNftsContext);
