import React from "react";

import { Notification as NotificationType } from "@contexts/Notification.context";
import { NOTIFICATION_CLEAR_TIME } from "@config/config";

import NotificationClose from "src/assets/NotificationClose";

import "./Notification.scss";

interface NotificationProps extends NotificationType {
  close: () => void;
}

const Notification = ({ id, variant, message, close }: NotificationProps) => {
  React.useEffect(() => {
    if (NOTIFICATION_CLEAR_TIME !== 0) {
      const timeout = setTimeout(() => {
        close();
      }, NOTIFICATION_CLEAR_TIME);

      return () => clearTimeout(timeout);
    }
  }, []);

  const imgVariantPath = `/images/icon-notification-${variant}.svg`;

  return (
    <div className="notification">
      <img src={imgVariantPath} alt="notification type" className="notification-variant" />
      <p className="p0 dark">{message}</p>
      <button type="button" className="notification-close-button" onClick={close}>
        <NotificationClose />
      </button>
    </div>
  );
};

export default Notification;
