import React from "react";
import { useTranslation } from "react-i18next";

import Link from "@components/navigation/Link/Link";
import Checkbox from "@components/dataEntry/Checkbox/Checkbox";
import { CheckboxProps } from "@components/dataEntry/Checkbox/Checkbox.types";

interface AgreementCheckboxProps extends CheckboxProps {
  content: string;
  url?: string | null;
}

const AgreementCheckbox = ({
  content,
  url,
  required,
  name,
  value,
  defaultChecked,
  disabled,
  onChange
}: AgreementCheckboxProps) => {
  const { t } = useTranslation("common");

  const agreementLink = url && (
    <Link href={url} target="_blank" withImage={false}>
      {t("fullContent")}
    </Link>
  );

  const checkboxLabel = agreementLink ? (
    <>
      {content} - {agreementLink}
    </>
  ) : (
    <>{content}</>
  );

  return (
    <Checkbox
      label={checkboxLabel}
      name={name}
      value={value}
      onChange={onChange}
      defaultChecked={defaultChecked}
      required={required}
      disabled={disabled}
      labelTextSize="small"
    />
  );
};

export default AgreementCheckbox;
