import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { Pagination } from "@src/types/api/Pagination.types";
import { OrderType } from "@src/types/api/OrderType.types";
import { SubmissionDto, SubmissionsOrderBy, TicketDto } from "@services/Api/api.dto";
import { sleep } from "@utils/sleep";

import { useEvents } from "./EventsData.context";
import { useApiClient } from "@services/Api/ApiClientContext";
import {
  SocketEvents,
  SubmissionAddedData,
  TicketSentToSubmissionData,
  TicketUsedData
} from "@services/Websocket/Socket.types";
import useSocketSubscribe from "@services/Websocket/useSocketSubscribe";

export interface SubmissionsData {
  status: FetchStatus;
  hasSubmissions: boolean;
  searchedSubmission: string;
  submissions: {
    data: SubmissionDto[];
    meta: Pagination;
  };
  error: Error | AxiosError | null;
}

interface ContextValue extends SubmissionsData {
  addSubmission: (newSubmission: SubmissionDto) => void;
  updateSubmission: (submissionId: string, updatedSubmission: SubmissionDto) => void;
  updateSubmissionTicket: (ticket: TicketDto) => void;
  refetchSubmissionsData: (
    eventId: string,
    page: number,
    perPage?: number,
    order?: OrderType,
    orderBy?: SubmissionsOrderBy,
    search?: string
  ) => Promise<void>;
  setSearchedSubmission: (value: string) => void;
}

const initialSubmissionsData = (): SubmissionsData => ({
  status: "loading",
  hasSubmissions: false,
  searchedSubmission: "",
  submissions: { data: [], meta: { page: 1, perPage: SUBMISSIONS_PER_PAGE, total: 50, totalPages: 1 } },
  error: null
});

const SubmissionsDataContext = React.createContext(null as any);

const SUBMISSIONS_PER_PAGE = 20;

export const SubmissionsDataProvider = ({ children }: IContext) => {
  const [submissionsData, setSubmissionsData] = useState<SubmissionsData>(initialSubmissionsData());
  const { eventId } = useParams();
  const { lockAddAgreements, incrementSubmissionsCount } = useEvents();

  const { getEventSubmissions } = useApiClient();

  useEffect(() => {
    if (!eventId) return;

    fetchSubmissionsData(eventId, 1, SUBMISSIONS_PER_PAGE);
  }, [eventId]);

  useEffect(() => {
    if (submissionsData.status !== "success") return;

    const hasSubmissions = submissionsData.submissions.data.length > 0;
    setSubmissionsData((prevState) => ({ ...prevState, hasSubmissions }));
  }, [submissionsData.submissions]);

  useSocketSubscribe(SocketEvents.TICKET_SENT_TO_SUBMISSION, ({ submission }: TicketSentToSubmissionData) => {
    updateSubmission(submission.id, submission);
  });

  useSocketSubscribe(SocketEvents.SUBMISSION_ADDED, ({ submission }: SubmissionAddedData) => {
    addSubmission(submission);

    if (eventId) {
      lockAddAgreements(eventId);
      incrementSubmissionsCount(eventId);
    }
  });

  useSocketSubscribe(
    SocketEvents.TICKET_USED,
    ({ ticket }: TicketUsedData) => {
      if (!eventId) return;

      updateSubmissionTicket(ticket);
    },
    [eventId]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchSubmissionsData = async (eventId: string, page: number, perPage: number, search?: string) => {
    setSubmissionsData(initialSubmissionsData());

    try {
      const { data } = await getEventSubmissions(eventId, page, perPage);
      return setSubmissionsData((prevState) => ({
        ...prevState,
        submissions: data,
        status: "success"
      }));
    } catch (e: any) {
      setSubmissionsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const addSubmission = React.useCallback(
    (newSubmission: SubmissionDto) => {
      setSubmissionsData((prevState) => ({
        ...prevState,
        submissions: {
          ...prevState.submissions,
          data: [...prevState.submissions.data, newSubmission]
        }
      }));
    },
    [submissionsData]
  );

  const updateSubmission = React.useCallback(
    (submissionId: string, updatedSubmission: SubmissionDto) => {
      setSubmissionsData((prevState) => ({
        ...prevState,
        submissions: {
          ...prevState.submissions,
          data: prevState.submissions.data.map((submission) => {
            if (submission.id === submissionId) {
              return { ...submission, ...updatedSubmission };
            }

            return submission;
          })
        }
      }));
    },
    [submissionsData]
  );

  const updateSubmissionTicket = React.useCallback(
    (ticket: TicketDto) => {
      const foundSubmission = submissionsData.submissions.data.find(
        (submission) => submission.id === ticket.submission
      );

      if (!foundSubmission) return;

      setSubmissionsData((prevState) => ({
        ...prevState,
        submissions: {
          ...prevState.submissions,
          data: prevState.submissions.data.map((submission) => {
            if (submission.id === ticket.submission) {
              return { ...submission, ticket };
            }

            return submission;
          })
        }
      }));
    },
    [submissionsData]
  );

  const refetchSubmissionsData = async (
    eventId: string,
    page: number,
    perPage: number = SUBMISSIONS_PER_PAGE,
    order?: OrderType,
    orderBy?: SubmissionsOrderBy,
    search?: string
  ) => {
    try {
      setSubmissionsData((prevState) => ({ ...prevState, status: "loading" }));
      await sleep(500);

      const { data } = await getEventSubmissions(eventId, page, perPage, order, orderBy, search);
      return setSubmissionsData((prevState) => ({ ...prevState, submissions: data, status: "success" }));
    } catch (e: any) {
      setSubmissionsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const setSearchedSubmission = (value: string) => {
    setSubmissionsData((prevState) => ({ ...prevState, searchedSubmission: value }));
  };

  const contextValue: ContextValue = {
    ...submissionsData,
    addSubmission,
    updateSubmission,
    updateSubmissionTicket,
    refetchSubmissionsData,
    setSearchedSubmission
  };

  return <SubmissionsDataContext.Provider value={contextValue}>{children}</SubmissionsDataContext.Provider>;
};

export const useSubmissions = (): ContextValue => useContext(SubmissionsDataContext);
