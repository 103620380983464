import React from "react";
import classNames from "classnames";

import { selectAllText } from "@utils/selectAllText";

import "./SeedDisplay.scss";

interface SeedDisplayProps {
  seed: string;
  fullWidth?: boolean;
  className?: string;
}

const SeedDisplay = ({ seed, fullWidth, className }: SeedDisplayProps) => {
  const containerId = `seed-display-${Date.now()}`;

  const handleSelectText = () => selectAllText(containerId);

  const classes = classNames("seed-display", { "full-width": fullWidth }, className);

  return (
    <div id={containerId} className={classes} onClick={handleSelectText}>
      {seed.split(" ").map((word) => (
        <span key={`${word}-${new Date().getTime()}`} className="p0">
          {word}
        </span>
      ))}
    </div>
  );
};

export default SeedDisplay;
