import React, { useRef, useState } from "react";
import { Cropper, CropperRef, CropperPreviewRef, CircleStencil } from "react-advanced-cropper";
import classNames from "classnames";

import { captureCroppedImage } from "./utils/captureCroppedImage";
import UtilsBar from "./components/UtilsBar/UtilsBar";

import "react-advanced-cropper/dist/style.css";
import "./ImageCropper.scss";

interface ImageCropperProps {
  cropperRef: React.RefObject<CropperRef>;
  imageFile?: string;
  minWidth?: number;
  minHeight?: number;
  className?: string;
  getOriginalImage?: (file: File) => void;
  onError?: (error: string) => void;
}

const ImageCropper = ({
  cropperRef,
  imageFile,
  minWidth = 300,
  minHeight = 300,
  className,
  getOriginalImage,
  onError
}: ImageCropperProps) => {
  const previewRef = useRef<CropperPreviewRef>(null);

  const [originalImage, setOriginalImage] = useState<File | null>(null);
  const [imageBlob, setImageBlob] = useState<string>(imageFile || "");
  const [mode, setMode] = useState("crop");
  const [errorMessage, setErrorMessage] = useState("");

  const reset = () => {
    setMode("crop");
  };

  const download = () => {
    if (cropperRef.current) {
      const newTab = window.open();
      if (newTab) {
        newTab.document.body.innerHTML = `<img src="${captureCroppedImage(cropperRef)}"/>`;
      }
    }
  };

  const upload = (file: File) => {
    reset();
    setMode("crop");

    const blob = URL.createObjectURL(file);
    setImageBlob(blob);
    setOriginalImage(file);
    getOriginalImage && getOriginalImage(file);
  };

  const setFullSize = () => {
    if (cropperRef.current) {
      cropperRef.current.setCoordinates((state, settings) => {
        return {
          width: state.imageSize.width,
          height: state.imageSize.height,
          left: 0,
          top: 0
        };
      });
    }
  };

  const handleError = (error: string) => {
    setErrorMessage(error);
    onError && onError(error);
  };

  const onUpdate = () => {
    previewRef.current?.refresh();
  };

  const cropperEnabled = mode === "crop";

  const defaultSize = ({ imageSize, visibleArea }: any) => {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
      top: 0
    };
  };

  const imageCropperClasses = classNames("image-cropper", className);
  const overlayClassName = classNames("cropper-overlay", cropperEnabled && "cropper-overlay--faded");

  return (
    <div className={imageCropperClasses}>
      <Cropper
        src={imageBlob}
        ref={cropperRef}
        stencilComponent={CircleStencil}
        stencilProps={{
          movable: cropperEnabled,
          resizable: cropperEnabled,
          lines: cropperEnabled,
          handlers: cropperEnabled,
          grid: true,
          overlayClassName: overlayClassName
        }}
        backgroundWrapperProps={{
          scaleImage: cropperEnabled,
          moveImage: cropperEnabled
        }}
        defaultSize={defaultSize}
        onUpdate={onUpdate}
        minWidth={minWidth}
        minHeight={minHeight}
        className="cropper"
      />

      <UtilsBar setFullSize={setFullSize} onDownload={download} onUpload={upload} onError={handleError} />

      {errorMessage && <p className="mt-2 p0 danger-1 text-center">{errorMessage}</p>}
    </div>
  );
};

export default ImageCropper;
