import { OrganizationDto } from "@services/Api/api.dto";

export const organizationMock: OrganizationDto = {
  id: "",
  name: "",
  email: "",
  url: "",
  description: "",
  blockchainWallet: "",
  imageUrl: null
};
