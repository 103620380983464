import React from "react";
import classNames from "classnames";

import { IStack } from "./Stack.types";
import "./Stack.scss";

const Stack = ({
  rowGap,
  colGap,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  wrap,
  fullSize,
  fullWidth,
  maxContent,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onBlur
}: IStack) => {
  const gap = `${rowGap ? rowGap : "0"}px ${colGap ? colGap : "0"}px`;

  const classes = classNames(
    "stack",
    { "max-content": maxContent, "full-size": fullSize, "full-width": fullWidth },
    className
  );

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onBlur={onBlur}
      className={classes}
      style={{
        gap,
        justifyContent,
        alignItems,
        flexWrap: wrap
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
