import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import EventsNavList from "../../components/EventsNavList/EventsNavList";

import "./EventsListLayout.scss";

const EventsListLayout = ({ children }: IChildren) => {
  return (
    <div className="events-list-layout">
      <div className="events-list-layout-sidebar-part">
        <EventsNavList />
      </div>
      <div className="events-list-layout-content-part">{children}</div>
    </div>
  );
};

export default EventsListLayout;
