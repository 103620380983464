export const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <path
      fill="#FFF"
      d="M.8 5.6c0-.5.3-1.2.6-1.6l2.7-2.7C4.4 1 5.2.7 5.7.7h8.7c.5 0 .9.5.9.9v12.6c0 .5-.5.9-.9.9H1.6c-.5 0-.9-.5-.9-.9V5.6h.1zm2.4 8.5v-3.9c0-.5.5-.9.9-.9h7.8c.5 0 .9.5.9.9v3.9H14V1.9h-1.2v3.9c0 .5-.5.9-.9.9H6.4c-.4 0-.7-.3-.7-.9V1.9c-.4 0-.7.1-.8.3L2.2 4.9c-.1.1-.3.4-.3.7V14h1.3zm8.4 0v-3.7H4.4V14h7.2v.1zM6.8 5.3c0 .2.2.3.3.3H9c.2 0 .3-.2.3-.3V2.2c0-.2-.2-.3-.3-.3H7.1c-.2 0-.3.1-.3.3v3.1z"
    ></path>
  </svg>
);

export const UploadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
      <path
        fill="#FFF"
        d="M17.2 12.1c-.4 0-.8.3-.8.8v2.8c0 .6-.4 1-1 1H8.6c-.6 0-1-.4-1-1v-2.8c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.8c0 1.4 1.1 2.5 2.5 2.5h6.8c1.4 0 2.5-1.1 2.5-2.5v-2.8c.1-.5-.2-.8-.6-.8z"
      />
      <path
        fill="#FFF"
        d="m10.1 9.4 1.1-1.1V14c0 .4.3.8.8.8s.8-.3.8-.8V8.4l1.1 1c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L12.5 6c-.3-.3-.8-.3-1 0L9.1 8.3c-.3.3-.3.8 0 1.1.3.3.7.3 1 0z"
      />
    </svg>
  );
};

export const FullsizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path
        fill="#FFF"
        d="M16 15.1c0 .5-.4.9-.9.9h-4c-.8 0-1.1-.9-.6-1.5l1.3-1.3L8 9.4l-3.8 3.8 1.3 1.3c.5.6.1 1.5-.6 1.5h-4c-.5 0-.9-.4-.9-.9v-4c0-.8.9-1.1 1.5-.6l1.3 1.3L6.6 8 2.8 4.2 1.5 5.5C.9 6 0 5.6 0 4.9v-4C0 .4.4 0 .9 0h4c.7 0 1.1.9.6 1.5L4.2 2.8 8 6.6l3.8-3.8-1.3-1.3c-.5-.6-.1-1.5.6-1.5h4c.5 0 .9.4.9.9v4c0 .8-.9 1.1-1.5.6l-1.3-1.3L9.4 8l3.8 3.8 1.3-1.3c.5-.5 1.5-.2 1.5.6v4z"
      ></path>
    </svg>
  );
};
