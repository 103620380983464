import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";

import useVisible from "@hooks/useVisible";
import ClickAwayListener from "@components/common/ClickAwayListener/ClickAwayListener";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { EditorUtilsProps } from "../../TextEditor.types";
import LinkIcon from "../../icons/LinkIcon";
import "./CustomUtils.scss";

const AddLink = ({ editor }: EditorUtilsProps) => {
  const [linkUrl, setLinkUrl] = useState("");
  const [buttonUtilsRef, setButtonUtilRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const { isVisible, visibilityToggle, setVisibilityFalse } = useVisible(false);

  const { t: commonT } = useTranslation("common");

  const { styles, attributes } = usePopper(buttonUtilsRef, popperRef, {
    placement: "bottom",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  const handleChangeLinkUrl = (e: React.ChangeEvent<HTMLInputElement>) => setLinkUrl(e.target.value);

  const handleAddLink = () => {
    // remove link
    if (linkUrl === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: linkUrl }).run();

    setVisibilityFalse();
    setLinkUrl("");
  };

  return (
    <div className="text-editor-custom-utils">
      <button
        ref={setButtonUtilRef}
        title={commonT("addLink.button")}
        onClick={visibilityToggle}
        className="menu-utils-button"
      >
        <LinkIcon />
      </button>

      {isVisible && (
        <ClickAwayListener onClickAway={setVisibilityFalse}>
          <div
            ref={setPopperRef}
            className="text-editor-custom-utils-modal"
            style={{ ...styles.popper, zIndex: 100 }}
            {...attributes.popper}
          >
            <h3>{commonT("addLink.modal.title")}</h3>

            <Input
              label={commonT("addLink.modal.inputs.url.label")}
              placeholder={commonT("addLink.modal.inputs.url.placeholder")}
              value={linkUrl}
              onChange={handleChangeLinkUrl}
            />
            <Group justifyContent="flex-end" fullWidth>
              <Button onClick={handleAddLink}>{commonT("save")}</Button>
            </Group>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default AddLink;
