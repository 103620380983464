import React from "react";

import Stack from "@components/arrangement/Stack/Stack";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";

import "./EventNftSkeleton.scss";

const EventNftSkeleton = () => {
  return (
    <Stack rowGap={50} className="event-nft-skeleton">
      {/* EditNft */}
      <ContentLoader width={720} height={515} viewBox="0 0 720 515">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="52" rx="10" ry="10" width="360" height="340" />
        <rect x="380" y="52" rx="10" ry="10" width="340" height="340" />
      </ContentLoader>

      {/* NftSettings */}
      <ContentLoader width={720} height={230} viewBox="0 0 720 230">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="52" rx="10" ry="10" width="720" height="175" />
      </ContentLoader>

      {/* ClaimingUsers */}
      <ContentLoader width={720} height={300} viewBox="0 0 720 300">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="52" rx="10" ry="10" width="720" height="20" />

        <rect x="0" y="92" rx="10" ry="10" width="460" height="20" />
        <rect x="480" y="92" rx="10" ry="10" width="140" height="20" />
        <rect x="640" y="92" rx="10" ry="10" width="30" height="20" />
        <rect x="690" y="92" rx="10" ry="10" width="30" height="20" />

        <rect x="0" y="132" rx="10" ry="10" width="460" height="20" />
        <rect x="480" y="132" rx="10" ry="10" width="140" height="20" />
        <rect x="640" y="132" rx="10" ry="10" width="30" height="20" />
        <rect x="690" y="132" rx="10" ry="10" width="30" height="20" />

        <rect x="0" y="172" rx="10" ry="10" width="460" height="20" />
        <rect x="480" y="172" rx="10" ry="10" width="140" height="20" />
        <rect x="640" y="172" rx="10" ry="10" width="30" height="20" />
        <rect x="690" y="172" rx="10" ry="10" width="30" height="20" />

        <rect x="0" y="212" rx="10" ry="10" width="460" height="20" />
        <rect x="480" y="212" rx="10" ry="10" width="140" height="20" />
        <rect x="640" y="212" rx="10" ry="10" width="30" height="20" />
        <rect x="690" y="212" rx="10" ry="10" width="30" height="20" />

        <rect x="0" y="252" rx="10" ry="10" width="460" height="20" />
        <rect x="480" y="252" rx="10" ry="10" width="140" height="20" />
        <rect x="640" y="252" rx="10" ry="10" width="30" height="20" />
        <rect x="690" y="252" rx="10" ry="10" width="30" height="20" />
      </ContentLoader>
    </Stack>
  );
};

export default EventNftSkeleton;
