import React, { memo } from "react";
import classNames from "classnames";

import { mergeRefs } from "@utils/mergeRefs";

import { SearchInputProps } from "./SearchInput.types";
import SearchIcon from "src/assets/SearchIcon";

import "./SearchInput.scss";

const SearchInput = ({
  id,
  inputRef,
  name,
  value,
  label = "",
  placeholder = "",
  description = "",
  error = "",
  disabled = false,
  required = false,
  readOnly = false,
  paddingSize = "normal",
  onClick,
  onChange,
  onBlur,
  onFocus,
  className = ""
}: SearchInputProps) => {
  const inputId = React.useId();
  const classes = classNames("search-input", className);
  const inputClasses = classNames(`input-padding-${paddingSize}`, { "input-error": error });

  const _inputRef = React.useRef<HTMLInputElement>(null);
  const mergedInputRefs = inputRef ? mergeRefs([inputRef, _inputRef]) : _inputRef;

  const setFocus = React.useCallback(() => {
    if (!_inputRef?.current) return;
    _inputRef.current.focus();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!_inputRef?.current) return;
      _inputRef.current.blur();
    }
  };

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id || name || inputId} className="search-input-label">
          {label}
          {required && <span className="label-asterisk">*</span>}
        </label>
      )}

      {description && <p className="search-input-description">{description}</p>}

      <div className="search-input-inputWrapper">
        <input
          id={id || name}
          ref={mergedInputRefs}
          name={name || inputId}
          value={value}
          type="search"
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          className={inputClasses}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
        />
        <div className="input-adornment" onClick={setFocus}>
          <SearchIcon color="#5C44EC" className="input-adornment-icon" />
        </div>
      </div>

      {error && <p className="search-input-errorText">{error}</p>}
    </div>
  );
};

export default memo(SearchInput);
