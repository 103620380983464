import * as Crypto from "@waves/ts-lib-crypto";

import { AssetData, DataEntry, LeaseInfo, ScriptInfo, ScriptInfoMeta, Transaction } from "../blockchain.types";
import { Device } from "../utils/skey-device";

export interface TransactionsOptions {}

export class BCReadService {
  private server?: string;

  constructor(server?: string) {
    this.server = server;
  }

  public update(server?: string) {
    this.server = server;
  }

  async scriptInfoMeta(address: string) {
    const path = `/addresses/scriptInfo/${address}/meta`;
    return await this.request<ScriptInfoMeta>(path);
  }

  async getScript(address: string) {
    const path = `/addresses/scriptInfo/${address}`;
    return await this.request<ScriptInfo>(path).then((data) => data.script);
  }

  async getScriptHash(address: string) {
    const base64 = await this.getScript(address);
    if (!base64) return null;

    const bin = Crypto.base64Decode(base64.replace(/base64:/g, "").trimEnd());
    return Crypto.base16Encode(Crypto.sha256(bin));
  }

  async getData(address: string) {
    return await this.request<DataEntry[]>(`/addresses/data/${address}`);
  }

  async transactions(address: string, limit = 1000, after?: string) {
    const afterParam = after ? `after=${after}` : "";
    const path = `/transactions/address/${address}/limit/${limit}?${afterParam}`;
    return await this.request<Transaction[][]>(path).then((r) => r[0]);
  }

  async activeLeases(address: string) {
    return await this.request<LeaseInfo[]>(`/leasing/active/${address}`);
  }

  async getDataByKey(address: string, key: string) {
    return await this.request<DataEntry | null>(`/addresses/data/${address}/${key}`);
  }

  async getAssetUrl(address: string) {
    const data = await this.getDataByKey(address, "details").catch(() => null);
    if (!data || data.type !== "string") return null;

    const details: Device["details"] = JSON.parse(data.value as string);
    return details?.assetUrl ?? null;
  }

  async getAccountNfts(address: string, limit: number = 1000) {
    return await this.request<AssetData[]>(`/assets/nft/${address}/limit/${limit}`);
  }

  async allTransactions(address: string, limit: number, maxRequests: number) {
    const all: Transaction[] = [];
    let after: string | undefined;

    for (let i = 0; i < maxRequests; i++) {
      const txes = await this.transactions(address, limit, after);
      all.push(...txes);

      if (txes.length < limit) {
        return all;
      }

      after = txes[txes.length - 1].id;
    }

    throw new Error("Max number of requests reached");
  }

  async aliases(address: string, chainId: string) {
    const all = await this.request<string[]>(`/alias/by-address/${address}`);

    return all
      .filter((item) => item.includes(`alias:${chainId}:`))
      .map((item) => item.replace(`alias:${chainId}:`, ""));
  }

  async request<T = any>(path: string): Promise<T> {
    if (!this.server) throw new Error("server_url_missing");

    const url = `${this.server}${path}`;

    const res = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });

    const data = await res.json();

    if (!res.ok) throw new Error(data.message ?? "Error");

    return data;
  }
}
