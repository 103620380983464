import React from "react";
import { useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";

import "./EditNft.scss";

const EditNft = () => {
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  const handleOpenModal = () => openModal({ type: "editEventNft" });

  const nftImageUrl = event.occasionalNFT?.imageUrl || "/images/nft-image-placeholder.svg";
  const editButtonDisabled = eventStatus === "ended";

  return (
    <div className="edit-nft">
      <h3 className="edit-nft-title">{t("editEventNft.title")}</h3>

      <Group colGap={20} alignItems="flex-start" equalWidth className="edit-nft-content">
        <Stack className="edit-nft-edit" justifyContent="space-between">
          <p className="p0 mb-3">{t("editEventNft.description")}</p>

          <Stack rowGap={10} className="edit-nft-value">
            <p className="p4 black-2">{t("editEventNft.properities.name")}</p>
            <p className="p4">{event.occasionalNFT?.name || "-"}</p>
          </Stack>

          <Tooltip tip={commonT("eventEndedDisabled")} hide={!editButtonDisabled} width={200} textCenter>
            <Button className="mt-3" onClick={handleOpenModal} disabled={editButtonDisabled}>
              {commonT("edit")}
            </Button>
          </Tooltip>
        </Stack>

        <div className="edit-nft-preview">
          <img src={nftImageUrl} alt="nft image preview" className="edit-nft-preview-image" />
        </div>
      </Group>
    </div>
  );
};

export default EditNft;
