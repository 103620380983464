import React from "react";
import { useTranslation } from "react-i18next";

import { AccordionActiveState } from "@components/common/Accordion/Accordion.types";
import { SKEY_TIX_MAIL } from "@constants/constants";
import { TIX_APP_ANDROID, TIX_APP_IOS } from "src/constants/constants";

import Accordion from "@components/common/Accordion/Accordion";
import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";

import { faqQuestions } from "@constants/faqQuestions";
import "./Faq.scss";

const Faq = () => {
  const { t } = useTranslation("helpPage");

  const faqQuestionsWithoutButtons = faqQuestions.slice(3);

  return (
    <Group alignItems="flex-start" colGap={40} className="faq-section">
      <h2 className="title">{t("faq.title")}</h2>

      <Accordion>
        <Accordion.Tab inititalVariant={(faqQuestions[0].inititalVariant as AccordionActiveState) || "default"}>
          <Accordion.Summary>{t(faqQuestions[0].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <p className="p0 text-justify mb-3">{t(faqQuestions[0].answer as any)}</p>

            <Group colGap={30}>
              <a href={TIX_APP_IOS} target="_blank" rel="noopener noreferrer nofollow">
                <img src="/images/app-store.svg" alt="app store" />
              </a>

              <a href={TIX_APP_ANDROID} target="_blank" rel="noopener noreferrer nofollow">
                <img src="/images/google-play.svg" alt="google-play" />
              </a>
            </Group>
          </Accordion.Details>
        </Accordion.Tab>

        <Accordion.Tab>
          <Accordion.Summary>{t(faqQuestions[1].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <p className="p0 text-justify mb-3">{t(faqQuestions[1].answer as any)}</p>
            {faqQuestions[1].links && (
              <Group colGap={30}>
                {faqQuestions[1].links.map(({ label, href }, index) => {
                  return (
                    <Link key={index} title={label} href={href} target="_blank">
                      {t(label as any)}
                    </Link>
                  );
                })}
              </Group>
            )}
          </Accordion.Details>
        </Accordion.Tab>

        <Accordion.Tab>
          <Accordion.Summary>{t(faqQuestions[2].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <p className="p0 text-justify mb-3">{t(faqQuestions[2].answer as any, { skeyTixEmail: SKEY_TIX_MAIL })}</p>
          </Accordion.Details>
        </Accordion.Tab>

        {faqQuestionsWithoutButtons?.map(({ question, answer }, index) => {
          return (
            <Accordion.Tab key={index}>
              <Accordion.Summary>{t(question as any)}</Accordion.Summary>
              <Accordion.Details>
                <p className="p0 text-justify mb-3">{t(answer as any)}</p>
              </Accordion.Details>
            </Accordion.Tab>
          );
        })}
      </Accordion>
    </Group>
  );
};

export default Faq;
