import React from "react";
import DOMPurify from "dompurify";
import { sanitize } from "dompurify";

import "./TextEditorViewer.scss";

interface TextEditorViewerProps {
  content?: string;
}

const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

DOMPurify.addHook("beforeSanitizeAttributes", function (node) {
  if (node.tagName === "A") {
    if (!node.hasAttribute("target")) {
      node.setAttribute("target", "_self");
    }

    if (node.hasAttribute("target")) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute("target") as string);
    }
  }
});

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE) as string);
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
    if (node.getAttribute("target") === "_blank") {
      node.setAttribute("rel", "noopener");
    }
  }
});

DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    if (!src.startsWith("https://www.youtube.com/embed/")) {
      return node.parentNode?.removeChild(node);
    }
  }
});

const DOMPURIFY_TAGS_CONFIG = {
  ADD_TAGS: ["iframe"],
  ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"]
};

const TextEditorViewer = ({ content }: TextEditorViewerProps) => {
  if (!content) return <></>;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(content, DOMPURIFY_TAGS_CONFIG)
      }}
      className="text-editor-viewer"
    />
  );
};

export default TextEditorViewer;
