import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const Reddit = ({ width = 31, height = 31, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4593 15.0446C30.4593 23.3524 23.7245 30.0872 15.4167 30.0872C7.10884 30.0872 0.374023 23.3524 0.374023 15.0446C0.374023 6.73677 7.10884 0.00195312 15.4167 0.00195312C23.7245 0.00195312 30.4593 6.73677 30.4593 15.0446ZM23.3515 12.8756C24.568 12.8345 25.586 13.7813 25.631 14.9938C25.6459 15.8395 25.1706 16.6179 24.4145 16.9959C24.4295 17.2167 24.4295 17.4375 24.4145 17.6583C24.4145 21.0338 20.4843 23.7731 15.6372 23.7731C10.79 23.7731 6.85983 21.03 6.85983 17.6583C6.84485 17.4375 6.84485 17.2167 6.85983 16.9959C6.63899 16.8986 6.44061 16.7601 6.26843 16.5992C5.38134 15.7684 5.34017 14.3763 6.17111 13.4931C7.00206 12.6062 8.39445 12.565 9.2778 13.3958C11.0146 12.2207 13.0545 11.5771 15.1506 11.5434L16.2622 6.31918V6.3117C16.3184 6.05722 16.5692 5.90005 16.8199 5.95618L20.5105 6.69341C20.7464 6.28176 21.1731 6.00483 21.6484 5.95618C22.4756 5.86637 23.2167 6.46513 23.3066 7.29217C23.3964 8.11922 22.7975 8.86018 21.9703 8.95C21.1431 9.03981 20.402 8.44105 20.3122 7.61401L17.0894 6.93666L16.1125 11.6332C18.1824 11.6781 20.1961 12.318 21.9104 13.4856C22.2997 13.1151 22.8125 12.8944 23.3515 12.8756ZM12.0813 14.9938C11.2504 14.9938 10.5729 15.6711 10.5729 16.5019C10.5729 17.3327 11.2466 18.0026 12.0813 18.01C12.9123 18.01 13.5897 17.3327 13.5897 16.5019C13.5897 15.6711 12.9123 14.9938 12.0813 14.9938ZM15.5997 21.801C16.9397 21.8571 18.2498 21.4454 19.3203 20.6409V20.7007C19.485 20.5398 19.4887 20.2704 19.3278 20.1057C19.1668 19.941 18.8973 19.9373 18.7326 20.0982C17.8156 20.7531 16.7077 21.075 15.5848 21.0151C14.4619 21.0675 13.3577 20.7307 12.4519 20.0683C12.2984 19.9448 12.0851 19.9448 11.9353 20.0683C11.7632 20.2105 11.737 20.4687 11.8792 20.6409C12.9497 21.4454 14.2597 21.8571 15.5997 21.801ZM17.5423 16.5543C17.5423 17.3851 18.2198 18.0624 19.0508 18.0624L19.0395 18.1186H19.1144C19.9453 18.0849 20.5929 17.3851 20.5592 16.5543C20.5592 15.7235 19.8817 15.0462 19.0508 15.0462C18.2198 15.0462 17.5423 15.7235 17.5423 16.5543Z"
        fill={color}
      />
    </svg>
  );
};

export default Reddit;
