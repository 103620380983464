import {
  saveTicketAsPDF,
  saveTicketAsPNG,
  saveQrAsPdf,
  saveQrAsPng
} from "skey-tix-generator/dist/frontend/ticketFile";
import { TicketData } from "skey-tix-generator/dist/interfaces";

import { FILE_LANG, IS_DEMO_MODE } from "src/config/helpers";
import { EventDto } from "@services/Api/api.dto";

import { arrayBufferToBase64 } from "@utils/arrayBufferToBase64";
import { buildTicketFileName } from "./buildTicketFileName";

const buildTicket = (ticketId: string, ticketQr: ArrayBuffer, event: EventDto, organizerName: string): TicketData => {
  const qrCode = arrayBufferToBase64(ticketQr);

  return {
    organizer: organizerName,
    organizerLogo: event.logoUrl,
    eventName: event.name,
    eventWebsite: event.url,
    ticketId,
    eventFromTimestamp: event.start,
    timezone: event.timezone,
    location: event.location,
    qrCode
  };
};

export const downloadTicketAsPdf = (
  ticketId: string,
  globalId: number,
  ticketQr: ArrayBuffer,
  event: EventDto,
  organizerName: string
) => {
  const ticketData = buildTicket(ticketId, ticketQr, event, organizerName);
  const fileName = buildTicketFileName(globalId, event.name);

  saveTicketAsPDF(ticketData, FILE_LANG, fileName, undefined, IS_DEMO_MODE);
};

export const downloadTicketAsPng = (
  ticketId: string,
  globalId: number,
  ticketQr: ArrayBuffer,
  event: EventDto,
  organizerName: string
) => {
  const ticketData = buildTicket(ticketId, ticketQr, event, organizerName);
  const fileName = buildTicketFileName(globalId, event.name);

  saveTicketAsPNG(ticketData, FILE_LANG, fileName, undefined, IS_DEMO_MODE);
};

export const downloadQrCodeAsPdf = (globalId: number, ticketQr: ArrayBuffer, event: EventDto) => {
  const qrCode = arrayBufferToBase64(ticketQr);
  const fileName = buildTicketFileName(globalId, event.name, true);

  saveQrAsPdf(qrCode, fileName);
};

export const downloadQrCodeAsPng = (globalId: number, ticketQr: ArrayBuffer, event: EventDto) => {
  const qrCode = arrayBufferToBase64(ticketQr);
  const fileName = buildTicketFileName(globalId, event.name, true);

  saveQrAsPng(qrCode, fileName);
};
