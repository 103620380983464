import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedClaimingUsers } from "../../contexts/SelectedClaimingUsers.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./SendGroupNfts.scss";

const SendGroupNfts = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { event } = useSelectedEvent();
  const { closeModal } = useEventsModals();
  const { selectedClaimingUsers, clearSelectedClaimingUsers } = useSelectedClaimingUsers();
  const { sendNftsToAllSelected } = useApiClient();

  const { t } = useTranslation(["eventsModals", "common"]);

  const handleSendGroupNft = () => {
    if (selectedClaimingUsers.length <= 0) return;

    setSubmitting(true);

    const usersList = selectedClaimingUsers.map((user) => {
      return {
        email: user.email,
        ticketId: user.ticket.id
      };
    });

    sendNftsToAllSelected(event.id, usersList)
      .then((res) => {
        if (res.status === 204) {
          closeModal();
          clearSelectedClaimingUsers();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="send-group-nfts-modal">
            <p className="p0 mt-3">{t("eventsModals:sendGroupNfts.description")}</p>

            <Group colGap={20} className="mt-5">
              <Button type="submit" variant="primary" onClick={handleSendGroupNft} disabled={submitting}>
                {t("common:send")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SendGroupNfts;
