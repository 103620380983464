import React from "react";
import classNames from "classnames";

import useTagHeight from "@hooks/useTagHeight";
import { useAccordionTab } from "../AccordionTab/AccordionTab";

import { AccordionActiveState, AccordionDetailsProps } from "../Accordion.types";
import "./AccordionDetails.scss";

const AccordionDetails = ({ children }: AccordionDetailsProps) => {
  const { elementHeight, tagRef } = useTagHeight();
  const { variant } = useAccordionTab();

  const getHeight = (variant: AccordionActiveState) => {
    if (variant === "default") {
      return 0;
    }
    if (variant === "hover") {
      return 0;
    }
    if (variant === "active") {
      return elementHeight;
    }
    throw new Error("Wrong variant");
  };

  const initialHeight = getHeight(variant);
  const [contentHeight, setContentHeight] = React.useState<number>(initialHeight);

  React.useEffect(() => {
    setContentHeight(getHeight(variant));
  }, [variant, elementHeight]);

  const classes = classNames("accordion-tab-content", `accordion-tab-content-${variant}`);

  return (
    <div className={classes} style={{ height: contentHeight }}>
      <div ref={tagRef} className="pb-4">
        {children}
      </div>
    </div>
  );
};

export default AccordionDetails;
