import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { STORAGE_KEYS } from "@config/helpers";
import { PATHS } from "@router/paths";

import { WalletStatus } from "./blockchain.types";
import { BCServiceManager, BCServices } from "./BCServiceManager";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";

export interface WvsProviderProps {
  connectOnLoad?: boolean;
  children: React.ReactNode;
}

interface ContextValue {
  status: WalletStatus;
  publicState: WavesKeeper.IPublicStateResponse | null;
  services: BCServices;
  keeper: WavesKeeper.TWavesKeeperApi | null;
  connect: () => Promise<boolean>;
  disconnect: () => boolean;
}

export const WvsContext = React.createContext<ContextValue>(null as any);

export const serviceManager = new BCServiceManager();

export const WvsProvider = ({ connectOnLoad = false, children }: WvsProviderProps) => {
  const [status, setStatus] = useState<WalletStatus>("loading");
  const [publicState, setPublicState] = useState<WavesKeeper.IPublicStateResponse | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const hasAlreadyGrantedPermissions = BrowserStorageManager.readLocalStorage<boolean>(STORAGE_KEYS.CONNECTED);

  const handleConnect = () => {
    return services.keeperService.connect(setStatus, setPublicState).then((res) => {
      if (location.pathname === PATHS.poapNft.path) navigate(PATHS.poapNftExplore.path);
      return res;
    });
  };

  const handleDisconnect = () => {
    return services.keeperService.disconnect(setStatus, setPublicState);
  };

  useEffect(() => {
    serviceManager.update(publicState ?? undefined, setPublicState);
  }, [publicState]);

  const services = serviceManager.getServices();

  useLayoutEffect(() => {
    services.keeperService.waitForKeeper(2000).then((result) => {
      setStatus(result ? "available" : "not_available");

      if (connectOnLoad && hasAlreadyGrantedPermissions) handleConnect();
    });
  }, []);

  const state: ContextValue = {
    status,
    publicState,
    keeper: services.keeperService.keeper,
    services,
    connect: handleConnect,
    disconnect: handleDisconnect
  };

  return <WvsContext.Provider value={state}>{children}</WvsContext.Provider>;
};

export const useWvs = (): ContextValue => React.useContext(WvsContext);
