import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { WorkerTokenDto } from "@services/Api/api.dto";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useEventsModals } from "../../contexts/EventsModals.context";
import { useWorkerTokens } from "../../contexts/WorkerTokensData.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./RemoveWorkerToken.scss";

interface RemoveWorkerTokenProps {
  workerToken: WorkerTokenDto;
}

const RemoveWorkerToken = ({ workerToken }: RemoveWorkerTokenProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useTranslation(["eventsModals", "common"]);

  const { removeWorkerToken: fetchRemoveWorkerToken } = useApiClient();
  const { removeWorkerToken } = useWorkerTokens();
  const { closeModal } = useEventsModals();

  const handleRemoveWorkerToken = () => {
    setSubmitting(true);

    fetchRemoveWorkerToken(workerToken.id)
      .then((res) => {
        if (res?.status === 200) {
          removeWorkerToken(workerToken.id);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="remove-worker-token-modal">
            <p className="p4 mt-4">
              <Trans
                t={t}
                i18nKey="eventsModals:removeWorkerToken.description"
                components={{ strong: <strong /> }}
                values={{ controller: workerToken.controller, name: workerToken.label }}
              />
            </p>

            <Group colGap={20} className="mt-3">
              <Button type="submit" variant="primary" onClick={handleRemoveWorkerToken} loading={submitting}>
                {t("common:remove")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default RemoveWorkerToken;
