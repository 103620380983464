import React from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";
import { useApiKeys } from "@panel/Settings/contexts/ApiKeysContext";

import Button from "@components/dataEntry/Buttons/Button/Button";
import ApiKeysTableRow from "./ApiKeysTableRow/ApiKeysTableRow";

import { tableHeaders } from "./tableHeaders";
import "./ApiKeysTable.scss";

const ApiKeysTable = () => {
  const { openModal } = useSettingsModals();
  const { apiKeys } = useApiKeys();

  const { t } = useTranslation("settingsPage");

  const handleCreateApiKey = () => openModal({ type: "createApiKey" });

  return (
    <div className="api-keys-table mt-6">
      <h3>{t("apiKeysTable.title")}</h3>

      <div className="table-wrapper">
        <div className="table">
          <div className="table-row">
            {tableHeaders.map(({ label, className }) => (
              <div key={nanoid()} className={`table-header-cell ${className || ""}`}>
                <p>{t(label as any)}</p>
              </div>
            ))}
          </div>

          {apiKeys.map((apiKey) => {
            return <ApiKeysTableRow key={apiKey.id} apiKey={apiKey} />;
          })}
        </div>
      </div>

      <Button variant="primary" onClick={handleCreateApiKey} className="mt-5 mb-5">
        {t("apiKeysTable.button")}
      </Button>
    </div>
  );
};

export default ApiKeysTable;
