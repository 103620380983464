import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";

import Button from "@components/dataEntry/Buttons/Button/Button";

import "./ExploreNftsError.scss";

const ExploreNftsError = () => {
  const { error, getAccountNfts, walletAddress } = useBrowseNfts();

  const { t } = useTranslation(["apiResponse", "common"]);

  const handleTryAgain = () => getAccountNfts(walletAddress);

  const errorMessage = useMemo(() => {
    if (!error) return "";
    if (error.message === "invalid address") return t("apiResponse:errors.getNftsAssetsInvalidAddress");
    return t("apiResponse:errors.getNftsAssetsBase");
  }, [error]);

  return (
    <div className="exlorer-nfts-error">
      <p className="p0 danger-1">{errorMessage}</p>

      <div>
        <Button size="big" onClick={handleTryAgain}>
          {t("common:tryAgain")}
        </Button>
      </div>
    </div>
  );
};

export default ExploreNftsError;
