import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ACCEPT_IMAGE_EXTENSIONS, MAX_SIZE_IMAGE_FILE } from "@config/config";

import UtilsButton from "../UtilsButton/UtilsButton";
import { FullsizeIcon, SaveIcon, UploadIcon } from "../UtilsIcons/UtilsIcons";

import "./UtilsBar.scss";

interface UtilsBarProps {
  setFullSize: () => void;
  onDownload: () => void;
  onUpload: (file: File) => void;
  onError: (error: string) => void;
  acceptFormats?: string;
  maxSize?: number;
  className?: string;
}

const UtilsBar = ({ setFullSize, onDownload, onUpload, onError, acceptFormats, maxSize, className }: UtilsBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t: commonT } = useTranslation("common");
  const { t: formErrorsT } = useTranslation("formErrors");

  const acceptExtensions = acceptFormats || ACCEPT_IMAGE_EXTENSIONS;
  const maxImageSize = maxSize || MAX_SIZE_IMAGE_FILE;
  const maxImageSizeMB = `${maxImageSize / 1024 / 1024}MB`;

  const onUploadButtonClick = () => {
    inputRef.current?.click();
  };

  const onLoadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files?.length) return;

    onError("");
    const file = files[0];

    if (file.size > maxImageSize) {
      onError(formErrorsT("file.toBig", { property: maxImageSizeMB }));
    }

    if (onUpload) {
      onUpload(file);
    }

    event.target.value = "";
  };

  const classes = classNames("utils-buttons", className);

  return (
    <div className={classes}>
      <UtilsButton title={commonT("imageCropper.fullSize")} onClick={setFullSize}>
        <FullsizeIcon />
      </UtilsButton>

      <UtilsButton title={commonT("imageCropper.download")} onClick={onDownload}>
        <SaveIcon />
      </UtilsButton>

      <UtilsButton title={commonT("imageCropper.upload")} onClick={onUploadButtonClick}>
        <UploadIcon />
        <input
          ref={inputRef}
          type="file"
          accept={acceptExtensions}
          onChange={onLoadImage}
          className="utils-buttons-upload-input"
        />
      </UtilsButton>
    </div>
  );
};

export default UtilsBar;
