import React from "react";

const CloseImagePreview: React.FC<{ width?: number; height?: number; className?: string }> = ({
  width = 42,
  height = 42,
  className = ""
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="white"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className || ""}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M18 6l-12 12"></path>
      <path d="M6 6l12 12"></path>
    </svg>
  );
};

export default CloseImagePreview;
