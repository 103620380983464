import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageAsBase64 } from "skey-tix-generator/dist/frontend/ticketFile";
import { TicketData, IGetImageAsBase64 } from "skey-tix-generator/dist/interfaces";

import { FILE_LANG, IS_DEMO_MODE } from "src/config/helpers";
import { TicketDto } from "@services/Api/api.dto";
import { arrayBufferToBase64 } from "@utils/arrayBufferToBase64";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useUserData } from "@contexts/UserData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import TicketSkeleton from "./TicketSkeleton/TicketSkeleton";
import "./TicketPreview.scss";

interface TicketPreviewProps {
  ticket: TicketDto;
}

const TicketPreview = ({ ticket }: TicketPreviewProps) => {
  const [ticketImage, setTicketImage] = useState("");
  const { organization } = useUserData();
  const { event } = useSelectedEvent();
  const { getTicketFile } = useApiClient();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);

  const buildTicket = (ticketId: string, qrCode: string): TicketData => ({
    organizer: organization?.name as string,
    organizerLogo: event.logoUrl,
    eventName: event.name,
    eventWebsite: event.url,
    ticketId,
    eventFromTimestamp: event.start,
    timezone: event.timezone,
    location: event.location,
    qrCode
  });

  const handlePreviewImage = useCallback(() => {
    getTicketFile(ticket.id, "qr")
      .then((res) => {
        if (res?.status !== 201) return;
        const qrCode = arrayBufferToBase64(res.data);
        const ticketData = buildTicket(ticket.id, qrCode);
        return ticketData;
      })
      .then((ticketData) => {
        return getImageAsBase64({
          ticket: ticketData,
          lang: FILE_LANG,
          demo: IS_DEMO_MODE
        } as IGetImageAsBase64);
      })
      .then((res) => setTicketImage(res));
  }, []);

  useEffect(() => {
    handlePreviewImage();
  }, []);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="ticket-preview-modal">
            <h2 className="mb-2">{t("eventsModals:ticketPreview.title")}</h2>
            {ticketImage ? (
              <img src={ticketImage} alt="event ticket" className="ticket-preview-modal-image" />
            ) : (
              <TicketSkeleton />
            )}

            <Button onClick={closeModal} className="mt-5">
              {t("common:close")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default TicketPreview;
