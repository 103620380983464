import React from "react";

const TextHighlight: React.FC<{ width?: number; height?: number; className?: string }> = ({
  width = 16,
  height = 16,
  className = ""
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M3 19h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"></path>
      <path d="M12.5 5.5l4 4"></path>
      <path d="M4.5 13.5l4 4"></path>
      <path d="M21 15v4h-8l4 -4z"></path>
    </svg>
  );
};

export default TextHighlight;
