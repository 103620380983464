export const tableHeaders = [
  {
    label: "gatesSettings.tableHeaders.number",
    className: "number-cell"
  },
  {
    label: "gatesSettings.tableHeaders.name"
  },
  {
    label: "gatesSettings.tableHeaders.controller"
  },
  {
    label: "gatesSettings.tableHeaders.actions",
    className: " acitons-cell"
  }
];
