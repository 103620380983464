import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import Stack from "@components/arrangement/Stack/Stack";
import AuthSidebar from "@components/layoutElements/AuthSidebar/AuthSidebar";

import "./AuthLayout.scss";

const AuthLayout = ({ children }: IChildren) => {
  return (
    <div className="auth-layout">
      <div className="sidebar-part">
        <AuthSidebar />
      </div>

      <div className="content-part">
        <Stack justifyContent="center" className="auth-layout-content">
          <img src="/images/skey-tix-logo-big.svg" alt="skey tix logo" className="mb-5" width={230} height={57} />
          {children}
        </Stack>
      </div>
    </div>
  );
};

export default AuthLayout;
