import { TFunction } from "i18next";
import * as yup from "yup";

export const createWorkerTokenSchema = (errorsT: TFunction) =>
  yup.object({
    label: yup
      .string()
      .max(20, errorsT("string.max", { count: 20 }))
      .required(errorsT("required")),
    controller: yup
      .string()
      .typeError(errorsT("string.base"))
      .max(20, errorsT("string.max", { count: 20 }))
  });
