import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import "./NavLink.scss";

export interface NavLinkProps {
  to: string;
  name: string;
  caseSensitive?: boolean;
  end?: boolean;
}
const NavLink = ({ to, name, caseSensitive, end }: NavLinkProps) => {
  return (
    <RouterLink
      to={to}
      caseSensitive={caseSensitive}
      end={end}
      className={({ isActive }) => `nav-link ${isActive ? "nav-link-active" : ""}`}
    >
      {name}
    </RouterLink>
  );
};

export default NavLink;
