import React, { useEffect, useRef } from "react";
import { debounce } from "@utils/debounce";

const SCROLL_OFFSET = 5;

const calcDistanceFromBottom = (scrollHeight: number, scrollTop: number, clientHeight: number) => {
  const scrollBottom = scrollHeight - (scrollTop + clientHeight);
  return `${scrollBottom}px`;
};

export const useScrollShadow = (scrollShadowSize: number = 25, deps?: unknown[]) => {
  const wrapperRef = useRef<any>(null);
  const contentRef = useRef<any>(null);

  const isOnTopRef = useRef(true);
  const isOnBottomRef = useRef(false);

  const onScrollHandler = (event: Event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target as any;

    const wrapper = getWrapperElement();

    const hitTop = scrollTop < SCROLL_OFFSET;
    const hitBottom = scrollTop > scrollHeight - clientHeight - SCROLL_OFFSET || scrollHeight === clientHeight;

    const scrollTopPx = `${scrollTop}px`;
    const scrollBottomPx = calcDistanceFromBottom(scrollHeight, scrollTop, clientHeight);

    wrapper?.style.setProperty("--scroll-top", scrollTopPx);
    wrapper?.style.setProperty("--scroll-bottom", scrollBottomPx);

    if (hitTop !== isOnTopRef.current) {
      isOnTopRef.current = hitTop;
      const shadowTopOpacity = hitTop ? "0" : "1";
      wrapper?.style.setProperty("--shadow-top-opacity", shadowTopOpacity);
    }

    if (hitBottom !== isOnBottomRef.current) {
      isOnBottomRef.current = hitBottom;
      const shadowBottomOpacity = hitBottom ? "0" : "1";
      wrapper?.style.setProperty("--shadow-bottom-opacity", shadowBottomOpacity);
    }
  };

  const getWrapperElement = () => {
    wrapperRef.current ||= contentRef.current?.parentElement;
    return wrapperRef.current;
  };

  useEffect(() => {
    const content = contentRef.current;
    const wrapper = getWrapperElement();
    if (!content || !wrapper) {
      return;
    }
    wrapper.className += " scroll-shadow-wrapper";
    wrapper?.style.setProperty("--scroll-shadow-size", `${scrollShadowSize}px`);

    const hitBottom =
      content.scrollTop > content.scrollHeight - content.clientHeight - SCROLL_OFFSET ||
      content.scrollHeight === content.clientHeight;

    isOnBottomRef.current = hitBottom;
    const shadowBottomOpacity = hitBottom ? "0" : "1";
    wrapper?.style.setProperty("--shadow-bottom-opacity", shadowBottomOpacity);

    const scrollTopPx = `${content.scrollTop}px`;
    const scrollBottomPx = calcDistanceFromBottom(content.scrollHeight, content.scrollTop, content.clientHeight);

    wrapper?.style.setProperty("--scroll-top", scrollTopPx);
    wrapper?.style.setProperty("--scroll-bottom", scrollBottomPx);

    let height = content.clientHeight;

    const resizeObserver = new ResizeObserver(
      debounce((entries: any) => {
        const entry = entries[0];
        const { clientHeight } = entry.target;
        if (clientHeight !== height) {
          height = clientHeight;
          onScrollHandler(entry);
        }
      }, 300)
    );
    resizeObserver.observe(content);

    content.addEventListener("scroll", onScrollHandler);
    return () => content.removeEventListener("scroll", onScrollHandler);
  }, deps || []);

  return { wrapperRef, contentRef };
};
