import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Motion from "@components/ux/Motion/Motion";
import { formatDateTime } from "@utils/formatDateTime";
import { PATHS } from "src/router/paths";

import { EventNavItemProps } from "./EventNavItem.types";
import "./EventNavItem.scss";

const EventNavItem = ({
  eventId,
  name,
  startDate,
  timezone,
  ticketsBurned,
  ticketsMinted,
  eventStatus,
  submissionsCount,
  occasionalNFT
}: EventNavItemProps) => {
  const navigate = useNavigate();
  const { eventId: eventIdParam } = useParams();

  const { t } = useTranslation("eventsViews");
  const navigateToEvent = (eventId: string) => () => navigate(PATHS.event.getPath(eventId));

  const isSelected = useMemo(() => eventIdParam === eventId, [eventIdParam, eventId]);
  const buildTicketCounter = useMemo(() => {
    return ticketsMinted ? `${ticketsBurned}/${ticketsMinted}` : t("noTicket");
  }, [ticketsBurned, ticketsMinted]);

  const buildClass = `event-nav-item ${isSelected ? "event-nav-item-selected" : ""} ${
    eventStatus === "ended" ? "event-item-status-ended" : ""
  }`;

  return (
    <button onClick={navigateToEvent(eventId)} className={buildClass}>
      <Stack rowGap={6} justifyContent="space-between" className="event-nav-item-content">
        <Group justifyContent="space-between" fullWidth>
          <p className="p4">{formatDateTime(startDate, timezone)}</p>

          {eventStatus === "started" && (
            <Motion appear={true} duration={0}>
              <div className="event-started-dot" />
            </Motion>
          )}
          {eventStatus === "ended" && (
            <Motion appear={true} duration={0}>
              <p className="event-ended p4 op-60">{t("eventStatus.ended")}</p>
            </Motion>
          )}
        </Group>
        <h3 className="event-nav-item-name">{name}</h3>

        <Group justifyContent="space-between" alignItems="center" fullWidth>
          <Group colGap={10}>
            <img src="/images/icon-event-ticket.svg" alt="event tickets" />
            <p className="p4">{buildTicketCounter}</p>
          </Group>

          <Group colGap={10}>
            {occasionalNFT && <img src="/images/icon-event-has-nft.svg" alt="event has nft" />}
            <img src="/images/icon-event-members.svg" alt="event members" />
            <p className="p0">{submissionsCount}</p>
          </Group>
        </Group>
      </Stack>
    </button>
  );
};

export default EventNavItem;
