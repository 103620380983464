import { SubmissionTableHeaderData } from "../SubmissionsTable.types";

export const tableHeaders: SubmissionTableHeaderData[] = [
  // Column 1
  {
    rows: [
      {
        label: "",
        sortable: false
      }
    ],
    className: "cell-checkbox"
  },
  // Column 2
  {
    rows: [
      {
        label: "submissionsTable.headers.memberName",
        sortable: true,
        orderBy: "lastName"
      },
      {
        label: "submissionsTable.headers.email",
        sortable: false
      }
    ],
    className: "cell-memebr-data"
  },
  // Column 3
  {
    rows: [
      {
        label: "submissionsTable.headers.notes",
        sortable: false
      }
    ],
    className: "cell-notes"
  },
  // Column 4
  {
    rows: [
      {
        label: "submissionsTable.headers.signUpDate",
        sortable: true,
        orderBy: "createdAt"
      },
      {
        label: "submissionsTable.headers.sendTicketDate",
        sortable: false
      }
    ],
    className: "cell-dates"
  },
  // Column 5
  {
    rows: [
      {
        label: "submissionsTable.headers.actions",
        sortable: false
      }
    ],
    className: "cell-actions"
  }
];
