import React, { useState, FocusEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";
import { validateWalletAddress } from "@services/blockchain/utils/validateWalletAddress";

import SearchInput from "@components/dataEntry/SearchInput/SearchInput";
import NftCollection from "@app/poap-nft/components/NftCollection/NftCollection";

import ExploreNftsEmptyState from "@app/poap-nft/components/ExploreNftsEmptyState/ExploreNftsEmptyState";
import ExploreNftsLoader from "@app/poap-nft/components/ExploreNftsLoader/ExploreNftsLoader";
import ExploreNftsError from "@app/poap-nft/components/ExploreNftsError/ExploreNftsError";

import "./ExploreNfts.scss";

const ExploreNfts = () => {
  const { nfts, status, error, walletAddress, updateWalletAddress } = useBrowseNfts();

  const [searchWalletAddress, setSearchWalletAddress] = useState(walletAddress);
  const [walletAddressError, setWalletAddressError] = useState("");

  const { t } = useTranslation(["poapNft"]);
  const { t: errorsT } = useTranslation("formErrors");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWalletAddress(e.target.value);
  };

  const handleInputBlur = (e: FocusEvent<any, Element>) => {
    const { value } = e.target;

    setWalletAddressError("");
    const error = value.length > 0 ? validateWalletAddress(errorsT, value) : null;

    if (!error) {
      updateWalletAddress(value);
    } else {
      setWalletAddressError(error);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      setSearchWalletAddress(walletAddress);
      setWalletAddressError("");
    }
  }, [walletAddress]);

  const isEmpty = (nfts.length <= 0 && status === "success") || status === "idle";
  const loading = status === "loading";
  const isError = status === "failed" || error;

  return (
    <div className="explore-nfts-page">
      <div className="explore-nfts-page-header">
        <h1 className="title">{t("poapNft:browseNfts")}</h1>

        <SearchInput
          name="wallet-address"
          label={t("poapNft:exploreNfts.searchWallet.label")}
          placeholder={t("poapNft:exploreNfts.searchWallet.placeholder")}
          value={searchWalletAddress}
          paddingSize="big"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          error={walletAddressError}
          className="saerch-nft"
        />
      </div>

      <div className="explore-nfts-page-content">
        {isEmpty ? (
          <ExploreNftsEmptyState />
        ) : (
          <div className="explore-nfts-list">
            {nfts.map(({ year, data }) => (
              <NftCollection key={year} title={year} collection={data} />
            ))}
          </div>
        )}

        {loading && <ExploreNftsLoader />}
        {isError && <ExploreNftsError />}
      </div>
    </div>
  );
};

export default ExploreNfts;
