import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@components/arrangement/Stack/Stack";

import "./SubmissionsTableNoMatch.scss";

const SubmissionsTableNoMatch = () => {
  const { t } = useTranslation("common");

  return (
    <Stack rowGap={24} alignItems="center" fullWidth className="mt-6">
      <img src="/images/icon-submissions-no-match.svg" alt="no match members" width={43} height={45} />

      <p className="p0">{t("noMatchingResults")}</p>
    </Stack>
  );
};

export default SubmissionsTableNoMatch;
