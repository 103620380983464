import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";
import { useApiKeys } from "@panel/Settings/contexts/ApiKeysContext";
import "./RemoveApiKey.scss";

interface RemoveApiKeyProps {
  apiKeyId: string;
  apiKeyName: string;
}

const RemoveApiKey = ({ apiKeyId, apiKeyName }: RemoveApiKeyProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useTranslation(["settingsPage", "common"]);

  const { removeApiKey: fetchRemoveApiKey } = useApiClient();
  const { closeModal } = useSettingsModals();
  const { removeApiKey } = useApiKeys();

  const handleRemoveAPiKey = () => {
    setSubmitting(true);

    fetchRemoveApiKey(apiKeyId)
      .then((res) => {
        if (res?.status === 200) {
          removeApiKey(apiKeyId);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack rowGap={20} className="remove-api-key-modal">
            <p className="p0 mt-4">
              <Trans
                t={t}
                i18nKey="settingsPage:removeApiKeyModal.description"
                values={{ apiKeyName }}
                components={{ br: <br />, strong: <strong /> }}
              />
            </p>
            <Group colGap={20} className="mt-3">
              <Button type="submit" variant="primary" onClick={handleRemoveAPiKey} loading={submitting}>
                {t("common:remove")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default RemoveApiKey;
