import React from "react";
import classNames from "classnames";

import useVisible from "@hooks/useVisible";
import Input from "../Input/Input";

import ShowPasswordButton from "./ShowPasswordButton/ShowPasswordButton";
import { PasswordInputProps } from "./PasswordInput.types";
import "./PasswordInput.scss";

const PasswordInput = ({
  id,
  name,
  value,
  label = "",
  placeholder = "",
  description = "",
  error = "",
  disabled = false,
  required = false,
  onChange,
  onBlur,
  className = ""
}: PasswordInputProps) => {
  const { isVisible, visibilityToggle } = useVisible(false);
  const classes = classNames("password-input", className);

  return (
    <Input
      id={id}
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      description={description}
      error={error}
      disabled={disabled}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      type={isVisible ? "text" : "password"}
      autoComplete="current-password"
      inputAdornment={<ShowPasswordButton isVisible={isVisible} onClick={visibilityToggle} />}
      className={classes}
    />
  );
};

export default PasswordInput;
