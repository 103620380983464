import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./OrderNftModule.scss";

const OrderNftModule = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { eventId } = useParams();

  const { orderNftModule } = useApiClient();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);

  const handleOrderNftModule = () => {
    setSubmitting(true);

    orderNftModule(eventId || "")
      .then((res) => {
        if (res?.status === 204) {
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="order-nft-module-modal">
            <h2>{t("eventsModals:orderOccasionalNftModule.title")}</h2>
            <p className="p0 mt-4 mb-4">{t("eventsModals:orderOccasionalNftModule.description")}</p>

            <Group colGap={20}>
              <Button type="submit" variant="primary" onClick={handleOrderNftModule} loading={submitting}>
                {t("common:enable")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default OrderNftModule;
