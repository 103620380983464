import React from "react";
import { useScrollShadow } from "@components/ux/ScrollShadowWrapper/useScrollShadow";
import "./ScrollShadowWrapper.scss";

interface ScrollShadowWrapperProps {
  children: React.ReactNode;
  contentClassName: string;
  scrollShadowSize?: number;
}

const ScrollShadowWrapper = ({ children, contentClassName, scrollShadowSize }: ScrollShadowWrapperProps) => {
  const { contentRef, wrapperRef } = useScrollShadow(scrollShadowSize);

  return (
    <div ref={wrapperRef}>
      <div ref={contentRef} className={contentClassName}>
        {children}
      </div>
    </div>
  );
};

export default ScrollShadowWrapper;
