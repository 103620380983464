import React from "react";
import classNames from "classnames";

import "./UtilsButton.scss";

interface UtilsButtonProps {
  title?: string;
  className?: string;
  onClick?: () => void;
  onBlur?: () => void;
  children: React.ReactNode | React.ReactElement;
}

const UtilsButton = ({ title, className, onClick, onBlur, children }: UtilsButtonProps) => {
  const classes = classNames("image-cropper-utils-btn", className);

  return (
    <button title={title} className={classes} onClick={onClick} onBlur={onBlur}>
      {children}
    </button>
  );
};

export default UtilsButton;
