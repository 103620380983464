import React from "react";

import Group from "@components/arrangement/Group/Group";
import DemoMark from "@components/common/DemoMark/DemoMark";

import "./BaseHeader.scss";

const BaseHeader = () => {
  return (
    <div className="base-header">
      <Group justifyContent="space-between" className="base-header-content">
        <DemoMark>
          <img src="/images/skey-tix-logo.svg" alt="skey tix logo" />
        </DemoMark>
      </Group>
    </div>
  );
};

export default BaseHeader;
