import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import Header from "@components/layoutElements/Header/Header";
import Navbar from "@components/layoutElements/Navbar/Navbar";
import Footer from "@components/layoutElements/Footer/Footer";

import "./PanelLayout.scss";

const PanelLayout = ({ children }: IChildren) => {
  return (
    <div className="panel-layout">
      <div className="header-part">
        <Header />
      </div>
      <div className="navbar-part">
        <Navbar />
      </div>
      <div className="content-part">{children}</div>
      <div className="footer-part">
        <Footer />
      </div>
    </div>
  );
};

export default PanelLayout;
