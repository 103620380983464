import { ClaimingUserDto, SubmissionDto, TicketDto, WorkerTokenDto } from "@services/Api/api.dto";
import { modalsContextFactory } from "@contexts/ModalsContextFactory";

interface WorkerTokenModal {
  type: "editWorkerToken" | "removeWorkerToken";
  data: WorkerTokenDto;
}

interface TicketModal {
  type: "ticketPreview";
  data: TicketDto;
}

interface SubmissionModal {
  type: "submissionDetails";
  data: SubmissionDto;
}

interface SendTicketModal {
  type: "sendTicket";
  data: SubmissionDto;
}

interface EventNft {
  type: "sendNft" | "nftPreview";
  data: ClaimingUserDto;
}

interface WithoutObjectModal {
  type:
    | "createEvent"
    | "editEvent"
    | "editEventPageContent"
    | "editEventPageSettings"
    | "editAgreements"
    | "createWorkerToken"
    | "editTicketSettings"
    | "orderTickets"
    | "removeEvent"
    | "sendGroupTickets"
    | "sendAllTickets"
    | "sendGroupNfts"
    | "sendAllNfts"
    | "orderNftModule"
    | "createEventNft"
    | "editEventNft"
    | "editEventNftSettings";

  data?: null;
}

export type EventModal =
  | WorkerTokenModal
  | TicketModal
  | SubmissionModal
  | SendTicketModal
  | EventNft
  | WithoutObjectModal;

export const { ModalsProvider: EventsModalsProvider, useModalsContext: useEventsModals } =
  modalsContextFactory<EventModal>();
