import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const Telegram = ({ width = 30, height = 31, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 31"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1388 0C7.19916 0 0.703125 6.77002 0.703125 15.0445C0.703125 23.319 7.19916 30.089 15.1388 30.089C23.0784 30.089 29.5744 23.319 29.5744 15.0445C29.5744 6.77002 23.0784 0 15.1388 0ZM18.9883 22.4414C19.2289 22.5667 19.4695 22.6921 19.71 22.5667C19.9506 22.4414 20.1912 22.1906 20.1912 21.9399C20.7927 18.931 22.2363 11.4087 22.8378 8.65058C22.8378 8.39984 22.8378 8.27447 22.5972 8.1491C22.4769 8.02373 22.2363 8.02373 21.9957 8.02373C19.1086 9.15207 9.96599 12.6624 6.23679 14.1669C5.99619 14.2923 5.8759 14.543 5.8759 14.7937C5.8759 15.0445 5.99619 15.2952 6.23679 15.2952C7.92094 15.7967 10.0863 16.5489 10.0863 16.5489C10.0863 16.5489 11.0487 19.8086 11.6502 21.4384C11.7704 21.6891 11.8907 21.8145 12.1313 21.8145C12.3719 21.8145 12.4922 21.8145 12.7328 21.6891C13.5749 20.8115 14.8982 19.5578 14.8982 19.5578C14.8982 19.5578 17.5447 21.313 18.9883 22.4414ZM11.2893 16.0475L12.4922 20.0593L12.7328 17.5519C12.7328 17.5519 17.3041 13.2893 19.8303 10.7819C19.9506 10.7819 19.9506 10.6565 19.9506 10.5311C19.8303 10.4058 19.8303 10.4058 19.71 10.5311C16.5823 12.5371 11.2893 16.0475 11.2893 16.0475Z"
        fill={color}
      />
    </svg>
  );
};

export default Telegram;
