import React, { useCallback, useState } from "react";
import { nanoid } from "nanoid";

import { IContext } from "@src/types/IContext.types";
import NotificationPanel from "@components/layoutElements/NotificationPanel/NotificationPanel";

export type NotificationVariant = "info" | "success" | "error";

export interface Notification {
  id: string;
  variant: NotificationVariant;
  message: string;
}

interface ContextValue {
  notifications: Notification[];
  notify: (variant: NotificationVariant, message: string) => void;
  removeNotification: (id: string) => void;
  clearNotifications: () => void;
}

const NotificationContext = React.createContext(null as any);

export const NotificationProvider = ({ children }: IContext) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notify = useCallback((variant: NotificationVariant, message: string) => {
    const id = nanoid();
    const notification = { id, variant, message };
    return setNotifications((prevState) => [...prevState, notification]);
  }, []);

  const removeNotification = useCallback((id: string) => {
    return setNotifications((prevState) => prevState.filter((notification) => notification.id !== id));
  }, []);

  const clearNotifications = useCallback(() => {
    return setNotifications([]);
  }, []);

  const contextValue: ContextValue = {
    notifications,
    notify,
    removeNotification,
    clearNotifications
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <NotificationPanel />
    </NotificationContext.Provider>
  );
};

export const useNotification = (): ContextValue => React.useContext(NotificationContext);
