import React from "react";

const InfoIcon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  opacity?: string | number;
  className?: string;
}> = ({ width = 20, height = 20, color = "white", opacity = 0.6, className = "" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity={opacity}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM11.25 5C11.25 5.33152 11.1183 5.64946 10.8839 5.88388C10.6495 6.1183 10.3315 6.25 10 6.25C9.66848 6.25 9.35054 6.1183 9.11612 5.88388C8.8817 5.64946 8.75 5.33152 8.75 5C8.75 4.66848 8.8817 4.35054 9.11612 4.11612C9.35054 3.8817 9.66848 3.75 10 3.75C10.3315 3.75 10.6495 3.8817 10.8839 4.11612C11.1183 4.35054 11.25 4.66848 11.25 5ZM8.75 8.75C8.50136 8.75 8.2629 8.84877 8.08709 9.02459C7.91127 9.2004 7.8125 9.43886 7.8125 9.6875C7.8125 9.93614 7.91127 10.1746 8.08709 10.3504C8.2629 10.5262 8.50136 10.625 8.75 10.625H9.06625C9.11301 10.625 9.15917 10.6355 9.20133 10.6558C9.24349 10.676 9.28057 10.7054 9.30984 10.7419C9.3391 10.7784 9.35982 10.8209 9.37045 10.8665C9.38107 10.912 9.38135 10.9593 9.37125 11.005L8.7975 13.5875C8.72634 13.9074 8.72796 14.2391 8.80223 14.5583C8.87651 14.8775 9.02154 15.1759 9.22661 15.4315C9.43168 15.6871 9.69156 15.8933 9.98704 16.035C10.2825 16.1767 10.6061 16.2501 10.9338 16.25H11.25C11.4986 16.25 11.7371 16.1512 11.9129 15.9754C12.0887 15.7996 12.1875 15.5611 12.1875 15.3125C12.1875 15.0639 12.0887 14.8254 11.9129 14.6496C11.7371 14.4738 11.4986 14.375 11.25 14.375H10.9338C10.887 14.375 10.8408 14.3645 10.7987 14.3442C10.7565 14.324 10.7194 14.2946 10.6902 14.2581C10.6609 14.2216 10.6402 14.1791 10.6296 14.1335C10.6189 14.088 10.6187 14.0407 10.6287 13.995L11.2025 11.4125C11.2737 11.0926 11.272 10.7609 11.1978 10.4417C11.1235 10.1225 10.9785 9.82413 10.7734 9.56854C10.5683 9.31295 10.3084 9.1067 10.013 8.96502C9.71748 8.82334 9.39394 8.74986 9.06625 8.75H8.75Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
