import React from "react";
import classNames from "classnames";

import HorizontalLine from "src/assets/PlusIcon/HorizontalLine";
import VerticalLine from "src/assets/PlusIcon/VerticalLine";

import { AccordionActiveState } from "../Accordion.types";
import "./AccordionPlusIcon.scss";

export interface AccordionPlusIconProps {
  variant: AccordionActiveState;
}

const AccordionPlusIcon = ({ variant }: AccordionPlusIconProps) => {
  const classes = classNames(`accordion-plus-icon accordion-plus-icon-${variant}`);

  return (
    <div role="button" className={classes}>
      <HorizontalLine className="plus-line horizontal" />
      <VerticalLine className="plus-line vartical" />
    </div>
  );
};

export default AccordionPlusIcon;
