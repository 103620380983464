import React, { useState, useEffect } from "react";

interface MovingImageProps {
  href: string;
  force?: number;
  className?: string;
}

const MovingImage = ({ href, force = 2, className }: MovingImageProps) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const { x, y } = mousePosition;

  const windowWidth = window.innerWidth / force;
  const windowHeight = window.innerHeight / force;
  const mouseX = x / windowWidth;
  const mouseY = y / windowHeight;

  const imageStyle: React.CSSProperties = {
    backgroundImage: `url(${href})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    position: "absolute",
    transform: `translate3d(-${mouseX}%, -${mouseY}%, 0)`
  };

  return <div role="img" style={imageStyle} className={className} />;
};

export default MovingImage;
