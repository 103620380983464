import React, { memo } from "react";
import classNames from "classnames";

import { CheckboxProps } from "./Checkbox.types";
import "./Checkbox.scss";

const Checkbox = ({
  id,
  name,
  value,
  label,
  checked,
  defaultChecked,
  disabled = false,
  required = false,
  readonly = false,
  error = "",
  onChange,
  className = "",
  labelTextSize = "normal"
}: CheckboxProps) => {
  const inputId = React.useId();

  const checkboxClasses = classNames("checkbox", { "checkbox-disabled": disabled, "checkbox-error": error }, className);
  const labelClasses = classNames(`label-text-${labelTextSize}`);

  return (
    <div className="checkbox-wrapper">
      <label htmlFor={id || name || inputId} className={checkboxClasses}>
        <input
          type="checkbox"
          id={id || name || inputId}
          name={name || inputId}
          value={value}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          readOnly={readonly}
          className="checkbox-input"
        />
        <span className="checkbox-input-box" />

        {label && (
          <div className={labelClasses}>
            {label}
            {required && <span className="label-asterisk">*</span>}
          </div>
        )}
      </label>

      {error && <p className="checkbox-error-text">{error}</p>}
    </div>
  );
};

export default memo(Checkbox);
