import React from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import NavLink from "@components/navigation/NavLink/NavLink";
import { mainNavPaths } from "src/router/paths";
import "./Navbar.scss";

const Navbar = () => {
  const { t: layoutT } = useTranslation("layout");

  return (
    <div className="navbar">
      <Group className="navbar-content">
        {mainNavPaths.map(({ path, pathName, tKey }) => (
          <NavLink key={nanoid()} to={path} name={layoutT(tKey as any) || (pathName as string)} />
        ))}
      </Group>
    </div>
  );
};

export default Navbar;
