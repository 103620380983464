import { SHARE_NFT_SERVICE_URL } from "@config/config";
import { APP_LANG } from "@config/helpers";

export const generateShareNftUrl = (assetId: string, network?: string) => {
  if (network === "mainnet") {
    return `${SHARE_NFT_SERVICE_URL}/${APP_LANG}/${assetId}`;
  }

  return `${SHARE_NFT_SERVICE_URL}/${APP_LANG}/demo/${assetId}`;
};
