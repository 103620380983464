import React from "react";
import { useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Stack from "@components/arrangement/Stack/Stack";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import Button from "@components/dataEntry/Buttons/Button/Button";
import EventInfoContent from "@components/dataDisplay/TextEditorViewer/TextEditorViewer";

import "./EventPageContent.scss";

const EventPageContent = () => {
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation(["eventsViews", "common"]);

  const handleOpenModal = () => openModal({ type: "editEventPageContent" });

  const editButtonTip = eventStatus === "started" ? t("common:eventStartedDisabled") : t("common:eventEndedDisabled");
  const editButtonDisabled = eventStatus === "started" || eventStatus === "ended";

  const imageSrc = event.page.imageUrl || "/images/event-image-placeholder.svg";

  return (
    <div className="event-page-content">
      <h3 className="event-page-content-title">{t("eventsViews:eventPageContent.title")}</h3>
      <div className="event-page-content-content">
        <Stack rowGap={15} className=" mb-4">
          <p className="p4 black-2">{t("eventsViews:eventPageContent.image")}</p>
          <img src={imageSrc} alt="event image" className="event-page-content-image" />
        </Stack>

        {event.page.content && (
          <Stack rowGap={15} className="mt-3 mb-4">
            <p className="p4 black-2">{t("eventsViews:eventPageContent.description")}</p>
            <div className="event-page-content-description-wrapper">
              <EventInfoContent content={event.page.content} />
              <div className="description-overlay" />
            </div>
          </Stack>
        )}

        <Tooltip tip={editButtonTip} hide={!editButtonDisabled} width={200} textCenter>
          <Button variant="primary" disabled={editButtonDisabled} onClick={handleOpenModal}>
            {t("common:edit")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default EventPageContent;
