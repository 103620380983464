import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";
import { createFileFromBlob } from "@utils/createFileFromBlob";
import { saveFile } from "@utils/saveFile";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useSelectedSubmissions } from "@app/panel/Events/contexts/SelectedSubmissions.context";
import { useSelectedClaimingUsers } from "@app/panel/Events/contexts/SelectedClaimingUsers.context";
import { useTickets } from "@app/panel/Events/contexts/TicketsData.context";
import { useSubmissions } from "@app/panel/Events/contexts/SubmissionsData.context";
import { useClaimingUsers } from "@app/panel/Events/contexts/ClaimingUsersData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useNotification } from "@contexts/Notification.context";

import Button from "@components/dataEntry/Buttons/Button/Button";
import Dropdown from "@components/common/Dropdown/Dropdown";

const EventHeaderActions = () => {
  const location = useLocation();

  const { event } = useSelectedEvent();
  const { selectedSubmissions } = useSelectedSubmissions();
  const { selectedClaimingUsers } = useSelectedClaimingUsers();

  const { tickets } = useTickets();
  const { submissions } = useSubmissions();
  const { claimingUsers } = useClaimingUsers();

  const { openModal } = useEventsModals();
  const { getTicketsFiles, getAttendanceList, getAgreementsList } = useApiClient();
  const { notify } = useNotification();

  const { t } = useTranslation("eventsViews");

  const downloadTicketsFiles = (type: "qr" | "ticketCard") => {
    getTicketsFiles(event.id, type).then((res) => {
      const file = createFileFromBlob(res);
      saveFile(file);
    });
  };

  const downloadAttendanceList = () => {
    getAttendanceList(event.id).then((res) => {
      const file = createFileFromBlob(res);
      saveFile(file);
    });
  };

  const downloadAgreementsList = () => {
    getAgreementsList(event.id).then((res) => {
      const file = createFileFromBlob(res);
      saveFile(file);
    });
  };

  const handleDownloadQrCodes = () => {
    notify("info", t("eventHeader.downloadQrCodesInfo"));
    downloadTicketsFiles("qr");
  };

  const handleDownloadTickets = () => {
    notify("info", t("eventHeader.downloadTicketsInfo"));
    downloadTicketsFiles("ticketCard");
  };

  const handleSendAllTickets = () => {
    openModal({ type: "sendAllTickets" });
  };

  const handleSendGroupTickets = () => {
    if (selectedSubmissions.length <= 0) return;
    openModal({ type: "sendGroupTickets" });
  };

  const handleSendAllNfts = () => {
    openModal({ type: "sendAllNfts" });
  };

  const handleSendGroupNfts = () => {
    if (selectedClaimingUsers.length <= 0) return;
    openModal({ type: "sendGroupNfts" });
  };

  const handleRemoveEvent = () => openModal({ type: "removeEvent" });

  const noSelectedSubmissions = selectedSubmissions.length <= 0;
  const noSelectedClaimingUsers = selectedClaimingUsers.length <= 0;
  const noSubmissions = submissions.data.length <= 0;
  const noTickets = tickets.data.length <= 0;
  const noAgreements = event.agreements.length <= 0;
  const noClaimingUsers = claimingUsers.data.length <= 0;
  const eventHasEnableNft = event.page.occasionalNFT;
  const eventEnded = event.status === "ended";

  const downloadTicketsDisabled = noTickets;
  const downloadAttendanceListDisabled = noSubmissions;
  const downloadAgreementsListDisabled = noSubmissions || noAgreements;
  const sendTicketsDisabled = noTickets || noSubmissions || eventEnded;
  const removeEventDisabled = event.status !== "draft";
  const sendAllNftsDisabled = noClaimingUsers || !eventHasEnableNft || !eventEnded;
  const sendGroupNftsDisabled = noClaimingUsers || noSelectedClaimingUsers || !eventHasEnableNft || !eventEnded;

  const ticketsActions = useMemo(() => {
    return location.pathname === PATHS.tickets.getPath(event.id);
  }, [location, event]);

  const eventSettingsActions = useMemo(() => {
    return location.pathname === PATHS.eventSettings.getPath(event.id);
  }, [location, event]);

  const submissionsActions = useMemo(() => {
    return location.pathname === PATHS.eventSubmissions.getPath(event.id);
  }, [location, event]);

  const nftActions = useMemo(() => {
    return location.pathname === PATHS.eventNft.getPath(event.id);
  }, [location, event]);

  return (
    <Dropdown>
      <Dropdown.Target>
        <Button variant="secondary">{t("eventHeader.dropdownButton")}</Button>
      </Dropdown.Target>

      <Dropdown.Menu>
        {ticketsActions && (
          <>
            <Dropdown.MenuItem onClick={handleDownloadTickets} disabled={downloadTicketsDisabled}>
              {t("eventHeader.dropdownActions.downloadTickets")}
            </Dropdown.MenuItem>

            <Dropdown.MenuItem onClick={handleDownloadQrCodes} disabled={downloadTicketsDisabled}>
              {t("eventHeader.dropdownActions.downloadQrCodes")}
            </Dropdown.MenuItem>
          </>
        )}

        {eventSettingsActions && (
          <>
            <Dropdown.MenuItem onClick={handleRemoveEvent} disabled={removeEventDisabled}>
              {t("eventHeader.dropdownActions.deleteEvent")}
            </Dropdown.MenuItem>
          </>
        )}

        {submissionsActions && (
          <>
            <Dropdown.MenuItem onClick={handleSendAllTickets} disabled={sendTicketsDisabled}>
              {t("eventHeader.dropdownActions.sendTicketsAllSubmissions")}
            </Dropdown.MenuItem>

            <Dropdown.MenuItem onClick={handleSendGroupTickets} disabled={sendTicketsDisabled || noSelectedSubmissions}>
              {t("eventHeader.dropdownActions.sendTicketsForSelectedSubmissions")}
            </Dropdown.MenuItem>

            <Dropdown.MenuItem onClick={downloadAttendanceList} disabled={downloadAttendanceListDisabled}>
              {t("eventHeader.dropdownActions.downloadAttendanceList")}
            </Dropdown.MenuItem>

            <Dropdown.MenuItem onClick={downloadAgreementsList} disabled={downloadAgreementsListDisabled}>
              {t("eventHeader.dropdownActions.downloadAgreementsList")}
            </Dropdown.MenuItem>
          </>
        )}

        {nftActions && (
          <>
            <Dropdown.MenuItem onClick={handleSendAllNfts} disabled={sendAllNftsDisabled}>
              {t("eventHeader.dropdownActions.sendNftAllSubmissions")}
            </Dropdown.MenuItem>

            <Dropdown.MenuItem onClick={handleSendGroupNfts} disabled={sendGroupNftsDisabled}>
              {t("eventHeader.dropdownActions.sendNftForSelectedSubmissions")}
            </Dropdown.MenuItem>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EventHeaderActions;
