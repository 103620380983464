import commonEn from "@locales/en/common.json";
import formErrorsEn from "@locales/en/form_errors.json";
import apiResponseEn from "@locales/en/api_response.json";
import layoutEn from "@locales/en/layout.json";
import authViewsEn from "@locales/en/auth_views.json";
import eventsViewsEn from "@locales/en/events_views.json";
import eventsModalsEn from "@locales/en/events_modals.json";
import settingsPageEn from "@locales/en/settings_page.json";
import helpPageEn from "@locales/en/help_page.json";
import publicEn from "@locales/en/public.json";
import poapNftEn from "@locales/en/poap_nft.json";
import notFoundEn from "@locales/en/not_found.json";
import recaptchaEn from "@locales/en/recaptcha.json";
import timezonesEn from "@locales/en/timezones.json";

import commonPl from "@locales/pl/common.json";
import formErrorsPl from "@locales/pl/form_errors.json";
import apiResponsePl from "@locales/pl/api_response.json";
import layoutPl from "@locales/pl/layout.json";
import authViewsPl from "@locales/pl/auth_views.json";
import eventsViewsPl from "@locales/pl/events_views.json";
import eventsModalsPl from "@locales/pl/events_modals.json";
import settingsPagePl from "@locales/pl/settings_page.json";
import helpPagePl from "@locales/pl/help_page.json";
import publicPl from "@locales/pl/public.json";
import poapNftPl from "@locales/pl/poap_nft.json";
import notFoundPl from "@locales/pl/not_found.json";
import recaptchaPl from "@locales/pl/recaptcha.json";
import timezonesPl from "@locales/pl/timezones.json";

export const resources = {
  en: {
    common: commonEn,
    formErrors: formErrorsEn,
    apiResponse: apiResponseEn,
    layout: layoutEn,
    authViews: authViewsEn,
    eventsViews: eventsViewsEn,
    eventsModals: eventsModalsEn,
    settingsPage: settingsPageEn,
    helpPage: helpPageEn,
    public: publicEn,
    poapNft: poapNftEn,
    notFound: notFoundEn,
    recaptcha: recaptchaEn,
    timezones: timezonesEn
  },
  pl: {
    common: commonPl,
    formErrors: formErrorsPl,
    apiResponse: apiResponsePl,
    layout: layoutPl,
    authViews: authViewsPl,
    eventsViews: eventsViewsPl,
    eventsModals: eventsModalsPl,
    settingsPage: settingsPagePl,
    helpPage: helpPagePl,
    public: publicPl,
    poapNft: poapNftPl,
    notFound: notFoundPl,
    recaptcha: recaptchaPl,
    timezones: timezonesPl
  }
};
