import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";
import { deviceInfo } from "@utils/browser/deviceInfo";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";

import Button from "@components/dataEntry/Buttons/Button/Button";

import "./PoapNft.scss";

const PoapNft = () => {
  const navigate = useNavigate();
  const { openModal } = usePoapNftModals();

  const { t } = useTranslation(["poapNft", "common"]);

  const goToSearchNfts = () => navigate(PATHS.poapNftSearch.path);

  const isMobile = deviceInfo.isMobileDevice();
  const openConnectWallet = () => openModal({ type: "connectWallet" });

  return (
    <div className="poap-nft-page">
      <div className="poap-nft-page-header">
        <h1 className="header-title">{t("poapNft:poapNftPage.title")}</h1>
        <object type="image/svg+xml" data="/animations/nft-hands.svg" className="header-image" />
      </div>

      <div className="poap-nft-page-content">
        <h2>{t("poapNft:poapNftPage.description")}</h2>

        <div className="poap-buttons">
          {!isMobile && (
            <Button size="big" onClick={openConnectWallet} disabled={isMobile}>
              {t("common:connectWallet")}
            </Button>
          )}

          <Button size="big" variant="secondary" onClick={goToSearchNfts}>
            {t("common:browseNfts")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PoapNft;
