import { TFunction } from "i18next";
import { ApiKeyAccess } from "@services/Api/api.dto";

export const formatAccessPrivileges = (access: ApiKeyAccess, t: TFunction) => {
  switch (access) {
    case "read":
      return t("accessPrivilegesOptions.onlyRead");
    case "write":
      return t("accessPrivilegesOptions.writeRead");
    default:
      return t("accessPrivilegesOptions.onlyRead");
  }
};
