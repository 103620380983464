import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { AxiosError } from "axios";

import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { ClaimingOccasionalNftResponseDto } from "@services/Api/api.dto";
import { HttpError } from "@services/httpErrors.enum";

import { usePublicApiClient } from "@services/Api/PublicApiClientContext";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import SeedDisplay from "@components/dataDisplay/SeedDisplay/SeedDisplay";
import CopyButton from "@components/dataEntry/Buttons/CopyButton/CopyButton";
import ReceivedNftPreview from "./components/ReceivedNftPreview/ReceivedNftPreview";
import Spinner from "@components/ux/Spinner/Spinner";

import "./ClaimPage.scss";

interface ClaimingData {
  data: ClaimingOccasionalNftResponseDto | null;
  status?: FetchStatus;
  error: string | null;
}

const CLAIM_JWT_TOKEN_PARAM = "token";

const initialClaimingData = () => ({
  data: null,
  status: undefined,
  error: null
});

const ClaimPage = () => {
  const [claimingData, setClaimingData] = useState<ClaimingData>(initialClaimingData());

  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get(CLAIM_JWT_TOKEN_PARAM);

  const { claimingOccasionalNft } = usePublicApiClient();
  const { t } = useTranslation(["public", "apiResponse", "common"]);

  const handleSetData = (data: ClaimingOccasionalNftResponseDto | null) => {
    setClaimingData((prevState) => ({ ...prevState, data }));
  };

  const handleSetStatus = (status: FetchStatus | undefined) => {
    setClaimingData((prevState) => ({ ...prevState, status }));
  };

  const handleSetError = (error: string | null) => {
    setClaimingData((prevState) => ({ ...prevState, error }));
  };

  const handleClaim = async () => {
    try {
      initialClaimingData();
      handleSetStatus("loading");

      if (!tokenParam) {
        handleSetStatus("failed");
        handleSetError(t("apiResponse:errors.claimingOccasionalNftInvalidUrl"));
        return;
      }

      const res = await claimingOccasionalNft(tokenParam);

      if (res.status === 201) {
        handleSetStatus("success");
        handleSetData(res.data);
      }
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      handleSetStatus("failed");

      if (e?.response?.status === 403 && e?.response?.data.message === HttpError.OCCASIONAL_NFT_JWT_ERROR) {
        handleSetError(t("apiResponse:errors.claimingOccasionalNftInvalidJwt"));
      } else {
        handleSetError(t("apiResponse:errors.claimingOccasionalNft"));
      }
    }
  };

  const isClaimedNft = claimingData.data;
  const isLoading = claimingData.status === "loading";

  return (
    <>
      <Helmet>
        <title>SkeyTix Claim NFT</title>
      </Helmet>

      <div className="claim-page">
        <div className="claim-page-header">
          <h1 className="header-title">{t("public:claimPage.title")}</h1>
          <ReceivedNftPreview url={claimingData?.data?.occasionalNftAssetUrl} isClaimed={!!isClaimedNft} />
        </div>

        <Stack rowGap={24} alignItems="center" fullWidth className="claim-page-content">
          <h2 className="claim-info">{t("public:claimPage.description")}</h2>

          {isLoading && <Spinner className="mt-1" />}

          {!isLoading &&
            (isClaimedNft ? (
              <>
                <SeedDisplay seed={claimingData.data!.seed} className="claim-seed" />
                <CopyButton value={claimingData.data!.seed} />
              </>
            ) : (
              <>
                {claimingData.error && <p className="p0 danger-1">{claimingData.error}</p>}
                <Button onClick={handleClaim} disabled={isLoading}>
                  {t("common:claim")}
                </Button>
              </>
            ))}
        </Stack>
      </div>
    </>
  );
};

export default ClaimPage;
