import { useTranslation } from "react-i18next";
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton } from "react-share";

import { AssetData } from "@services/blockchain/blockchain.types";
import { useWvs } from "@services/blockchain/Waves.context";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";
import { generateShareNftUrl } from "@app/poap-nft/utils/generateShareNftUrl";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";

import CopyText from "@components/dataDisplay/CopyText/CopyText";
import ShareButton from "@components/dataEntry/Buttons/ShareButton/ShareButton";

import "./ShareNft.scss";

const POST_CONTENT = "Take a look at my unique POAP NFT created on the #SkeyNetwork blockchain!";
const HASHTAGS = ["NFT", "POAP", "web3", "skeynetwork"];
const HASHTAGS_FACEBOOK = "#POAP";

interface ShareNftProps {
  nft: AssetData;
}

const ShareNft = ({ nft }: ShareNftProps) => {
  const { closeModal } = usePoapNftModals();
  const { publicState } = useWvs();

  const { t } = useTranslation(["poapNft"]);

  const network = publicState?.account?.network;
  const nftPageUrl = generateShareNftUrl(nft.assetId, network);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal} className="share-nft-modal">
          <Stack rowGap={40} className="share-nft-modal-content">
            <ActionIcon onClick={closeModal} color="gray" className="close-btn">
              <img src="/images/icon-close-modal.svg" alt="Close modal" width={20} height={20} />
            </ActionIcon>

            <h2>{t("poapNft:modals.shareNft.title")}</h2>

            <Stack rowGap={20}>
              <p className="p0">{t("poapNft:modals.shareNft.description")}</p>
              <CopyText text={nftPageUrl} />
            </Stack>

            <Stack rowGap={20} fullWidth>
              <p className="p0">{t("poapNft:modals.shareNft.socialMediaInfo")}</p>

              <Group colGap={10} fullWidth justifyContent="space-between" equalWidth>
                <TwitterShareButton url={nftPageUrl} title={POST_CONTENT} hashtags={HASHTAGS}>
                  <ShareButton>
                    <img src="/images/share-socials/twitter-x.svg" alt="share nft on x" width={20} height={20} />
                  </ShareButton>
                </TwitterShareButton>

                <FacebookShareButton url={nftPageUrl} hashtag={HASHTAGS_FACEBOOK}>
                  <ShareButton variant="facebook">
                    <img src="/images/share-socials/facebook.svg" alt="share nft on facebook" width={20} height={20} />
                  </ShareButton>
                </FacebookShareButton>

                <LinkedinShareButton url={nftPageUrl}>
                  <ShareButton variant="linkedin">
                    <img src="/images/share-socials/linkedin.svg" alt="share nft on linkedin" width={20} height={20} />
                  </ShareButton>
                </LinkedinShareButton>

                <TelegramShareButton url={nftPageUrl} title={POST_CONTENT}>
                  <ShareButton variant="telegram">
                    <img src="/images/share-socials/telegram.svg" alt="share nft on telegram" width={20} height={20} />
                  </ShareButton>
                </TelegramShareButton>
              </Group>
            </Stack>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default ShareNft;
