import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";

import useVisible from "@hooks/useVisible";
import ClickAwayListener from "@components/common/ClickAwayListener/ClickAwayListener";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { EditorVideoProps, EditorUtilsProps } from "../../TextEditor.types";
import VideoIcon from "../../icons/VideoIcon";
import "./CustomUtils.scss";

const initialStateVideo = (): EditorVideoProps => ({ url: "", width: 640, height: 480 });

const AddVideo = ({ editor }: EditorUtilsProps) => {
  const [video, setVideo] = useState<EditorVideoProps>(initialStateVideo());
  const [buttonUtilsRef, setButtonUtilRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const { isVisible, visibilityToggle, setVisibilityFalse } = useVisible(false);

  const { t: commonT } = useTranslation("common");

  const { styles, attributes } = usePopper(buttonUtilsRef, popperRef, {
    placement: "bottom",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  const handleChangeVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleAddVideo = () => {
    if (!video.url) return;

    if (video) {
      editor.commands.setYoutubeVideo({
        src: video.url,
        width: video.width || 640,
        height: video.height || 480
      });

      setVisibilityFalse();
      setVideo(initialStateVideo());
    }
  };

  return (
    <div className="text-editor-custom-utils">
      <button
        ref={setButtonUtilRef}
        title={commonT("addVideo.button")}
        onClick={visibilityToggle}
        className="menu-utils-button"
      >
        <VideoIcon />
      </button>

      {isVisible && (
        <ClickAwayListener onClickAway={setVisibilityFalse}>
          <div
            ref={setPopperRef}
            className="text-editor-custom-utils-modal"
            style={{ ...styles.popper, zIndex: 100 }}
            {...attributes.popper}
          >
            <h3>{commonT("addVideo.modal.title")}</h3>

            <Input
              name="url"
              label={commonT("addVideo.modal.inputs.url.label")}
              placeholder={commonT("addVideo.modal.inputs.url.placeholder")}
              value={video.url}
              onChange={handleChangeVideo}
              required
            />
            <Group colGap={10} fullWidth>
              <Input
                type="number"
                name="width"
                label={commonT("addVideo.modal.inputs.width.label")}
                placeholder={commonT("addVideo.modal.inputs.width.placeholder")}
                value={video.width}
                onChange={handleChangeVideo}
                minValue={1}
              />
              <Input
                type="number"
                name="height"
                label={commonT("addVideo.modal.inputs.height.label")}
                placeholder={commonT("addVideo.modal.inputs.height.placeholder")}
                value={video.height}
                onChange={handleChangeVideo}
                minValue={1}
              />
            </Group>

            <Group justifyContent="flex-end" fullWidth>
              <Button onClick={handleAddVideo}>{commonT("save")}</Button>
            </Group>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default AddVideo;
