import React from "react";
import LinkIcon from "src/assets/LinkIcon";
import EmailIcon from "src/assets/EmailIcon";
import TelegramIcon from "src/assets//TelegramIcon";
import "./Link.scss";
import RecoveryIcon from "src/assets/RecoveryIcon";

export interface LinkImageProps {
  type?: "link" | "email" | "telegram" | "recovery";
  className?: string;
}

const LinkImage = ({ type = "link", className = "" }: LinkImageProps) => {
  switch (type) {
    case "link":
      return <LinkIcon className={className} />;
    case "email":
      return <EmailIcon className={className} />;
    case "telegram":
      return <TelegramIcon className={className} />;
    case "recovery":
      return <RecoveryIcon className={className} />;
    default:
      return <LinkIcon className={className} />;
  }
};

export default LinkImage;
