import React from "react";
import { useTranslation } from "react-i18next";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { copyToClipboard } from "@utils/copyToClipboard";

import "./MobileGuardModal.scss";

const MobileGuardModal = () => {
  const { t: layoutT } = useTranslation("layout");
  const currentUrl = window.location.href;

  const handleCopyToClipboard = () => copyToClipboard(currentUrl);
  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack alignItems="center" className="mobile-guards-modal">
            <img src="/images/icon-not-mobile-device.svg" alt="not mobile device icon" className="mt-6" />
            <h2 className="mt-6 text-center">{layoutT("mobileDeviceGuardModal.header")}</h2>
            <p className="mt-3 mb-5 text-center">{layoutT("mobileDeviceGuardModal.subHeader")}</p>

            <Button
              onClick={handleCopyToClipboard}
              variant="primary"
              size="big"
              rightIcon={<img src={"/images/icon-copy-white.svg"} alt="icon copy button" />}
            >
              {layoutT("mobileDeviceGuardModal.button")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default MobileGuardModal;
