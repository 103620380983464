import React from "react";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import OrganizationData from "../../components/OrganizationData/OrganizationData";

import "./Organization.scss";

const Organization = () => {
  const { t } = useTranslation("settingsPage");

  return (
    <Group alignItems="flex-start" colGap={40} className="organization-settings">
      <h2 className="title">{t("organization.title")}</h2>

      <div className="organization-settings-content">
        <hr />
        <p className="p0 mt-3">{t("organization.description")}</p>
        <OrganizationData />
      </div>
    </Group>
  );
};

export default Organization;
