import React, { useState } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";

import useVisible from "@hooks/useVisible";
import Motion from "@components/ux/Motion/Motion";

import { TooltipProps } from "./Tooltip.types";
import "./Tooltip.scss";

const Tooltip = ({
  tip,
  position = "top",
  width = 0,
  hide = false,
  textCenter = false,
  fullWidth = false,
  displayAfterClick = false,
  bgColor = "black",
  className = "",
  children
}: TooltipProps) => {
  const [tooltipRef, setTooltipRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const { isVisible, setVisibilityTrue, setVisibilityFalse, visibilityToggle } = useVisible(false);

  const { styles, attributes } = usePopper(tooltipRef, popperRef, {
    placement: position,
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 12]
        }
      }
    ]
  });

  const tipWidth = width > 0 ? `${width}px` : "auto";
  const showTooltip = !hide && isVisible;

  const tooltipWrapperClasses = classNames("tooltip-wrapper", { "full-width": fullWidth }, className);
  const tipClasses = classNames("tooltip-tip", `tooltip-tip-bg-${bgColor}`, { "tooltip-tip-text-center": textCenter });

  return (
    <div
      ref={setTooltipRef}
      onMouseEnter={setVisibilityTrue}
      onMouseLeave={setVisibilityFalse}
      onMouseDown={setVisibilityFalse}
      onClick={() => displayAfterClick && visibilityToggle()}
      className={tooltipWrapperClasses}
    >
      {showTooltip && (
        <Motion appear duration={0}>
          <div
            ref={setPopperRef}
            role="tooltip"
            className={tipClasses}
            style={{ ...styles.popper, width: tipWidth }}
            {...attributes.popper}
          >
            {tip}
          </div>
        </Motion>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
