import { FILE_NAME_SEPARATOR } from "@config/config";
import { formatTicketGlobalId } from "./formatTicketGlobalId";

export const buildTicketFileName = (ticketGlobalId: number, eventName: string, onlyQr?: boolean) => {
  const ticketId = formatTicketGlobalId(ticketGlobalId);
  const event = eventName.replace(/ /g, FILE_NAME_SEPARATOR).toLowerCase();

  const qrTag = onlyQr ? `${FILE_NAME_SEPARATOR}qr` : "";

  return ticketId + FILE_NAME_SEPARATOR + event + qrTag;
};
