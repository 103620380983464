import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import axios from "axios";

import { useNotification } from "@contexts/Notification.context";
import { ACCEPT_IMAGE_EXTENSIONS, MAX_SIZE_IMAGE_FILE } from "@config/config";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "../Buttons/Button/Button";

import { UploadImageProps } from "./UploadImageProps.types";
import "./UploadImage.scss";

const UploadImage = ({ title, imageUrl = "", accept, maxSize, className, onChange, onError }: UploadImageProps) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState("");
  const [error, setError] = useState<string>("");

  const fileInput = useRef<HTMLInputElement>(null as any);
  const { notify } = useNotification();

  const acceptExtensions = accept || ACCEPT_IMAGE_EXTENSIONS;
  const maxImageSize = maxSize || MAX_SIZE_IMAGE_FILE;
  const maxImageSizeMB = `${maxImageSize / 1024 / 1024}MB`;

  const { t: commonT } = useTranslation("common");
  const { t: responseT } = useTranslation("apiResponse");
  const { t: formErrorsT } = useTranslation("formErrors");

  // Download image when imageUrl exists and convert to File type and set as selectedImage
  useEffect(() => {
    if (selectedImage || !imageUrl) return;

    let filename = "";
    let extension = "";

    axios
      .get<Blob>(imageUrl, { responseType: "blob" })
      .then((res) => {
        switch (res.status) {
          case 200:
            // eslint-disable-next-line no-case-declarations
            const header = res.headers["content-disposition"];
            // eslint-disable-next-line no-case-declarations
            const encodedFilename = header!.split(/"/)[1];
            filename = decodeURI(encodedFilename);
            extension = `image/${filename.split(".")[1]}`;
            return res.data;
          default:
            return undefined;
        }
      })
      .then((data) => {
        if (!data) return;

        const fetchedFile = new File([data], filename || "", {
          type: extension || "unkown"
        });

        setSelectedImage(fetchedFile);
      })
      .catch(() => {
        notify("error", responseT("errors.getFile"));
      });
  }, [imageUrl]);

  // Convert selectedImage (file type) to ObjectUrl and set as previewImage
  useEffect(() => {
    if (!selectedImage) {
      setPreviewImage("");
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  // Call onChange when selectedImage changed
  useEffect(() => {
    if (!onChange) return;

    onChange(selectedImage);
  }, [selectedImage]);

  // Call onError when selectedImage is not valid
  useEffect(() => {
    if (!onError) return;

    onError(error);
  }, [error]);

  const handleAdd = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    fileInput.current.value = "";
    setSelectedImage(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setError("");

    const { files } = event.target;
    if (!files?.length) return;

    const isValid = validateFile(files?.[0]);
    if (isValid) setSelectedImage(files?.[0]);
  };

  const validateFile = (file: File) => {
    if (file.size > maxImageSize) {
      setError(formErrorsT("file.toBig", { property: maxImageSizeMB }));
      return false;
    }

    return true;
  };

  const imageSource = previewImage ? previewImage : "/images/upload-image-placeholder.svg";
  const imageClass = previewImage ? "image-preview" : "image-placeholder";
  const classes = classNames("upload-image", className);

  return (
    <div className={classes}>
      <input
        ref={fileInput}
        type="file"
        accept={acceptExtensions}
        onChange={handleChange}
        className="upload-image-input"
      />
      <p className="p4 bold mb-2">{title || commonT("imagePreview")}</p>

      <Group alignItems="flex-end" colGap={20}>
        <div className="image-container">
          <img src={imageSource} alt="uploaded image" className={imageClass} />
        </div>

        <Stack rowGap={20}>
          <Button variant="secondary" onClick={handleRemove} className="upload-image-button">
            {commonT("remove")}
          </Button>
          <Button variant="secondary" onClick={handleAdd} className="upload-image-button">
            {commonT("add")}
          </Button>
        </Stack>
      </Group>
    </div>
  );
};

export default UploadImage;
