import React from "react";
import { useTranslation } from "react-i18next";

import { AssetData } from "@services/blockchain/blockchain.types";
import { parseNftImageUrl } from "@services/blockchain/utils/nft-parsers";

import { useWvs } from "@services/blockchain/Waves.context";
import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";

import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";

import "./NftCard.scss";

interface NftCardProps {
  nft: AssetData;
}

const NftCard = ({ nft }: NftCardProps) => {
  const { nftManageEnable } = useBrowseNfts();
  const { publicState } = useWvs();
  const { openModal } = usePoapNftModals();
  const { t } = useTranslation(["common"]);

  const { name, description } = nft;

  const handleOpenPreview = () => openModal({ type: "nftPreview", data: nft });
  const handleTransfer = () => openModal({ type: "transferNft", data: nft });
  const handleBurn = () => openModal({ type: "burnNft", data: nft });
  const handleShareNft = () => openModal({ type: "shareNft", data: nft });

  const imageUrl =
    parseNftImageUrl(publicState?.account?.network || "testnet", description) || "/images/skey-logo-signet.png";
  const imageAlt = name || "Skey network logo";

  return (
    <div className="nft-card">
      <div onClick={handleOpenPreview} className="nft-card-image-box">
        <img src={imageUrl} alt={imageAlt} loading="lazy" className="nft-image" />

        <div className="nft-image-overlay">
          <img src="/images/icon-image-loupe.svg" alt="" className="overlay-icon" />
        </div>
      </div>

      <div className="nft-card-content">
        <h2 className="nft-title">{name}</h2>
      </div>

      {nftManageEnable && (
        <div className="nft-card-buttons">
          <Tooltip tip={t("common:burnToken")} position="top" bgColor="white">
            <ActionIcon color="danger" onClick={handleBurn}>
              <img src="/images/icon-blockchain-burn.svg" alt="Burn token" />
            </ActionIcon>
          </Tooltip>

          <Tooltip tip={t("common:transferToken")} position="top" bgColor="white">
            <ActionIcon onClick={handleTransfer}>
              <img src="/images/icon-blockchain-transaction.svg" alt="Transfer token" />
            </ActionIcon>
          </Tooltip>

          <Tooltip tip={t("common:shareToken")} position="top" bgColor="white">
            <ActionIcon onClick={handleShareNft}>
              <img src="/images/icon-blockchain-share.svg" alt="Share token" />
            </ActionIcon>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default NftCard;
