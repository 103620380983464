import { CropperRef } from "react-advanced-cropper";

import { DEFAULT_IMAGE_TYPE } from "@config/config";

export const captureCroppedImage = (
  ref: React.RefObject<CropperRef>,
  type: string = DEFAULT_IMAGE_TYPE,
  maxWidth: number = 1920,
  maxHeight: number = 1080
) => {
  return ref.current?.getCanvas({ maxWidth, maxHeight })?.toDataURL(type);
};
