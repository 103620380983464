import React from "react";

import { useEvents } from "@app/panel/Events/contexts/EventsData.context";

import BasicInfo from "./sections/BasicInfo/BasicInfo";
import EventPageContent from "./sections/EventPageContent/EventPageContent";
import EventPageSettings from "./sections/EventPageSettings/EventPageSettings";
import EventAgreements from "./sections/EventAgreements/EventAgreements";
import GatesSettings from "./sections/GatesSettings/GatesSettings";
import TicketSettings from "./sections/TicketSettings/TicketSettings";
import EventSettingsSkeleton from "@app/panel/Events/skeletons/EventSettingsSkeleton/EventSettingsSkeleton";

import "./EventSettings.scss";

const EventSettings = () => {
  const { status: fetchEventsStatus } = useEvents();

  if (fetchEventsStatus === "loading") {
    return <EventSettingsSkeleton />;
  }

  return (
    <div className="event-settings">
      <BasicInfo />
      <EventPageContent />
      <EventPageSettings />
      <EventAgreements />
      <GatesSettings />
      <TicketSettings />
    </div>
  );
};

export default EventSettings;
