import React from "react";
import { useTranslation } from "react-i18next";

import Dots from "@components/common/Dots/Dots";
import Spinner from "@components/ux/Spinner/Spinner";

import "./ExploreNftsLoader.scss";

const ExploreNftsLoader = () => {
  const { t } = useTranslation("common");

  return (
    <div className="exlorer-nfts-loader">
      <h2 className="relative">
        {t("loading")} <Dots />
      </h2>

      <Spinner />
    </div>
  );
};

export default ExploreNftsLoader;
