import React from "react";
import { MotionProps } from "./Motion.types";
import "./Motion.scss";

const speedTimes = {
  speed0: 200,
  speed1: 300,
  speed2: 600,
  speed3: 1000,
  speed4: 1500,
  speed5: 3000
};

const Motion = ({
  children,
  spin,
  shake,
  appear,
  slide,
  pulse,
  duration,
  style,
  onAnimationEnd = () => {},
  className = ""
}: MotionProps) => {
  const motionRef = React.useRef(null as any);

  const buildAnimation = (): string => {
    let animation = `${className} `;
    if (spin) {
      animation += `spin-${duration}`;
    }
    if (shake) {
      animation += `shake-${duration}`;
    }

    if (appear) {
      animation += `appear-${duration}`;
    }

    if (slide?.active) {
      animation += `slide slide-${slide.direction} slide-${slide.direction}-${duration}`;
    }

    if (pulse) {
      animation += ` pulse pulse-${duration}`;
    }

    return animation;
  };

  React.useEffect(() => {
    setTimeout(async () => {
      onAnimationEnd();
    }, speedTimes[`speed${duration}`]);
  }, [spin, shake, appear, slide, duration, onAnimationEnd]);

  return (
    <div className={`${buildAnimation()} motion`} style={{ ...style }} ref={motionRef}>
      {children}
    </div>
  );
};

export default Motion;
