import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import BaseHeader from "@components/layoutElements/BaseHeader/BaseHeader";
import Footer from "@components/layoutElements/Footer/Footer";

import "./EventPageLayout.scss";

const EventPageLayout = ({ children }: IChildren) => {
  return (
    <div className="event-page-layout">
      <div className="header-part">
        <BaseHeader />
      </div>

      <div className="content-part">{children}</div>

      <div className="footer-part">
        <Footer />
      </div>
    </div>
  );
};

export default EventPageLayout;
