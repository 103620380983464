import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@components/arrangement/Stack/Stack";
import "./SubmissionsEmptyState.scss";

const SubmissionsEmptyState = () => {
  const { t } = useTranslation("eventsViews");

  return (
    <Stack rowGap={25} justifyContent="center" alignItems="center" className="submission-empty-state">
      <img src="/images/icon-members-empty-state.svg" alt="no registered members" />
      <p className="p4 text-center">{t("submissionsEmptyState.description")}</p>
    </Stack>
  );
};

export default SubmissionsEmptyState;
