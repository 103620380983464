import React from "react";
import { useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { formatDateTime } from "@utils/formatDateTime";
import { getTimezoneData } from "@app/panel/Events/utils/timezones";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";

import "./BasicInfo.scss";

const BasicInfo = () => {
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation(["eventsViews", "common"]);
  const { t: timezonesT } = useTranslation("timezones");

  const handleOpenModal = () => openModal({ type: "editEvent" });

  const editButtonTip = eventStatus === "started" ? t("common:eventStartedDisabled") : t("common:eventEndedDisabled");
  const editButtonDisabled = eventStatus === "started" || eventStatus === "ended";
  const eventTimezone = getTimezoneData(event.timezone, timezonesT);

  return (
    <div className="event-basic-info">
      <h3 className="event-basic-info-title">{t("eventsViews:eventBasicInfo.title")}</h3>
      <div className="event-basic-info-content">
        <Stack rowGap={12}>
          <p className="p4 black-2">{t("eventsViews:eventBasicInfo.eventName")}</p>
          <p className="p4">{event.name}</p>
        </Stack>

        <Group colGap={50} justifyContent="space-between" alignItems="flex-start" className="mt-5" fullWidth>
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.locationtName")}</p>
            <p className="p4">{event.location.name || "-"}</p>
          </Stack>
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.eventUrl")}</p>
            <p className="p4 content-value">{event.url || "-"}</p>
          </Stack>
        </Group>

        <Group colGap={50} justifyContent="space-between" alignItems="flex-start" className="mt-5">
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.locationAddress")}</p>
            <p className="p4">{event.location.address || "-"}</p>
          </Stack>
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.locationUrl")}</p>
            <p className="p4 content-value">{event.location.url || "-"}</p>
          </Stack>
        </Group>

        <Group colGap={50} justifyContent="space-between" alignItems="flex-start" className="mt-5">
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.organizersEmail")}</p>
            <p className="p4 content-value">{event.email || "-"}</p>
          </Stack>
        </Group>

        <hr className="event-basic-info-hr" />

        <Group colGap={50} justifyContent="space-between" alignItems="flex-start" className="mt-5 mb-4">
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.startDate")}</p>
            <p className="p4">{formatDateTime(event.start || "-", event.timezone)}</p>
          </Stack>
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.endDate")}</p>
            <p className="p4">{formatDateTime(event.end || "-", event.timezone)}</p>
          </Stack>
        </Group>

        <Group colGap={50} justifyContent="space-between" alignItems="flex-start" className="mt-5 mb-4">
          <Stack rowGap={12} fullWidth>
            <p className="p4 black-2">{t("eventsViews:eventBasicInfo.timezone")}</p>
            <p className="p4">{eventTimezone?.name}</p>
          </Stack>
        </Group>

        <Tooltip tip={editButtonTip} hide={!editButtonDisabled} width={200} textCenter>
          <Button variant="primary" onClick={handleOpenModal} disabled={editButtonDisabled}>
            {t("common:edit")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default BasicInfo;
