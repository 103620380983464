import React from "react";

import { IChildren } from "@src/types/IChildren.types";

import MovingImage from "@components/common/MovingImage/MovingImage";
import PoapExplorerHeader from "@components/layoutElements/PoapExplorerHeader/PoapExplorerHeader";
import NftFooter from "@components/layoutElements/NftFooter/NftFooter";

import "./PoapExploreLayout.scss";

const PoapExploreLayout = ({ children }: IChildren) => {
  return (
    <div className="poap-explore-layout">
      <div className="header-part">
        <PoapExplorerHeader />
      </div>

      <div className="main-part">
        <MovingImage href="/images/nft-layout-gray-circle-desktop1.svg" force={2.5} className="desktop-md" />
        <MovingImage href="/images/nft-layout-gray-circle-desktop2.svg" force={2} className="desktop-md" />
        <MovingImage href="/images/nft-layout-gray-circle-desktop3.svg" force={2.5} className="desktop-md" />

        <div className="main-content">{children}</div>
      </div>

      <div className="footer-part">
        <NftFooter />
      </div>
    </div>
  );
};

export default PoapExploreLayout;
