import { TFunction } from "i18next";
import * as yup from "yup";

export const editAgreementsSchema = (labelsT: TFunction, errorsT: TFunction) =>
  yup.object({
    agreements: yup.array().of(
      yup.object().shape({
        index: yup.number(),
        name: yup.string().required(errorsT("required")).max(100, errorsT("string.max")),
        content: yup.string().required(errorsT("required")),
        url: yup.string().nullable().url(errorsT("string.url"))
      })
    )
  });
