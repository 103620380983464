import { Timezone } from "@src/types/Timezone.types";
import { TFunction } from "i18next";

export const timezones: Timezone[] = [
  { offset: "-11:00", label: "niue", tzCode: "Pacific/Niue" },
  { offset: "-11:00", label: "pagoPago", tzCode: "Pacific/Pago_Pago" },
  { offset: "-10:00", label: "hawaiiTime", tzCode: "Pacific/Honolulu" },
  { offset: "-10:00", label: "rarotonga", tzCode: "Pacific/Rarotonga" },
  { offset: "-10:00", label: "tahiti", tzCode: "Pacific/Tahiti" },
  { offset: "-09:30", label: "marquesas", tzCode: "Pacific/Marquesas" },
  { offset: "-09:00", label: "alaskaTime", tzCode: "America/Anchorage" },
  { offset: "-09:00", label: "gambier", tzCode: "Pacific/Gambier" },
  { offset: "-08:00", label: "pacificTime", tzCode: "America/Los_Angeles" },
  { offset: "-08:00", label: "pacificTimeTijuana", tzCode: "America/Tijuana" },
  { offset: "-08:00", label: "pacificTimeVancouver", tzCode: "America/Vancouver" },
  { offset: "-08:00", label: "pacificTimeWhitehorse", tzCode: "America/Whitehorse" },
  { offset: "-08:00", label: "pitcairn", tzCode: "Pacific/Pitcairn" },
  { offset: "-07:00", label: "mountainTime", tzCode: "America/Denver" },
  { offset: "-07:00", label: "mountainTimeArizona", tzCode: "America/Phoenix" },
  { offset: "-07:00", label: "mountainTimeChihuahuaMazatlan", tzCode: "America/Mazatlan" },
  { offset: "-07:00", label: "mountainTimeDawsonCreek", tzCode: "America/Dawson_Creek" },
  { offset: "-07:00", label: "mountainTimeEdmonton", tzCode: "America/Edmonton" },
  { offset: "-07:00", label: "mountainTimeHermosillo", tzCode: "America/Hermosillo" },
  { offset: "-07:00", label: "mountainTimeYellowknife", tzCode: "America/Yellowknife" },
  { offset: "-06:00", label: "belize", tzCode: "America/Belize" },
  { offset: "-06:00", label: "centralTime", tzCode: "America/Chicago" },
  { offset: "-06:00", label: "centralTimeMexicoCity", tzCode: "America/Mexico_City" },
  { offset: "-06:00", label: "centralTimeRegina", tzCode: "America/Regina" },
  { offset: "-06:00", label: "centralTimeTegucigalpa", tzCode: "America/Tegucigalpa" },
  { offset: "-06:00", label: "centralTimeWinnipeg", tzCode: "America/Winnipeg" },
  { offset: "-06:00", label: "costaRica", tzCode: "America/Costa_Rica" },
  { offset: "-06:00", label: "elSalvador", tzCode: "America/El_Salvador" },
  { offset: "-06:00", label: "galapagos", tzCode: "Pacific/Galapagos" },
  { offset: "-06:00", label: "guatemala", tzCode: "America/Guatemala" },
  { offset: "-06:00", label: "managua", tzCode: "America/Managua" },
  { offset: "-05:00", label: "americaCancun", tzCode: "America/Cancun" },
  { offset: "-05:00", label: "bogota", tzCode: "America/Bogota" },
  { offset: "-05:00", label: "easterIsland", tzCode: "Pacific/Easter" },
  { offset: "-05:00", label: "easternTime", tzCode: "America/New_York" },
  { offset: "-05:00", label: "easternTimeIqaluit", tzCode: "America/Iqaluit" },
  { offset: "-05:00", label: "easternTimeToronto", tzCode: "America/Toronto" },
  { offset: "-05:00", label: "guayaquil", tzCode: "America/Guayaquil" },
  { offset: "-05:00", label: "havana", tzCode: "America/Havana" },
  { offset: "-05:00", label: "jamaica", tzCode: "America/Jamaica" },
  { offset: "-05:00", label: "lima", tzCode: "America/Lima" },
  { offset: "-05:00", label: "nassau", tzCode: "America/Nassau" },
  { offset: "-05:00", label: "panama", tzCode: "America/Panama" },
  { offset: "-05:00", label: "portAuPrince", tzCode: "America/Port-au-Prince" },
  { offset: "-05:00", label: "rioBranco", tzCode: "America/Rio_Branco" },
  { offset: "-04:00", label: "atlanticTimeHalifax", tzCode: "America/Halifax" },
  { offset: "-04:00", label: "barbados", tzCode: "America/Barbados" },
  { offset: "-04:00", label: "bermuda", tzCode: "Atlantic/Bermuda" },
  { offset: "-04:00", label: "boaVista", tzCode: "America/Boa_Vista" },
  { offset: "-04:00", label: "caracas", tzCode: "America/Caracas" },
  { offset: "-04:00", label: "curacao", tzCode: "America/Curacao" },
  { offset: "-04:00", label: "grandTurk", tzCode: "America/Grand_Turk" },
  { offset: "-04:00", label: "guyana", tzCode: "America/Guyana" },
  { offset: "-04:00", label: "laPaz", tzCode: "America/La_Paz" },
  { offset: "-04:00", label: "manaus", tzCode: "America/Manaus" },
  { offset: "-04:00", label: "martinique", tzCode: "America/Martinique" },
  { offset: "-04:00", label: "portOfSpain", tzCode: "America/Port_of_Spain" },
  { offset: "-04:00", label: "portoVelho", tzCode: "America/Porto_Velho" },
  { offset: "-04:00", label: "puertoRico", tzCode: "America/Puerto_Rico" },
  { offset: "-04:00", label: "santoDomingo", tzCode: "America/Santo_Domingo" },
  { offset: "-04:00", label: "thule", tzCode: "America/Thule" },
  { offset: "-03:30", label: "newfoundlandTimeStJohns", tzCode: "America/St_Johns" },
  { offset: "-03:00", label: "araguaina", tzCode: "America/Araguaina" },
  { offset: "-03:00", label: "asuncion", tzCode: "America/Asuncion" },
  { offset: "-03:00", label: "belem", tzCode: "America/Belem" },
  { offset: "-03:00", label: "buenosAires", tzCode: "America/Argentina/Buenos_Aires" },
  { offset: "-03:00", label: "campoGrande", tzCode: "America/Campo_Grande" },
  { offset: "-03:00", label: "cayenne", tzCode: "America/Cayenne" },
  { offset: "-03:00", label: "cuiaba", tzCode: "America/Cuiaba" },
  { offset: "-03:00", label: "fortaleza", tzCode: "America/Fortaleza" },
  { offset: "-03:00", label: "godthab", tzCode: "America/Godthab" },
  { offset: "-03:00", label: "maceio", tzCode: "America/Maceio" },
  { offset: "-03:00", label: "miquelon", tzCode: "America/Miquelon" },
  { offset: "-03:00", label: "montevideo", tzCode: "America/Montevideo" },
  { offset: "-03:00", label: "palmer", tzCode: "Antarctica/Palmer" },
  { offset: "-03:00", label: "paramaribo", tzCode: "America/Paramaribo" },
  { offset: "-03:00", label: "puntaArenas", tzCode: "America/Punta_Arenas" },
  { offset: "-03:00", label: "recife", tzCode: "America/Recife" },
  { offset: "-03:00", label: "rothera", tzCode: "Antarctica/Rothera" },
  { offset: "-03:00", label: "salvador", tzCode: "America/Bahia" },
  { offset: "-03:00", label: "santiago", tzCode: "America/Santiago" },
  { offset: "-03:00", label: "stanley", tzCode: "Atlantic/Stanley" },
  { offset: "-02:00", label: "noronha", tzCode: "America/Noronha" },
  { offset: "-02:00", label: "saoPaulo", tzCode: "America/Sao_Paulo" },
  { offset: "-02:00", label: "southGeorgia", tzCode: "Atlantic/South_Georgia" },
  { offset: "-01:00", label: "azores", tzCode: "Atlantic/Azores" },
  { offset: "-01:00", label: "capeVerde", tzCode: "Atlantic/Cape_Verde" },
  { offset: "-01:00", label: "scoresbysund", tzCode: "America/Scoresbysund" },
  { offset: "+00:00", label: "abidjan", tzCode: "Africa/Abidjan" },
  { offset: "+00:00", label: "accra", tzCode: "Africa/Accra" },
  { offset: "+00:00", label: "bissau", tzCode: "Africa/Bissau" },
  { offset: "+00:00", label: "canaryIslands", tzCode: "Atlantic/Canary" },
  { offset: "+00:00", label: "casablanca", tzCode: "Africa/Casablanca" },
  { offset: "+00:00", label: "danmarkshavn", tzCode: "America/Danmarkshavn" },
  { offset: "+00:00", label: "dublin", tzCode: "Europe/Dublin" },
  { offset: "+00:00", label: "elAaiun", tzCode: "Africa/El_Aaiun" },
  { offset: "+00:00", label: "faeroe", tzCode: "Atlantic/Faroe" },
  { offset: "+00:00", label: "gmt", tzCode: "Etc/GMT" },
  { offset: "+00:00", label: "lisbon", tzCode: "Europe/Lisbon" },
  { offset: "+00:00", label: "london", tzCode: "Europe/London" },
  { offset: "+00:00", label: "monrovia", tzCode: "Africa/Monrovia" },
  { offset: "+00:00", label: "reykjavik", tzCode: "Atlantic/Reykjavik" },
  { offset: "+01:00", label: "algiers", tzCode: "Africa/Algiers" },
  { offset: "+01:00", label: "amsterdam", tzCode: "Europe/Amsterdam" },
  { offset: "+01:00", label: "andorra", tzCode: "Europe/Andorra" },
  { offset: "+01:00", label: "berlin", tzCode: "Europe/Berlin" },
  { offset: "+01:00", label: "brussels", tzCode: "Europe/Brussels" },
  { offset: "+01:00", label: "budapest", tzCode: "Europe/Budapest" },
  { offset: "+01:00", label: "centralEuropeanTimeBelgrade", tzCode: "Europe/Belgrade" },
  { offset: "+01:00", label: "centralEuropeanTimePrague", tzCode: "Europe/Prague" },
  { offset: "+01:00", label: "ceuta", tzCode: "Africa/Ceuta" },
  { offset: "+01:00", label: "copenhagen", tzCode: "Europe/Copenhagen" },
  { offset: "+01:00", label: "gibraltar", tzCode: "Europe/Gibraltar" },
  { offset: "+01:00", label: "lagos", tzCode: "Africa/Lagos" },
  { offset: "+01:00", label: "luxembourg", tzCode: "Europe/Luxembourg" },
  { offset: "+01:00", label: "madrid", tzCode: "Europe/Madrid" },
  { offset: "+01:00", label: "malta", tzCode: "Europe/Malta" },
  { offset: "+01:00", label: "monaco", tzCode: "Europe/Monaco" },
  { offset: "+01:00", label: "ndjamena", tzCode: "Africa/Ndjamena" },
  { offset: "+01:00", label: "oslo", tzCode: "Europe/Oslo" },
  { offset: "+01:00", label: "paris", tzCode: "Europe/Paris" },
  { offset: "+01:00", label: "rome", tzCode: "Europe/Rome" },
  { offset: "+01:00", label: "stockholm", tzCode: "Europe/Stockholm" },
  { offset: "+01:00", label: "tirane", tzCode: "Europe/Tirane" },
  { offset: "+01:00", label: "tunis", tzCode: "Africa/Tunis" },
  { offset: "+01:00", label: "vienna", tzCode: "Europe/Vienna" },
  { offset: "+01:00", label: "warsaw", tzCode: "Europe/Warsaw" },
  { offset: "+01:00", label: "zurich", tzCode: "Europe/Zurich" },
  { offset: "+02:00", label: "amman", tzCode: "Asia/Amman" },
  { offset: "+02:00", label: "athens", tzCode: "Europe/Athens" },
  { offset: "+02:00", label: "beirut", tzCode: "Asia/Beirut" },
  { offset: "+02:00", label: "bucharest", tzCode: "Europe/Bucharest" },
  { offset: "+02:00", label: "cairo", tzCode: "Africa/Cairo" },
  { offset: "+02:00", label: "chisinau", tzCode: "Europe/Chisinau" },
  { offset: "+02:00", label: "damascus", tzCode: "Asia/Damascus" },
  { offset: "+02:00", label: "gaza", tzCode: "Asia/Gaza" },
  { offset: "+02:00", label: "helsinki", tzCode: "Europe/Helsinki" },
  { offset: "+02:00", label: "jerusalem", tzCode: "Asia/Jerusalem" },
  { offset: "+02:00", label: "johannesburg", tzCode: "Africa/Johannesburg" },
  { offset: "+02:00", label: "khartoum", tzCode: "Africa/Khartoum" },
  { offset: "+02:00", label: "kiev", tzCode: "Europe/Kiev" },
  { offset: "+02:00", label: "maputo", tzCode: "Africa/Maputo" },
  { offset: "+02:00", label: "moscowKaliningrad", tzCode: "Europe/Kaliningrad" },
  { offset: "+02:00", label: "nicosia", tzCode: "Asia/Nicosia" },
  { offset: "+02:00", label: "riga", tzCode: "Europe/Riga" },
  { offset: "+02:00", label: "sofia", tzCode: "Europe/Sofia" },
  { offset: "+02:00", label: "tallinn", tzCode: "Europe/Tallinn" },
  { offset: "+02:00", label: "tripoli", tzCode: "Africa/Tripoli" },
  { offset: "+02:00", label: "vilnius", tzCode: "Europe/Vilnius" },
  { offset: "+02:00", label: "windhoek", tzCode: "Africa/Windhoek" },
  { offset: "+03:00", label: "baghdad", tzCode: "Asia/Baghdad" },
  { offset: "+03:00", label: "istanbul", tzCode: "Europe/Istanbul" },
  { offset: "+03:00", label: "minsk", tzCode: "Europe/Minsk" },
  { offset: "+03:00", label: "moscow", tzCode: "Europe/Moscow" },
  { offset: "+03:00", label: "nairobi", tzCode: "Africa/Nairobi" },
  { offset: "+03:00", label: "qatar", tzCode: "Asia/Qatar" },
  { offset: "+03:00", label: "riyadh", tzCode: "Asia/Riyadh" },
  { offset: "+03:00", label: "dyowa", tzCode: "Antarctica/Syowa" },
  { offset: "+03:30", label: "tehran", tzCode: "Asia/Tehran" },
  { offset: "+04:00", label: "baku", tzCode: "Asia/Baku" },
  { offset: "+04:00", label: "dubai", tzCode: "Asia/Dubai" },
  { offset: "+04:00", label: "mahe", tzCode: "Indian/Mahe" },
  { offset: "+04:00", label: "mauritius", tzCode: "Indian/Mauritius" },
  { offset: "+04:00", label: "moscowSamara", tzCode: "Europe/Samara" },
  { offset: "+04:00", label: "reunion", tzCode: "Indian/Reunion" },
  { offset: "+04:00", label: "tbilisi", tzCode: "Asia/Tbilisi" },
  { offset: "+04:00", label: "yerevan", tzCode: "Asia/Yerevan" },
  { offset: "+04:30", label: "kabul", tzCode: "Asia/Kabul" },
  { offset: "+05:00", label: "aqtau", tzCode: "Asia/Aqtau" },
  { offset: "+05:00", label: "aqtobe", tzCode: "Asia/Aqtobe" },
  { offset: "+05:00", label: "ashgabat", tzCode: "Asia/Ashgabat" },
  { offset: "+05:00", label: "dushanbe", tzCode: "Asia/Dushanbe" },
  { offset: "+05:00", label: "karachi", tzCode: "Asia/Karachi" },
  { offset: "+05:00", label: "kerguelen", tzCode: "Indian/Kerguelen" },
  { offset: "+05:00", label: "maldives", tzCode: "Indian/Maldives" },
  { offset: "+05:00", label: "mawson", tzCode: "Antarctica/Mawson" },
  { offset: "+05:00", label: "moscowYekaterinburg", tzCode: "Asia/Yekaterinburg" },
  { offset: "+05:00", label: "tashkent", tzCode: "Asia/Tashkent" },
  { offset: "+05:30", label: "colombo", tzCode: "Asia/Colombo" },
  { offset: "+05:30", label: "indiaStandardTime", tzCode: "Asia/Kolkata" },
  { offset: "+05:45", label: "kathmandu", tzCode: "Asia/Kathmandu" },
  { offset: "+06:00", label: "almaty", tzCode: "Asia/Almaty" },
  { offset: "+06:00", label: "bishkek", tzCode: "Asia/Bishkek" },
  { offset: "+06:00", label: "chagos", tzCode: "Indian/Chagos" },
  { offset: "+06:00", label: "dhaka", tzCode: "Asia/Dhaka" },
  { offset: "+06:00", label: "moscowOmsk", tzCode: "Asia/Omsk" },
  { offset: "+06:00", label: "thimphu", tzCode: "Asia/Thimphu" },
  { offset: "+06:00", label: "vostok", tzCode: "Antarctica/Vostok" },
  { offset: "+06:30", label: "cocos", tzCode: "Indian/Cocos" },
  { offset: "+06:30", label: "rangoon", tzCode: "Asia/Yangon" },
  { offset: "+07:00", label: "bangkok", tzCode: "Asia/Bangkok" },
  { offset: "+07:00", label: "christmas", tzCode: "Indian/Christmas" },
  { offset: "+07:00", label: "davis", tzCode: "Antarctica/Davis" },
  { offset: "+07:00", label: "hanoi", tzCode: "Asia/Saigon" },
  { offset: "+07:00", label: "hovd", tzCode: "Asia/Hovd" },
  { offset: "+07:00", label: "jakarta", tzCode: "Asia/Jakarta" },
  { offset: "+07:00", label: "moscowKrasnoyarsk", tzCode: "Asia/Krasnoyarsk" },
  { offset: "+08:00", label: "brunei", tzCode: "Asia/Brunei" },
  { offset: "+08:00", label: "chinaTimeBeijing", tzCode: "Asia/Shanghai" },
  { offset: "+08:00", label: "choibalsan", tzCode: "Asia/Choibalsan" },
  { offset: "+08:00", label: "hongKong", tzCode: "Asia/Hong_Kong" },
  { offset: "+08:00", label: "kualaLumpur", tzCode: "Asia/Kuala_Lumpur" },
  { offset: "+08:00", label: "macau", tzCode: "Asia/Macau" },
  { offset: "+08:00", label: "makassar", tzCode: "Asia/Makassar" },
  { offset: "+08:00", label: "manila", tzCode: "Asia/Manila" },
  { offset: "+08:00", label: "moscowIrkutsk", tzCode: "Asia/Irkutsk" },
  { offset: "+08:00", label: "singapore", tzCode: "Asia/Singapore" },
  { offset: "+08:00", label: "taipei", tzCode: "Asia/Taipei" },
  { offset: "+08:00", label: "ulaanbaatar", tzCode: "Asia/Ulaanbaatar" },
  { offset: "+08:00", label: "westernTimePerth", tzCode: "Australia/Perth" },
  { offset: "+08:30", label: "pyongyang", tzCode: "Asia/Pyongyang" },
  { offset: "+09:00", label: "dili", tzCode: "Asia/Dili" },
  { offset: "+09:00", label: "jayapura", tzCode: "Asia/Jayapura" },
  { offset: "+09:00", label: "moscowYakutsk", tzCode: "Asia/Yakutsk" },
  { offset: "+09:00", label: "palau", tzCode: "Pacific/Palau" },
  { offset: "+09:00", label: "seoul", tzCode: "Asia/Seoul" },
  { offset: "+09:00", label: "tokyo", tzCode: "Asia/Tokyo" },
  { offset: "+09:30", label: "centralTimeDarwin", tzCode: "Australia/Darwin" },
  { offset: "+10:00", label: "dumontDurville", tzCode: "Antarctica/DumontDUrville" },
  { offset: "+10:00", label: "easternTimeBrisbane", tzCode: "Australia/Brisbane" },
  { offset: "+10:00", label: "guam", tzCode: "Pacific/Guam" },
  { offset: "+10:00", label: "moscowVladivostok", tzCode: "Asia/Vladivostok" },
  { offset: "+10:00", label: "portMoresby", tzCode: "Pacific/Port_Moresby" },
  { offset: "+10:00", label: "truk", tzCode: "Pacific/Chuuk" },
  { offset: "+10:30", label: "centralTimeAdelaide", tzCode: "Australia/Adelaide" },
  { offset: "+11:00", label: "casey", tzCode: "Antarctica/Casey" },
  { offset: "+11:00", label: "easternTimeHobart", tzCode: "Australia/Hobart" },
  { offset: "+11:00", label: "easternTimeMelbourneSydney", tzCode: "Australia/Sydney" },
  { offset: "+11:00", label: "efate", tzCode: "Pacific/Efate" },
  { offset: "+11:00", label: "guadalcanal", tzCode: "Pacific/Guadalcanal" },
  { offset: "+11:00", label: "kosrae", tzCode: "Pacific/Kosrae" },
  { offset: "+11:00", label: "moscowMagadan", tzCode: "Asia/Magadan" },
  { offset: "+11:00", label: "norfolk", tzCode: "Pacific/Norfolk" },
  { offset: "+11:00", label: "noumea", tzCode: "Pacific/Noumea" },
  { offset: "+11:00", label: "ponape", tzCode: "Pacific/Pohnpei" },
  { offset: "+12:00", label: "funafuti", tzCode: "Pacific/Funafuti" },
  { offset: "+12:00", label: "kwajalein", tzCode: "Pacific/Kwajalein" },
  { offset: "+12:00", label: "majuro", tzCode: "Pacific/Majuro" },
  { offset: "+12:00", label: "moscowPetropavlovskKamchatskiy", tzCode: "Asia/Kamchatka" },
  { offset: "+12:00", label: "nauru", tzCode: "Pacific/Nauru" },
  { offset: "+12:00", label: "tarawa", tzCode: "Pacific/Tarawa" },
  { offset: "+12:00", label: "wake", tzCode: "Pacific/Wake" },
  { offset: "+12:00", label: "wallis", tzCode: "Pacific/Wallis" },
  { offset: "+13:00", label: "auckland", tzCode: "Pacific/Auckland" },
  { offset: "+13:00", label: "enderbury", tzCode: "Pacific/Enderbury" },
  { offset: "+13:00", label: "fakaofo", tzCode: "Pacific/Fakaofo" },
  { offset: "+13:00", label: "fiji", tzCode: "Pacific/Fiji" },
  { offset: "+13:00", label: "tongatapu", tzCode: "Pacific/Tongatapu" },
  { offset: "+14:00", label: "apia", tzCode: "Pacific/Apia" },
  { offset: "+14:00", label: "kiritimati", tzCode: "Pacific/Kiritimati" }
];

export const timezonesToSelect = timezones.map(({ label: name, tzCode: value }) => ({
  name,
  value
}));

export const tzCodes = timezones.map(({ tzCode }) => tzCode);

export const getTimezonesTranslated = (t: TFunction<"timezones">) =>
  timezonesToSelect.map((timezone) => ({ ...timezone, name: t(timezone.name as any) }));

export const getTimezoneData = (tzCode: string, t: TFunction<"timezones">) => {
  const timezone = timezonesToSelect.find((timezone) => timezone.value === tzCode);
  return { name: t(timezone?.name as any), value: timezone?.value || "" };
};

// const newTimezones = timezones.map((timezone) => {
//   const transKey = Object.entries(JSON.parse(trans)).find(([key, value]) => value === timezone.label);
//   return { ...timezone, label: transKey?.[0] };
// });
