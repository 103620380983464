import * as Transactions from "@waves/waves-transactions";

import { WalletStatus } from "../blockchain.types";
import { STORAGE_KEYS } from "@config/helpers";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";

export class BCKeeperService {
  private connected = false;
  private publicState?: WavesKeeper.IPublicStateResponse;
  private setPublicState?: (state: WavesKeeper.IPublicStateResponse | null) => void;

  public update(
    publicState?: WavesKeeper.IPublicStateResponse,
    setPublicState?: (state: WavesKeeper.IPublicStateResponse | null) => void
  ) {
    this.publicState = publicState;
    this.setPublicState = setPublicState;
  }

  get keeper(): WavesKeeper.TWavesKeeperApi | null {
    const win = window as any;

    if (win.SkeyKeeper) return win.SkeyKeeper;
    if (win.WavesKeeper) return win.WavesKeeper;
    return null;
  }

  private updateProxy = (state: WavesKeeper.IPublicStateResponse | null) => {
    if (this.connected) {
      this.setPublicState?.(state);
    }
  };

  async waitForTx(txId: string) {
    if (!this.keeper || !this.publicState) {
      // eslint-disable-next-line no-console
      console.error("cannot wait for tx");
      await this.delay(1000);
      return;
    }

    await Transactions.waitForTx(txId, { apiBase: this.publicState.network?.server });
  }

  delay(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  async connect(
    setStatus: (status: WalletStatus) => void,
    setPublicState: (state: WavesKeeper.IPublicStateResponse | null) => void
  ) {
    if (!this.keeper) {
      // eslint-disable-next-line no-console
      console.error("keeper not available");
      return false;
    }

    try {
      setStatus("loading");
      const result = await this.keeper.publicState();
      if (!result) return false;

      setStatus(result ? "connected" : "refused");

      result && BrowserStorageManager.writeLocalStorage(STORAGE_KEYS.CONNECTED, true);

      this.connected = true;
      setPublicState(result);

      this.keeper?.on("update", this.updateProxy);
      return true;
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
      if (Number(err?.code) === 14) setStatus("no_account");
      else if (Number(err?.code) === 10) setStatus("refused");
      else setStatus("not_available");

      // eslint-disable-next-line no-console
      console.log("updated", err?.code);

      return false;
    }
  }

  disconnect(
    setStatus: (status: WalletStatus) => void,
    setPublicState: (state: WavesKeeper.IPublicStateResponse | null) => void
  ) {
    if (!this.keeper) {
      // eslint-disable-next-line no-console
      console.error("keeper not available");
      return false;
    }

    this.connected = false;

    setPublicState(null);
    setStatus("available");
    BrowserStorageManager.removeLocalStorageKey(STORAGE_KEYS.CONNECTED);

    return true;
  }

  // Returns true if keeper is ready
  // and false if timeout was reached
  waitForKeeper(timeout: number) {
    const interval = 100;
    const start = Date.now();

    return new Promise<boolean>((resolve) => {
      const handle = setInterval(async () => {
        if (this.keeper?.initialPromise) {
          await this.keeper.initialPromise;

          clearInterval(handle);
          return resolve(true);
        }

        const timeDiff = Date.now() - start;

        if (timeDiff >= timeout) {
          clearInterval(handle);
          return resolve(false);
        }
      }, interval);
    });
  }
}
