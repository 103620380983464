import { memo } from "react";
import classNames from "classnames";
import { ActionIconProps } from "./ActionIcon.types";
import "./ActionIcon.scss";

const ActionIcon = ({
  color = "default",
  children,
  disabled,
  size = 35,
  clickable,
  className,
  onClick
}: ActionIconProps) => {
  const sizeStyle = { width: size, height: size };
  const classes = classNames(
    "action-icon",
    `action-icon-color-${color}`,
    { "btn-clickable": onClick || clickable },
    className
  );

  return (
    <button type="button" onClick={onClick} style={sizeStyle} className={classes} disabled={disabled}>
      {children}
    </button>
  );
};

export default memo(ActionIcon);
