import React, { useCallback, useMemo, useState } from "react";

import { SubmissionDto, SubmissionsOrderBy } from "@services/Api/api.dto";
import {
  downloadQrCodeAsPdf,
  downloadQrCodeAsPng,
  downloadTicketAsPdf,
  downloadTicketAsPng
} from "@app/panel/Events/utils/ticketsDownload";

import { useUserData } from "@contexts/UserData.context";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useSubmissions } from "@app/panel/Events/contexts/SubmissionsData.context";
import { useSelectedSubmissions } from "@app/panel/Events/contexts/SelectedSubmissions.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import SubmissionsTableRender from "./components/SubmissionsTableRender/SubmissionsTableRender";
import useSortableTable from "@hooks/useSortableTable";
import { debounce } from "@utils/debounce";

const SUBMISSIONS_PER_PAGE = 20;
const DEBOUNCED_CHANGE_TIME = 100;

const SubmissionsTable = () => {
  const [searchValue, setSearchValue] = useState("");

  const { organization } = useUserData();
  const { event, eventStatus } = useSelectedEvent();
  const { getTicketFile } = useApiClient();
  const { submissions, hasSubmissions, refetchSubmissionsData, setSearchedSubmission } = useSubmissions();
  const { addSubmission, removeSubmission, checkIsSelectedMember } = useSelectedSubmissions();
  const { openModal } = useEventsModals();
  const { sortSettings, setSortingSettings, resetSorting } = useSortableTable<SubmissionsOrderBy>();

  const organizerName = useMemo(() => organization?.name || "", []);

  const handleCheckMember = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (checked) {
      addSubmission(value);
    } else {
      removeSubmission(value);
    }
  };

  const buildTableRowClass = (id: string) => {
    let rowClass = "table-row";
    const isSelected = checkIsSelectedMember(id);

    if (isSelected) rowClass += " table-row-checked";

    return rowClass;
  };

  const handleSaveTicketAsPdf = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;
        downloadTicketAsPdf(ticketId, globalId, res.data, event, organizerName);
      });
    },
    []
  );

  const handleSaveTicketAsPng = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;

        downloadTicketAsPng(ticketId, globalId, res.data, event, organizerName);
      });
    },
    []
  );

  const handleSaveQrCodeAsPdf = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;

        downloadQrCodeAsPdf(globalId, res.data, event);
      });
    },
    []
  );

  const handleSaveQrCodeAsPng = useCallback(
    (ticketId: string, globalId: number) => () => {
      getTicketFile(ticketId, "qr").then((res) => {
        if (res?.status !== 201) return;

        downloadQrCodeAsPng(globalId, res.data, event);
      });
    },
    []
  );

  const handleOpenSubmissionDetails = useCallback(
    (submission: SubmissionDto) => () => {
      openModal({ type: "submissionDetails", data: submission });
    },
    []
  );

  const handleSendTicket = useCallback(
    (submission: SubmissionDto) => () => {
      openModal({ type: "sendTicket", data: submission });
    },
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      refetchSubmissionsData(event.id, page, SUBMISSIONS_PER_PAGE);
    },
    [event]
  );

  const handleSort = (orderBy: SubmissionsOrderBy) => () => {
    setSortingSettings(orderBy);
    setSearchValue("");
    setSearchedSubmission("");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    resetSorting();
    setSearchValue(searchValue);
    setSearchedSubmission(searchValue);
  };

  React.useEffect(() => {
    if (!sortSettings) return;

    refetchSubmissionsData(
      event.id,
      submissions.meta.page,
      SUBMISSIONS_PER_PAGE,
      sortSettings.order,
      sortSettings.orderBy
    );
  }, [sortSettings]);

  const debouncedRefetchSubmissions = debounce(() => {
    if (!event.id || (searchValue.length > 0 && searchValue.length < 3)) return;

    if (searchValue.length === 0 && hasSubmissions && submissions.meta.search) {
      refetchSubmissionsData(event.id, 1, SUBMISSIONS_PER_PAGE);
    } else if (searchValue.length > 0 && hasSubmissions) {
      refetchSubmissionsData(event.id, submissions.meta.page, SUBMISSIONS_PER_PAGE, undefined, undefined, searchValue);
    }
  }, DEBOUNCED_CHANGE_TIME);

  React.useEffect(debouncedRefetchSubmissions, [searchValue, event.id, hasSubmissions]);

  return (
    <SubmissionsTableRender
      eventStatus={eventStatus}
      eventTimezone={event.timezone}
      sortSettings={sortSettings}
      searchValue={searchValue}
      checkMember={handleCheckMember}
      buildTableRowClass={buildTableRowClass}
      checkIsSelectedMember={checkIsSelectedMember}
      saveTicketAsPdf={handleSaveTicketAsPdf}
      saveTicketAsPng={handleSaveTicketAsPng}
      saveQrCodeAsPdf={handleSaveQrCodeAsPdf}
      saveQrCodeAsPng={handleSaveQrCodeAsPng}
      openSubmissionDetails={handleOpenSubmissionDetails}
      sendTicket={handleSendTicket}
      onPageChange={handlePageChange}
      handleSort={handleSort}
      handleSearch={handleSearch}
    />
  );
};

export default SubmissionsTable;
