import React from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import { formatDate, formatDateTime } from "@utils/formatDateTime";
import { formatTicketGlobalId } from "@app/panel/Events/utils/formatTicketGlobalId";

import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";
import Link from "@components/navigation/Link/Link";
import Dropdown from "@components/common/Dropdown/Dropdown";
import Pagination from "@components/navigation/Pagination/Pagination";

import { tableHeaders } from "../data/tableHeaders";
import { TicketsTableRenderProps } from "../TicketsTable.types";
import "./TicketsTableRender.scss";

const TicketsTableRender = ({
  event,
  tickets,
  saveTicketAsPdf,
  saveTicketAsPng,
  saveQrCodeAsPdf,
  saveQrCodeAsPng,
  openPreviewTicket,
  onPageChange
}: TicketsTableRenderProps) => {
  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  return (
    <>
      <div className="tickets-table">
        <div className="tickets-table-row">
          {tableHeaders.map(({ label, className }) => (
            <div key={nanoid()} className={`header-cell ${className || ""}`}>
              <p>{t(label as any)}</p>
            </div>
          ))}
        </div>

        {tickets.data.map((ticket) => {
          const { id, globalId, explorerUrl, history } = ticket;
          const termOfValidity = event.end ? formatDate(event.end, event.timezone) : "-";
          const ticketUsedDate = history.find((history) => history.status === "used");
          const formattedUsedDate = ticketUsedDate ? formatDateTime(ticketUsedDate?.timestamp, event.timezone) : "-";

          return (
            <div key={`ticket-${globalId}`} className="tickets-table-row">
              <div className="row-cell cell-tickets-number">
                <p className="p4">{globalId ? formatTicketGlobalId(globalId) : "-"}</p>
              </div>
              <div className="row-cell cell-termOfValidity">
                <p className="p4">{termOfValidity}</p>
              </div>
              <div className="row-cell cell-comments">
                <p className="p4">-</p>
              </div>
              <div className="row-cell cell-validate">
                <p className="p4">{formattedUsedDate}</p>
              </div>

              <div className="row-cell cell-actions">
                <Group colGap={10}>
                  <Tooltip tip={t("ticketsTable.tips.explorerLink")} position="top">
                    <Link href={explorerUrl} target="_blank" withImage={false}>
                      <ActionIcon clickable>
                        <img src="/images/icon-blockchain-transaction.svg" alt="blockchain address url" />
                      </ActionIcon>
                    </Link>
                  </Tooltip>

                  <Tooltip tip={t("ticketsTable.tips.showTicket")} position="top">
                    <ActionIcon onClick={openPreviewTicket(ticket)}>
                      <img src="/images/icon-show-details.svg" alt="show ticket" />
                    </ActionIcon>
                  </Tooltip>

                  <Dropdown>
                    <Dropdown.Target>
                      <ActionIcon clickable>
                        <img src="/images/icon-ticket-dropdown.svg" alt="ticket options button" />
                      </ActionIcon>
                    </Dropdown.Target>
                    <Dropdown.Menu>
                      <Dropdown.MenuItem onClick={saveTicketAsPdf(id, globalId)}>
                        {commonT("ticketDropdownActions.downloadTicketAsPDF")}
                      </Dropdown.MenuItem>
                      <Dropdown.MenuItem onClick={saveTicketAsPng(id, globalId)}>
                        {commonT("ticketDropdownActions.downloadTicketAsPNG")}
                      </Dropdown.MenuItem>
                      <Dropdown.MenuItem onClick={saveQrCodeAsPdf(id, globalId)}>
                        {commonT("ticketDropdownActions.downloadQrCodeAsPDF")}
                      </Dropdown.MenuItem>
                      <Dropdown.MenuItem onClick={saveQrCodeAsPng(id, globalId)}>
                        {commonT("ticketDropdownActions.downloadQrCodeAsPNG")}
                      </Dropdown.MenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </Group>
              </div>
            </div>
          );
        })}
      </div>

      {tickets.meta && (
        <Pagination
          currentPage={tickets.meta.page}
          totalCount={tickets.meta.total}
          pageSize={tickets.meta.perPage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

export default TicketsTableRender;
