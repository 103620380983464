import React from "react";
import { useTranslation } from "react-i18next";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";

import "./OrganizationGuardModal.scss";

const OrganizationGuardModal = () => {
  const { t } = useTranslation("layout");

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack alignItems="center" className="organization-guard-modal">
            <img src="/images/icon-modal-info.svg" className="mt-6" />
            <h2 className="text-center mt-6">{t("organizationGuardModal.description")}</h2>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default OrganizationGuardModal;
