import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { formatDateTime } from "@utils/formatDateTime";
import { TicketStatus } from "@services/Api/api.dto";
import { useSubmissions } from "@app/panel/Events/contexts/SubmissionsData.context";

import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";
import Checkbox from "@components/dataEntry/Checkbox/Checkbox";
import Dropdown from "@components/common/Dropdown/Dropdown";
import Pagination from "@components/navigation/Pagination/Pagination";
import SearchInput from "@components/dataEntry/SearchInput/SearchInput";

import { SubmissionsTableRenderProps } from "../../SubmissionsTable.types";
import SubmissionsTableHeader from "../SubmissionsTableHeader/SubmissionsTableHeader";
import SubmissionsTableLoader from "../SubmissionsTableLoader/SubmissionsTableLoader";
import SubmissionsTableNoMatch from "../SubmissionsTableNoMatch/SubmissionsTableNoMatch";

import "./SubmissionsTableRender.scss";

const SubmissionsTableRender = ({
  eventStatus,
  eventTimezone,
  sortSettings,
  searchValue,
  checkMember,
  buildTableRowClass,
  checkIsSelectedMember,
  saveTicketAsPdf,
  saveTicketAsPng,
  saveQrCodeAsPdf,
  saveQrCodeAsPng,
  openSubmissionDetails,
  sendTicket,
  onPageChange,
  handleSort,
  handleSearch
}: SubmissionsTableRenderProps) => {
  const { submissions, status: fetchSubmissionsStatus, searchedSubmission, hasSubmissions } = useSubmissions();

  const { t } = useTranslation(["eventsViews", "common"]);

  const sendTicketTip = useMemo(() => {
    return eventStatus === "ended" ? t("common:eventEndedDisabled") : t("eventsViews:submissionsTable.tips.sendTicket");
  }, [eventStatus]);

  const sendTicketDisabled = useMemo(() => {
    return eventStatus === "ended";
  }, [eventStatus]);

  const showSearchLoader = useMemo(() => {
    return hasSubmissions && fetchSubmissionsStatus === "loading";
  }, [fetchSubmissionsStatus, searchedSubmission]);

  const showSearchNoMatch = useMemo(() => {
    return submissions.data.length === 0 && searchedSubmission.length > 0 && fetchSubmissionsStatus === "success";
  }, [submissions, searchedSubmission, fetchSubmissionsStatus]);

  const showTable = useMemo(() => {
    return submissions.data.length > 0 && fetchSubmissionsStatus === "success";
  }, [submissions, fetchSubmissionsStatus]);

  return (
    <>
      <div className="event-submissions">
        <SearchInput value={searchValue} onChange={handleSearch} className="event-submissions-search-input" />

        <div className="table">
          <SubmissionsTableHeader sortSettings={sortSettings} handleSort={handleSort} />

          {showSearchLoader && <SubmissionsTableLoader />}
          {showSearchNoMatch && <SubmissionsTableNoMatch />}

          {showTable &&
            submissions.data.map((submission) => {
              const { id, firstName, lastName, email, note, ticket, ticketLastSentAt, registeredAt } = submission;

              const ticketUsed = ticket?.status === TicketStatus.USED;

              const memberName = firstName + " " + lastName;
              const formattedRegisteredDate = formatDateTime(registeredAt, eventTimezone);

              const formattedSentTicketDate = ticketLastSentAt
                ? formatDateTime(ticketLastSentAt, eventTimezone)
                : t("eventsViews:submissionsTable.notSent");

              const classes = classNames(buildTableRowClass(id), { "table-row-ticket-used": ticketUsed });

              return (
                <div key={id} className={classes}>
                  <div className="row-cell cell-checkbox">
                    <Checkbox value={id} onChange={checkMember} checked={checkIsSelectedMember(id)} />
                  </div>

                  <div className="row-cell cell-memebr-data">
                    <Group colGap={8}>
                      <p>{memberName}</p>
                      {ticketUsed && <img src="/images/icon-orange-check.svg" alt="user validated the ticket" />}
                    </Group>

                    <p>{email}</p>
                  </div>

                  <div className="row-cell cell-notes">
                    <p>{note || "-"}</p>
                  </div>

                  <div className="row-cell cell-dates">
                    <p>{formattedRegisteredDate}</p>
                    <p>{formattedSentTicketDate}</p>
                  </div>

                  <div className="row-cell cell-actions">
                    <Group colGap={10}>
                      {ticketUsed ? (
                        <Tooltip tip={t("common:ticketUsed")} position="top" textCenter>
                          <ActionIcon>
                            <img src="/images/icon-event-ticket.svg" alt="user validated the ticket" />
                          </ActionIcon>
                        </Tooltip>
                      ) : (
                        <Tooltip tip={sendTicketTip} position="top" textCenter>
                          <ActionIcon onClick={sendTicket(submission)} disabled={sendTicketDisabled}>
                            <img src="/images/icon-send.svg" alt="send ticket" />
                          </ActionIcon>
                        </Tooltip>
                      )}

                      <Tooltip tip={t("eventsViews:submissionsTable.tips.showDetails")} position="top">
                        <ActionIcon onClick={openSubmissionDetails(submission)}>
                          <img src="/images/icon-show-details.svg" alt="show ticket" />
                        </ActionIcon>
                      </Tooltip>

                      {ticketLastSentAt && ticket && (
                        <Dropdown>
                          <Dropdown.Target>
                            <ActionIcon className="clickable">
                              <img src="/images/icon-ticket-dropdown.svg" alt="ticket options button" />
                            </ActionIcon>
                          </Dropdown.Target>

                          <Dropdown.Menu>
                            <Dropdown.MenuItem onClick={saveTicketAsPdf(ticket.id, ticket.globalId)}>
                              {t("common:ticketDropdownActions.downloadTicketAsPDF")}
                            </Dropdown.MenuItem>

                            <Dropdown.MenuItem onClick={saveTicketAsPng(ticket.id, ticket.globalId)}>
                              {t("common:ticketDropdownActions.downloadTicketAsPNG")}
                            </Dropdown.MenuItem>

                            <Dropdown.MenuItem onClick={saveQrCodeAsPdf(ticket.id, ticket.globalId)}>
                              {t("common:ticketDropdownActions.downloadQrCodeAsPDF")}
                            </Dropdown.MenuItem>

                            <Dropdown.MenuItem onClick={saveQrCodeAsPng(ticket.id, ticket.globalId)}>
                              {t("common:ticketDropdownActions.downloadQrCodeAsPNG")}
                            </Dropdown.MenuItem>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Group>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {submissions.meta && (
        <Pagination
          currentPage={submissions.meta.page}
          totalCount={submissions.meta.total}
          pageSize={submissions.meta.perPage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

export default SubmissionsTableRender;
