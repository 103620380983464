import React from "react";
import { useTranslation } from "react-i18next";

import { selectAllText } from "@utils/selectAllText";
import { copyToClipboard } from "@utils/copyToClipboard";

import "./CopyText.scss";

interface CopyTextProps {
  text: string;
}

const CopyText = ({ text }: CopyTextProps) => {
  const { t } = useTranslation("common");

  const textId = `copy-text-${Date.now()}`;

  const handleCopyText = () => {
    selectAllText(textId);
    copyToClipboard(text);
  };

  return (
    <div title={t("copyToClipboard")} className="copy-text" onClick={handleCopyText}>
      <p id={textId} className="p0 copy-text-value">
        {text}
      </p>

      <img src="/images/icon-copy-blue.svg" alt="copy value" />
    </div>
  );
};

export default CopyText;
