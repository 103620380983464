import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CropperRef } from "react-advanced-cropper";
import { useFormik } from "formik";

import { parseFileToDataUrl } from "@utils/parseFileToDataUrl";
import { parseDataURLtoFile } from "@utils/parseDataURLtoFile";
import { captureCroppedImage } from "../../components/ImageCropper/utils/captureCroppedImage";

import { EventDto } from "@services/Api/api.dto";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useEvents } from "../../contexts/EventsData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";
import ImageCropper from "../../components/ImageCropper/ImageCropper";
import ImageDragAndDrop from "../../components/ImageDragAndDrop/ImageDragAndDrop";

import { createEventNftSchema } from "./createEventNft.schema";
import "./CreateEventNft.scss";

interface CreateEventNftForm {
  name: string;
  // image: File;
}

const CreateEventNft = () => {
  const cropperRef = useRef<CropperRef>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [nftImage, setNftImage] = useState<File | null>(null);
  const [imageInfo, setImageInfo] = useState<{ name: string; type: string }>({ name: "", type: "" });
  const [imageError, setImageError] = useState("");

  const { eventId } = useParams();

  const { createOccasionalNft } = useApiClient();
  const { updateEvent, getEvent } = useEvents();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const handleUplopadImage = (file: File | null) => setNftImage(file);

  const getCroppedImage = async () => {
    const current = cropperRef?.current;

    if (!current) return;

    const imageDataUrl = captureCroppedImage(cropperRef);
    return await parseDataURLtoFile(imageDataUrl || "", imageInfo.name, imageInfo.type);
  };

  const handleGetImageInfo = (file: File) => {
    setImageInfo({ name: file.name, type: file.type });
  };

  const validationSchema = createEventNftSchema(t, errorsT);
  const handleSetImageError = (error: string) => setImageError(error);

  const handleCreateNft = async ({ name }: CreateEventNftForm) => {
    setSubmitting(true);
    const croppedImage = await getCroppedImage();

    if (!eventId || !croppedImage || imageError) {
      setSubmitting(false);
      return;
    }
    createOccasionalNft(eventId || "", name, croppedImage)
      .then((res) => {
        if (res?.status === 201) {
          const event = getEvent(eventId);
          const updatedEvent = { ...event, occasionalNFT: res.data } as EventDto;
          updateEvent(eventId, updatedEvent);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<CreateEventNftForm>({
    initialValues: {
      name: ""
    },
    validationSchema,
    onSubmit: handleCreateNft
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;
  const disableSubmit = imageError.length > 0;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="create-event-nft-modal">
            <ScrollShadowWrapper contentClassName="create-event-nft-modal-content">
              <h2>{t("eventsModals:createEventNft.title")}</h2>
              <p className="p0 mt-2">{t("eventsModals:createEventNft.description")}</p>

              <Group colGap={10} alignItems="flex-start" className="mt-3 mb-4">
                <img src="/images/icon-notification-warning.svg" alt="create nft warning info" />
                <p className="p0">{t("eventsModals:createEventNft.warningInfo")}</p>
              </Group>

              <Stack rowGap={15} fullWidth>
                <Stack fullWidth>
                  <h3 className="mb-3">
                    {t("eventsModals:createEventNft.image")}
                    <span className="danger-1">*</span>
                  </h3>
                  {nftImage ? (
                    <ImageCropper
                      cropperRef={cropperRef}
                      imageFile={parseFileToDataUrl(nftImage)}
                      getOriginalImage={handleGetImageInfo}
                      onError={handleSetImageError}
                    />
                  ) : (
                    <ImageDragAndDrop onUpload={handleUplopadImage} />
                  )}
                </Stack>

                <Input
                  label={t("eventsModals:createEventNft.inputs.name.label")}
                  placeholder={t("eventsModals:createEventNft.inputs.name.placeholder")}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name && touched.name ? errors.name : ""}
                  maxBytes={16}
                  required
                />
              </Stack>

              <Group colGap={20} className="mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={handleSubmit}
                  loading={submitting}
                  disabled={disableSubmit}
                >
                  {t("common:save")}
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                  {t("common:cancel")}
                </Button>
              </Group>
            </ScrollShadowWrapper>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default CreateEventNft;
