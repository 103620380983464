import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import { OccasionalNFTRule } from "@services/Api/api.dto";
import { getCurrentRule, eventNftRulesOptions } from "../../utils/eventNftRules";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedEvent } from "../../contexts/SelectedEvent.context";
import { useEvents } from "../../contexts/EventsData.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Select from "@components/dataEntry/Select/Select";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { editEventNftSettingsSchema } from "./editEventNftSettings.schema";
import "./EditEventNftSettings.scss";

interface EditEventNftSettingsForm {
  occasionalNFTRule: OccasionalNFTRule;
}

const EditEventNftSettings = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { eventId } = useParams();

  const { event } = useSelectedEvent();
  const { updateEvent } = useEvents();
  const { updateOccasionalNft } = useApiClient();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation("eventsModals");
  const { t: errorsT } = useTranslation("formErrors");
  const { t: commonT } = useTranslation("common");

  const rulesOptions = eventNftRulesOptions(commonT);
  const currentRule = getCurrentRule(event.occasionalNFT?.occasionalNFTRule || "everyone", commonT);
  const validationSchema = editEventNftSettingsSchema(t, errorsT);

  const handleEditNftSettings = ({ occasionalNFTRule }: EditEventNftSettingsForm) => {
    setSubmitting(true);

    updateOccasionalNft(eventId || "", { occasionalNFTRule })
      .then((res) => {
        if (res?.status === 200) {
          if (eventId) {
            updateEvent(eventId, { occasionalNFT: res.data });
          }
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<EditEventNftSettingsForm>({
    initialValues: {
      occasionalNFTRule: event.occasionalNFT?.occasionalNFTRule || "everyone"
    },
    validationSchema,
    onSubmit: handleEditNftSettings
  });

  const { touched, errors, handleBlur, handleSubmit, setFieldValue, setFieldTouched } = formik;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-event-nft-settings-modal">
            <h2>{t("editEventNftSettings.title")}</h2>
            <p className="p0 mt-2">{t("editEventNftSettings.description")}</p>

            <Stack fullWidth className="mt-3">
              <Select
                label={t("editEventNftSettings.inputs.occasionalNFTRule.label")}
                placeholder={t("editEventNftSettings.inputs.occasionalNFTRule.placeholder")}
                options={rulesOptions}
                name="occasionalNFTRule"
                value={currentRule}
                onChange={(value: string) => {
                  setFieldValue("occasionalNFTRule", value);
                }}
                onBlur={handleBlur}
                onTouched={setFieldTouched}
                error={touched.occasionalNFTRule && errors.occasionalNFTRule ? errors.occasionalNFTRule : ""}
                required
              />
            </Stack>

            <Group colGap={20} className="mt-4">
              <Button type="submit" variant="primary" onClick={handleSubmit} loading={submitting}>
                {commonT("save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {commonT("cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditEventNftSettings;
