import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { IContext } from "src/types/IContext.types";
import { EventDto, EventStatus } from "@services/Api/api.dto";

import { useEvents } from "./EventsData.context";
import { eventMock } from "@mocks/event.mock";

interface ContextValue {
  event: EventDto;
  eventStatus: EventStatus;
  isEventEnded: boolean;
}

const SelectedEventContext = React.createContext(null as any);

export const SelectedEventProvider = ({ children }: IContext) => {
  const [event, setEvent] = useState<EventDto>(eventMock);
  const { eventId } = useParams();

  const { events, getEvent, status } = useEvents();

  useEffect(() => {
    if (eventId && status === "success") {
      const selectedEvent = getEvent(eventId);

      if (selectedEvent) {
        setEvent(selectedEvent);
      } else {
        setEvent(eventMock);
      }
    }
  }, [eventId, status, events]);

  const eventStatus = useMemo(() => event.status, [event.status]);
  const isEventEnded = useMemo(() => event.status === "ended", [event.status]);

  const contextValue: ContextValue = {
    event,
    eventStatus,
    isEventEnded
  };

  return <SelectedEventContext.Provider value={contextValue}>{children}</SelectedEventContext.Provider>;
};

export const useSelectedEvent = (): ContextValue => React.useContext(SelectedEventContext);
