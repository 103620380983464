import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNotification } from "@contexts/Notification.context";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Button from "@components/dataEntry/Buttons/Button/Button";
import UploadImage from "@components/dataEntry/UploadImage/UploadImage";
import TextEditor from "@components/dataEntry/TextEditor/TextEditor";

import "./EditEventPageContent.scss";

const EditEventPageContent = () => {
  const { event } = useSelectedEvent();

  const [eventPageImage, setEventPageImage] = useState<File | null>(null);
  const [eventPageImageError, setEventPageImageError] = useState<string>("");
  const [eventPageContent, setEventPageContent] = useState<string>(event.page.content || "");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { updateEvent } = useEvents();
  const { uploadEventPageImage, updateEventPageContent } = useApiClient();
  const { closeModal } = useEventsModals();
  const { notify } = useNotification();

  const { t } = useTranslation(["eventsModals", "apiResponse", "common"]);

  const handleSubmit = async () => {
    if (!event) return;
    setSubmitting(true);

    const results = await Promise.allSettled([
      updateEventPageContent(event.id, eventPageContent),
      uploadEventPageImage(event.id, eventPageImage || "")
    ]);

    const error = results.some((value) => value.status === "rejected");
    if (error) return setSubmitting(false);

    const responseEventData = (results[0] as any).value;
    const responseUploadImage = (results[1] as any).value;

    if (responseEventData.status === 200 && responseUploadImage.status === 200) {
      notify("success", t("apiResponse:success.updateEventPage"));
    } else if (responseEventData.status === 200 && responseUploadImage.status !== 200) {
      notify("success", t("apiResponse:success.updateEventPageContent"));
    } else if (responseEventData.status !== 200 && responseUploadImage.status === 200) {
      notify("success", t("apiResponse:success.uploadEventPageImage"));
    }

    const updatedEvent = {
      ...responseEventData.data,
      page: { ...responseEventData.data.page, imageUrl: responseUploadImage.data.page.imageUrl }
    };

    updateEvent(event.id, updatedEvent);
    closeModal();
    setSubmitting(false);
  };

  const handleChangeEventPageImage = (file: File | null) => setEventPageImage(file);
  const handleErrorEventPageImage = (error: string) => setEventPageImageError(error);
  const handleChangeEventPageContent = (content: string) => setEventPageContent(content);

  const sendButtonDisabled = !!eventPageImageError;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-event-page-content-modal">
            <ScrollShadowWrapper contentClassName="edit-event-page-content-modal-content">
              <h2>{t("eventsModals:editEventPageContent.title")}</h2>
              <p className="p0 mt-2">{t("eventsModals:editEventPageContent.description")}</p>

              <UploadImage
                title={t("eventsModals:editEventPageContent.eventPageImage")}
                imageUrl={event.page.imageUrl}
                onChange={handleChangeEventPageImage}
                onError={handleErrorEventPageImage}
                className="mt-4"
              />
              <p className="mt-2 mb-4 p0 danger-1">{eventPageImageError}</p>

              <p className="p4 bold mb-2">{t("eventsModals:editEventPageContent.eventDescription")}</p>

              <Group className="mt-3 mb-3">
                <TextEditor
                  content={event.page.content}
                  onChange={handleChangeEventPageContent}
                  className="edit-event-page-content-modal-editor"
                />
              </Group>
            </ScrollShadowWrapper>

            <Group colGap={20} className="mt-3">
              <Button
                type="submit"
                variant="primary"
                onClick={handleSubmit}
                loading={submitting}
                disabled={sendButtonDisabled}
              >
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditEventPageContent;
