import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";

import { copyToClipboard } from "@utils/copyToClipboard";
import { formatDateTime } from "@utils/formatDateTime";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import BadgeStatus from "@components/common/BadgeStatus/BadgeStatus";
import Link from "@components/navigation/Link/Link";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";

import EventHeaderSkeleton from "../../skeletons/EventHeaderSkeleton/EventHeaderSkeleton";
import EventHeaderActions from "./EventHeaderActions/EventHeaderActions";
import "./EventHeader.scss";

const EventHeader = () => {
  const { status: fetchEventStatus } = useEvents();
  const { event } = useSelectedEvent();

  const { t } = useTranslation("eventsViews");

  const buildTicketCounter = useMemo(() => {
    if (!event) return;
    return event.ticketsMinted ? `${event.ticketsBurned}/${event.ticketsMinted}` : t("noTicket");
  }, [event]);

  const handleCopyToClipboard = (text: string) => () => copyToClipboard(text);

  const bulidStatusText = event.status === "started" ? t("eventStatus.started") : t("eventStatus.ended");

  const eventHasEnableNft = event.page.occasionalNFT;

  if (fetchEventStatus === "loading") {
    return <EventHeaderSkeleton />;
  }

  return (
    <Stack rowGap={20} className="event-header">
      <Group justifyContent="space-between" fullWidth>
        <Group colGap={20} alignItems="center">
          <Group colGap={10} alignItems="center">
            <img src="/images/icon-event-date.svg" alt="event date" />
            <p className="p0">{formatDateTime(event.start, event.timezone)}</p>
          </Group>
          {event.status !== "draft" && <BadgeStatus variant={event.status} text={bulidStatusText} />}
        </Group>

        <Group colGap={20}>
          {eventHasEnableNft && <img src="/images/icon-event-has-nft.svg" alt="event has nft" />}

          <Group colGap={10} alignItems="center">
            <img src="/images/icon-event-members.svg" alt="event members" />
            <p className="p0">{event.submissionsCount}</p>
          </Group>

          <Group colGap={10} alignItems="center">
            <img src="/images/icon-event-ticket.svg" alt="event tickets" />
            <p className="p0">{buildTicketCounter}</p>
          </Group>

          <EventHeaderActions />
        </Group>
      </Group>

      <Group justifyContent="space-between" fullWidth>
        <Group colGap={10} alignItems="center">
          <img src="/images/icon-event-location-address.svg" alt="event location tag" />
          <p className="p0">{event.location.name || "-"}</p>
        </Group>

        <Group colGap={20} alignItems="center">
          <p className="p0">{t("eventHeader.eventWebsite.title")}</p>

          <Tooltip tip={t("eventHeader.eventWebsite.showWebsiteTip")} position="top">
            <Link href={event.url} target="_blank" withImage={false}>
              <ActionIcon size={40} clickable>
                <img src="/images/icon-link-blue.svg" alt="event website link" />
              </ActionIcon>
            </Link>
          </Tooltip>

          <Tooltip tip={t("eventHeader.eventWebsite.copyWebsiteUrl")} position="top">
            <ActionIcon size={40} onClick={handleCopyToClipboard(event.url)}>
              <img src="/images/icon-copy-blue.svg" alt="copy url button" />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>

      <h1 className="black-1 mt-3">{event.name}</h1>
    </Stack>
  );
};

export default EventHeader;
