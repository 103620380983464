/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { HttpError } from "@services/httpErrors.enum";
import { MAX_AGREEMENTS_COUNT } from "@config/config";
import { AgreementDto } from "@services/Api/api.dto";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useNotification } from "@contexts/Notification.context";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Button from "@components/dataEntry/Buttons/Button/Button";
import SwitchInput from "@components/dataEntry/SwitchInput/SwitchInput";
import Input from "@components/dataEntry/Input/Input";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";
import AddItemIcon from "src/assets/AddItemIcon";

import { EditAgreementsForm } from "./EditAgreements.types";
import { editAgreementsSchema } from "./editAgreements.schema";
import "./EditAgreements.scss";

const EditAgreements = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const arrayHelpersRef = useRef(null as any);
  const [endAgreementsListRef, setEndAgreementsListRef] = useState<HTMLDivElement | null>(null);

  const { event } = useSelectedEvent();
  const { updateEvent } = useEvents();
  const { updateEventAgreements } = useApiClient();
  const { closeModal } = useEventsModals();
  const { notify } = useNotification();

  const { t } = useTranslation("eventsModals");
  const { t: responseT } = useTranslation("apiResponse");
  const { t: errorsT } = useTranslation("formErrors");

  const validationSchema = editAgreementsSchema(t, errorsT);

  const agreementsWithoutId = (): Omit<AgreementDto, "id">[] => {
    return event.agreements.map((agreement) => ({ ...agreement, id: undefined }));
  };

  const generateNewAgreements = (index: number) => ({
    index: index,
    name: "",
    content: "",
    url: "",
    required: false
  });

  const initialAgreementsState = () => {
    if (event.agreements.length > 0) return agreementsWithoutId();

    return [generateNewAgreements(0)];
  };

  const initialValues = {
    agreements: initialAgreementsState()
  };

  const handleAddAgreement = (index: number) => () => {
    const agreement = generateNewAgreements(index);
    arrayHelpersRef.current.push(agreement);

    if (endAgreementsListRef) {
      setTimeout(() => {
        endAgreementsListRef.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleRemoveAgreement = (index: number) => () => {
    arrayHelpersRef.current.remove(index);
  };

  const handleUpdateAgreements = async ({ agreements }: EditAgreementsForm) => {
    if (!event) return;
    setSubmitting(true);

    const parsedAgreements = agreements.map((agreement) => ({ ...agreement, url: agreement.url || null }));

    updateEventAgreements(event.id, parsedAgreements)
      .then((res) => {
        if (res.status === 200) {
          notify("success", responseT("success.updateEventAgreements"));
          updateEvent(event.id, {
            ...event,
            agreements: res.data
          });
          closeModal();
        }
      })
      .catch((error: any) => {
        if (error?.response?.data.message === HttpError.EVENT_AGREEMENTS_LOCKED) {
          const updatedEvent = { ...event, agreementsLocked: true };
          updateEvent(event.id, updatedEvent);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleOnSubmit = (values: EditAgreementsForm) => {
    return handleUpdateAgreements(values);
  };

  const formik = useFormik<EditAgreementsForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleOnSubmit
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;

  const disabledAddAgreement = values.agreements.length === MAX_AGREEMENTS_COUNT || event.agreementsLocked;
  const disabledEditAgreement = event.agreementsLocked;

  const editInfoIconPath = event.agreementsLocked
    ? "/images/icon-notification-warning.svg"
    : "/images/icon-notification-info.svg";
  const editInfoText = event.agreementsLocked ? t("editAgreements.editWarning") : t("editAgreements.editInfo");

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-agreements-modal">
            <ScrollShadowWrapper contentClassName="edit-agreements-modal-content">
              <h2>{t("editAgreements.title")}</h2>

              <p className="p0 mt-2">{t("editAgreements.description")}</p>

              <Group colGap={10} alignItems="flex-start" className="mt-4 mb-4">
                <img src={editInfoIconPath} alt="edit agreements info" />
                <p className="p0">{editInfoText}</p>
              </Group>

              <FormikProvider value={formik}>
                <FieldArray name="agreements">
                  {(arrayHelpers) => {
                    arrayHelpersRef.current = arrayHelpers;

                    return (
                      <>
                        {values.agreements.length > 0 &&
                          values.agreements.map((agreement, index) => (
                            <Stack key={`agreement.${index}`} className="edit-agreements-modal-agreement">
                              <Group colGap={15} justifyContent="flex-end" fullWidth className="agreement-controls">
                                <SwitchInput
                                  label={t("editAgreements.agreementsRequired")}
                                  name={`agreements.${index}.required`}
                                  checked={agreement.required}
                                  onChange={handleChange}
                                  disabled={disabledEditAgreement}
                                />

                                <Tooltip tip={t("editAgreements.buttons.removeAgreement")} hide={disabledEditAgreement}>
                                  <ActionIcon
                                    size={40}
                                    onClick={handleRemoveAgreement(index)}
                                    disabled={disabledEditAgreement}
                                  >
                                    <img src="/images/icon-remove.svg" alt="remove agreement button" />
                                  </ActionIcon>
                                </Tooltip>
                              </Group>

                              <Stack rowGap={20} className="agreement-inputs">
                                <Input
                                  label={t("editAgreements.inputs.name.label", { number: index + 1 })}
                                  placeholder={t("editAgreements.inputs.name.placeholder")}
                                  name={`agreements[${index}].name`}
                                  value={agreement.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    // prettier-ignore
                                    // @ts-ignore
                                    errors.agreements?.[index]?.name && touched.agreements?.[index]?.name ? errors.agreements?.[index]?.name : ""
                                  }
                                  disabled={disabledEditAgreement}
                                  maxLength={100}
                                  required
                                />
                                <Input
                                  label={t("editAgreements.inputs.content.label", { number: index + 1 })}
                                  placeholder={t("editAgreements.inputs.content.placeholder")}
                                  name={`agreements[${index}].content`}
                                  value={agreement.content}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    // prettier-ignore
                                    // @ts-ignore
                                    errors.agreements?.[index]?.content && touched.agreements?.[index]?.content ? errors.agreements?.[index]?.content : ""
                                  }
                                  disabled={disabledEditAgreement}
                                  required
                                />
                                <Input
                                  label={t("editAgreements.inputs.url.label", { number: index + 1 })}
                                  placeholder={t("editAgreements.inputs.url.placeholder")}
                                  name={`agreements[${index}].url`}
                                  value={agreement.url || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    // prettier-ignore
                                    // @ts-ignore
                                    errors.agreements?.[index]?.url && touched.agreements?.[index]?.url ? errors.agreements?.[index]?.url : ""
                                  }
                                  disabled={disabledEditAgreement}
                                />
                              </Stack>
                            </Stack>
                          ))}

                        <Group justifyContent="flex-end" fullWidth className="mt-3 mb-3">
                          <Tooltip
                            tip={t("editAgreements.buttons.addAgreementTip", { max: MAX_AGREEMENTS_COUNT })}
                            hide={!disabledAddAgreement || disabledEditAgreement}
                          >
                            <Button
                              variant="subtle"
                              onClick={handleAddAgreement(values.agreements.length || 0)}
                              disabled={disabledAddAgreement}
                              className="add-agreement-button"
                            >
                              {t("editAgreements.buttons.addAgreement")}
                              <AddItemIcon />
                            </Button>
                          </Tooltip>
                        </Group>
                      </>
                    );
                  }}
                </FieldArray>
              </FormikProvider>

              <div ref={setEndAgreementsListRef} />
            </ScrollShadowWrapper>

            <Group colGap={20} className="mt-3">
              <Button type="submit" variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("editAgreements.buttons.submit")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("editAgreements.buttons.close")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditAgreements;
