import { Editor } from "@tiptap/react";

import TextBold from "../icons/TextBold";
import TextItalic from "../icons/TextItalic";
import TextUnderline from "../icons/TextUnderline";
import TextStrike from "../icons/TextStrike";
import TextHighlight from "../icons/TextHighlight";

import Heading1 from "../icons/Heading1";
import Heading2 from "../icons/Heading2";
import Heading3 from "../icons/Heading3";
import Heading4 from "../icons/Heading4";

import Blockquote from "../icons/Blockquote";
import HorizontalRule from "../icons/HorizontalRule";
import BreakLine from "../icons/BreakLine";
import BulletList from "../icons/BulletList";
import OrderedList from "../icons/OrderedList";

import TextAlignLeft from "../icons/TextAlignLeft";
import TextAlignCenter from "../icons/TextAlignCenter";
import TextAlignRight from "../icons/TextAlignRight";
import TextAlignJustify from "../icons/TextAlignJustify";

export const menuUtilsTextStyle = (editor: Editor) => [
  {
    titleKey: "textBold",
    onClick: () => editor.chain().focus().toggleBold().run(),
    disabled: !editor.can().chain().focus().toggleBold().run(),
    className: `menu-utils-button ${editor.isActive("bold") ? "is-active" : ""}`,
    icon: <TextBold />
  },
  {
    titleKey: "textItalic",
    onClick: () => editor.chain().focus().toggleItalic().run(),
    disabled: !editor.can().chain().focus().toggleItalic().run(),
    className: `menu-utils-button ${editor.isActive("italic") ? "is-active" : ""}`,
    icon: <TextItalic />
  },
  {
    titleKey: "textUnderline",
    onClick: () => editor.chain().focus().toggleUnderline().run(),
    disabled: !editor.can().chain().focus().toggleUnderline().run(),
    className: `menu-utils-button ${editor.isActive("underline") ? "is-active" : ""}`,
    icon: <TextUnderline />
  },
  {
    titleKey: "textStrike",
    onClick: () => editor.chain().focus().toggleStrike().run(),
    disabled: !editor.can().chain().focus().toggleStrike().run(),
    className: `menu-utils-button ${editor.isActive("strike") ? "is-active" : ""}`,
    icon: <TextStrike />
  },
  {
    titleKey: "textHighlight",
    onClick: () => editor.chain().focus().toggleHighlight().run(),
    disabled: !editor.can().chain().focus().toggleHighlight().run(),
    className: `menu-utils-button ${editor.isActive("highlight") ? "is-active" : ""}`,
    icon: <TextHighlight />
  }
];

export const menuUtilsHeadings = (editor: Editor) => [
  {
    titleKey: "heading",
    headingLevel: 1,
    onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    disabled: !editor.can().chain().focus().toggleHeading({ level: 1 }).run(),
    className: `menu-utils-button ${editor.isActive("heading", { level: 1 }) ? "is-active" : ""}`,
    icon: <Heading1 />
  },
  {
    titleKey: "heading",
    headingLevel: 2,
    onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    disabled: !editor.can().chain().focus().toggleHeading({ level: 2 }).run(),
    className: `menu-utils-button ${editor.isActive("heading", { level: 2 }) ? "is-active" : ""}`,
    icon: <Heading2 />
  },
  {
    titleKey: "heading",
    headingLevel: 3,
    onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    disabled: !editor.can().chain().focus().toggleHeading({ level: 3 }).run(),
    className: `menu-utils-button ${editor.isActive("heading", { level: 3 }) ? "is-active" : ""}`,
    icon: <Heading3 />
  },
  {
    titleKey: "heading",
    headingLevel: 4,
    onClick: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
    disabled: !editor.can().chain().focus().toggleHeading({ level: 4 }).run(),
    className: `menu-utils-button ${editor.isActive("heading", { level: 4 }) ? "is-active" : ""}`,
    icon: <Heading4 />
  }
];

export const menuUtilsElements = (editor: Editor) => [
  {
    titleKey: "blockquote",
    onClick: () => editor.chain().focus().toggleBlockquote().run(),
    disabled: !editor.can().chain().focus().toggleBlockquote().run(),
    className: `menu-utils-button ${editor.isActive("blockquote") ? "is-active" : ""}`,
    icon: <Blockquote />
  },
  {
    titleKey: "horizontalRule",
    onClick: () => editor.chain().focus().setHorizontalRule().run(),
    disabled: !editor.can().chain().focus().setHorizontalRule().run(),
    className: `menu-utils-button`,
    icon: <HorizontalRule />
  },
  {
    titleKey: "breakLine",
    onClick: () => editor.chain().focus().setHardBreak().run(),
    disabled: !editor.can().chain().focus().setHardBreak().run(),
    className: `menu-utils-button`,
    icon: <BreakLine />
  },
  {
    titleKey: "bulletList",
    onClick: () => editor.chain().focus().toggleBulletList().run(),
    disabled: !editor.can().chain().focus().toggleBulletList().run(),
    className: `menu-utils-button ${editor.isActive("bulletList") ? "is-active" : ""}`,
    icon: <BulletList />
  },
  {
    titleKey: "orderedList",
    onClick: () => editor.chain().focus().toggleOrderedList().run(),
    disabled: !editor.can().chain().focus().toggleOrderedList().run(),
    className: `menu-utils-button ${editor.isActive("orderedList") ? "is-active" : ""}`,
    icon: <OrderedList />
  }
];

export const menuUtilsTextAligns = (editor: Editor) => [
  {
    titleKey: "textAlignLeft",
    onClick: () => editor.chain().focus().setTextAlign("left").run(),
    disabled: !editor.can().chain().focus().setTextAlign("left").run(),
    className: `menu-utils-button ${editor.isActive({ textAlign: "left" }) ? "is-active" : ""}`,
    icon: <TextAlignLeft />
  },
  {
    titleKey: "textAlignCenter",
    onClick: () => editor.chain().focus().setTextAlign("center").run(),
    disabled: !editor.can().chain().focus().setTextAlign("center").run(),
    className: `menu-utils-button ${editor.isActive({ textAlign: "center" }) ? "is-active" : ""}`,
    icon: <TextAlignCenter />
  },
  {
    titleKey: "textAlignRight",
    onClick: () => editor.chain().focus().setTextAlign("right").run(),
    disabled: !editor.can().chain().focus().setTextAlign("right").run(),
    className: `menu-utils-button ${editor.isActive({ textAlign: "right" }) ? "is-active" : ""}`,
    icon: <TextAlignRight />
  },
  {
    titleKey: "textAlignJustify",
    onClick: () => editor.chain().focus().setTextAlign("justify").run(),
    disabled: !editor.can().chain().focus().setTextAlign("justify").run(),
    className: `menu-utils-button ${editor.isActive({ textAlign: "justify" }) ? "is-active" : ""}`,
    icon: <TextAlignJustify />
  }
];
