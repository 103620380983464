import React from "react";
import { useTranslation } from "react-i18next";

import { eventNftRules } from "@app/panel/Events/utils/eventNftRules";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";

import "./NftSettings.scss";

const NftSettings = () => {
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  const rules = commonT(eventNftRules[event.occasionalNFT?.occasionalNFTRule || "everyone"] as any);

  const handleOpenModal = () => openModal({ type: "editEventNftSettings" });

  const editButtonDisabled = eventStatus === "ended";

  return (
    <div className="nft-settings">
      <h3 className="nft-settings-title">{t("eventNft.nftSettings.title")}</h3>

      <div className="nft-settings-content">
        <Stack rowGap={12} fullWidth>
          <p className="p4 black-2">{t("eventNft.nftSettings.rules")}</p>
          <p className="p4 content-value">{rules}</p>
        </Stack>

        <Tooltip tip={commonT("eventEndedDisabled")} hide={!editButtonDisabled} width={200} textCenter>
          <Button className="mt-4" onClick={handleOpenModal} disabled={editButtonDisabled}>
            {commonT("edit")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default NftSettings;
