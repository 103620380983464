import React from "react";
import { useTranslation } from "react-i18next";

import { useEventsModals } from "../../contexts/EventsModals.context";
import { useSelectedEvent } from "../../contexts/SelectedEvent.context";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./EventNftEmptyState.scss";

const EventNftEmptyState = () => {
  const { eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();
  const { t } = useTranslation("eventsViews");

  const handleOpenModal = () => openModal({ type: "createEventNft" });

  const createButtonDisabled = eventStatus === "ended";

  return (
    <div className="event-nft-empty-state">
      <img src="/images/no-nfts-bg.png" alt="no nfts" className="event-nft-empty-state-background" />

      <Stack rowGap={30} alignItems="center" fullSize className="event-nft-empty-state-content">
        <img src="/images/icon-occasional-nft-empty-state.svg" alt="occasional nft empty state" />

        <p className="p0 text-center">{t("eventNft.emptyState.description")}</p>

        <Button variant="primary" onClick={handleOpenModal} disabled={createButtonDisabled}>
          {t("eventNft.emptyState.button")}
        </Button>
      </Stack>
    </div>
  );
};

export default EventNftEmptyState;
