import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetData } from "@services/blockchain/blockchain.types";

import { useTransaction } from "@services/blockchain/hooks/useTransaction";
import { useWvs } from "@services/blockchain/Waves.context";
import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./BurnNft.scss";

interface BurnNftProps {
  nft: AssetData;
}

const BurnNft = ({ nft }: BurnNftProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [burningError, setBurningError] = useState("");

  const tx = useTransaction();
  const { services } = useWvs();
  const { removeAssetData } = useBrowseNfts();
  const { closeModal } = usePoapNftModals();

  const { t } = useTranslation(["poapNft", "apiResponse", "common"]);

  const handleBurn = async () => {
    try {
      setBurningError("");
      setSubmitting(true);

      const params = services.txCreator.createBurnTxData(nft.assetId, 1);

      await tx
        .send(params)
        .then(() => removeAssetData(nft.assetId))
        .then(closeModal);
    } catch (e: any) {
      if (e?.message === "User denied message") {
        setBurningError(t("apiResponse:errors.bcTransactionRejected"));
      } else {
        setBurningError(t("apiResponse:errors.bcTransactionFailed"));
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack rowGap={20} className="burn-nft-modal">
            <h2>{t("poapNft:modals.burnNft.title")}</h2>

            <p className="p0">{t("poapNft:modals.burnNft.description")}</p>

            {burningError && (
              <Group colGap={8} className="transaction-error">
                <img src="/images/icon-notification-error.svg" alt="error icon" />
                <p className="p0 danger-1 text-center">{burningError}</p>
              </Group>
            )}

            <Group colGap={10} className="mt-2">
              <Button type="submit" variant="red" onClick={handleBurn} disabled={submitting}>
                {t("common:accept")}
              </Button>

              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default BurnNft;
