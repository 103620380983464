import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";
import { deviceInfo } from "@utils/browser/deviceInfo";
import { copyToClipboard } from "@utils/copyToClipboard";

import { useWvs } from "@services/blockchain/Waves.context";
import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";

import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./PoapExplorerHeader.scss";

const PoapExplorerHeader = () => {
  const { publicState, status: keeperStatus, disconnect } = useWvs();
  const { updateWalletAddress } = useBrowseNfts();
  const { openModal } = usePoapNftModals();

  const navigate = useNavigate();
  const { t } = useTranslation(["layout", "common"]);

  const isConnected = useMemo(() => keeperStatus === "connected", [keeperStatus]);
  const isMobile = deviceInfo.isMobileDevice();

  const handleOpenConnectModal = () => openModal({ type: "connectWallet" });

  const handleSelectMyAddress = () => {
    const address = publicState?.account?.address || "";
    copyToClipboard(address);
    updateWalletAddress(address);
  };

  const navigateToPoapNft = () => navigate(PATHS.poapNft.path);

  return (
    <div className="poap-explorer-header">
      <div className="poap-explorer-header-content">
        <button onClick={navigateToPoapNft}>
          <img src="/images/skey-tix-logo-poap.svg" alt="skey tix logo" className="skey-tix-logo-poap" />
        </button>

        {!isMobile && (
          <>
            {isConnected ? (
              <Group colGap={20}>
                <Group colGap={4}>
                  <p className="p0">{t("layout:nftExplorerHeader.walletAddress")}</p>

                  <div role="button" className="wallet-address-btn" onClick={handleSelectMyAddress}>
                    <p className="p0">{publicState?.account?.address}</p>
                  </div>
                </Group>

                <Button variant="secondary" onClick={disconnect}>
                  {t("common:disconnect")}
                </Button>
              </Group>
            ) : (
              <Group colGap={20}>
                <p className="p0">{t("layout:nftExplorerHeader.disconnected")}</p>
                <Button onClick={handleOpenConnectModal}>{t("common:connect")}</Button>
              </Group>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PoapExplorerHeader;
