import { BrowserRouter, useRoutes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { NotificationProvider } from "@contexts/Notification.context";
import { IS_PROD_MODE } from "@config/helpers";
import i18n from "src/translations/i18n";
import hotjar from "@scripts/hotjar";

import { panelRoutes } from "./routes/panel.routes";
import { publicRoutes } from "./routes/public.routes";
import { poapnftRoutes } from "./routes/poapnft.routes";
import { commonRoutes } from "./routes/common.routes";

const Routes = () => useRoutes([panelRoutes, publicRoutes, poapnftRoutes, commonRoutes]);

export const Router = () => (
  <HelmetProvider>
    <Helmet htmlAttributes={{ lang: i18n.language }}>
      {IS_PROD_MODE && <script type="text/javascript">{hotjar}</script>}
    </Helmet>

    <BrowserRouter>
      <NotificationProvider>
        <Routes />
      </NotificationProvider>
    </BrowserRouter>
  </HelmetProvider>
);
