import React, { useState, useCallback, useContext } from "react";
import classNames from "classnames";

import ClickAwayListener from "../ClickAwayListener/ClickAwayListener";
import DropdownTarget from "./common/DropdownTarget";
import DropdownMenu from "./common/DropdownMenu";
import DropdownMenuItem from "./common/DropdownMenuItem";

import { DropdownProps, ContextValue } from "./Dropdown.types";
import "./Dropdown.scss";

const DropdownDataContext = React.createContext(null as any);

const Dropdown = ({ children, className }: DropdownProps) => {
  const [dropdownRef, setDropdownRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleToggleOpenMenu = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);

  const contextValue: ContextValue = {
    isMenuOpen,
    dropdownRef,
    popperRef,
    setPopperRef,
    handleCloseMenu,
    handleToggleOpenMenu
  };

  const classes = classNames("dropdown", className);

  return (
    <DropdownDataContext.Provider value={contextValue}>
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div ref={setDropdownRef} className={classes}>
          {children}
        </div>
      </ClickAwayListener>
    </DropdownDataContext.Provider>
  );
};

export const useDropdownDataContext = (): ContextValue => useContext(DropdownDataContext);

Dropdown.Target = DropdownTarget;
Dropdown.Menu = DropdownMenu;
Dropdown.MenuItem = DropdownMenuItem;

export default Dropdown;
