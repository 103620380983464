import React from "react";
import { nanoid } from "nanoid";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { eventNavPaths } from "src/router/paths";
import Group from "@components/arrangement/Group/Group";
import NavLink from "@components/navigation/NavLink/NavLink";

import "./EventNavbar.scss";

const EventNavbar = () => {
  const { t: layoutT } = useTranslation("layout");
  const { eventId } = useParams();

  return (
    <div className="event-navbar">
      <Group alignItems="center">
        {eventNavPaths.map((path) => {
          const { tKey, pathName } = path;

          return (
            <NavLink
              key={nanoid()}
              to={path.getPath(eventId as string)}
              name={layoutT(tKey as any) || (pathName as string)}
            />
          );
        })}
      </Group>
    </div>
  );
};

export default EventNavbar;
