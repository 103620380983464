import { getScrollBarWidth } from "@utils/getScrollBarWidth";
import { useState, useEffect } from "react";

export const useImageViewer = () => {
  const [isPreviewOpen, setShowPreview] = useState<boolean>(false);

  const showPreview = () => {
    setShowPreview(true);
  };

  const dontClosePreview = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) closePreview();
    });

    if (isPreviewOpen) {
      const body = document.body;
      const scrollBarWidth = getScrollBarWidth();

      body.style.width = `calc(100vw - ${scrollBarWidth}px)`;
      body.style.overflow = "hidden";

      return () => {
        body.style.width = "";
        body.style.overflow = "";
      };
    }
  }, [isPreviewOpen]);

  return {
    isPreviewOpen,
    showPreview,
    dontClosePreview,
    closePreview
  };
};
