import { Path } from "src/router/common/Path";

export const PATHS = {
  // Auth paths ***********************************************
  login: new Path({
    path: "/login",
    pathName: "Login"
  }),
  resetPassword: new Path({
    path: "/reset-password",
    pathName: "Reset password"
  }),
  newPassword: new Path({
    path: "/new-password",
    pathName: "New password"
  }),
  passwordChanged: new Path({
    path: "/passwordChanged",
    pathName: "Password changed"
  }),
  register: new Path({
    path: "/register",
    pathName: "Register"
  }),
  registerConfirm: new Path({
    path: "/register-confirm",
    pathName: "Register confirm"
  }),
  activateAccount: new Path({
    path: "/activate-account",
    pathName: "Activate account"
  }),
  // Auth paths ***********************************************

  // Authorized panel paths *****************************************
  main: new Path({
    path: "/"
  }),
  events: new Path({
    path: "/events",
    pathName: "Events",
    tKey: "pathNames.events"
  }),
  event: new Path({
    path: "/events/:eventId/tickets", // tickets is default active tab
    ref: (eventId: string): string => `/events/${eventId}/tickets`
  }),
  tickets: new Path({
    path: "/events/:eventId/tickets",
    pathName: "Tickets",
    tKey: "pathNames.tickets",
    ref: (eventId: string): string => `/events/${eventId}/tickets`
  }),
  eventSettings: new Path({
    path: "/events/:eventId/event-settings",
    pathName: "Event settings",
    tKey: "pathNames.eventSettings",
    ref: (eventId: string): string => `/events/${eventId}/event-settings`
  }),
  eventSubmissions: new Path({
    path: "/events/:eventId/event-submissions",
    pathName: "Submissions",
    tKey: "pathNames.eventSubmissions",
    ref: (eventId: string): string => `/events/${eventId}/event-submissions`
  }),
  eventNft: new Path({
    path: "/events/:eventId/event-nft",
    pathName: "Nft",
    tKey: "pathNames.eventNft",
    ref: (eventId: string): string => `/events/${eventId}/event-nft`
  }),
  noEvents: new Path({
    path: "/events/no-events"
  }),
  settings: new Path({
    path: "/settings",
    pathName: "Settings",
    tKey: "pathNames.settings"
  }),
  help: new Path({
    path: "/help",
    pathName: "Help",
    tKey: "pathNames.help"
  }),
  // Authorized panel paths *****************************************

  // Public paths *********************************************
  eventPage: new Path({
    path: "/event-info/:eventId",
    pathName: "Event info",
    ref: (eventId: string): string => `/event-info/${eventId}`
  }),
  claimPage: new Path({
    path: "/claim",
    pathName: "Claim-page"
  }),
  // Public paths *********************************************

  // Poap nfts paths *********************************************
  poapNft: new Path({
    path: "/poap-nft",
    pathName: "Poap nft"
  }),
  poapNftSearch: new Path({
    path: "/poap-nft/search",
    pathName: "Poap nft search"
  }),
  poapNftExplore: new Path({
    path: "/poap-nft/explore",
    pathName: "Poap nft explore"
  }),
  // Poap nfts paths *********************************************

  // Common paths *********************************************
  notFound: new Path({
    path: "/*"
  })
  // Common paths *********************************************
} as const;

export const mainNavPaths = [PATHS.events, PATHS.settings, PATHS.help];
export const eventNavPaths = [PATHS.tickets, PATHS.eventSettings, PATHS.eventSubmissions, PATHS.eventNft];
