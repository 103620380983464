import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ClaimingUserDto, ClaimingUsersOrderBy } from "@services/Api/api.dto";
import { formatDateTime } from "@utils/formatDateTime";

import useSortableTable from "@hooks/useSortableTable";
import { useClaimingUsers } from "../../contexts/ClaimingUsersData.context";
import { useSelectedClaimingUsers } from "../../contexts/SelectedClaimingUsers.context";
import { useEventsModals } from "../../contexts/EventsModals.context";
import { useSelectedEvent } from "../../contexts/SelectedEvent.context";

import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";
import Checkbox from "@components/dataEntry/Checkbox/Checkbox";
import Pagination from "@components/navigation/Pagination/Pagination";

import ClaimingUsersTableHeader from "./components/ClaimingUsersTableHeader/ClaimingUsersTableHeader";

import "./ClaimingUsersTableRender.scss";

const USERS_PER_PAGE = 20;

const ClaimingUsersTable = () => {
  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  const { event } = useSelectedEvent();
  const { claimingUsers, refetchClaimingUsersData, getClaimingUser } = useClaimingUsers();
  const { addClaimingUser, removeClaimingUser, checkIsSelectedMember } = useSelectedClaimingUsers();
  const { sortSettings, setSortingSettings } = useSortableTable<ClaimingUsersOrderBy>();
  const { openModal } = useEventsModals();

  const handleSort = (orderBy: ClaimingUsersOrderBy) => () => setSortingSettings(orderBy);

  const handleOpenSendNftModal = (claimingUser: ClaimingUserDto) => () =>
    openModal({ type: "sendNft", data: claimingUser });

  const handleOpenShowNftDetailsModal = (claimingUser: ClaimingUserDto) => () =>
    openModal({ type: "nftPreview", data: claimingUser });

  const handlePageChange = useCallback((page: number) => {
    refetchClaimingUsersData(event.id, page, USERS_PER_PAGE);
  }, []);

  const handleCheckMember = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    const claimingUser = getClaimingUser(value);

    if (!claimingUser) return;

    if (checked) {
      addClaimingUser(claimingUser);
    } else {
      removeClaimingUser(claimingUser.id);
    }
  };

  const buildTableRowClass = (id: string) => {
    let rowClass = "table-row";
    const isSelected = checkIsSelectedMember(id);
    if (isSelected) rowClass += " table-row-checked";
    return rowClass;
  };

  useEffect(() => {
    if (!sortSettings) return;

    refetchClaimingUsersData(
      event.id,
      claimingUsers.meta.page,
      USERS_PER_PAGE,
      sortSettings.order,
      sortSettings.orderBy
    );
  }, [sortSettings]);

  return (
    <>
      <div className="claiming-users-table">
        <div className="table">
          <ClaimingUsersTableHeader sortSettings={sortSettings} handleSort={handleSort} />

          {claimingUsers.data.length > 0 &&
            claimingUsers.data.map((user) => {
              const { id, email, claimed, nftLastSentAt, claimingAt, ticket } = user;

              const userName = ticket.submission?.firstName + " " + ticket.submission?.lastName;

              const nftLastSentDate = nftLastSentAt
                ? formatDateTime(nftLastSentAt, event.timezone)
                : commonT("notSent");

              const claimingDate = claimed ? formatDateTime(claimingAt, event.timezone) : commonT("notDone");

              return (
                <div key={id} className={buildTableRowClass(id)}>
                  <div className="row-cell cell-checkbox">
                    <Checkbox value={id} onChange={handleCheckMember} checked={checkIsSelectedMember(id)} />
                  </div>

                  <div className="row-cell cell-user-data">
                    <p>{userName}</p>
                    <p>{email}</p>
                  </div>

                  <div className="row-cell cell-dates">
                    <p>{nftLastSentDate}</p>
                    <p>{claimingDate}</p>
                  </div>

                  <div className="row-cell cell-actions">
                    <Group colGap={10}>
                      <Tooltip tip={t("claimingUsersTable.tips.sendNft")} position="top">
                        <ActionIcon onClick={handleOpenSendNftModal(user)}>
                          <img src="/images/icon-send.svg" alt="send nft" />
                        </ActionIcon>
                      </Tooltip>

                      <Tooltip tip={t("claimingUsersTable.tips.showNft")} position="top">
                        <ActionIcon onClick={handleOpenShowNftDetailsModal(user)}>
                          <img src="/images/icon-show-details.svg" alt="show nft" />
                        </ActionIcon>
                      </Tooltip>
                    </Group>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {claimingUsers.meta && (
        <Pagination
          currentPage={claimingUsers.meta.page}
          totalCount={claimingUsers.meta.total}
          pageSize={claimingUsers.meta.perPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default ClaimingUsersTable;
