import i18n from "src/translations/i18n";
import { APP_STAGE } from "./config";

export const IS_DEMO_MODE = APP_STAGE === "dev";
export const IS_DEV_MODE = APP_STAGE === "dev";
export const IS_PROD_MODE = APP_STAGE === "prod";

export const APP_LANG = i18n.language;
export const FILE_LANG = i18n.language === "pl" ? "pl" : "en";

export enum STORAGE_KEYS {
  USER_LOGGED = "TkxZS1/EhkfFu8W7WVg==",
  CSRF_TOKEN = "X3Zsa8W6Cg==",
  CONNECTED = "connected"
}
