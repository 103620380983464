import React from "react";
import { IChildren } from "@src/types/IChildren.types";
import { useDropdownDataContext } from "../Dropdown";

const DropdownTarget = ({ children }: IChildren) => {
  const { handleToggleOpenMenu } = useDropdownDataContext();

  return (
    <div role="button" className="dropdown-target-button" onClick={handleToggleOpenMenu}>
      {children}
    </div>
  );
};

export default DropdownTarget;
