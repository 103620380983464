import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useWorkerTokens } from "@app/panel/Events/contexts/WorkerTokensData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import Button from "@components/dataEntry/Buttons/Button/Button";

import WorkerTokensTable from "../../../../components/WorkerTokensTable/WorkerTokensTable";
import "./GatesSettings.scss";

const GatesSettings = () => {
  const { eventStatus } = useSelectedEvent();
  const { workerTokens } = useWorkerTokens();
  const { openModal } = useEventsModals();

  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  const handleOpenModal = () => openModal({ type: "createWorkerToken" });

  const editButtonDisabled = eventStatus === "ended";

  return (
    <div className="event-gates-settings">
      <h3 className="event-gates-settings-title">{t("gatesSettings.title")}</h3>
      <div className="event-gates-settings-content">
        {workerTokens.length > 0 ? (
          <WorkerTokensTable workerTokens={workerTokens} />
        ) : (
          <p className="p0 mb-4">
            <Trans t={t} i18nKey="gatesSettings.emptyState" components={{ br: <br /> }} />
          </p>
        )}

        <Tooltip tip={commonT("eventEndedDisabled")} hide={!editButtonDisabled} width={200} textCenter>
          <Button variant="primary" onClick={handleOpenModal} disabled={editButtonDisabled}>
            {t("gatesSettings.button")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default GatesSettings;
