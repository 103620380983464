import React, { useState } from "react";

import { IContext } from "src/types/IContext.types";

interface ContextValue {
  selectedSubmissions: string[];
  addSubmission: (id: string) => void;
  removeSubmission: (id: string) => void;
  checkIsSelectedMember: (id: string) => boolean;
  clearSelectedSubmissions: () => void;
}

const SelectedSubmissionsContext = React.createContext(null as any);

export const SelectedSubmissionsProvider = ({ children }: IContext) => {
  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([]);

  const addSubmission = (id: string) => setSelectedSubmissions((prevState) => [...prevState, id]);

  const removeSubmission = (id: string) =>
    setSelectedSubmissions((prevState) => prevState.filter((submissionId) => submissionId !== id));

  const checkIsSelectedMember = (id: string) => {
    return selectedSubmissions.includes(id);
  };

  const clearSelectedSubmissions = () => setSelectedSubmissions([]);

  const contextValue: ContextValue = {
    selectedSubmissions,
    addSubmission,
    removeSubmission,
    checkIsSelectedMember,
    clearSelectedSubmissions
  };

  return <SelectedSubmissionsContext.Provider value={contextValue}>{children}</SelectedSubmissionsContext.Provider>;
};

export const useSelectedSubmissions = (): ContextValue => React.useContext(SelectedSubmissionsContext);
