import React from "react";
import { useEventPageModals } from "../contexts/EventPageModals.context";

import SignUpForEvent from "./SignUpForEvent/SignUpForEvent";

const EventPageModals = () => {
  const { activeModal } = useEventPageModals();

  if (activeModal === null) return <></>;

  const { type, data } = activeModal;

  if (type === "signUpForEvent") return <SignUpForEvent />;

  return <></>;
};

export default EventPageModals;
