import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { IChildren } from "@src/types/IChildren.types";
import { PATHS } from "@router/paths";
import { useWvs } from "@services/blockchain/Waves.context";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";

import Button from "@components/dataEntry/Buttons/Button/Button";
import MovingImage from "@components/common/MovingImage/MovingImage";
import NftFooter from "@components/layoutElements/NftFooter/NftFooter";

import "./PoapBaseLayout.scss";

const PoapBaseLayout = ({ children }: IChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");

  const { status: keeperStatus } = useWvs();
  const { openModal } = usePoapNftModals();

  const navigateToPoapNft = () => {
    if (location.pathname === PATHS.poapNft.path) return;
    navigate(PATHS.poapNft.path);
  };

  const handleOpenConnectModal = () => openModal({ type: "connectWallet" });

  const isConnected = React.useMemo(() => keeperStatus === "connected", [keeperStatus]);

  return (
    <div className="poap-base-layout">
      <div className="header-part">
        <button onClick={navigateToPoapNft}>
          <img src="/images/skey-tix-logo-poap.svg" alt="skey tix logo" className="skey-tix-logo-poap" />
        </button>

        {!isConnected && (
          <Button variant="secondary" onClick={handleOpenConnectModal} className="connect-wallet-btn">
            {t("connectWallet")}
          </Button>
        )}
      </div>

      <div className="main-part">
        <div className="white-ellipse-bg" />

        <MovingImage href="/images/nft-layout-gray-circle-desktop1.svg" force={2.5} className="desktop-md" />
        <MovingImage href="/images/nft-layout-gray-circle-desktop2.svg" force={2} className="desktop-md" />
        <MovingImage href="/images/nft-layout-gray-circle-desktop3.svg" force={2.5} className="desktop-md" />

        <img
          src="/images/nft-layout-blue-circle-desktop.svg"
          alt="layout circle"
          className="blue-circle desktop-md"
          width={968}
          height={968}
        />

        <img
          src="/images/nft-layout-blue-circle-mobile.svg"
          alt="layout circle"
          className="blue-circle mobile-md"
          width={576}
          height={576}
        />

        <div className="main-content">{children}</div>
      </div>

      <div className="footer-part">
        <NftFooter />
      </div>
    </div>
  );
};

export default PoapBaseLayout;
