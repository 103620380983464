import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { IS_DEMO_MODE } from "src/config/helpers";

import "./DemoMark.scss";

interface DemoMarkProps {
  children: React.ReactNode;
  className?: string;
}

const DemoMark = ({ children, className }: DemoMarkProps) => {
  const { t } = useTranslation("common");

  const classes = classNames("demo-mark", className);

  return (
    <div className={classes}>
      {children}

      {IS_DEMO_MODE && (
        <div className="demo-mark-flag">
          <p className="p4 bold">{t("demoVersion")}</p>
        </div>
      )}
    </div>
  );
};

export default DemoMark;
