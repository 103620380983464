import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./ApiKeysSkeleton.scss";

const ApiKeysSkeleton = () => {
  return (
    <div className="api-keys-skeleton">
      <ContentLoader width={815} height={348} viewBox="0 0 815 348">
        <rect x="0" y="0" rx="5" ry="5" width="150" height="22.5" />

        <rect x="0" y="72.5" rx="5" ry="5" width="815" height="30" />

        <rect x="0" y="122.5" rx="5" ry="5" width="755" height="30" />
        <rect x="775" y="122.5" rx="5" ry="5" width="40" height="30" />

        <rect x="0" y="172.5" rx="5" ry="5" width="755" height="30" />
        <rect x="775" y="172.5" rx="5" ry="5" width="40" height="30" />

        <rect x="0" y="222.5" rx="5" ry="5" width="755" height="30" />
        <rect x="775" y="222.5" rx="5" ry="5" width="40" height="30" />

        <rect x="0" y="272.5" rx="5" ry="5" width="755" height="30" />
        <rect x="775" y="272.5" rx="5" ry="5" width="40" height="30" />
      </ContentLoader>
    </div>
  );
};

export default ApiKeysSkeleton;
