import React from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import i18n from "src/translations/i18n";
import { IContext } from "src/types/IContext.types";
import { SKEY_TIX_API_URL } from "@config/config";
import { HttpError } from "@services/httpErrors.enum";
import { useNotification } from "@contexts/Notification.context";

import { ContextValue } from "./publicApi.types";
import { ClaimingOccasionalNftResponseDto, PublicEventDto, SubmissionDto } from "./api.dto";

const PublicApiClientContext = React.createContext(null as any);

export const PublicApiClientProvider = ({ children }: IContext) => {
  const { notify } = useNotification();
  const { t: responseT } = useTranslation("apiResponse");

  const apiClient = axios.create({
    baseURL: SKEY_TIX_API_URL,
    headers: {
      "Content-Type": "application/json",
      "X-API-LANG": i18n.language
    }
  });

  const getEventInfo = async (eventId: string): Promise<AxiosResponse<PublicEventDto>> => {
    try {
      const res = await apiClient.request({ method: "GET", url: `/events/${eventId}/public` });

      return res;
    } catch (e: any) {
      throw e;
    }
  };

  const createSubmission = async (
    eventId: string,
    recaptcha: string,
    firstName: string,
    lastName: string,
    email: string,
    note: string,
    acceptedAgreements: string[]
  ): Promise<AxiosResponse<SubmissionDto>> => {
    try {
      const res = await apiClient.request({
        method: "POST",
        url: `/events/${eventId}/submissions/captcha`,
        headers: { recaptcha },
        data: {
          firstName,
          lastName,
          email,
          note: note || null,
          acceptedAgreements
        }
      });

      if (res.status === 201) {
        notify("success", responseT("success.createSubmission"));
      }

      return res;
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      if (e?.response?.status === 403 && e?.response?.data.message === HttpError.EVENT_START_TIMESTAMP_REACHED) {
        notify("error", responseT("errors.createSubmissionEventStarted"));
      } else if (e?.response?.status === 403 && e?.response?.data.message === HttpError.EVENT_END_TIMESTAMP_REACHED) {
        notify("error", responseT("errors.createSubmissionEventEnded"));
      } else if (
        e?.response?.status === 403 &&
        e?.response?.data.message === HttpError.EVENT_SUBMISSION_ALREADY_EXISTS
      ) {
        notify("error", responseT("errors.createSubmissionAlreadyExists"));
      } else if (
        e?.response?.status === 400 &&
        e?.response?.data.message === HttpError.NOT_ENOUGH_TICKETS_FOR_SUBMISSIONS
      ) {
        notify("error", responseT("errors.createSubmissionNotEnoughTickets"));
      } else {
        notify("error", responseT("errors.createSubmission"));
      }

      throw e;
    }
  };

  const claimingOccasionalNft = async (jwt: string): Promise<AxiosResponse<ClaimingOccasionalNftResponseDto>> => {
    try {
      const res = await apiClient.request({
        method: "POST",
        url: `/occasionalNfts/validateTicket`,
        data: {
          jwt
        }
      });

      return res;
    } catch (e: any) {
      throw e;
    }
  };

  const contextValue: ContextValue = {
    getEventInfo,
    createSubmission,
    claimingOccasionalNft
  };

  return <PublicApiClientContext.Provider value={contextValue}>{children}</PublicApiClientContext.Provider>;
};

export const usePublicApiClient = (): ContextValue => React.useContext(PublicApiClientContext);
