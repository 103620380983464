import { TFunction } from "i18next";
import * as yup from "yup";

export const createEventSchema = (labelsT: TFunction, errorsT: TFunction, tzCodes: string[]) =>
  yup.object({
    eventName: yup
      .string()
      .max(69, errorsT("string.max", { count: 69 }))
      .required(errorsT("required")),
    startDate: yup.date().min(new Date(), errorsT("date.notInThePast")).required(errorsT("required")),
    endDate: yup
      .date()
      .required(errorsT("required"))
      .when("startDate", {
        is: (startDate: any) => {
          return startDate ? true : false;
        },
        then: yup.date().min(
          yup.ref("startDate"),
          errorsT("date.notBeforeDate", {
            date: labelsT("createEvent.inputs.startDate.label")
          })
        )
      })
      .test({
        name: "min1HourAfterStartDate",
        message: errorsT("date.min1HourAfterStartDate"),
        test: function (endDate, { parent }) {
          const { startDate } = parent;

          if (startDate && endDate) {
            const oneHourAfterStartDate = new Date(startDate);
            oneHourAfterStartDate.setHours(oneHourAfterStartDate.getHours() + 1);
            return endDate >= oneHourAfterStartDate;
          }

          return true;
        }
      }),
    timezone: yup.string().oneOf(tzCodes, errorsT("invalidValue")).required(errorsT("required")),
    eventUrl: yup.string().url(errorsT("string.url")),
    locationName: yup.string().max(69, errorsT("string.max", { count: 69 })),
    locationAddress: yup.string(),
    locationUrl: yup.string().url(errorsT("string.url")),
    organizatorEmail: yup.string().email(errorsT("string.email")).required(errorsT("required"))
  });
