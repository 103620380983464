import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./TicketsEmptyState.scss";
import { APP_STAGE } from "@config/config";

const TicketsEmptyState = () => {
  const { t } = useTranslation("eventsViews");

  const { openModal } = useEventsModals();
  const handleOpenModal = () => openModal({ type: "orderTickets" });

  const ticketsButtonText = useMemo(() => {
    if (APP_STAGE === "dev") return t("ticketsEmptyState.generateTicketsButton");
    return t("ticketsEmptyState.orderTicketsButton");
  }, [APP_STAGE]);

  return (
    <div className="tickets-empty-state">
      <Stack rowGap={40} justifyContent="center" alignItems="center" className="tickets-empty-state-content">
        <img src="/images/icon-order-tickets.svg" alt="generate event ticket" />
        <p className="p0 text-center">{t("ticketsEmptyState.description")}</p>
        <Button variant="primary" onClick={handleOpenModal}>
          {ticketsButtonText}
        </Button>
      </Stack>
    </div>
  );
};

export default TicketsEmptyState;
