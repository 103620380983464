import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useNotification } from "@contexts/Notification.context";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useTickets } from "@app/panel/Events/contexts/TicketsData.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Button from "@components/dataEntry/Buttons/Button/Button";
import DateTimePicker from "@components/dataEntry/DateTimePicker/DateTimePicker";
import SwitchInput from "@components/dataEntry/SwitchInput/SwitchInput";

import { editEventPageSettings } from "./editEventPageSettings.schema";
import { EditEventPageSettingsForm } from "./EditEventPageSettings.types";
import "./EditEventPageSettings.scss";

const EditEventPageSettings = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submissionEndRequired, setSubmissionEndRequired] = useState<boolean>(false);

  const { event } = useSelectedEvent();
  const { tickets } = useTickets();
  const { updateEvent } = useEvents();
  const { updateEventPage } = useApiClient();
  const { closeModal } = useEventsModals();
  const { notify } = useNotification();

  const { t } = useTranslation(["eventsModals", "apiResponse", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const validationSchema = editEventPageSettings(t, errorsT, event.end, submissionEndRequired);

  const initialValues = {
    enableSubmissions: event.page.enableSubmissions || false,
    uniqueSubmission: event.page.uniqueSubmission || false,
    automaticTicketSending: event.page.automaticTicketSending || false,
    submissionsButtonDisabled: event.page.submissionsButtonDisabled || false,
    submissionEnd: event.page.submissionEnd || event.start
  };

  const handleUpdateEventPage = async ({
    enableSubmissions,
    uniqueSubmission,
    automaticTicketSending,
    submissionsButtonDisabled,
    submissionEnd
  }: EditEventPageSettingsForm) => {
    if (!event) return;

    setSubmitting(true);

    updateEventPage(
      event.id,
      enableSubmissions,
      uniqueSubmission,
      automaticTicketSending,
      submissionsButtonDisabled,
      submissionEnd
    )
      .then((res) => {
        if (res.status === 200) {
          notify("success", t("apiResponse:success.updateEventPage"));
          updateEvent(event.id, res.data);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<EditEventPageSettingsForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleUpdateEventPage
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;
  const disabledAutomaticTicketSending = tickets.data.length <= 0;

  useEffect(() => {
    setSubmissionEndRequired(values.enableSubmissions);
  }, [values.enableSubmissions]);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-event-page-settings-modal">
            <ScrollShadowWrapper contentClassName="edit-event-page-settings-modal-content">
              <h2>{t("eventsModals:editEventPageSettings.title")}</h2>

              <p className="p0 mt-2 pb-2">{t("eventsModals:editEventPageSettings.description")}</p>

              {disabledAutomaticTicketSending && (
                <Group colGap={10} alignItems="flex-start" className="mt-2 mb-2">
                  <img src="/images/icon-notification-warning.svg" alt="edit agreements info" />
                  <p className="p0">{t("eventsModals:editEventPageSettings.disabledAutomaticTicketSending")}</p>
                </Group>
              )}

              <Stack rowGap={40} className="pt-2">
                <Group colGap={20} alignItems="flex-start" fullWidth equalWidth>
                  <SwitchInput
                    name="enableSubmissions"
                    label={t("eventsModals:editEventPageSettings.showSignUpButton")}
                    checked={values.enableSubmissions}
                    onChange={handleChange}
                  />
                  <SwitchInput
                    name="submissionsButtonDisabled"
                    label={t("eventsModals:editEventPageSettings.signUpButtonDisabled")}
                    checked={values.submissionsButtonDisabled}
                    onChange={handleChange}
                  />
                </Group>

                <Group colGap={20} alignItems="flex-start" fullWidth equalWidth>
                  <SwitchInput
                    name="uniqueSubmission"
                    label={t("eventsModals:editEventPageSettings.uniqueSubmission")}
                    checked={values.uniqueSubmission}
                    onChange={handleChange}
                  />
                  <SwitchInput
                    name="automaticTicketSending"
                    label={t("eventsModals:editEventPageSettings.automaticTicketSending")}
                    checked={values.automaticTicketSending}
                    onChange={handleChange}
                    disabled={disabledAutomaticTicketSending}
                    className="switch-input"
                  />
                </Group>
              </Stack>

              <Stack className="modal-inputs mt-6 mb-2">
                <DateTimePicker
                  name="submissionEnd"
                  label={t("eventsModals:editEventPageSettings.submissionEnd.label")}
                  placeholder={t("eventsModals:editEventPageSettings.submissionEnd.placeholder")}
                  value={dayjs(values.submissionEnd)}
                  onChange={(value) => {
                    setFieldValue("submissionEnd", value);
                  }}
                  onBlur={handleBlur}
                  error={errors.submissionEnd && touched.submissionEnd ? errors.submissionEnd : ""}
                  disabled={!values.enableSubmissions}
                />
              </Stack>
            </ScrollShadowWrapper>

            <Group colGap={20} className="mt-3">
              <Button type="submit" variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditEventPageSettings;
