import React from "react";
import { useNavigate } from "react-router-dom";

import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { PATHS } from "src/router/paths";

import EventViewSkeleton from "@app/panel/Events/skeletons/EventViewSkeleton/EventViewSkeleton";
import "./EventsList.scss";

const EventsList = () => {
  const { events, status: fetchEventsStatus } = useEvents();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (events.length > 0) navigate(PATHS.event.getPath(events[0]?.id));
  }, [events]);

  if (fetchEventsStatus === "loading") {
    return <EventViewSkeleton />;
  }

  return <></>;
};

export default EventsList;
