import { makeStyles } from "@material-ui/styles";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#5c44ec",
      main: "#5c44ec",
      dark: "#5c44ec"
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  }
});

export const useStyles = makeStyles({
  root: {
    "& .PrivatePickersYear-yearButton": {
      padding: "initial"
    },
    "& button": {
      transition: "background-color 0.2s ease-in"
    },
    "& .MuiPickersDay-today": {
      border: "1px solid #5c44ec"
    }
  },
  input: {
    "&": {
      marginLeft: "0px !important"
    },
    "& button": {
      marginRight: 0,
      transition: "background-color 0.2s ease-in"
    },
    "& button:active": {
      backgroundColor: "none"
    },
    "& svg path": {
      color: "#adb5bd"
    }
  }
});
