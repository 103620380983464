import { TFunction } from "i18next";
import { OccasionalNFTRule } from "@services/Api/api.dto";

export const eventNftRules: Record<OccasionalNFTRule, string> = {
  everyone: "eventNftRules.everyone",
  ticket_used: "eventNftRules.ticketUsed"
};

export const eventNftRulesOptions = (t: TFunction) => [
  {
    name: `${t("eventNftRules.everyone")}`,
    value: "everyone"
  },
  {
    name: `${t("eventNftRules.ticketUsed")}`,
    value: "ticket_used"
  }
];

export const getCurrentRule = (rule: OccasionalNFTRule, t: TFunction) => {
  const rules = eventNftRulesOptions(t);

  return rules.filter((item) => item.value === rule)[0];
};
