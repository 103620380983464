import { ClaimingUsersTableHeaderData } from "../ClaimingUsersTable.types";

export const tableHeaders: ClaimingUsersTableHeaderData[] = [
  // Column 1
  {
    rows: [
      {
        label: "",
        sortable: false
      }
    ],
    className: "cell-checkbox"
  },
  // Column 2
  {
    rows: [
      {
        label: "claimingUsersTable.headers.memberName",
        sortable: false
      },
      {
        label: "claimingUsersTable.headers.email",
        sortable: true,
        orderBy: "email"
      }
    ],
    className: "cell-user-data"
  },
  // Column 3
  {
    rows: [
      {
        label: "claimingUsersTable.headers.nftSentDate",
        sortable: false
      },
      {
        label: "claimingUsersTable.headers.claimingDate",
        sortable: false
      }
    ],
    className: "cell-dates"
  },
  // Column 4
  {
    rows: [
      {
        label: "claimingUsersTable.headers.actions",
        sortable: false
      }
    ],
    className: "cell-actions"
  }
];
