import { TFunction } from "i18next";
import * as yup from "yup";

export const createApiKeyValidationSchema = (errorsT: TFunction, isTemporaryKey: boolean) =>
  yup.object({
    name: yup
      .string()
      .max(20, errorsT("string.max", { count: 20 }))
      .required(errorsT("required")),
    accessPrivileges: yup.string().oneOf(["read", "write"], errorsT("invalidValue")).required(errorsT("required")),

    termOfValidity: yup.string().when([], {
      is: () => isTemporaryKey,
      then: yup.string().required(errorsT("required"))
    })
  });
