import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";

import CreateApiKey from "./CreateApiKey/CreateApiKey";
import RemoveApiKey from "./RemoveApiKey/RemoveApiKey";
import EditOrganizationData from "./EditOrganizationData/EditOrganizationData";

const SettingsModals = () => {
  const { activeModal } = useSettingsModals();

  if (activeModal === null) return <></>;
  const { type, data } = activeModal;

  if (type === "createApiKey") return <CreateApiKey />;
  if (type === "removeApiKey") return <RemoveApiKey apiKeyId={data.id} apiKeyName={data.label} />;
  if (type === "editOrganizationData") return <EditOrganizationData />;

  return <></>;
};

export default SettingsModals;
