import React from "react";
import { useTranslation } from "react-i18next";

import { useEventsModals } from "../../contexts/EventsModals.context";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./EventNftAccessDenied.scss";

const EventNftAccessDenied = () => {
  const { openModal } = useEventsModals();

  const { t } = useTranslation(["eventsViews", "common"]);

  const handleOpenModal = () => openModal({ type: "orderNftModule" });

  return (
    <Stack rowGap={30} fullWidth alignItems="center" className="event-nft-access-denied">
      <Stack rowGap={15} fullWidth alignItems="center">
        <img src="/images/icon-occasional-nft-access-denied.svg" alt="occasional nft module access denied" />
        <h3 className="text-center">{t("eventsViews:eventNft.accessDenied.title")}</h3>
        <p className="p0 premium">{t("eventsViews:eventNft.accessDenied.premium")}</p>
      </Stack>

      <p className="p0 text-center white-space-pre-wrap">{t("eventsViews:eventNft.accessDenied.description")}</p>

      <Button onClick={handleOpenModal}>{t("common:enable")}</Button>
    </Stack>
  );
};
export default EventNftAccessDenied;
