import { useTranslation } from "react-i18next";

import { SOCIALS } from "@constants/socials";
import { SKEY_NETWORK } from "@constants/constants";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Link from "@components/navigation/Link/Link";
import SocialIconLink from "@components/navigation/SocialIconLink/SocialIconLink";

import "./NftFooter.scss";

const NftFooter = () => {
  const { t } = useTranslation("layout");
  const year = new Date().getFullYear();

  return (
    <footer className="nft-footer">
      <div className="nft-footer-socials">
        <div className="socials-content">
          <Stack rowGap={8} className="socials-website">
            <p className="p0">{t("nftFooter.visitOurWebsite")}</p>

            <Link href={SKEY_NETWORK} withImage={false}>
              skey.network
            </Link>
          </Stack>

          <Stack rowGap={16} className="socials-links">
            <p className="p0">{t("nftFooter.followUs")}</p>

            <Group colGap={16} rowGap={16} wrap="wrap">
              {SOCIALS.map(({ title, icon, href }) => (
                <SocialIconLink key={title} href={href} title={title}>
                  {icon}
                </SocialIconLink>
              ))}
            </Group>
          </Stack>
        </div>
      </div>

      <Group fullWidth className="nft-footer-copy">
        <Group fullWidth alignItems="center" justifyContent="space-between" className="copy-content">
          <p className="p1">{t("nftFooter.copy", { year })}</p>

          <img src="/images/skey-network-logo-small.svg" alt="skey network logo" className="copy-skey-network-logo" />
        </Group>
      </Group>
    </footer>
  );
};

export default NftFooter;
