import React from "react";
import { isMobile } from "react-device-detect";

import { IChildren } from "@src/types/IChildren.types";

import MobileGuardModal from "./MobileGuardModal/MobileGuardModal";

const MobileDeviceGuard = ({ children }: IChildren) => {
  if (isMobile) {
    return <MobileGuardModal />;
  }
  return <>{children}</>;
};

export default MobileDeviceGuard;
