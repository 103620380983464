import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useApiKeys } from "@panel/Settings/contexts/ApiKeysContext";

import Group from "@components/arrangement/Group/Group";
import ApiKeysEmptyState from "../../components/ApiKeysEmptyState/ApiKeysEmptyState";
import ApiKeysTable from "../../components/ApiKeysTable/ApiKeysTable";
import ApiKeysSkeleton from "../../components/ApiKeysSkeleton/ApiKeysSkeleton";

import "./ApiSettings.scss";

const ApiSettings = () => {
  const { apiKeys, status: fetchStatus } = useApiKeys();
  const { t } = useTranslation("settingsPage");

  const hasApiKeys = useMemo(() => {
    return apiKeys.length > 0;
  }, [apiKeys]);

  return (
    <Group alignItems="flex-start" colGap={40} className="api-settings">
      <h2 className="title">{t("apiSettings.title")}</h2>
      <div className="api-settings-content">
        <hr />
        <p className="p0 mt-3">{t("apiSettings.description")}</p>
        {fetchStatus === "loading" ? <ApiKeysSkeleton /> : hasApiKeys ? <ApiKeysTable /> : <ApiKeysEmptyState />}
      </div>
    </Group>
  );
};

export default ApiSettings;
