import React, { HTMLAttributeAnchorTarget, ReactNode } from "react";
import classNames from "classnames";

import "./SocialIconLink.scss";

interface SocialIconLinkProps {
  href: string;
  target?: HTMLAttributeAnchorTarget;
  follow?: boolean;
  title?: string;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

const SocialIconLink = ({
  href,
  target = "_blanl",
  follow = false,
  title,
  disabled = false,
  className = "",
  children
}: SocialIconLinkProps) => {
  const rel = follow ? "dofollow" : `noopener noreferrer nofollow`;
  const classes = classNames("social-icon-link", { disabled: disabled }, className);

  return (
    <a href={href} target={target} rel={rel} title={title} className={classes}>
      {children}
    </a>
  );
};

export default SocialIconLink;
