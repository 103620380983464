import React from "react";
import { useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { parseBoolToString } from "@utils/parseBoolToString";
import { formatDateTime } from "@utils/formatDateTime";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./EventPageSettings.scss";

const EventPageSettings = () => {
  const { event, eventStatus } = useSelectedEvent();
  const { openModal } = useEventsModals();

  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  const handleOpenModal = () => openModal({ type: "editEventPageSettings" });

  const editButtonTip = eventStatus === "started" ? commonT("eventStartedDisabled") : commonT("eventEndedDisabled");
  const editButtonDisabled = eventStatus === "started" || eventStatus === "ended";
  const submissionEndDate = event.page.submissionEnd
    ? formatDateTime(event.page.submissionEnd, event.timezone)
    : formatDateTime(event.start, event.timezone);

  const parsedShowSignUpButtonInfo = parseBoolToString(event.page.enableSubmissions, commonT);
  const parsedSignUpButtonDisabledInfo = parseBoolToString(event.page.submissionsButtonDisabled, commonT);
  const parsedUniqueSubmissionInfo = parseBoolToString(event.page.uniqueSubmission, commonT);
  const parsedAutomaticTicketSendingInfo = parseBoolToString(event.page.automaticTicketSending, commonT);

  return (
    <div className="event-page-settings">
      <h3 className="event-page-settings-title">{t("eventPageSettings.title")}</h3>

      <div className="event-page-settings-content">
        <Group colGap={20} alignItems="flex-start" className="mb-4">
          <Stack rowGap={24} className="event-page-settings-content-col">
            <Stack rowGap={12}>
              <p className="p4 black-2">{t("eventPageSettings.showSignUpButton")}</p>
              <p className="p4 ">{parsedShowSignUpButtonInfo}</p>
            </Stack>

            <Stack rowGap={12}>
              <p className="p4 black-2">{t("eventPageSettings.signUpButtonDisabled")}</p>
              <p className="p4 ">{parsedSignUpButtonDisabledInfo}</p>
            </Stack>

            <Stack rowGap={12}>
              <p className="p4 black-2">{t("eventPageSettings.uniqueSubmission")}</p>
              <p className="p4 ">{parsedUniqueSubmissionInfo}</p>
            </Stack>

            <Stack rowGap={12}>
              <p className="p4 black-2">{t("eventPageSettings.automaticTicketSending")}</p>
              <p className="p4 ">{parsedAutomaticTicketSendingInfo}</p>
            </Stack>
          </Stack>

          <Stack rowGap={24} className="event-page-settings-content-col">
            <Stack rowGap={12}>
              <p className="p4 black-2">{t("eventPageSettings.submissionEndDate")}</p>
              <p className="p4">{submissionEndDate}</p>
            </Stack>
          </Stack>
        </Group>

        <Tooltip tip={editButtonTip} hide={!editButtonDisabled} width={200} textCenter>
          <Button variant="primary" disabled={editButtonDisabled} onClick={handleOpenModal}>
            {commonT("edit")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default EventPageSettings;
