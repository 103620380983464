import React, { useEffect } from "react";
import classNames from "classnames";

import useLockedBody from "@hooks/useLockedBody";
import useClickKey from "@hooks/useClickKey";

import "./Modal.scss";

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
}

const Modal = ({ children, className = "", onClose }: ModalProps) => {
  const { handleLocked, handleUnlocked } = useLockedBody();

  const handleClose = () => {
    onClose && onClose();
  };

  useClickKey("Escape", handleClose);

  useEffect(() => {
    handleLocked();

    return () => {
      handleUnlocked();
    };
  }, []);

  const classes = classNames("modal", className);

  return (
    // <ClickAwayListener mouseEvent="mouseup" onClickAway={handleClose}>
    <div className={classes}>{children}</div>
    // </ClickAwayListener>
  );
};

export default Modal;
