import React from "react";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import SortingIcon from "src/assets/SortingIcon";

import { ClaimingUsersOrderBy } from "@services/Api/api.dto";
import { ClaimingUsersTableHeaderProps } from "../../ClaimingUsersTable.types";
import { tableHeaders } from "../../data/tableHeaders";

const ClaimingUsersTableHeader = ({ sortSettings, handleSort }: ClaimingUsersTableHeaderProps) => {
  const { t } = useTranslation("eventsViews");

  const sortIconVariant = (orderBy?: ClaimingUsersOrderBy) => {
    if (!sortSettings || !orderBy) return;
    if (sortSettings.orderBy == orderBy && sortSettings.order === "asc") return "asc";
    if (sortSettings.orderBy == orderBy && sortSettings.order === "desc") return "desc";
    return "default";
  };

  return (
    <div className="table-header">
      {tableHeaders.map(({ rows, className }, index) => (
        <div key={`submissions-table-header-${index}`} className={`header-cell ${className || ""}`}>
          {rows.map(({ label, sortable, orderBy }, index) => {
            if (sortable) {
              const keyValue = `${label}-${index}`;

              return (
                <button key={keyValue} onClick={handleSort(orderBy as ClaimingUsersOrderBy)}>
                  <Group colGap={5}>
                    <p>{t(label as any)}</p>
                    <SortingIcon width={15} height={15} variant={sortIconVariant(orderBy)} />
                  </Group>
                </button>
              );
            }

            return <p key={`${label}-${index}`}>{t(label as any)}</p>;
          })}
        </div>
      ))}
    </div>
  );
};

export default ClaimingUsersTableHeader;
