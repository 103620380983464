import { EventDto } from "@services/Api/api.dto";

export const eventMock: EventDto = {
  id: "",
  email: "",
  name: "",
  ticketsGoal: 0,
  ticketsMinted: 0,
  ticketsBurned: 0,
  url: "",
  start: "",
  end: "",
  expired: false,
  offset: 0,
  timezone: "Europe/Warsaw",
  agreementsLocked: false,
  logoUrl: "",
  status: "draft",
  submissionsCount: 0,
  location: {
    name: "",
    address: "",
    url: ""
  },
  page: {
    content: "",
    imageUrl: "",
    enableSubmissions: false,
    uniqueSubmission: false,
    automaticTicketSending: false,
    submissionsButtonDisabled: false,
    submissionEnd: "",
    occasionalNFT: false
  },
  agreements: [],
  occasionalNFT: null,
  occasionalNFTSent: false
};
