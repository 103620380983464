import { useEventPageData } from "./contexts/EventPage.context";
import { Helmet } from "react-helmet-async";

import EventPageSkeleton from "./components/EventPageSkeleton/EventPageSkeleton";
import EventPageError from "./components/EventPageError/EventPageError";
import EventPageHeader from "./components/EventPageHeader/EventPageHeader";
import TextEditorViewer from "../../../components/dataDisplay/TextEditorViewer/TextEditorViewer";
import EventPageSidebar from "./components/EventPageSidebar/EventPageSidebar";

import "./EventPage.scss";

const EventPage = () => {
  const { event, status } = useEventPageData();

  if (status === "loading") return <EventPageSkeleton />;
  if (status === "failed") return <EventPageError />;

  return (
    <>
      <Helmet>
        <title>SkeyTix Event</title>
      </Helmet>

      <div className="event-page">
        <div className="event-page-content">
          <EventPageHeader />

          <div className="event-description">
            <TextEditorViewer content={event.page.content} />
          </div>
        </div>

        <div className="event-page-sidebar">
          <EventPageSidebar />
        </div>
      </div>
    </>
  );
};

export default EventPage;
