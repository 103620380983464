import { useState } from "react";
import { OrderType } from "@src/types/api/OrderType.types";

export interface SortSettings<T> {
  orderBy: T;
  order: OrderType;
}

const useSortableTable = <T,>() => {
  const [sortSettings, setSortSettings] = useState<SortSettings<T> | null>(null);

  const setSortingSettings = (orderBy: T) => {
    if (sortSettings && sortSettings.orderBy === orderBy) {
      if (sortSettings.order === "asc") {
        return setSortSettings({ orderBy, order: "desc" });
      } else return setSortSettings({ orderBy, order: "asc" });
    }

    return setSortSettings({ orderBy, order: "asc" });
  };

  const resetSorting = () => {
    setSortSettings(null);
  };

  return { sortSettings, setSortingSettings, resetSorting };
};

export default useSortableTable;
