import React from "react";
import { AxiosError } from "axios";

import { IContext } from "src/types/IContext.types";
import { FetchStatus } from "src/types/api/FetchStatus.types";

import { useUserData } from "@contexts/UserData.context";
import { useApiClient } from "@services/Api/ApiClientContext";
import { ApiKeyDto } from "@services/Api/api.dto";

export interface ApiKeysData {
  status: FetchStatus;
  apiKeys: ApiKeyDto[];
  error: Error | AxiosError | null;
}

interface ContextValue extends ApiKeysData {
  addApiKey: (key: ApiKeyDto) => void;
  removeApiKey: (keyId: string) => void;
  fetchApiKeysData: (organizationId: string) => Promise<void>;
}

const initialApiKeysData = (): ApiKeysData => ({
  status: "loading",
  apiKeys: [],
  error: null
});

const ApiKeysContext = React.createContext(null as any);

export const ApiKeysProvider = ({ children }: IContext) => {
  const [apiKeys, setApiKeys] = React.useState(initialApiKeysData());
  const { getOrganizationApiKeys } = useApiClient();
  const { organization, status } = useUserData();

  React.useEffect(() => {
    if (status === "success") fetchApiKeysData(organization?.id as string);
  }, [status]);

  const fetchApiKeysData = async (organizationId: string) => {
    setApiKeys(initialApiKeysData());

    try {
      const { data } = await getOrganizationApiKeys(organizationId);
      return setApiKeys((d) => ({ ...d, apiKeys: data, status: "success" }));
    } catch (e: any) {
      setApiKeys((d) => ({ ...d, status: "failed", error: e }));
    }
  };

  const addApiKey = React.useCallback((key: ApiKeyDto) => {
    setApiKeys((prevState) => ({ ...prevState, apiKeys: [...prevState.apiKeys, key] }));
  }, []);

  const removeApiKey = React.useCallback((keyId: string) => {
    setApiKeys((prevState) => ({ ...prevState, apiKeys: prevState.apiKeys.filter((key) => key.id !== keyId) }));
  }, []);

  const contextValue: ContextValue = {
    ...apiKeys,
    addApiKey,
    removeApiKey,
    fetchApiKeysData
  };

  return <ApiKeysContext.Provider value={contextValue}>{children}</ApiKeysContext.Provider>;
};

export const useApiKeys = (): ContextValue => React.useContext(ApiKeysContext);
