import React from "react";

import { AssetData } from "@services/blockchain/blockchain.types";
import NftCard from "../NftCard/NftCard";

import "./NftCollection.scss";

interface NftCollectionProps {
  title?: string | number;
  collection: AssetData[];
}

const NftCollection = ({ title, collection }: NftCollectionProps) => {
  return (
    <div className="nft-collection">
      <p className="nft-collection-title">{title}</p>

      <div className="nft-collection-list">
        {collection.map((nft) => {
          return <NftCard key={nft.assetId} nft={nft} />;
        })}
      </div>
    </div>
  );
};

export default NftCollection;
