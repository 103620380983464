import React from "react";
import ReactDOM from "react-dom/client";

import { welcome } from "@utils/welcome";
import App from "./app/App";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

welcome();

ReactRoot.render(<App />);
