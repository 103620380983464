import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import i18n from "src/translations/i18n";

import { LoginDto } from "./api.dto";

import { STORAGE_KEYS } from "@config/helpers";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";
import { GLOBAL_ABORT_TIMEOUT } from "@config/config";

export interface ApiClientOptions {
  baseUrl: string;
}

const CSRF_TOKEN_HEADER = "X-CSRF-TOKEN";
export class ApiClient {
  private axios: AxiosInstance;

  constructor(private options: ApiClientOptions) {
    this.axios = this.createAxiosInstance();
  }

  private createAxiosInstance() {
    const instance = axios.create({
      baseURL: this.options.baseUrl,
      headers: {
        "Content-Type": "application/json",
        "X-API-LANG": i18n.language
      },
      withCredentials: true
    });

    return instance;
  }

  private removeUserSession() {
    BrowserStorageManager.removeSessionStorageItems([STORAGE_KEYS.USER_LOGGED, STORAGE_KEYS.CSRF_TOKEN]);
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), GLOBAL_ABORT_TIMEOUT);

    const csrf = BrowserStorageManager.readSessionStorage<string>(STORAGE_KEYS.CSRF_TOKEN);

    try {
      const response = await this.axios.request({
        ...config,
        signal: controller.signal,
        headers: { ...config.headers, [CSRF_TOKEN_HEADER]: csrf } as any
      });

      clearTimeout(timeoutId);
      return response;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        this.removeUserSession();
      }

      throw e;
    }
  }

  async login(email: string, password: string): Promise<AxiosResponse<LoginDto>> {
    try {
      const res = await this.axios.post("/auth/login", {
        email,
        password
      });

      if (res.status === 201) {
        BrowserStorageManager.writeSessionStorage(STORAGE_KEYS.USER_LOGGED, "true");
        BrowserStorageManager.writeSessionStorage(STORAGE_KEYS.CSRF_TOKEN, res.data.csrf);
      }

      return res;
    } catch (e) {
      throw e;
    }
  }

  async logout(): Promise<AxiosResponse> {
    try {
      const res = await this.request({ method: "DELETE", url: "/auth/logout" });

      if (res.status === 204) {
        this.removeUserSession();
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        this.removeUserSession();
      }

      throw e;
    }
  }
}
