import { modalsContextFactory } from "@contexts/ModalsContextFactory";

interface WithoutObjectModal {
  type: "signUpForEvent";
  data?: null;
}

type EventPageModal = WithoutObjectModal;

export const { ModalsProvider: EventPageModalsProvider, useModalsContext: useEventPageModals } =
  modalsContextFactory<EventPageModal>();
