import { TFunction } from "i18next";
import { verifyAddress } from "@waves/ts-lib-crypto";

export const BC_ADDRESS_LENGTH = 35;
export const BC_ADDRESS_REGEX = /^[1-9A-HJ-NP-Za-km-z]{35}$/;

export const validateWalletAddress = (errorsT: TFunction, value: string, chainId?: string): string | null => {
  if (value.length < BC_ADDRESS_LENGTH) {
    return errorsT("walletAddress.toShort", { length: BC_ADDRESS_LENGTH });
  }

  if (value.length > BC_ADDRESS_LENGTH) {
    return errorsT("walletAddress.toLong", { length: BC_ADDRESS_LENGTH });
  }

  if (!BC_ADDRESS_REGEX.test(value)) {
    return errorsT("walletAddress.invalidCharacters");
  }

  if (chainId && !verifyAddress(value, { chainId })) {
    return errorsT("walletAddress.invalidChainId");
  }

  if (!verifyAddress(value)) {
    return errorsT("walletAddress.base");
  }

  return null;
};
