import { usePoapNftModals } from "../contexts/EventPageModals.context";

import ConnectWallet from "./ConnectWallet/ConnectWallet";
import PoapNftPreview from "./PoapNftPreview/PoapNftPreview";
import TransferNft from "./TransferNft/TransferNft";
import BurnNft from "./BurnNft/BurnNft";
import ShareNft from "./ShareNft/ShareNft";

const PoapNftModals = () => {
  const { activeModal } = usePoapNftModals();

  if (activeModal === null) return <></>;

  const { type, data } = activeModal;

  if (type === "connectWallet") return <ConnectWallet />;
  if (type === "nftPreview") return <PoapNftPreview nft={data} />;
  if (type === "transferNft") return <TransferNft nft={data} />;
  if (type === "burnNft") return <BurnNft nft={data} />;
  if (type === "shareNft") return <ShareNft nft={data} />;

  return <></>;
};

export default PoapNftModals;
