import React, { memo, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ThemeProvider } from "@mui/material/styles";

import { formatUtcDateTime } from "@utils/formatDateTime";
import i18n from "src/translations/i18n";
import useVisible from "@hooks/useVisible";
import Input from "../Input/Input";

import { DateTimePickerProps } from "./DateTimePicker.types";
import { theme, useStyles } from "./DateTimePicker.styles";

import "dayjs/locale/en";
import "dayjs/locale/pl";

const DateTimePicker = ({
  id,
  name,
  value,
  label,
  placeholder,
  description,
  error,
  disabled = false,
  required = false,
  ampm = false,
  hideTabs = false,
  inputFormat = "DD/MM/YYYY HH:mm",
  onClick,
  onChange,
  onBlur
}: DateTimePickerProps) => {
  const [selectedDateTime, setSelectedDateTime] = React.useState<Dayjs | string | null>(value || null);
  const { isVisible, setVisibilityTrue, setVisibilityFalse } = useVisible(false);
  const { t } = useTranslation("common");
  const classes = useStyles();

  const localeTextTranslation = {
    previousMonth: t("previousMonth"),
    nextMonth: t("nextMonth"),
    openPreviousView: t("openPreviousView"),
    openNextView: t("openNextView")
  };

  const handleClick = () => {
    setVisibilityTrue();
    onClick && onClick();
  };

  const handleBlur = (e: any) => {
    if (!isVisible && onBlur) onBlur(e);
  };

  const handleSelect = (date: Dayjs | string | null) => {
    if (date === null) return;
    const formatted = formatUtcDateTime(date);

    setSelectedDateTime(formatted);
    onChange && onChange(formatted);
  };

  useMemo(() => {
    if (value) setSelectedDateTime(value);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language} localeText={localeTextTranslation}>
      <ThemeProvider theme={theme}>
        <MuiDateTimePicker
          open={isVisible}
          onOpen={setVisibilityTrue}
          onClose={setVisibilityFalse}
          value={selectedDateTime}
          onChange={(newValue) => {
            handleSelect(newValue);
          }}
          ampm={ampm}
          hideTabs={hideTabs}
          inputFormat={inputFormat}
          disabled={disabled}
          PopperProps={{ className: classes.root, disablePortal: false }}
          InputAdornmentProps={{ className: classes.input }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Input
              id={id}
              inputRef={inputRef}
              name={name}
              label={label}
              required={required}
              description={description}
              error={error}
              inputAdornment={InputProps?.endAdornment}
              onClick={handleClick}
              onBlur={handleBlur}
              {...{ ...inputProps, placeholder: placeholder, readOnly: true, type: "text" }}
            />
          )}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default memo(DateTimePicker);
