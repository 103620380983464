import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IContext } from "src/types/IContext.types";
import { FetchStatus } from "src/types/api/FetchStatus.types";
import { WorkerTokenDto } from "@services/Api/api.dto";

import { useApiClient } from "@services/Api/ApiClientContext";

export interface WorkerTokensData {
  status: FetchStatus;
  workerTokens: WorkerTokenDto[];
  error: Error | AxiosError | null;
}

interface ContextValue extends WorkerTokensData {
  fetchWorkerTokensData: (eventId: string) => Promise<void>;
  addWorkerToken: (workerToken: WorkerTokenDto) => void;
  updateWorkerToken: (workerTokenId: string, updatedWorkerToken: WorkerTokenDto) => void;
  removeWorkerToken: (workerTokenId: string) => void;
}

const initialWorkerTokensData = (): WorkerTokensData => ({
  status: "loading",
  workerTokens: [],
  error: null
});

const WorkerTokensDataContext = React.createContext(null as any);

export const WorkerTokensDataProvider = ({ children }: IContext) => {
  const [workerTokens, setWorkerTokens] = useState(initialWorkerTokensData());
  const { eventId } = useParams();
  const { getWorkerTokens } = useApiClient();

  useEffect(() => {
    if (!eventId) return;

    fetchWorkerTokensData(eventId);
  }, [eventId]);

  const fetchWorkerTokensData = async (eventId: string) => {
    setWorkerTokens(initialWorkerTokensData());

    try {
      const { data } = await getWorkerTokens(eventId);
      return setWorkerTokens((d) => ({ ...d, workerTokens: data, status: "success" }));
    } catch (e: any) {
      setWorkerTokens((d) => ({ ...d, status: "failed", error: e }));
    }
  };

  const addWorkerToken = React.useCallback((workerToken: WorkerTokenDto) => {
    setWorkerTokens((prevState) => ({ ...prevState, workerTokens: [...prevState.workerTokens, workerToken] }));
  }, []);

  const updateWorkerToken = React.useCallback((workerTokenId: string, updatedWorkerToken: WorkerTokenDto) => {
    setWorkerTokens((prevState) => ({
      ...prevState,
      workerTokens: prevState.workerTokens.map((workerToken) => {
        if (workerToken.id === workerTokenId) {
          return updatedWorkerToken;
        }

        return workerToken;
      })
    }));
  }, []);

  const removeWorkerToken = React.useCallback((workerTokenId: string) => {
    setWorkerTokens((prevState) => ({
      ...prevState,
      workerTokens: prevState.workerTokens.filter((workerToken) => workerToken.id !== workerTokenId)
    }));
  }, []);

  const contextValue: ContextValue = {
    ...workerTokens,
    addWorkerToken,
    updateWorkerToken,
    removeWorkerToken,
    fetchWorkerTokensData
  };

  return <WorkerTokensDataContext.Provider value={contextValue}>{children}</WorkerTokensDataContext.Provider>;
};

export const useWorkerTokens = (): ContextValue => React.useContext(WorkerTokensDataContext);
