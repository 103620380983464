import { Outlet, RouteObject } from "react-router-dom";

import { PATHS } from "../paths";
import CombinedElements from "@router/common/CombinedElements";

import { PublicApiClientProvider } from "@services/Api/PublicApiClientContext";
import { EventPageProvider } from "@app/public/EventPage/contexts/EventPage.context";
import { EventPageModalsProvider } from "@app/public/EventPage/contexts/EventPageModals.context";

import EventPageLayout from "@components/layouts/EventPageLayout/EventPageLayout";
import ClaimNftLayout from "@components/layouts/ClaimNftLayout/ClaimNftLayout";

import EventPage from "@app/public/EventPage/EventPage";
import ClaimPage from "@app/public/ClaimPage/ClaimPage";
import EventPageModals from "@app/public/EventPage/modals/EventPageModals";

export const publicRoutes: RouteObject = {
  element: (
    <PublicApiClientProvider>
      <Outlet />
    </PublicApiClientProvider>
  ),
  children: [
    {
      path: PATHS.eventPage.path,
      element: (
        <EventPageLayout>
          <CombinedElements elements={[EventPageProvider, EventPageModalsProvider]}>
            <EventPage />
            <EventPageModals />
          </CombinedElements>
        </EventPageLayout>
      )
    },
    {
      path: PATHS.claimPage.path,
      element: (
        <ClaimNftLayout>
          <ClaimPage />
        </ClaimNftLayout>
      )
    }
  ]
};
