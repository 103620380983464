export type AppStage = "dev" | "prod" | null;

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const APP_VERSION = require("../../package.json").version;

const APP_STAGE_ENV = (process.env.REACT_APP_STAGE as AppStage) || null;

const SKEY_TIX_API_URL_ENV = process.env.REACT_APP_SKEY_TIX_API_URL || null;
const SKEY_TIX_API_SOCKET_URL_ENV = process.env.REACT_APP_SKEY_TIX_API_SOCKET_URL || null;
const RECAPTCHA_KEY_ENV = process.env.REACT_APP_RECAPTCHA_KEY || null;
const WVS_NETWORK_SERVER_ENV = process.env.REACT_APP_WVS_NETWORK_SERVER || null;
const PINATA_GATEWAY_MAINNET_URL_ENV = process.env.REACT_APP_PINATA_GATEWAY_MAINNET_URL || null;
const PINATA_GATEWAY_TESTNET_URL_ENV = process.env.REACT_APP_PINATA_GATEWAY_TESTNET_URL || null;
const SHARE_NFT_SERVICE_URL_ENV = process.env.REACT_APP_SHARE_NFT_SERVICE_URL || null;

const getAppStage = () => {
  if (APP_STAGE_ENV) return APP_STAGE_ENV;
  throw new Error("There is no provided env variable: 'APP_STAGE_ENV'. ");
};

const getSkeyTixApiUrl = () => {
  if (SKEY_TIX_API_URL_ENV) return SKEY_TIX_API_URL_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_SKEY_TIX_API_URL'. ");
};

const getSkeyTixApiSocketUrl = () => {
  if (SKEY_TIX_API_SOCKET_URL_ENV) return SKEY_TIX_API_SOCKET_URL_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_SKEY_TIX_API_SOCKET_URL'. ");
};

const getReCaptchaKey = () => {
  if (RECAPTCHA_KEY_ENV) return RECAPTCHA_KEY_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_RECAPTCHA_KEY'.");
};

const getWavesNetworkServer = () => {
  if (WVS_NETWORK_SERVER_ENV) return WVS_NETWORK_SERVER_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_WVS_NETWORK_SERVER'.");
};

const getPinataGatewayMainnetUrl = () => {
  if (PINATA_GATEWAY_MAINNET_URL_ENV) return PINATA_GATEWAY_MAINNET_URL_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_PINATA_GATEWAY_MAINNET_URL'.");
};

const getPinataGatewayTestnetUrl = () => {
  if (PINATA_GATEWAY_TESTNET_URL_ENV) return PINATA_GATEWAY_TESTNET_URL_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_PINATA_GATEWAY_TESTNET_URL'.");
};

const getShareNftServiceUrl = () => {
  if (SHARE_NFT_SERVICE_URL_ENV) return SHARE_NFT_SERVICE_URL_ENV;
  throw new Error("There is no provided env variable: 'REACT_APP_SHARE_NFT_SERVICE_URL'.");
};

export const APP_STAGE = getAppStage();
export const SKEY_TIX_API_URL = getSkeyTixApiUrl();
export const SKEY_TIX_API_SOCKET_URL = getSkeyTixApiSocketUrl();
export const RECAPTCHA_KEY = getReCaptchaKey();
export const WVS_NETWORK_SERVER = getWavesNetworkServer();
export const PINATA_GATEWAY_MAINNET_URL = getPinataGatewayMainnetUrl();
export const PINATA_GATEWAY_TESTNET_URL = getPinataGatewayTestnetUrl();
export const SHARE_NFT_SERVICE_URL = getShareNftServiceUrl();

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === "true";
export const NOTIFICATION_CLEAR_TIME = Number(process.env.REACT_APP_NOTIFICATION_CLEAR_TIME) || 0;
export const MAX_SIZE_IMAGE_FILE = Number(process.env.REACT_APP_MAX_SIZE_IMAGE_FILE) || 5242880;
export const MAX_AGREEMENTS_COUNT = Number(process.env.REACT_APP_MAX_AGREEMENTS_COUNT) || 10;
export const WVS_CHAIN_ID = process.env.REACT_APP_WVS_CHAIN_ID || "R";
export const GLOBAL_ABORT_TIMEOUT = 1000 * 15; //[ms]

export const ACCEPT_IMAGE_EXTENSIONS = "image/png,image/jpeg";
export const TICKET_PREVIEW_QR_URL = "https://skey.network/";
export const FORMAT_DATE_TIME = "DD/MM/YYYY HH:mm";
export const FORMAT_DATE = "DD/MM/YYYY";
export const FORMAT_TIME = "HH:mm";
export const FILE_NAME_SEPARATOR = "_";
export const DEFAULT_IMAGE_TYPE = "image/jpeg";

// Environment variables only dev stage
const TESTNET_PROXY_URL_ENV = process.env.REACT_APP_TESTNET_PROXY_URL || null;

const getTestnetProxyUrl = () => {
  if (TESTNET_PROXY_URL_ENV) return TESTNET_PROXY_URL_ENV;
  else if (!TESTNET_PROXY_URL_ENV && APP_STAGE === "dev") {
    throw new Error("There is no provided env variable: 'REACT_APP_TESTNET_PROXY_URL'. ");
  }
};

export const TESTNET_PROXY_URL = getTestnetProxyUrl();
export const TESTNET_MAX_TICKET_GOAL_ENV = Number(process.env.REACT_APP_TESTNET_MAX_TICKET_GOAL) || 10;
