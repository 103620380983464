import React from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import { MenuUtilsBarProps } from "../../TextEditor.types";
import { menuUtilsTextStyle, menuUtilsHeadings, menuUtilsElements, menuUtilsTextAligns } from "../../data/menuUtlis";
import AddLink from "../customUtils/AddLink";
import AddImage from "../customUtils/AddImage";
import AddVideo from "../customUtils/AddVideo";

import "./MenuUtilsBar.scss";

const MenuUtilsBar = ({ editor }: MenuUtilsBarProps) => {
  const { t: commonT } = useTranslation("common");

  if (!editor) {
    return null;
  }

  return (
    <div className="text-editor-menu-utils-bar">
      <div className="menu-utils-group">
        {menuUtilsTextStyle(editor).map(({ titleKey, onClick, disabled, className, icon }) => (
          <button
            key={nanoid()}
            title={commonT(titleKey as any)}
            onClick={onClick}
            disabled={disabled}
            className={className}
          >
            {icon}
          </button>
        ))}
      </div>

      <div className="menu-utils-group">
        {menuUtilsHeadings(editor).map(({ titleKey, headingLevel, onClick, disabled, className, icon }) => (
          <button
            key={nanoid()}
            title={commonT(titleKey as any, { level: headingLevel })}
            onClick={onClick}
            disabled={disabled}
            className={className}
          >
            {icon}
          </button>
        ))}
      </div>

      <div className="menu-utils-group">
        {menuUtilsElements(editor).map(({ titleKey, onClick, disabled, className, icon }) => (
          <button
            key={nanoid()}
            title={commonT(titleKey as any)}
            onClick={onClick}
            disabled={disabled}
            className={className}
          >
            {icon}
          </button>
        ))}
      </div>

      <div className="menu-utils-group">
        {menuUtilsTextAligns(editor).map(({ titleKey, onClick, disabled, className, icon }) => (
          <button
            key={nanoid()}
            title={commonT(titleKey as any)}
            onClick={onClick}
            disabled={disabled}
            className={className}
          >
            {icon}
          </button>
        ))}
      </div>

      <div className="menu-utils-group">
        <AddLink editor={editor} />
        <AddImage editor={editor} />
        <AddVideo editor={editor} />
      </div>
    </div>
  );
};

export default MenuUtilsBar;
