import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useApiClient } from "@services/Api/ApiClientContext";
import { PATHS } from "src/router/paths";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import PasswordInput from "@components/dataEntry/PasswordInput/PasswordInput";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { newPasswordValidationSchema } from "./NewPasswordForm.schema";
import "./NewPassword.scss";

export const TOKEN_PARAM = "token";

interface NewPasswordForm {
  newPassword: string;
}

const NewPassword = () => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setNewPassword } = useApiClient();

  const { t } = useTranslation("authViews");
  const { t: errorsT } = useTranslation("formErrors");

  const params = new URLSearchParams(location.search);
  const token = params.get(TOKEN_PARAM) as string;

  const validationSchema = newPasswordValidationSchema(errorsT);

  const onFormSubmit = (newPassword: string) => {
    setSubmitting(true);
    setNewPassword(token, newPassword).finally(() => setSubmitting(false));
  };

  const { values, touched, errors, setFieldTouched, handleChange, handleBlur, handleSubmit } =
    useFormik<NewPasswordForm>({
      initialValues: { newPassword: "" },
      validationSchema,
      onSubmit: (values) => onFormSubmit(values.newPassword)
    });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name, false);
    handleChange(e);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className="new-password">
      <h2 className="mb-3">{t("newPassword.title")}</h2>
      <form onSubmit={onSubmit}>
        <Stack rowGap={20}>
          <PasswordInput
            name="newPassword"
            label={t("newPassword.labels.newPassword")}
            placeholder={t("newPassword.placeholders.newPassword")}
            className="new-password-input mb-4"
            value={values.newPassword}
            onChange={onChange}
            onBlur={handleBlur}
            error={touched.newPassword ? errors.newPassword : ""}
          />
          <Group colGap={20}>
            <Button type="submit" variant="primary" size="big" loading={submitting}>
              {t("newPassword.submit")}
            </Button>
            <Button variant="secondary" size="big" onClick={() => navigate(PATHS.main.path)}>
              {t("newPassword.cancel")}
            </Button>
          </Group>
        </Stack>
      </form>
    </div>
  );
};

export default NewPassword;
