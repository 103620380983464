import React, { memo } from "react";
import classNames from "classnames";

import { calculateBytesLength } from "@utils/calculateBytesLength";

import { InputProps } from "./Input.types";
import "./Input.scss";

const Input = ({
  id,
  inputRef,
  name,
  value,
  label = "",
  placeholder = "",
  description = "",
  error = "",
  disabled = false,
  required = false,
  readOnly = false,
  inputAdornment,
  type = "text",
  autoComplete = "off",
  minValue,
  maxValue,
  step,
  maxLength,
  maxBytes,
  onClick,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  className = ""
}: InputProps) => {
  const inputId = React.useId();

  const baseInputRender = () => (
    <input
      id={id}
      ref={inputRef}
      name={name || inputId}
      value={value}
      type={type}
      autoComplete={autoComplete}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      min={minValue}
      max={maxValue}
      step={step}
      onClick={onClick}
      onChange={onChange}
      onBlur={onBlur}
      className={error && "input-error"}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    />
  );

  const inputRender = () => {
    if (inputAdornment) {
      return (
        <div className="input-wrapper">
          {baseInputRender()}
          {!disabled && <div className="input-adornment">{inputAdornment}</div>}
        </div>
      );
    }

    return <>{baseInputRender()}</>;
  };

  const maxInputSize = maxBytes || maxLength;
  const inputValueLength = value?.toString().length || 0;
  const inputValueBytes = calculateBytesLength(value?.toString() || "");
  const currentInputSize = inputValueBytes || inputValueLength;

  const classes = classNames("input", className);
  const inputCharCounterClasses = classNames("input-char-counter", {
    "input-char-counter--error": maxInputSize && currentInputSize > maxInputSize
  });

  return (
    <div className={classes}>
      <div className="input-header">
        {label && (
          <label htmlFor={id || name || inputId} className="input-label">
            {label}
            {required && <span className="label-asterisk">*</span>}
          </label>
        )}

        {maxInputSize && (
          <span className={inputCharCounterClasses}>
            {currentInputSize}/{maxInputSize}
          </span>
        )}
      </div>

      {inputRender()}
      {description && <p className="input-description">{description}</p>}
      {error && <p className="input-error-text">{error}</p>}
    </div>
  );
};

export default memo(Input);
