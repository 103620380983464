import React from "react";

import Faq from "./sections/Faq/Faq";
import NftFaq from "./sections/NftFaq/NftFaq";
import Helpdesk from "./sections/Helpdesk/Helpdesk";

import "./Help.scss";

const Help = () => {
  return (
    <div className="help-page">
      <Faq />
      <NftFaq />
      <Helpdesk />
    </div>
  );
};

export default Help;
