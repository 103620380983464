import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./SubmissionsTableLoader.scss";

const SubmissionsTableLoader = () => {
  return (
    <div className="submissions-table-loader">
      <ContentLoader width={720} height={400} viewBox="0 0 720 400">
        {/* 1 line */}
        <rect x="0" y="5" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="5" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="5" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="5" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="5" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="5" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="5" rx="5" ry="5" width="30" height="30" />
        {/* 2 line */}
        <rect x="40" y="45" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="45" rx="5" ry="5" width="130" height="30" />

        <rect x="0" y="95" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="95" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="95" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="95" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="95" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="95" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="95" rx="5" ry="5" width="30" height="30" />

        <rect x="40" y="135" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="135" rx="5" ry="5" width="130" height="30" />

        <rect x="0" y="185" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="185" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="185" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="185" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="185" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="185" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="185" rx="5" ry="5" width="30" height="30" />

        <rect x="40" y="225" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="225" rx="5" ry="5" width="130" height="30" />

        <rect x="0" y="275" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="275" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="275" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="275" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="275" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="275" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="275" rx="5" ry="5" width="30" height="30" />

        <rect x="40" y="315" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="315" rx="5" ry="5" width="130" height="30" />
      </ContentLoader>
    </div>
  );
};

export default SubmissionsTableLoader;
