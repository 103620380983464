import React from "react";
import { useTranslation } from "react-i18next";

import { useUserData } from "@contexts/UserData.context";
import { useSettingsModals } from "@panel/Settings/contexts/SettingsModalsContext";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./OrganizationData.scss";

const OrganizationData = () => {
  const { organization } = useUserData();
  const { openModal } = useSettingsModals();

  const { t } = useTranslation(["settingsPage", "common"]);

  const handleEditOrganization = () => openModal({ type: "editOrganizationData" });

  const imageSource = organization?.imageUrl ? organization?.imageUrl : "/images/upload-image-placeholder.svg";

  return (
    <div className="organization-data">
      <Stack rowGap={24} className="organization-data-base-info">
        <p className="p0 bold">{t("settingsPage:organizationData.title")}</p>

        <Stack rowGap={4}>
          <p className="p4 black-2">{t("settingsPage:organizationData.name")}</p>
          <p className="p4">{organization?.name || "-"}</p>
        </Stack>

        <Stack rowGap={4}>
          <p className="p4 black-2">{t("settingsPage:organizationData.defaultEmail")}</p>
          <p className="p4">{organization?.email || "-"}</p>
        </Stack>

        <Button variant="primary" className="mt-2" onClick={handleEditOrganization}>
          {t("common:edit")}
        </Button>
      </Stack>

      <Stack className="organization-data-logo">
        <p className="p4 bold mb-2">{t("settingsPage:organizationData.logoTitle")}</p>
        <img src={imageSource} alt="organization logo" className="logo-preview" />
      </Stack>
    </div>
  );
};

export default OrganizationData;
