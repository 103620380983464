import React from "react";
import { useTranslation } from "react-i18next";

import { SKEY_KEEPER_CHROME } from "@constants/constants";
import { deviceInfo } from "@utils/browser/deviceInfo";

import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";
import { useWvs } from "@services/blockchain/Waves.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./ConnectWallet.scss";

const ConnectWallet = () => {
  const { closeModal } = usePoapNftModals();
  const { status: keeperStatus, connect } = useWvs();

  const isDesktop = deviceInfo.platform === "desktop";
  const isChromium = deviceInfo.browserInfo.name === "Chrome";
  const isKeeperUnavailable = keeperStatus === "not_available";

  const { t } = useTranslation(["poapNft", "common"]);

  const installSkeyKeeper = () => window.open(SKEY_KEEPER_CHROME);

  const handleConnect = () => {
    connect().then(closeModal);
  };

  const errorMsg = () => {
    switch (true) {
      case !isDesktop:
        return t("poapNft:keeperErrors.onlyDesktop");
      case !isChromium:
        return t("poapNft:keeperErrors.onlyChrome");
      case isKeeperUnavailable:
        return t("poapNft:keeperErrors.notInstalled");
      default:
        return "";
    }
  };

  const isConnecting = keeperStatus === "loading";
  const showErrorMsg = !isDesktop || !isChromium || isKeeperUnavailable;
  const connectBtnDisabled = !isDesktop || !isChromium || isKeeperUnavailable;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack rowGap={18} className="connect-wallet-modal">
            <h2>{t("poapNft:modals.connectWallet.title")}</h2>
            <p className="p0">{t("poapNft:modals.connectWallet.description")}</p>

            {showErrorMsg && (
              <Group colGap={8}>
                <img src="/images/icon-notification-error.svg" alt="Skey Keeper not available" width={20} height={20} />

                <p className="p0 danger-1">{errorMsg()}</p>
              </Group>
            )}

            <Group justifyContent="center" fullWidth className="mt-4 mb-4">
              <img src="/images/skey-keeper-logo.png" alt="skey keeper logo" width={294} height={54} />
            </Group>

            <Group colGap={20} fullWidth>
              <Button onClick={handleConnect} loading={isConnecting} disabled={connectBtnDisabled}>
                {t("common:connect")}
              </Button>

              <Button variant="secondary" onClick={installSkeyKeeper}>
                {t("common:installSkeyKeeper")}
              </Button>

              <Button variant="secondary" onClick={closeModal}>
                {t("common:close")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default ConnectWallet;
