export const tableHeaders = [
  {
    label: "ticketsTable.headers.ticketNumber",
    className: "cell-tickets-number"
  },
  {
    label: "ticketsTable.headers.termOfValidity",
    className: "cell-termOfValidity"
  },
  {
    label: "ticketsTable.headers.comments",
    className: "cell-comments"
  },
  {
    label: "ticketsTable.headers.validate",
    className: "cell-validate"
  },
  {
    label: "ticketsTable.headers.actions",
    className: "cell-actions"
  }
];
