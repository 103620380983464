import { AxiosResponse } from "axios";

export const createFileFromBlob = (res: AxiosResponse<Blob>) => {
  const header = res.headers["content-disposition"];
  const encodedFilename = header!.split(/"/)[1];
  const filename = decodeURI(encodedFilename);
  const extension = filename.split(".")[1];

  const file = new File([res.data], filename ?? "filename", {
    type: extension || "unkown"
  });

  return file;
};
