import React, { useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";

import { IContext } from "src/types/IContext.types";
import { SKEY_TIX_API_SOCKET_URL } from "@config/config";

import { STORAGE_KEYS } from "@config/helpers";
import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";

interface ContextValue {
  socket: Socket;
}

const SocketContext = React.createContext(null as any);

export const SocketProvider = ({ children }: IContext) => {
  const csrf = BrowserStorageManager.readSessionStorage<string>(STORAGE_KEYS.CSRF_TOKEN);

  const socket = useRef(
    io(SKEY_TIX_API_SOCKET_URL, {
      extraHeaders: {
        "X-CSRF-TOKEN": csrf!
      },
      withCredentials: true
    })
  );

  useEffect(() => {
    socket.current.on("error", (msg: string) => {
      // eslint-disable-next-line no-console
      console.error("SocketIO: Error", msg);
    });

    return () => {
      if (socket) {
        socket.current.removeAllListeners();
        socket.current.close();
      }
    };
  }, [socket, csrf]);

  const contextValue: ContextValue = {
    socket: socket.current
  };

  return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};

export const useSocket = (): ContextValue => React.useContext(SocketContext);
