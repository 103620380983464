import React from "react";
import classNames from "classnames";

import "./ShareButton.scss";

interface ShareButtonProps {
  variant?: "black" | "facebook" | "linkedin" | "telegram";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  onBlur?: () => void;
  children: React.ReactNode | React.ReactElement;
}

const ShareButton = ({ variant = "black", disabled, className = "", onClick, onBlur, children }: ShareButtonProps) => {
  const classes = classNames(`share-button share-button-${variant}`, { "share-button-disabled": disabled }, className);

  return (
    <div role="button" onClick={onClick} onBlur={onBlur} className={classes}>
      {children}
    </div>
  );
};

export default ShareButton;
