import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@components/arrangement/Stack/Stack";
import "./AuthSidebar.scss";

const AuthSidebar = () => {
  const { t } = useTranslation("layout");

  return (
    <Stack justifyContent="center" className="auth-sidebar">
      <img src="/images/auth-sidebar-bg.png" alt="skey tix login" className="auth-sidebar-image" />
      <p className="p2 black-2 auth-sidebar-info">{t("sidebar.text")}</p>
    </Stack>
  );
};

export default AuthSidebar;
