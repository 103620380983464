import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useApiClient } from "@services/Api/ApiClientContext";
import { PATHS } from "src/router/paths";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { resetPasswordValidationSchema } from "./ResetPasswordForm.schema";
import "./ResetPassword.scss";

interface ResetPasswordForm {
  email: string;
}

const ResetPassword = () => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { t } = useTranslation("authViews");
  const { t: errorsT } = useTranslation("formErrors");
  const { resetPassword } = useApiClient();
  const navigate = useNavigate();

  const validationSchema = resetPasswordValidationSchema(errorsT);

  const onFormSubmit = async (email: string) => {
    setSubmitting(true);
    try {
      const res = await resetPassword(email);
      if (res?.status === 204) {
        resetForm();
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { values, touched, errors, setFieldTouched, handleChange, handleBlur, handleSubmit, resetForm } =
    useFormik<ResetPasswordForm>({
      initialValues: { email: "" },
      validationSchema,
      onSubmit: (values) => onFormSubmit(values.email)
    });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name, false);
    handleChange(e);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className="reset-password">
      <h2 className="mb-4">{t("resetPassword.title")}</h2>
      <p className="p0 mb-8">{t("resetPassword.description")}</p>
      <form onSubmit={onSubmit}>
        <Input
          name="email"
          label={t("resetPassword.labels.email")}
          placeholder={t("resetPassword.placeholders.email")}
          className="reset-password-input mb-6"
          value={values.email}
          onChange={onChange}
          onBlur={handleBlur}
          error={touched.email ? errors.email : ""}
        />
        <Group colGap={20}>
          <Button type="submit" variant="primary" size="big" loading={submitting}>
            {t("resetPassword.submit")}
          </Button>
          <Button variant="secondary" size="big" onClick={() => navigate(PATHS.login.path)}>
            {t("resetPassword.goBack")}
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default ResetPassword;
