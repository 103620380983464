import React, { memo } from "react";
import classNames from "classnames";

import { OptionProps } from "../Select.types";
import "./Option.scss";

const Option = ({ name, isActive, onClick }: OptionProps) => {
  const classes = classNames("option", { "option-active": isActive });

  return (
    <div onClick={onClick} className={classes}>
      {name}
    </div>
  );
};

export default memo(Option);
