import React from "react";

const OrderedList: React.FC<{ width?: number; height?: number; className?: string }> = ({
  width = 16,
  height = 16,
  className = ""
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M11 6h9"></path>
      <path d="M11 12h9"></path>
      <path d="M12 18h8"></path>
      <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4"></path>
      <path d="M6 10v-6l-2 2"></path>
    </svg>
  );
};

export default OrderedList;
