import React from "react";

import ApiSettings from "./sections/ApiSettings/ApiSettings";
import Organization from "./sections/Organization/Organization";

import "./Settings.scss";

const Settings = () => {
  return (
    <div className="settings-page">
      <ApiSettings />
      <Organization />
    </div>
  );
};

export default Settings;
