import { TFunction } from "i18next";
import * as yup from "yup";

export const createAccountSchema = (labelsT: TFunction, errorsT: TFunction) =>
  yup.object({
    organizationName: yup
      .string()
      .required(errorsT("required"))
      .max(1024, errorsT("string.max", { count: 1024 })),
    email: yup.string().email(errorsT("string.email")).required(errorsT("required")),
    password: yup
      .string()
      .min(8, errorsT("string.min", { count: 8 }))
      .max(64, errorsT("string.max", { count: 64 }))
      .required(errorsT("required")),
    confirmPassword: yup
      .string()
      .required(errorsT("required"))
      .oneOf([yup.ref("password"), null], errorsT("password.mustMatch"))
  });
