import React, { useMemo } from "react";

import { useTickets } from "@app/panel/Events/contexts/TicketsData.context";

import TicketsEmptyState from "@app/panel/Events/components/TicketsEmptyState/TicketsEmptyState";
import TicketsTable from "@app/panel/Events/components/TicketsTable/TicketsTable";
import TicketsTableSkeleton from "@app/panel/Events/skeletons/TicketsTableSkeleton/TicketsTableSkeleton";

import "./Tickets.scss";

const Tickets = () => {
  const { tickets, status } = useTickets();

  const hasTickets = useMemo(() => {
    return tickets.data.length > 0;
  }, [tickets]);

  if (status === "loading") {
    return <TicketsTableSkeleton />;
  }

  const ticketsContent = hasTickets ? <TicketsTable /> : <TicketsEmptyState />;

  return <div className="tickets">{ticketsContent}</div>;
};

export default Tickets;
