import { TFunction } from "i18next";
import * as yup from "yup";

export const signUpForEventSchema = (errorsT: TFunction, requiredAgreements: string[]) =>
  yup.object({
    firstName: yup.string().required(errorsT("required")),
    lastName: yup.string().required(errorsT("required")),
    email: yup.string().email(errorsT("string.email")).required(errorsT("required")),
    note: yup.string().max(65, errorsT("string.max", { count: 65 })),
    acceptedAgreements: yup
      .array()
      .of(yup.string())
      .test("requiredAgreements", errorsT("requiredAgreements"), function (agreements) {
        return requiredAgreements.every((elem) => agreements?.includes(elem));
      })
  });
