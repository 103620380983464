import { APP_STAGE } from "@config/config";
import { useEventsModals } from "../contexts/EventsModals.context";

import CreateEvents from "./CreateEvent/CreateEvent";
import EditEvent from "./EditEvent/EditEvent";
import EditEventPageContent from "./EditEventPageContent/EditEventPageContent";
import EditEventPageSettings from "./EditEventPageSettings/EditEventPageSettings";
import EditAgreements from "./EditAgreements/EditAgreements";
import CreateWorkerToken from "./CreateWorkerToken/CreateWorkerToken";
import RemoveWorkerToken from "./RemoveWorkerToken/RemoveWorkerToken";
import EditWorkerToken from "./EditWorkerToken/EditWorkerToken";
import EditTicketSettings from "./EditTicketSettings/EditTicketSettings";
import OrderTickets from "./OrderTickets/OrderTickets";
import TicketPreview from "./TicketPreview/TicketPreview";
import ArchiveEvent from "./RemoveEvent/RemoveEvent";
import SubmissionDetails from "./SubmissionDetails/SubmissionDetails";
import SendTicket from "./SendTicket/SendTicket";
import SendGroupTickets from "./SendGroupTickets/SendGroupTickets";
import SendGroupNfts from "./SendGroupNfts/SendGroupNfts";
import SendAllTicekts from "./SendAllTicekts/SendAllTicekts";
import SendAllNfts from "./SendAllNfts/SendAllNfts";
import TestnetGeneratedTickets from "./TestnetGeneratedTickets/TestnetGeneratedTickets";
import OrderNftModule from "./OrderNftModule/OrderNftModule";
import CreateEventNft from "./CreateEventNft/CreateEventNft";
import EditEventNft from "./EditEventNft/EditEventNft";
import EditEventNftSettings from "./EditEventNftSettings/EditEventNftSettings";
import SendOccasionalNft from "./SendOccasionalNft/SendOccasionalNft";
import NftPreview from "./NftPreview/NftPreview";

const EventsModals = () => {
  const { activeModal } = useEventsModals();

  if (activeModal === null) return <></>;
  const { type, data } = activeModal;

  if (type === "createEvent") return <CreateEvents />;
  if (type === "editEvent") return <EditEvent />;
  if (type === "editEventPageContent") return <EditEventPageContent />;
  if (type === "editEventPageSettings") return <EditEventPageSettings />;
  if (type === "editAgreements") return <EditAgreements />;
  if (type === "createWorkerToken") return <CreateWorkerToken />;
  if (type === "editWorkerToken") return <EditWorkerToken workerToken={data} />;
  if (type === "removeWorkerToken") return <RemoveWorkerToken workerToken={data} />;
  if (type === "editTicketSettings") return <EditTicketSettings />;
  if (type === "ticketPreview") return <TicketPreview ticket={data} />;
  if (type === "removeEvent") return <ArchiveEvent />;
  if (type === "submissionDetails") return <SubmissionDetails submission={data} />;
  if (type === "sendTicket") return <SendTicket submission={data} />;
  if (type === "sendGroupTickets") return <SendGroupTickets />;
  if (type === "sendGroupNfts") return <SendGroupNfts />;
  if (type === "sendAllTickets") return <SendAllTicekts />;
  if (type === "sendAllNfts") return <SendAllNfts />;
  if (type === "orderNftModule") return <OrderNftModule />;
  if (type === "createEventNft") return <CreateEventNft />;
  if (type === "editEventNft") return <EditEventNft />;
  if (type === "editEventNftSettings") return <EditEventNftSettings />;
  if (type === "sendNft") return <SendOccasionalNft claimingUser={data} />;
  if (type === "nftPreview") return <NftPreview claimingUser={data} />;

  if (type === "orderTickets") {
    if (APP_STAGE === "dev") return <TestnetGeneratedTickets />;
    else return <OrderTickets />;
  }

  return <></>;
};

export default EventsModals;
