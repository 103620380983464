import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useClaimingUsers } from "@app/panel/Events/contexts/ClaimingUsersData.context";

import ClaimingUsersEmptyState from "@app/panel/Events/components/ClaimingUsersEmptyState/ClaimingUsersEmptyState";
import ClaimingUsersTable from "@app/panel/Events/components/ClaimingUsersTable/ClaimingUsersTable";
import "./ClaimingUsers.scss";

const ClaimingUsers = () => {
  const { event } = useSelectedEvent();
  const { claimingUsers } = useClaimingUsers();
  const { t } = useTranslation("eventsViews");

  const nftHasBeenSent = useMemo(() => {
    return event.occasionalNFTSent;
  }, [event]);

  return (
    <div className="claiming-users">
      <h3 className="claiming-users-title">{t("claimingUsers.title")}</h3>

      {nftHasBeenSent && claimingUsers.data.length > 0 ? <ClaimingUsersTable /> : <ClaimingUsersEmptyState />}
    </div>
  );
};

export default ClaimingUsers;
