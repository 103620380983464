import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const Twitter = ({ width = 31, height = 26, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 26"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45056 25.7917C20.8033 25.7917 27.0153 15.9802 27.0153 7.48613C27.0153 7.2101 27.0153 6.93407 27.0033 6.65804C28.2072 5.75468 29.2546 4.61294 30.0853 3.32063C28.9777 3.83504 27.7858 4.1738 26.5338 4.33691C27.8099 3.54647 28.7851 2.27925 29.2546 0.773649C28.0627 1.5139 26.7385 2.04086 25.3299 2.32944C24.1982 1.07477 22.5971 0.296875 20.8273 0.296875C17.4203 0.296875 14.6514 3.18261 14.6514 6.73333C14.6514 7.23519 14.7116 7.72451 14.8079 8.20129C9.6793 7.93781 5.12858 5.36574 2.08273 1.47626C1.55302 2.42981 1.25205 3.53392 1.25205 4.71331C1.25205 6.94662 2.34759 8.91645 3.99692 10.0707C2.98565 10.0331 2.03458 9.74453 1.20389 9.26776C1.20389 9.29285 1.20389 9.31794 1.20389 9.35558C1.20389 12.4672 3.33478 15.0769 6.15189 15.6666C5.63422 15.8171 5.09246 15.8924 4.52664 15.8924C4.12935 15.8924 3.74411 15.8548 3.3709 15.7795C4.15343 18.339 6.44082 20.1959 9.13754 20.2461C7.01869 21.9775 4.35809 23.0064 1.46875 23.0064C0.975153 23.0064 0.481557 22.9813 0 22.9185C2.7208 24.7253 5.97131 25.7917 9.45056 25.7917Z"
        fill={color}
      />
    </svg>
  );
};

export default Twitter;
