import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { getTimezoneData, tzCodes, getTimezonesTranslated } from "@app/panel/Events/utils/timezones";
import { parseDateTimeWithTimezone } from "@utils/formatDateTime";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Select from "@components/dataEntry/Select/Select";
import DateTimePicker from "@components/dataEntry/DateTimePicker/DateTimePicker";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { editEventSchema } from "./editEvent.schema";
import "./EditEvent.scss";

interface EditEventForm {
  eventName: string;
  startDate: string;
  endDate: string;
  timezone: string;
  eventUrl: string;
  locationName: string;
  locationAddress: string;
  locationUrl: string;
  organizatorEmail: string;
}

const EditEvent = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { event } = useSelectedEvent();
  const { updateEvent } = useEvents();
  const { updateEvent: fetchUpdateEvent } = useApiClient();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);
  const { t: errorsT } = useTranslation("formErrors");
  const { t: timezoneT } = useTranslation("timezones");

  const validationSchema = editEventSchema(t, errorsT, tzCodes);
  const timezone = getTimezonesTranslated(timezoneT);

  const handleUpdateEvent = ({
    eventName,
    startDate,
    endDate,
    timezone,
    eventUrl,
    locationName,
    locationAddress,
    locationUrl,
    organizatorEmail
  }: EditEventForm) => {
    setSubmitting(true);

    fetchUpdateEvent(
      event.id,
      eventName,
      startDate as string,
      endDate,
      timezone,
      eventUrl,
      locationName,
      locationAddress,
      locationUrl,
      organizatorEmail
    )
      .then((res) => {
        if (res?.status === 200) {
          updateEvent(event.id, res.data);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const eventTimezone = getTimezoneData(event.timezone, timezoneT);

  const formik = useFormik<EditEventForm>({
    initialValues: {
      eventName: event.name || "",
      startDate: event.start || "",
      endDate: event.end || "",
      timezone: eventTimezone?.value || "",
      eventUrl: event.url || "",
      locationName: event.location.name || "",
      locationAddress: event.location.address || "",
      locationUrl: event.location.url || "",
      organizatorEmail: event.email || ""
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleUpdateEvent
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched } = formik;

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-event-modal">
            <ScrollShadowWrapper contentClassName="create-event-modal-content">
              <h2>{t("eventsModals:editEvent.title")}</h2>

              <Group colGap={20} alignItems="flex-start" fullWidth className="mt-5">
                <div className="create-event-modal-icon-input">
                  <img src="/images/icon-event-name.svg" alt="event name icon" />
                </div>

                <Input
                  label={t("eventsModals:editEvent.inputs.eventName.label")}
                  placeholder={t("eventsModals:editEvent.inputs.eventName.placeholder")}
                  name="eventName"
                  value={values.eventName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.eventName && touched.eventName ? errors.eventName : ""}
                  maxLength={69}
                  required
                />
              </Group>

              <Group colGap={40} alignItems="flex-start" className="mt-4" fullWidth>
                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="create-event-modal-icon-input">
                    <img src="/images/icon-event-location-name.svg" alt="location name icon" />
                  </div>

                  <Input
                    label={t("eventsModals:editEvent.inputs.locationName.label")}
                    placeholder={t("eventsModals:editEvent.inputs.locationName.placeholder")}
                    name="locationName"
                    value={values.locationName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.locationName && touched.locationName ? errors.locationName : ""}
                    maxLength={69}
                  />
                </Group>

                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="create-event-modal-icon-input">
                    <img src="/images/icon-event-url.svg" alt="event url icon" />
                  </div>

                  <Input
                    label={t("eventsModals:editEvent.inputs.eventUrl.label")}
                    placeholder={t("eventsModals:editEvent.inputs.eventUrl.placeholder")}
                    description={t("eventsModals:editEvent.inputs.eventUrl.description")}
                    name="eventUrl"
                    value={values.eventUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.eventUrl && touched.eventUrl ? errors.eventUrl : ""}
                  />
                </Group>
              </Group>

              <Group colGap={40} alignItems="flex-start" className="mt-3" fullWidth>
                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="create-event-modal-icon-input">
                    <img src="/images/icon-event-location-address.svg" alt="location address icon" />
                  </div>

                  <Input
                    label={t("eventsModals:editEvent.inputs.locationAddress.label")}
                    placeholder={t("eventsModals:editEvent.inputs.locationAddress.placeholder")}
                    name="locationAddress"
                    value={values.locationAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.locationAddress && touched.locationAddress ? errors.locationAddress : ""}
                  />
                </Group>

                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="create-event-modal-icon-input">
                    <img src="/images/icon-event-location-url.svg" alt="location url icon" />
                  </div>

                  <Input
                    label={t("eventsModals:editEvent.inputs.locationUrl.label")}
                    placeholder={t("eventsModals:editEvent.inputs.locationUrl.placeholder")}
                    name="locationUrl"
                    value={values.locationUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.locationUrl && touched.locationUrl ? errors.locationUrl : ""}
                  />
                </Group>
              </Group>

              <Group colGap={40} alignItems="flex-start" className="mt-4" fullWidth>
                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="create-event-modal-icon-input">
                    <img src="/images/icon-event-organizator-email.svg" alt="organizator email icon" />
                  </div>

                  <Input
                    label={t("eventsModals:editEvent.inputs.organizatorEmail.label")}
                    placeholder={t("eventsModals:editEvent.inputs.organizatorEmail.placeholder")}
                    description={t("eventsModals:editEvent.inputs.organizatorEmail.description")}
                    name="organizatorEmail"
                    value={values.organizatorEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.organizatorEmail && touched.organizatorEmail ? errors.organizatorEmail : ""}
                    required
                  />
                </Group>

                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="create-event-modal-icon-input">
                    <img src="/images/icon-event-timezone.svg" alt="timezone icon" />
                  </div>

                  <Select
                    label={t("eventsModals:editEvent.inputs.timezone.label")}
                    placeholder={t("eventsModals:editEvent.inputs.timezone.placeholder")}
                    options={timezone}
                    name="timezone"
                    onChange={(value: string) => {
                      setFieldValue("timezone", value);
                    }}
                    value={eventTimezone}
                    onBlur={handleBlur}
                    onTouched={setFieldTouched}
                    error={touched.timezone && errors.timezone ? errors.timezone : ""}
                    required
                  />
                </Group>
              </Group>

              <hr className="mt-4 mb-5" />

              <Group colGap={40} alignItems="flex-start" fullWidth className="mb-5">
                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="edit-event-modal-icon-input">
                    <img src="/images/icon-event-date.svg" alt="event start date icon" />
                  </div>
                  <DateTimePicker
                    label={t("eventsModals:editEvent.inputs.startDate.label")}
                    placeholder={t("eventsModals:editEvent.inputs.startDate.placeholder")}
                    name="startDate"
                    value={parseDateTimeWithTimezone(values.startDate, eventTimezone?.value)}
                    onChange={(value) => {
                      setFieldValue("startDate", value);
                    }}
                    onBlur={handleBlur}
                    error={errors.startDate && touched.startDate ? errors.startDate : ""}
                    required
                  />
                </Group>

                <Group colGap={20} alignItems="flex-start" fullWidth>
                  <div className="edit-event-modal-icon-input">
                    <img src="/images/icon-event-date.svg" alt="event end date icon" />
                  </div>
                  <DateTimePicker
                    label={t("eventsModals:editEvent.inputs.endDate.label")}
                    placeholder={t("eventsModals:editEvent.inputs.endDate.placeholder")}
                    name="endDate"
                    value={parseDateTimeWithTimezone(values.endDate, eventTimezone?.value)}
                    onChange={(value) => {
                      setFieldValue("endDate", value);
                    }}
                    onBlur={handleBlur}
                    error={errors.endDate && touched.endDate ? errors.endDate : ""}
                    required
                  />
                </Group>
              </Group>
            </ScrollShadowWrapper>

            <Group colGap={20} className="mt-3">
              <Button variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditEvent;
