import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";

import { useApiClient } from "@services/Api/ApiClientContext";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import PasswordInput from "@components/dataEntry/PasswordInput/PasswordInput";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { createAccountSchema } from "./createAccount.schema";
import "./CreateAccount.scss";

interface CreateAccountForm {
  email: string;
  password: string;
  confirmPassword: string;
  organizationName: string;
}

const CreateAccount = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const { register } = useApiClient();

  const { t } = useTranslation("authViews");
  const { t: commonT } = useTranslation("common");
  const { t: errorsT } = useTranslation("formErrors");

  const validationSchema = createAccountSchema(t, errorsT);

  const handleRegister = ({ email, password, organizationName }: CreateAccountForm) => {
    setSubmitting(true);

    register(email, password, organizationName)
      .then((res) => {
        if (res.status === 204) {
          navigate(PATHS.registerConfirm.path);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<CreateAccountForm>({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      organizationName: ""
    },
    validationSchema,
    onSubmit: handleRegister
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;

  const goBack = () => navigate(-1);

  return (
    <div className="create-account-page">
      <Stack rowGap={20}>
        <h2>{t("createAccount.title")}</h2>
        <p className="p0">{t("createAccount.description")}</p>
      </Stack>

      <Stack rowGap={20} className="mt-3 create-account-page-form">
        <Input
          label={t("createAccount.labels.organizationName")}
          placeholder={t("createAccount.placeholders.organizationName")}
          name="organizationName"
          value={values.organizationName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.organizationName && touched.organizationName ? errors.organizationName : ""}
        />
        <Input
          label={t("createAccount.labels.email")}
          placeholder={t("createAccount.placeholders.email")}
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email ? errors.email : ""}
        />
        <PasswordInput
          label={t("createAccount.labels.password")}
          placeholder={t("createAccount.placeholders.password")}
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password && touched.password ? errors.password : ""}
        />
        <PasswordInput
          label={t("createAccount.labels.confirmPassword")}
          placeholder={t("createAccount.placeholders.confirmPassword")}
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
        />

        <Group colGap={20} className="mt-3">
          <Button type="submit" variant="primary" size="big" onClick={handleSubmit} loading={submitting}>
            {commonT("create")}
          </Button>
          <Button variant="secondary" size="big" onClick={goBack}>
            {commonT("back")}
          </Button>
        </Group>
      </Stack>
    </div>
  );
};

export default CreateAccount;
