import React from "react";

import { useSubmissions } from "@app/panel/Events/contexts/SubmissionsData.context";

import SubmissionsTable from "@app/panel/Events/components/SubmissionsTable/SubmissionsTable";
import SubmissionsEmptyState from "@app/panel/Events/components/SubmissionsEmptyState/SubmissionsEmptyState";
import SubmissionsSkeleton from "@app/panel/Events/skeletons/SubmissionsSkeleton/SubmissionsSkeleton";

import "./EventSubmissions.scss";

const EventSubmissions = () => {
  const { status: fetchStatus, hasSubmissions } = useSubmissions();

  if (fetchStatus === "loading" && !hasSubmissions) return <SubmissionsSkeleton />;
  const submissionsContent = hasSubmissions ? <SubmissionsTable /> : <SubmissionsEmptyState />;

  return <div className="event-submissions">{submissionsContent}</div>;
};

export default EventSubmissions;
