import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";
import UploadImage from "@components/dataEntry/UploadImage/UploadImage";

import "./EditTicketSettings.scss";

const EditTicketSettings = () => {
  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { event } = useSelectedEvent();
  const { updateEvent } = useEvents();
  const { uploadEventLogo } = useApiClient();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);

  const handleAttachImages = () => {
    if (!event) return;
    setSubmitting(true);

    uploadEventLogo(event.id, image || "")
      .then((res) => {
        if (res?.status === 200) {
          updateEvent(event.id, res.data);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleChange = (file: File | null) => setImage(file);
  const handleError = (error: string) => setError(error);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="edit-ticket-settings-modal">
            <h2 className="mb-5">{t("eventsModals:editTicketSettings.title")}</h2>

            <UploadImage imageUrl={event.logoUrl} onChange={handleChange} onError={handleError} />
            <p className="mt-2 p0 danger-1">{error}</p>
            <Group colGap={20} className="mt-6">
              <Button
                type="submit"
                variant="primary"
                onClick={handleAttachImages}
                loading={submitting}
                disabled={!!error}
              >
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default EditTicketSettings;
