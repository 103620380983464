import React from "react";

const SortingIcon: React.FC<{
  variant?: "default" | "asc" | "desc";
  width?: number;
  height?: number;
  className?: string;
}> = ({ variant = "default", width = 24, height = 24, className = "" }) => {
  switch (variant) {
    case "asc": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="#120e2f80"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M6 9l6 6l6 -6"></path>
        </svg>
      );
    }
    case "desc": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="#120e2f80"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M6 15l6 -6l6 6"></path>
        </svg>
      );
    }
    default: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="#120e2f80"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M8 9l4 -4l4 4"></path>
          <path d="M16 15l-4 4l-4 -4"></path>
        </svg>
      );
    }
  }
};

export default SortingIcon;
