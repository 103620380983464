import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./EventHeaderSkeleton.scss";

const EventHeaderSkeleton = () => {
  return (
    <div className="events-header-skeleton">
      <ContentLoader width={720} height={256} viewBox="0 0 720 256">
        <rect x="0" y="0" rx="5" ry="5" width="570" height="42.5" />
        <rect x="600" y="0" rx="5" ry="5" width="120" height="42.5" />

        <rect x="0" y="62.5" rx="5" ry="5" width="570" height="40" />
        <rect x="600" y="62.5" rx="5" ry="5" width="120" height="40" />

        <rect x="0" y="122.5" rx="5" ry="5" width="720" height="133.5" />
      </ContentLoader>
    </div>
  );
};

export default EventHeaderSkeleton;
