import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./EventSettingsSkeleton.scss";

const EventSettingsSkeleton = () => {
  return (
    <Stack rowGap={50} className="event-settings-skeleton">
      {/* Basic Info */}
      <ContentLoader width={720} height={670} viewBox="0 0 720 670">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="70" rx="10" ry="10" width="720" height="600" />
      </ContentLoader>

      {/* Event Page Content */}
      <ContentLoader width={720} height={470} viewBox="0 0 720 470">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="70" rx="10" ry="10" width="720" height="400" />
      </ContentLoader>

      {/* Event Page Settings */}
      <ContentLoader width={720} height={470} viewBox="0 0 720 470">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="70" rx="10" ry="10" width="720" height="400" />
      </ContentLoader>

      {/* Event Agreements */}
      <ContentLoader width={720} height={330} viewBox="0 0 720 330">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="70" rx="10" ry="10" width="720" height="260" />
      </ContentLoader>

      {/* Gates Settings */}
      <ContentLoader width={720} height={240} viewBox="0 0 720 240">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="70" rx="10" ry="10" width="720" height="170" />
      </ContentLoader>

      {/* Ticket Settings */}
      <ContentLoader width={720} height={720} viewBox="0 0 720 720">
        <rect x="20" y="0" rx="5" ry="5" width="300" height="20" />
        <rect x="0" y="70" rx="10" ry="10" width="360" height="423" />
        <rect x="380" y="70" rx="10" ry="10" width="340" height="600" />
      </ContentLoader>
    </Stack>
  );
};

export default EventSettingsSkeleton;
