import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import EventHeader from "@app/panel/Events/components/EventHeader/EventHeader";
import EventNavbar from "@app/panel/Events/components/EventNavbar/EventNavbar";

import "./EventLayout.scss";

const EventLayout = ({ children }: IChildren) => {
  return (
    <div className="event-layout">
      <div className="event-layout-header-part">
        <EventHeader />
      </div>
      <div className="event-layout-navbar-part">
        <EventNavbar />
      </div>
      <div className="event-layout-content-part">{children}</div>
    </div>
  );
};

export default EventLayout;
