import React from "react";
import i18n from "src/translations/i18n";

import { useEventPageData } from "@app/public/EventPage/contexts/EventPage.context";
import { formatDateWithWeekday } from "@utils/formatDateTime";

import Stack from "@components/arrangement/Stack/Stack";
import StatusBadge from "../StatusBadge/StatusBadge";

import "./EventPageHeader.scss";

const EventPageHeader = () => {
  const { event } = useEventPageData();
  const lang = i18n.language;

  const eventStartDate = formatDateWithWeekday(event.start, lang, event.timezone);
  const eventEnded = event.status === "ended";

  return (
    <Stack rowGap={35} className="event-page-header">
      <h1 className="black-1">{event.name}</h1>
      <h2>{eventStartDate}</h2>

      {eventEnded ? <StatusBadge /> : <hr className="event-page-header-horizontalRule" />}
    </Stack>
  );
};

export default EventPageHeader;
