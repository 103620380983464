export enum HttpError {
  INVALID_CREDENTIALS = "err_invalid_credentials",
  EVENT_TICKETS_ALREADY_CREATED = "err_events_ticket_already_created",
  ORGANIZATION_TICKET_LIMIT_REACHED = "err_ticket_limit_reached",
  EVENT_LOCKED = "err_event_is_locked",
  TICKET_STATUS_INVALID = "err_ticket_status_invalid",
  USER_LOGIN_ALREADY_EXISTS = "err_user_login_already_exists",
  ALREADY_IS_MEMBER = "err_user_already_is_member",
  TICKET_EVENT_MISMATCH = "err_ticket_event_mismatch",
  INVALID_WEBHOOK_TOKEN = "err_invalid_webhook_token",
  USER_EMAIL_ALREADY_EXISTS = "err_user_email_already_exists",
  USER_NOT_FOUND = "err_user_not_found",
  RESET_PASSWORD_TOKEN_INVALID = "err_reset_password_token_invalid",
  ORGANIZATION_NAME_ALREADY_EXISTS = "err_organization_name_already_exists",
  EVENT_TICKETS_NOT_CREATED = "err_event_tickets_not_created",
  EVENT_END_TIMESTAMP_NOT_REACHED = "err_event_end_timestamp_not_reached",
  EVENT_START_TIMESTAMP_REACHED = "err_event_start_timestamp_reached",
  EVENT_TIME_RANGE_MISMATCH = "err_event_time_range_mismatch",
  UUID_NOT_VALID = "err_uuid_not_valid",
  SUPPORT_UNAVAILABLE = "err_support_unavailable",
  MINT_ALREADY_COMPLETED = "err_mint_already_completed",
  MINT_ALREADY_IN_PROGRESS = "err_mint_already_in_progress",
  BALANCE_TOO_LOW_TO_MINT = "err_balance_too_low_to_mint",
  MINT_NOT_COMPLETED = "err_mint_not_completed",
  EVENT_ALREADY_ENDED = "err_event_already_ended",
  EVENT_NOT_STARTED = "err_event_not_started",
  EVENT_AGREEMENTS_LOCKED = "err_event_agreements_locked",
  SUBMISSION_NOT_FOUND = "err_submission_not_found",
  NOT_ENOUGH_TICKETS_FOR_SUBMISSIONS = "err_not_enough_tickets_for_submissions",
  EVENT_END_TIMESTAMP_REACHED = "err_event_end_timestamp_reached",
  EVENT_SUBMISSION_ALREADY_EXISTS = "err_event_submission_already_exists",
  EVENT_SUBMISSION_END_TIMESTAMP_REACHED = "err_event_submission_end_timestamp_reached",
  EVENT_SUBMISSION_END_GREATER_THAN_EVENT_END = "err_event_submission_end_greater_than_event_end",
  EVENT_OCCASIONAL_NFTS_DISABLED = "err_event_occasional_nfts_disabled",
  EVENT_OCCASIONAL_NFTS_NOT_SET = "err_event_occasional_nfts_not_set",
  OCCASIONAL_NFT_JWT_ERROR = "err_occasional_nft_jwt_error",
  ACTIVATION_JWT_ERROR = "err_activation_jwt_error",
  OCCASIONAL_NFT_ALREADY_EXISTS = "err_occasional_nft_already_exists",
  JWT_EXPIRED = "jwt_expired",
  FAUCET_ERR = "err_faucet_not_working",
  USER_ALREADY_ACTIVATED = "err_user_already_activated",
  USER_INACTIVE = "err_user_inactive"
}
