import { Outlet, RouteObject, Navigate } from "react-router-dom";

import { PATHS } from "src/router/paths";
import CombinedElements from "../common/CombinedElements";

// Guards ***************************************************************
import MobileDeviceGuard from "@guards/MobileDeviceGuard/MobileDeviceGuard";
import AuthQuard from "@guards/AuthGuard";
import HasOrganizationGuard from "@guards/HasOrganizationGuard/HasOrganizationGuard";
import HasEventsGuard from "@app/panel/Events/guards/HasEventsGuard";

// Providers ************************************************************
import { ApiClientProvider } from "@services/Api/ApiClientContext";
import { SocketProvider } from "@services/Websocket/SocketContext";
import { TestnetProxyClientProvider } from "@services/TestnetProxy/TestnetProxyContext";

import { UserDataProvider } from "@contexts/UserData.context";
import { EventsDataProvider } from "@app/panel/Events/contexts/EventsData.context";
import { EventsModalsProvider } from "@app/panel/Events/contexts/EventsModals.context";
import { SelectedEventProvider } from "@app/panel/Events/contexts/SelectedEvent.context";
import { WorkerTokensDataProvider } from "@app/panel/Events/contexts/WorkerTokensData.context";
import { TicketsDataProvider } from "@app/panel/Events/contexts/TicketsData.context";
import { SubmissionsDataProvider } from "@app/panel/Events/contexts/SubmissionsData.context";
import { ClaimingUsersDataProvider } from "@app/panel/Events/contexts/ClaimingUsersData.context";
import { SelectedSubmissionsProvider } from "@app/panel/Events/contexts/SelectedSubmissions.context";
import { SelectedClaimingUsersProvider } from "@app/panel/Events/contexts/SelectedClaimingUsers.context";
import { SettingsModalsProvider } from "@panel/Settings/contexts/SettingsModalsContext";
import { ApiKeysProvider } from "@panel/Settings/contexts/ApiKeysContext";

// Layouts **************************************************************
import AuthLayout from "@components/layouts/AuthLayout/AuthLayout";
import PanelLayout from "@components/layouts/PanelLayout/PanelLayout";
import EventsListLayout from "@app/panel/Events/layouts/EventsListLayout/EventsListLayout";

// Auth views ************************************************************
import LoginPage from "@panel/Auth/LoginPage/LoginPage";
import ResetPassword from "@panel/Auth/ResetPassword/ResetPassword";
import NewPassword from "@panel/Auth/NewPassword/NewPassword";
import PasswordChanged from "@panel/Auth/PasswordChanged/PasswordChanged";
import CreateAccount from "@app/panel/Auth/CreateAccount/CreateAccount";
import RegisterConfirm from "@app/panel/Auth/RegisterConfirm/RegisterConfirm";
import ActivateAccount from "@app/panel/Auth/ActivateAccount/ActivateAccount";

// Events views ***********************************************************
import EventLayout from "@app/panel/Events/layouts/EventLayout/EventLayout";
import EventsModals from "@app/panel/Events/modals/EventsModals";
import EventsList from "@app/panel/Events/views/EventsList/EventsList";
import Tickets from "@app/panel/Events/views/Tickets/Tickets";
import EventSettings from "@app/panel/Events/views/EventSettings/EventSettings";
import EventSubmissions from "@app/panel/Events/views/EventSubmissions/EventSubmissions";
import EventNft from "@app/panel/Events/views/EventNft/EventNft";
import NoEvents from "@app/panel/Events/views/NoEvents/NoEvents";

// Other views ************************************************************
import Settings from "@panel/Settings/Settings";
import SettingsModals from "@panel/Settings/modals/SettingsModals";
import Help from "@panel/Help/Help";

const authRoutes: RouteObject = {
  element: (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ),
  children: [
    {
      path: PATHS.login.path,
      element: <LoginPage />
    },
    {
      path: PATHS.resetPassword.path,
      element: <ResetPassword />
    },
    {
      path: PATHS.newPassword.path,
      element: <NewPassword />
    },
    {
      path: PATHS.passwordChanged.path,
      element: <PasswordChanged />
    },
    {
      path: PATHS.register.path,
      element: <CreateAccount />
    },
    {
      path: PATHS.registerConfirm.path,
      element: <RegisterConfirm />
    },
    {
      path: PATHS.activateAccount.path,
      element: <ActivateAccount />
    }
  ]
};

const authorizedPanelRoutes: RouteObject = {
  element: (
    <CombinedElements elements={[AuthQuard, UserDataProvider, HasOrganizationGuard]}>
      <PanelLayout>
        <Outlet />
      </PanelLayout>
    </CombinedElements>
  ),
  children: [
    {
      path: PATHS.main.path,
      element: <Navigate to={PATHS.events.path} />
    },
    {
      element: (
        <CombinedElements elements={[SocketProvider, EventsDataProvider, EventsModalsProvider, HasEventsGuard]}>
          <Outlet />
        </CombinedElements>
      ),
      children: [
        {
          element: (
            <EventsListLayout>
              <Outlet />
            </EventsListLayout>
          ),
          children: [
            {
              path: PATHS.events.path,
              element: <EventsList />
            },
            {
              element: (
                <CombinedElements
                  elements={[
                    SelectedEventProvider,
                    WorkerTokensDataProvider,
                    SubmissionsDataProvider,
                    TicketsDataProvider,
                    ClaimingUsersDataProvider,
                    SelectedSubmissionsProvider,
                    SelectedClaimingUsersProvider
                  ]}
                >
                  <EventLayout>
                    <Outlet />
                    <EventsModals />
                  </EventLayout>
                </CombinedElements>
              ),
              children: [
                {
                  path: PATHS.tickets.path,
                  element: <Tickets />
                },
                {
                  path: PATHS.eventSettings.path,
                  element: <EventSettings />
                },
                {
                  path: PATHS.eventSubmissions.path,
                  element: <EventSubmissions />
                },
                {
                  path: PATHS.eventNft.path,
                  element: <EventNft />
                }
              ]
            }
          ]
        },
        {
          path: PATHS.noEvents.path,
          element: (
            <>
              <NoEvents />
              <EventsModals />
            </>
          )
        }
      ]
    },
    {
      path: PATHS.settings.path,
      element: (
        <CombinedElements elements={[SettingsModalsProvider, ApiKeysProvider]}>
          <Settings />
          <SettingsModals />
        </CombinedElements>
      )
    },
    {
      path: PATHS.help.path,
      element: <Help />
    }
  ]
};

export const panelRoutes: RouteObject = {
  element: (
    <MobileDeviceGuard>
      <ApiClientProvider>
        <SocketProvider>
          <TestnetProxyClientProvider>
            <Outlet />
          </TestnetProxyClientProvider>
        </SocketProvider>
      </ApiClientProvider>
    </MobileDeviceGuard>
  ),
  children: [authRoutes, authorizedPanelRoutes]
};
