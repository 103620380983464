import { ClaimingUserDto, EventDto, SubmissionDto, TicketDto } from "@services/Api/api.dto";

export enum SocketEvents {
  TICKET_USED = "ticketUsed",
  EVENT_STATUS_CHANGED = "eventStatusChanged",
  EVENT_TICKETS_EXPIRED = "eventTicketsExpired",
  SUBMISSION_ADDED = "submissionAdded",
  TICKET_SENT_TO_SUBMISSION = "ticketSentToSubmission",
  TICKETS_MINTED = "ticketsMinted",
  OCCASIONAL_NFTS_SENT = "occasionalNftsSent",
  OCCASIONAL_NFTS_CLAIM_EVENT = "occasionalNftsClaim",
  OCCASIONAL_NFTS_SENT_TO_CLAIMING_USER = "occasionalNftsSentToClaimingUser"
}

export interface EventStatusChangedData {
  event: EventDto;
}

export interface TicketUsedData {
  ticket: TicketDto;
}

export interface TicketSentToSubmissionData {
  submission: SubmissionDto;
}

export interface SubmissionAddedData {
  submission: SubmissionDto;
}

export interface OccasionalNftsSentData {
  eventId: string;
}
export interface ClaimingUserData {
  claimingUser: ClaimingUserDto;
}

export type WebsocketEvent =
  | TicketUsedEvent
  | EventStatusChangedEvent
  | EventTicketsExpiredEvent
  | SubmissionAddedEvent
  | TicketSentToSubmissionEvent
  | TicketsMintedEvent
  | OccasionalNFTsSentEvent
  | OccasionalNFTsClaimEvent
  | OccasionalNFTsSentToClaimingUserEvent;

export interface TicketUsedEvent {
  name: "ticketUsed";
  data: {
    ticket: TicketDto;
  };
}

export interface EventStatusChangedEvent {
  name: "eventStatusChanged";
  data: {
    event: EventDto;
  };
}

export interface EventTicketsExpiredEvent {
  name: "eventTicketsExpired";
  data: {
    event: EventDto;
  };
}

export interface SubmissionAddedEvent {
  name: "submissionAdded";
  data: {
    submission: SubmissionDto;
  };
}

export interface TicketSentToSubmissionEvent {
  name: "ticketSentToSubmission";
  data: {
    submission: SubmissionDto;
  };
}

export interface TicketsMintedEvent {
  name: "ticketsMinted";
  data: Record<string, never>;
}

export interface OccasionalNFTsSentEvent {
  name: "occasionalNftsSent";
  data: {
    eventId: string;
  };
}

export interface OccasionalNFTsClaimEvent {
  name: "occasionalNftsClaim";
  data: {
    claimingUser: ClaimingUserDto;
  };
}

export interface OccasionalNFTsSentToClaimingUserEvent {
  name: "occasionalNftsSentToClaimingUser";
  data: {
    claimingUser: ClaimingUserDto;
  };
}
