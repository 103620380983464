import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { useApiClient } from "@services/Api/ApiClientContext";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Textarea from "@components/dataEntry/Textarea/Textarea";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { helpdeskModalValidationSchema } from "./HelpdeskModal.schema";
import "./HelpdeskModal.scss";

export interface HelpdeskModalProps {
  onClose: () => void;
}

interface HelpdeskForm {
  message: string;
}

const HelpdeskModal = ({ onClose }: HelpdeskModalProps) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { t } = useTranslation("helpPage");
  const { t: errorsT } = useTranslation("formErrors");

  const { sendEmail } = useApiClient();

  const validationSchema = helpdeskModalValidationSchema(errorsT);

  const onFormSubmit = (message: string) => {
    setSubmitting(true);
    sendEmail(message)
      .then((res) => {
        if (res?.status === 204) {
          onClose();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik<HelpdeskForm>({
    initialValues: { message: "" },
    validationSchema,
    onSubmit: (values) => onFormSubmit(values.message)
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <form onSubmit={onSubmit}>
            <Stack rowGap={20} className="helpdesk-modal">
              <h2>{t("helpdeskModal.title")}</h2>
              <p className="p0">{t("helpdeskModal.description")}</p>
              <Textarea
                name="message"
                label={t("helpdeskModal.input.label")}
                placeholder={t("helpdeskModal.input.placeholder")}
                required
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.message && touched.message ? errors.message : ""}
              />

              <Group colGap={20}>
                <Button type="submit" variant="primary" loading={submitting}>
                  {t("helpdeskModal.buttons.submit")}
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  {t("helpdeskModal.buttons.close")}
                </Button>
              </Group>
            </Stack>
          </form>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default HelpdeskModal;
