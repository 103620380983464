import React from "react";

const TextAlignCenter: React.FC<{ width?: number; height?: number; className?: string }> = ({
  width = 16,
  height = 16,
  className = ""
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M4 6l16 0"></path>
      <path d="M8 12l8 0"></path>
      <path d="M6 18l12 0"></path>
    </svg>
  );
};

export default TextAlignCenter;
