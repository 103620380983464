import React from "react";
import classNames from "classnames";

import { AccordionProps } from "./Accordion.types";
import AccordionTab from "./AccordionTab/AccordionTab";
import AccordionSummary from "./AccordionSummary/AccordionSummary";
import AccordionDetails from "./AccordionDetails/AccordionDetails";

import "./Accordion.scss";

const Accordion = ({ children, className }: AccordionProps) => {
  const classes = classNames("accordion", className);

  return <div className={classes}>{children}</div>;
};

Accordion.Tab = AccordionTab;
Accordion.Summary = AccordionSummary;
Accordion.Details = AccordionDetails;

export default Accordion;
