import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import i18n from "src/translations/i18n";
import { RECAPTCHA_KEY } from "@config/config";
import { formatDateWithWeekday } from "@utils/formatDateTime";

import { HttpError } from "@services/httpErrors.enum";
import { usePublicApiClient } from "@services/Api/PublicApiClientContext";
import { useEventPageData } from "@app/public/EventPage/contexts/EventPage.context";
import { useEventPageModals } from "@app/public/EventPage/contexts/EventPageModals.context";
import { useWindowDimensions } from "@hooks/useWindowDimensions";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ScrollShadowWrapper from "@components/ux/ScrollShadowWrapper/ScrollShadowWrapper";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";
import RecaptchaDisclaimer from "@components/common/RecaptchaDisclaimer/RecaptchaDisclaimer";

import AgreementCheckbox from "../../components/AgreementCheckbox/AgreementCheckbox";
import { getRequiredAgreementsId } from "../../utils/getRequiredAgreementsId";
import { signUpForEventSchema } from "./signUpForEvent.schema";
import "./SignUpForEvent.scss";

interface SignUpForEventForm {
  firstName: string;
  lastName: string;
  email: string;
  note: string;
  acceptedAgreements: string[];
}

const SignUpForEvent = () => {
  const [modalRef, setModalRef] = useState<HTMLElement | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { eventId } = useParams();
  const { height: windowHeight } = useWindowDimensions();
  const lang = i18n.language;

  const { event, updateEventStatus } = useEventPageData();
  const { createSubmission } = usePublicApiClient();
  const { closeModal } = useEventPageModals();

  const { t } = useTranslation(["public", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const eventStartDate = formatDateWithWeekday(event.start, lang, event.timezone);
  const requiredAgreements = getRequiredAgreementsId(event.agreements);

  const validationSchema = signUpForEventSchema(errorsT, requiredAgreements);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    note: "",
    acceptedAgreements: []
  };

  const handleSignUpForEvent = useCallback(
    async ({ firstName, lastName, email, note, acceptedAgreements }: SignUpForEventForm) => {
      if (!eventId || !recaptchaRef) return;

      const recaptchaToken = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();

      if (!recaptchaToken) throw Error("Recaptcha token is not generated");

      setSubmitting(true);

      createSubmission(eventId, recaptchaToken, firstName, lastName, email, note, acceptedAgreements)
        .then((res) => {
          if (res?.status === 201) {
            closeModal();
          }
        })
        .catch((e: any) => {
          if (e?.response?.status === 403 && e?.response?.data.message === HttpError.EVENT_START_TIMESTAMP_REACHED) {
            updateEventStatus("started");
          } else if (
            e?.response?.status === 403 &&
            e?.response?.data.message === HttpError.EVENT_END_TIMESTAMP_REACHED
          ) {
            updateEventStatus("ended");
          }
        })
        .finally(() => setSubmitting(false));
    },

    [recaptchaRef]
  );

  const formik = useFormik<SignUpForEventForm>({
    initialValues,
    validationSchema,
    onSubmit: handleSignUpForEvent
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  const handleCheckAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (checked) {
      setFieldValue("acceptedAgreements", [...values.acceptedAgreements, value]);
    } else {
      setFieldValue(
        "acceptedAgreements",
        values.acceptedAgreements.filter((v) => v !== value)
      );
    }
  };

  useEffect(() => {
    if (!windowHeight || !modalRef) return;

    modalRef?.style.setProperty("--vertical-height", `${windowHeight * 0.01}px`);
  }, [windowHeight, modalRef]);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <div ref={setModalRef} className="sign-up-for-event-modal">
            <ScrollShadowWrapper contentClassName="sign-up-for-event-modal-content">
              <Group colGap={20} alignItems="flex-start" fullWidth>
                <Stack rowGap={20}>
                  <Group colGap={20} className="sign-up-for-event-modal-organization">
                    {event.logoUrl && <img src={event.logoUrl} alt="organizer logo" />}
                    <Stack rowGap={5}>
                      <p className="organization-header">{t("public:modals.signUpForEvent.organization")}</p>
                      <p className="organization-name">{event.organization.name}</p>
                    </Stack>
                  </Group>

                  <h1>{event.name}</h1>
                  <h2>{eventStartDate}</h2>
                </Stack>
                {event.page.imageUrl && (
                  <img src={event.page.imageUrl} alt="event image" className="event-image desktop-md" />
                )}
              </Group>

              <div className="sign-up-for-event-modal-inputs mt-4">
                <Input
                  label={t("public:modals.signUpForEvent.inputs.firstName.label")}
                  placeholder={t("public:modals.signUpForEvent.inputs.firstName.placeholder")}
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName && touched.firstName ? errors.firstName : ""}
                  required
                />

                <Input
                  label={t("public:modals.signUpForEvent.inputs.lastName.label")}
                  placeholder={t("public:modals.signUpForEvent.inputs.lastName.placeholder")}
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName ? errors.lastName : ""}
                  required
                />
              </div>

              <div className="sign-up-for-event-modal-inputs mt-4">
                <Input
                  label={t("public:modals.signUpForEvent.inputs.email.label")}
                  placeholder={t("public:modals.signUpForEvent.inputs.email.placeholder")}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email ? errors.email : ""}
                  required
                />

                <Input
                  label={t("public:modals.signUpForEvent.inputs.note.label")}
                  placeholder={t("public:modals.signUpForEvent.inputs.note.placeholder")}
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.note && touched.note ? errors.note : ""}
                />
              </div>

              {event.agreements.length > 0 && (
                <Stack rowGap={15} className="mt-5">
                  <p className="p4">{t("public:modals.signUpForEvent.agreementsHeader")}</p>

                  {event.agreements.map(({ id, content, url, required }, index) => {
                    return (
                      <AgreementCheckbox
                        key={id}
                        name={`acceptedAgreements[${index}]`}
                        value={id}
                        content={content}
                        url={url}
                        required={required}
                        checked={values.acceptedAgreements.includes(id as string)}
                        onChange={handleCheckAgreement}
                      />
                    );
                  })}
                </Stack>
              )}

              {touched.acceptedAgreements && errors.acceptedAgreements && (
                <p className="p4 mt-2 danger-1">{errors.acceptedAgreements}</p>
              )}

              <RecaptchaDisclaimer className="mt-4 text-center" />
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{ visibility: "hidden" }}
                theme="dark"
                size="invisible"
                sitekey={RECAPTCHA_KEY}
              />
            </ScrollShadowWrapper>

            <Group colGap={20} className="sign-up-for-event-modal-buttons">
              <Button variant="primary" onClick={handleSubmit} disabled={submitting}>
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SignUpForEvent;
