import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";
import { SKEY_KEEPER_CHROME } from "@constants/constants";

import Accordion from "@components/common/Accordion/Accordion";
import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";

import { nftFaqQuestions } from "@constants/nftFaqQuestions";
import "./NftFaq.scss";

const NftFaq = () => {
  const { t } = useTranslation("helpPage");

  const baseNftFaqQuestions = nftFaqQuestions.slice(0, 2);

  return (
    <Group alignItems="flex-start" colGap={40} className="nft-faq-section">
      <h2 className="title">{t("nftFaq.title")}</h2>

      <Accordion>
        {baseNftFaqQuestions?.map(({ question, answer }, index) => {
          return (
            <Accordion.Tab key={index}>
              <Accordion.Summary>{t(question as any)}</Accordion.Summary>
              <Accordion.Details>
                <p className="p0 text-justify mb-3">{t(answer as any)}</p>
              </Accordion.Details>
            </Accordion.Tab>
          );
        })}

        <Accordion.Tab>
          <Accordion.Summary>{t(nftFaqQuestions[2].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <p className="p0 text-justify mb-3">
              <Trans t={t} i18nKey={nftFaqQuestions[2].answer as any}>
                <Link target="_blank" href={PATHS.poapNft.path} withImage={false}>
                  Skey Tixplorer
                </Link>
              </Trans>
            </p>
          </Accordion.Details>
        </Accordion.Tab>

        <Accordion.Tab>
          <Accordion.Summary>{t(nftFaqQuestions[3].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <ol className="list">
              <li className="p0">{t((nftFaqQuestions[3].answer as any).steps.one)}</li>
              <li className="p0">{t((nftFaqQuestions[3].answer as any).steps.two)}</li>
              <li className="p0">
                <Trans t={t} i18nKey={(nftFaqQuestions[3].answer as any).steps.three}>
                  <Link target="_blank" href={SKEY_KEEPER_CHROME} withImage={false}>
                    Skey Keeper
                  </Link>
                </Trans>
              </li>
              <li className="p0">{t((nftFaqQuestions[3].answer as any).steps.four)}</li>
              <li className="p0">{t((nftFaqQuestions[3].answer as any).steps.five)}</li>
              <li className="p0">
                <Trans t={t} i18nKey={(nftFaqQuestions[3].answer as any).steps.six}>
                  <Link target="_blank" href={PATHS.poapNft.path} withImage={false}>
                    Skey Tixplorer
                  </Link>
                </Trans>
              </li>
            </ol>
          </Accordion.Details>
        </Accordion.Tab>

        <Accordion.Tab>
          <Accordion.Summary>{t(nftFaqQuestions[4].question as any)}</Accordion.Summary>
          <Accordion.Details>
            <p className="p0 text-justify mb-3">{t((nftFaqQuestions[4].answer as any).info)}</p>
            <ul className="list">
              <li className="p0">
                <Trans t={t} i18nKey={(nftFaqQuestions[4].answer as any).steps.one}>
                  <Link target="_blank" href={PATHS.poapNft.path} withImage={false}>
                    Skey Tixplorer
                  </Link>
                </Trans>
              </li>
              <li className="p0">{t((nftFaqQuestions[4].answer as any).steps.two)}</li>
              <li className="p0">{t((nftFaqQuestions[4].answer as any).steps.three)}</li>
              <li className="p0">{t((nftFaqQuestions[4].answer as any).steps.four)}</li>
            </ul>
          </Accordion.Details>
        </Accordion.Tab>
      </Accordion>
    </Group>
  );
};

export default NftFaq;
