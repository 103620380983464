import { saveActivationQrAsPng } from "skey-tix-generator/dist/frontend/ticketFile";
import { ActivationData } from "skey-tix-generator/dist/interfaces";

import { FILE_LANG, IS_DEMO_MODE } from "src/config/helpers";
import { EventDto, WorkerTokenDto } from "@services/Api/api.dto";

import { arrayBufferToBase64 } from "@utils/arrayBufferToBase64";
import { buildActivationTicketFileName } from "./buildActivationTicketFileName";

const buildActivationTicket = (ticketQr: ArrayBuffer, event: EventDto, workerToken: WorkerTokenDto): ActivationData => {
  const qrCode = arrayBufferToBase64(ticketQr);

  return {
    gateName: workerToken.label,
    qr: qrCode,
    controller: workerToken.controller,
    eventName: event.name,
    eventStart: event.start,
    timezone: event.timezone,
    eventLocation: event.location.name
  };
};

export const downloadActivationTicketAsPng = (
  ticketQr: ArrayBuffer,
  event: EventDto,
  workerToken: WorkerTokenDto,
  organizerName: string
) => {
  const activationTicketData = buildActivationTicket(ticketQr, event, workerToken);

  const fileName = buildActivationTicketFileName(organizerName, workerToken.label);

  saveActivationQrAsPng(activationTicketData, FILE_LANG, fileName, undefined, IS_DEMO_MODE);
};
