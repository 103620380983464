import { TFunction } from "i18next";
import * as yup from "yup";

import { calculateBytesLength } from "@utils/calculateBytesLength";

export const createEventNftSchema = (labelsT: TFunction, errorsT: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(4, errorsT("string.min", { count: 4 }))
      .test("bytesLength", errorsT("string.to_long"), function (value) {
        return calculateBytesLength(value || "") < 16;
      })
      .required(errorsT("required"))
  });
