import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "src/router/paths";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["notFound", "common"]);

  const goToHomePage = () => navigate(PATHS.main.path);

  return (
    <div className="not-found">
      <h2 className="mb-4">{t("notFound:title")}</h2>
      <p className="p0 mb-9">{t("notFound:description")}</p>

      <Button variant="primary" size="big" onClick={goToHomePage}>
        {t("common:goToHomePage")}
      </Button>
    </div>
  );
};

export default NotFound;
