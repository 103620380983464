import React, { memo, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { nanoid } from "nanoid";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useVisible from "@hooks/useVisible";
import ClickAwayListener from "../../common/ClickAwayListener/ClickAwayListener";
import Input from "../Input/Input";

import SelectIconButton from "./SelectIconButton/SelectIconButton";
import Option from "./Option/Option";
import { SelectOption, SelectProps } from "./Select.types";
import "./Select.scss";

const Select = ({
  id,
  name,
  options,
  label,
  placeholder,
  description,
  value,
  error,
  disabled,
  required,
  onChange,
  onBlur,
  onTouched,
  className
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<SelectOption>(value || { name: "", value: "" });
  const { isVisible, setVisibilityTrue, setVisibilityFalse, visibilityToggle } = useVisible(false);
  const [isClicked, setIsClicked] = useState(false);
  const [searchInput, setSearchInput] = useState(value?.name || "");
  const [selectOptions, setSelectOptions] = useState(options ? [...options] : []);

  const [inputRef, setInputRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const { t: commonT } = useTranslation("common");

  const { styles, attributes } = usePopper(inputRef, popperRef, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 3]
        }
      }
    ]
  });

  const optionsStyles = useMemo(() => {
    if (inputRef)
      return {
        ...styles.popper,
        width: window.getComputedStyle(inputRef).getPropertyValue("width"),
        zIndex: 10
      };

    return styles.popper;
  }, [inputRef, styles]);

  const handleSelect = (option: SelectOption) => () => {
    setSelectedValue(option);
    setSearchInput(option.name);
    // onChange && onChange(option.value);
    setVisibilityFalse();
    setSelectOptions(options ? [...options] : []);
  };

  const handleVisibilityFalse = () => {
    setVisibilityFalse();
  };

  const handleOpen = () => {
    setVisibilityTrue();
    setIsClicked(true);
  };

  const handleBlur = (e: any) => {
    if (!isVisible && onBlur) onBlur(e);
  };

  const handleOnSearch = (value: string) => {
    setSelectedValue({ name: value, value: value });
    setSearchInput(value);

    if (options) {
      const searchedContent = options.filter((option) => {
        return option.name.toString().toLocaleLowerCase().trim().includes(value.toLocaleLowerCase().trim());
      });

      setSelectOptions(searchedContent);
      if (searchedContent.length <= 0) setSelectedValue({ name: value, value: "invalid data" });
    }
  };

  React.useEffect(() => {
    if (isClicked && !isVisible && onTouched) {
      onTouched(name as string, true, true);
    }
  }, [isClicked, isVisible, onBlur]);

  React.useEffect(() => {
    if (selectedValue && onChange) onChange(selectedValue.value);
  }, [selectedValue]);

  const isActiveOption = (value: any, selectedValue: any) => value === selectedValue;

  const classes = classNames("select", className);

  return (
    <ClickAwayListener onClickAway={handleVisibilityFalse}>
      <div className={classes}>
        <Input
          id={id}
          inputRef={setInputRef}
          name={name}
          label={label}
          placeholder={placeholder}
          description={description}
          error={error}
          disabled={disabled}
          required={required}
          onClick={options && handleOpen}
          onBlur={handleBlur}
          inputAdornment={<SelectIconButton onClick={visibilityToggle} />}
          value={searchInput}
          onChange={(el) => handleOnSearch(el.target.value)}
          className="select-input"
        />

        {isVisible && (
          <div ref={setPopperRef} style={optionsStyles} {...attributes.popper}>
            <div className="select-options">
              {selectOptions?.map((option) => (
                <Option
                  key={`select-option-${option.value}-${nanoid()}`}
                  name={option.name}
                  isActive={isActiveOption(option.value, selectedValue.value)}
                  onClick={handleSelect(option)}
                />
              ))}

              {selectOptions.length <= 0 && <p className="p0 black-2 empty-options">{commonT("noMatches")}</p>}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default memo(Select);
