import React, { ReactNode, HTMLAttributeAnchorTarget } from "react";
import classNames from "classnames";

import Group from "@components/arrangement/Group/Group";
import LinkImage from "./LinkImage";

import "./Link.scss";

export interface LinkProps {
  href: string;
  target?: HTMLAttributeAnchorTarget;
  follow?: boolean;
  title?: string;
  children: ReactNode;
  withImage?: boolean;
  imageType?: "link" | "email" | "telegram" | "recovery";
  className?: string;
  disabled?: boolean;
  download?: string;
}

const Link = ({
  href,
  target = "_self",
  follow = false,
  title,
  children,
  withImage = true,
  imageType = "link",
  className = "",
  disabled = false,
  download
}: LinkProps) => {
  const rel = follow ? "dofollow" : `noopener noreferrer nofollow`;
  const classes = classNames("link", { disabled: disabled }, className);

  return (
    <a href={href} target={target} rel={rel} title={title} className={classes} download={download}>
      <Group colGap={8}>
        {children}
        {withImage && <LinkImage type={imageType} className="link-image" />}
      </Group>
    </a>
  );
};

export default Link;
