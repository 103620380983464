import React from "react";
import { nanoid } from "nanoid";
import classNames from "classnames";

import { usePagination, DOTS } from "@hooks/usePagination";

import ArrowLeft from "src/assets/ArrowLeft";
import ArrowRight from "src/assets/ArrowRight";

import { PaginationProps } from "./Pagination.types";
import "./Pagination.scss";

const Pagination = ({
  currentPage,
  pageSize,
  totalCount,
  siblingCount = 1,
  className,
  onPageChange
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  const classes = classNames("pagination", className);
  const paginationItemClasses = classNames("pagination-item", { disabled: currentPage === 1 });

  return (
    <ul className={classes}>
      <li className={paginationItemClasses} onClick={onPrevious}>
        <ArrowLeft width={18} height={18} className="pagination-arrow" />
      </li>

      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={nanoid()} className="pagination-item pagination-dots">
              ...
            </li>
          );
        }

        return (
          <li
            key={nanoid()}
            className={`pagination-item ${pageNumber === currentPage ? "selected" : ""}`}
            onClick={() => onPageChange(pageNumber as number)}
          >
            {pageNumber}
          </li>
        );
      })}

      <li className={`pagination-item ${currentPage === lastPage ? "disabled" : ""}`} onClick={onNext}>
        <ArrowRight width={18} height={18} className="pagination-arrow" />
      </li>
    </ul>
  );
};

export default Pagination;
