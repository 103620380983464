import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "src/router/paths";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./PasswordChanged.scss";

const PasswordChanged = () => {
  const { t } = useTranslation("authViews");
  const navigate = useNavigate();

  return (
    <div className="password-changed">
      <h2 className="mb-3">{t("passwordChanged.title")}</h2>
      <p className="p0 mb-8">{t("passwordChanged.description")}</p>
      <Button variant="primary" size="big" onClick={() => navigate(PATHS.login.path)}>
        {t("passwordChanged.button")}
      </Button>
    </div>
  );
};

export default PasswordChanged;
