import React from "react";
import { SelectIconButtonProps } from "../Select.types";
import "./SelectIconButton.scss";

const SelectIconButton = ({ onClick }: SelectIconButtonProps) => {
  return (
    <button type="button" onClick={onClick} className="select-icon-button">
      <img src="/images/input-select-icon.svg" alt="select" />
    </button>
  );
};

export default SelectIconButton;
