import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useEventsModals } from "../../contexts/EventsModals.context";
import { useWorkerTokens } from "../../contexts/WorkerTokensData.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { createWorkerTokenSchema } from "./createWorkerToken.schema";
import "./CreateWorkerToken.scss";

interface CreateWorkerTokenForm {
  label: string;
  controller: string;
}

const CreateWorkerToken = () => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { eventId } = useParams();

  const { createWorkerToken } = useApiClient();
  const { addWorkerToken } = useWorkerTokens();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const validationSchema = createWorkerTokenSchema(errorsT);

  const handleAddWorkerToken = ({ label, controller }: CreateWorkerTokenForm) => {
    setSubmitting(true);

    createWorkerToken(eventId || "", label, controller)
      .then((res) => {
        if (res?.status === 201) {
          addWorkerToken(res.data);
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik<CreateWorkerTokenForm>({
    initialValues: {
      label: "",
      controller: ""
    },
    validationSchema,
    onSubmit: handleAddWorkerToken
  });

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="create-worker-token-modal">
            <h2>{t("eventsModals:createWorkerToken.title")}</h2>

            <Group colGap={40} equalWidth fullWidth className="mt-6">
              <Input
                label={t("eventsModals:createWorkerToken.inputs.label.label")}
                placeholder={t("eventsModals:createWorkerToken.inputs.label.placeholder")}
                name="label"
                value={values.label}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.label && touched.label ? errors.label : ""}
                maxLength={20}
                required
              />
              <Input
                label={t("eventsModals:createWorkerToken.inputs.controller.label")}
                placeholder={t("eventsModals:createWorkerToken.inputs.controller.placeholder")}
                name="controller"
                value={values.controller}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.controller && touched.controller ? errors.controller : ""}
                maxLength={20}
              />
            </Group>

            <Group colGap={20} className="mt-6">
              <Button variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("common:save")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default CreateWorkerToken;
