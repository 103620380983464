import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import { useUserData } from "@contexts/UserData.context";

import OrganizationGuardModal from "./OrganizationGuardModal/OrganizationGuardModal";

const HasOrganizationGuard = ({ children }: IChildren) => {
  const { organization, status } = useUserData();

  if (!organization && status !== "loading") {
    return <OrganizationGuardModal />;
  }
  return <>{children}</>;
};

export default HasOrganizationGuard;
