import { DOCUMENTATION_API_URL } from "src/constants/constants";

export const faqQuestions = [
  {
    question: "faq.questions.one.question",
    answer: "faq.questions.one.answer",
    inititalVariant: "active",
    links: []
  },
  {
    question: "faq.questions.two.question",
    answer: "faq.questions.two.answer",
    links: [
      {
        label: "faq.questions.two.links.docs",
        href: DOCUMENTATION_API_URL
      }
    ]
  },
  {
    question: "faq.questions.three.question",
    answer: "faq.questions.three.answer"
  },
  {
    question: "faq.questions.four.question",
    answer: "faq.questions.four.answer"
  },
  {
    question: "faq.questions.five.question",
    answer: "faq.questions.five.answer"
  },
  {
    question: "faq.questions.six.question",
    answer: "faq.questions.six.answer"
  },
  {
    question: "faq.questions.seven.question",
    answer: "faq.questions.seven.answer"
  },
  {
    question: "faq.questions.eight.question",
    answer: "faq.questions.eight.answer"
  }
];
