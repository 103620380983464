import { modalsContextFactory } from "@contexts/ModalsContextFactory";
import { AssetData } from "@services/blockchain/blockchain.types";

interface NftModal {
  type: "nftPreview" | "transferNft" | "burnNft" | "shareNft";
  data: AssetData;
}

interface WithoutObjectModal {
  type: "connectWallet" | "some-modal";
  data?: null;
}

type PoapNftModal = NftModal | WithoutObjectModal;

export const { ModalsProvider: PoapNftModalsProvider, useModalsContext: usePoapNftModals } =
  modalsContextFactory<PoapNftModal>();
