import React from "react";
import { nanoid } from "nanoid";
import { ContentLoaderProps } from "./ContentLoader.types";

const ContentLoader = ({
  id,
  animate = true,
  baseUrl = "",
  title = "loading...",
  backgroundColor = "#f5f6f7",
  foregroundColor = "#eee",
  backgroundOpacity = 1,
  foregroundOpacity = 1,
  gradientRatio = 2,
  gradientDirection = "left-right",
  speed = 2.5,
  interval = 0.25,
  viewBox,
  animateBegin,
  beforeMask,
  style = {},
  children,
  ...props
}: ContentLoaderProps) => {
  const baseId = id || nanoid();
  const idClip = `${baseId}-diff`;
  const idGradient = `${baseId}-animated-diff`;
  const idAria = `${baseId}-aria`;

  const keyTimes = `0; ${interval}; 1`;
  const duration = `${speed}s`;
  const gradientTransform = gradientDirection === "top-bottom" ? "rotate(90)" : undefined;

  return (
    <svg aria-labelledby={idAria} role="img" style={{ ...style }} {...props}>
      {title ? <title id={idAria}>{title}</title> : null}
      {beforeMask && React.isValidElement(beforeMask) ? beforeMask : null}

      <rect
        role="presentation"
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath={`url(${baseUrl}#${idClip})`}
        style={{ fill: `url(${baseUrl}#${idGradient})` }}
      />

      <defs>
        <clipPath id={idClip}>{children}</clipPath>

        <linearGradient id={idGradient} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={backgroundColor} stopOpacity={backgroundOpacity}>
            {animate && (
              <animate
                attributeName="offset"
                values={`${-gradientRatio}; ${-gradientRatio}; 1`}
                keyTimes={keyTimes}
                dur={duration}
                repeatCount="indefinite"
                begin={animateBegin}
              />
            )}
          </stop>

          <stop offset="50%" stopColor={foregroundColor} stopOpacity={foregroundOpacity}>
            {animate && (
              <animate
                attributeName="offset"
                values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 + gradientRatio / 2}`}
                keyTimes={keyTimes}
                dur={duration}
                repeatCount="indefinite"
                begin={animateBegin}
              />
            )}
          </stop>

          <stop offset="100%" stopColor={backgroundColor} stopOpacity={backgroundOpacity}>
            {animate && (
              <animate
                attributeName="offset"
                values={`0; 0; ${1 + gradientRatio}`}
                keyTimes={keyTimes}
                dur={duration}
                repeatCount="indefinite"
                begin={animateBegin}
              />
            )}
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ContentLoader;
