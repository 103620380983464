import { TFunction } from "i18next";
import * as yup from "yup";

export const newPasswordValidationSchema = (errorsT: TFunction) =>
  yup.object({
    newPassword: yup
      .string()
      .required(errorsT("required"))
      .min(8, errorsT("string.min", { count: 8 }))
      .max(64, errorsT("string.max", { count: 64 }))
  });
