import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import Link from "@components/navigation/Link/Link";

import { RecaptchaDisclaimerProps } from "./RecaptchaDisclaimer.types";
import "./RecaptchaDisclaimer.scss";

const RecaptchaDisclaimer = ({ className = "" }: RecaptchaDisclaimerProps) => {
  const { t } = useTranslation("recaptcha");

  const classes = classNames("recaptcha-disclaimer", className);

  return (
    <div className={classes}>
      <Trans i18nKey="disclaimer" t={t}>
        <Link href="https://policies.google.com/privacy" target="_blank" withImage={false}>
          Privacy Policy
        </Link>
        <Link href="https://policies.google.com/terms" target="_blank" withImage={false}>
          Terms of Service
        </Link>
      </Trans>
    </div>
  );
};

export default RecaptchaDisclaimer;
