import React from "react";
import { usePopper } from "react-popper";

import { IChildren } from "@src/types/IChildren.types";
import Stack from "@components/arrangement/Stack/Stack";
import Motion from "@components/ux/Motion/Motion";

import { useDropdownDataContext } from "../Dropdown";

const DropdownMenu = ({ children }: IChildren) => {
  const { isMenuOpen, dropdownRef, popperRef, setPopperRef } = useDropdownDataContext();

  const { styles, attributes } = usePopper(dropdownRef, popperRef, {
    placement: "bottom",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 5]
        }
      }
    ]
  });

  return (
    <>
      {isMenuOpen && (
        <div ref={setPopperRef} className="dropdown-menu" style={{ ...styles.popper }} {...attributes.popper}>
          <Motion appear={true} duration={0}>
            <Stack rowGap={8}>{children}</Stack>
          </Motion>
        </div>
      )}
    </>
  );
};

export default DropdownMenu;
