import React from "react";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { EventDto } from "@services/Api/api.dto";

import { EventStatusChangedData, SocketEvents } from "@services/Websocket/Socket.types";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useUserData } from "@contexts/UserData.context";
import useSocketSubscribe from "@services/Websocket/useSocketSubscribe";

export interface EventsData {
  status: FetchStatus;
  events: EventDto[];
  error: Error | AxiosError | null;
}

interface ContextValue extends EventsData {
  addEvent: (event: EventDto) => void;
  getEvent: (eventId: string) => EventDto | null;
  updateEvent: (eventId: string, updatedEvent: Partial<EventDto>) => void;
  removeEvent: (eventId: string) => void;
  incrementTicketsBurned: (eventId: string) => void;
  incrementSubmissionsCount: (eventId: string) => void;
  lockAddAgreements: (eventId: string) => void;
  fetchEventsData: (organizationId: string) => Promise<void>;
}

const initialEventsData = (): EventsData => ({
  status: "loading",
  events: [],
  error: null
});

const EventsDataContext = React.createContext(null as any);

export interface SocketDataResponse {
  event: EventDto;
}

export const EventsDataProvider = ({ children }: IContext) => {
  const [eventsData, setEventsData] = React.useState<EventsData>(initialEventsData());

  const { getEvents } = useApiClient();
  const { organization, status: fetchUserDataStatus } = useUserData();

  useSocketSubscribe(SocketEvents.EVENT_STATUS_CHANGED, ({ event }: EventStatusChangedData) => {
    updateEvent(event.id, event);
  });

  React.useEffect(() => {
    // Fetch events data when organization data was fetched
    if (fetchUserDataStatus === "success") fetchEventsData(organization?.id as string);
  }, [fetchUserDataStatus]);

  const fetchEventsData = async (organizationId: string) => {
    setEventsData(initialEventsData());

    try {
      const { data } = await getEvents(organizationId);
      return setEventsData((prevState) => ({ ...prevState, events: data, status: "success" }));
    } catch (e: any) {
      setEventsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const addEvent = React.useCallback((event: EventDto) => {
    setEventsData((prevState) => ({ ...prevState, events: [{ ...event, status: "draft" }, ...prevState.events] }));
  }, []);

  const getEvent = React.useCallback(
    (eventId: string) => {
      const foundEvent = eventsData.events.find((event) => event.id === eventId);
      return foundEvent ?? null;
    },
    [eventsData]
  );

  const updateEvent = React.useCallback((eventId: string, updatedEvent: Partial<EventDto>) => {
    setEventsData((prevState) => ({
      ...prevState,
      events: prevState.events.map((event) => {
        if (event.id === eventId) {
          return Object.assign(event, updatedEvent);
        }

        return event;
      })
    }));
  }, []);

  const incrementTicketsBurned = React.useCallback((eventId: string) => {
    setEventsData((prevState) => ({
      ...prevState,
      events: prevState.events.map((event) => {
        if (event.id === eventId && event.ticketsBurned < event.ticketsMinted) {
          return { ...event, ticketsBurned: event.ticketsBurned + 1 };
        }
        return event;
      })
    }));
  }, []);

  const incrementSubmissionsCount = React.useCallback((eventId: string) => {
    setEventsData((prevState) => ({
      ...prevState,
      events: prevState.events.map((event) => {
        if (event.id === eventId) {
          return { ...event, submissionsCount: event.submissionsCount + 1 };
        }
        return event;
      })
    }));
  }, []);

  const lockAddAgreements = React.useCallback(
    (eventId: string) => {
      const event = eventsData.events.find((event) => event.id === eventId);

      if (!event || event.agreementsLocked) return;

      setEventsData((prevState) => ({
        ...prevState,
        events: prevState.events.map((event) => {
          if (event.id === eventId) {
            return { ...event, agreementsLocked: true };
          }
          return event;
        })
      }));
    },
    [eventsData]
  );

  const removeEvent = React.useCallback((eventId: string) => {
    setEventsData((prevState) => ({
      ...prevState,
      events: prevState.events.filter((event) => event.id !== eventId)
    }));
  }, []);

  const contextValue: ContextValue = {
    ...eventsData,
    addEvent,
    getEvent,
    updateEvent,
    removeEvent,
    incrementTicketsBurned,
    incrementSubmissionsCount,
    lockAddAgreements,
    fetchEventsData
  };

  return <EventsDataContext.Provider value={contextValue}>{children}</EventsDataContext.Provider>;
};

export const useEvents = (): ContextValue => React.useContext(EventsDataContext);
