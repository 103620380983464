import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { PATHS } from "src/router/paths";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./RemoveEvent.scss";

const RemoveEvent = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const { event } = useSelectedEvent();
  const { closeModal } = useEventsModals();
  const { removeEvent: fetchRemoveEvent } = useApiClient();
  const { removeEvent } = useEvents();

  const { t } = useTranslation(["eventsModals", "common"]);

  const handleRemoveEvent = () => {
    setSubmitting(true);

    fetchRemoveEvent(event.id)
      .then((res) => {
        if (res?.status === 204) {
          removeEvent(event.id);
          closeModal();
          navigate(PATHS.events.path);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="remove-event-modal">
            <h2>{t("eventsModals:removeEvent.title")}</h2>
            <p className="p4 mt-2">{t("eventsModals:removeEvent.description")}</p>

            <Group colGap={20} className="mt-5">
              <Button type="submit" variant="primary" onClick={handleRemoveEvent} disabled={submitting}>
                {t("common:remove")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default RemoveEvent;
