import React from "react";

const SearchIcon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  opacity?: string | number;
  className?: string;
}> = ({ width = 21, height = 20, color = "#120E2F", opacity = 1, className = "" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity={opacity}
        d="M1.66992 8.00108C1.66992 8.92034 1.85098 9.83061 2.20277 10.6799C2.55456 11.5292 3.07018 12.3009 3.7202 12.9509C4.37022 13.6009 5.14191 14.1165 5.9912 14.4683C6.84049 14.8201 7.75076 15.0012 8.67002 15.0012C9.58929 15.0012 10.4996 14.8201 11.3488 14.4683C12.1981 14.1165 12.9698 13.6009 13.6198 12.9509C14.2699 12.3009 14.7855 11.5292 15.1373 10.6799C15.4891 9.83061 15.6701 8.92034 15.6701 8.00108C15.6701 7.08181 15.4891 6.17155 15.1373 5.32225C14.7855 4.47296 14.2699 3.70128 13.6198 3.05126C12.9698 2.40124 12.1981 1.88562 11.3488 1.53383C10.4996 1.18204 9.58929 1.00098 8.67002 1.00098C7.75076 1.00098 6.84049 1.18204 5.9912 1.53383C5.14191 1.88562 4.37022 2.40124 3.7202 3.05126C3.07018 3.70128 2.55456 4.47296 2.20277 5.32225C1.85098 6.17155 1.66992 7.08181 1.66992 8.00108Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={opacity}
        d="M19.671 19.0011L13.6709 13.001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
