import { TFunction } from "i18next";
import * as yup from "yup";

export const editEventPageSettings = (
  labelsT: TFunction,
  errorsT: TFunction,
  endEventDate: string,
  submissionEndRequired: boolean
) =>
  yup.object({
    submissionEnd: yup
      .date()
      .min(new Date(), errorsT("date.notInThePast"))
      .max(
        new Date(endEventDate),
        errorsT("date.notAfterDate", { date: labelsT("editEventPageSettings.submissionEnd.notAfterDateErrorLabel") })
      )
      .when([], {
        is: () => submissionEndRequired,
        then: yup.date().nullable().required(errorsT("required"))
      })
  });
