import React from "react";

const LinkIcon: React.FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 1L6.25 9.75M6.25 2.75H2.75C2.28587 2.75 1.84075 2.93437 1.51256 3.26256C1.18437 3.59075 1 4.03587 1 4.5V13.25C1 13.7141 1.18437 14.1592 1.51256 14.4874C1.84075 14.8156 2.28587 15 2.75 15H11.5C11.9641 15 12.4092 14.8156 12.7374 14.4874C13.0656 14.1592 13.25 13.7141 13.25 13.25V9.75L6.25 2.75ZM9.75 1H15H9.75ZM15 1V6.25V1Z"
        stroke="#5C44EC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
