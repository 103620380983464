import React, { useCallback, useMemo } from "react";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

import { useUserData } from "@contexts/UserData.context";
import { WorkerTokenDto } from "@services/Api/api.dto";
import { useApiClient } from "@services/Api/ApiClientContext";
import { useSelectedEvent } from "@app/panel/Events/contexts/SelectedEvent.context";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Group from "@components/arrangement/Group/Group";
import Tooltip from "@components/dataDisplay/Tooltip/Tooltip";
import ActionIcon from "@components/dataEntry/Buttons/ActionIcon/ActionIcon";

import { downloadActivationTicketAsPng } from "@app/panel/Events/utils/activationTicketDownload";
import { tableHeaders } from "./tableHeaders";
import "./WorkerTokensTable.scss";

interface WorkerTokensTableProps {
  workerTokens: WorkerTokenDto[];
}

const WorkerTokensTable = ({ workerTokens }: WorkerTokensTableProps) => {
  const { t } = useTranslation("eventsViews");
  const { t: commonT } = useTranslation("common");

  const { organization } = useUserData();
  const { event, eventStatus } = useSelectedEvent();
  const { getWorkerTokenQr } = useApiClient();
  const { openModal } = useEventsModals();

  const handleEdit = (workerToken: WorkerTokenDto) => () => openModal({ type: "editWorkerToken", data: workerToken });

  const handleRemove = (workerToken: WorkerTokenDto) => () =>
    openModal({ type: "removeWorkerToken", data: workerToken });

  const organizerName = useMemo(() => organization?.name || "", []);

  const handleDownloadTicket = useCallback(
    (workerToken: WorkerTokenDto) => () => {
      getWorkerTokenQr(workerToken.id).then((res) => {
        if (res?.status !== 200) return;

        downloadActivationTicketAsPng(res.data, event, workerToken, organizerName);
      });
    },
    []
  );

  const removeTip = useMemo(() => {
    switch (eventStatus) {
      case "started":
        return commonT("eventStartedDisabled");
      case "ended":
        return commonT("eventEndedDisabled");
      default:
        return commonT("remove");
    }
  }, [eventStatus]);

  const editTip = useMemo(() => {
    switch (eventStatus) {
      case "started":
        return commonT("eventStartedDisabled");
      case "ended":
        return commonT("eventEndedDisabled");
      default:
        return commonT("edit");
    }
  }, [eventStatus]);

  const tipWidth = eventStatus === "started" || eventStatus === "ended" ? 200 : undefined;
  const disableEdit = eventStatus === "started" || eventStatus === "ended";

  return (
    <div className="worker-tokens-table">
      <div className="table-row">
        {tableHeaders.map(({ label, className }) => (
          <div key={nanoid()} className={`header-cell ${className || ""}`}>
            {t(label as any)}
          </div>
        ))}
      </div>

      {workerTokens.map((workerToken, index) => {
        return (
          <div key={nanoid()} className="table-row">
            <div className="row-cell number-cell">{index + 1}</div>
            <div className="row-cell">{workerToken.label}</div>
            <div className="row-cell">{workerToken.controller || "-"}</div>

            <div className="row-cell acitons-cell">
              <Group colGap={10}>
                <Tooltip tip={removeTip} position="top" width={tipWidth} textCenter>
                  <ActionIcon disabled={disableEdit} onClick={handleRemove(workerToken)}>
                    <img src="/images/icon-delete.svg" alt="delete gate button" />
                  </ActionIcon>
                </Tooltip>

                <Tooltip tip={editTip} position="top" width={tipWidth} textCenter>
                  <ActionIcon disabled={disableEdit} onClick={handleEdit(workerToken)}>
                    <img src="/images/icon-edit.svg" alt="edit gate button" />
                  </ActionIcon>
                </Tooltip>

                <Tooltip tip={commonT("download")} position="top">
                  <ActionIcon onClick={handleDownloadTicket(workerToken)}>
                    <img src="/images/icon-download.svg" alt="download QR code button" />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkerTokensTable;
