import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import Stack from "@components/arrangement/Stack/Stack";
import NotFoundSidebar from "@components/layoutElements/NotFoundSidebar/NotFoundSidebar";

import "./NotFoundLayout.scss";

const NotFoundLayout = ({ children }: IChildren) => {
  return (
    <div className="not-found-layout">
      <div className="sidebar-part">
        <NotFoundSidebar />
      </div>

      <div className="content-part">
        <Stack justifyContent="center" className="not-found-layout-content">
          <img src="/images/skey-tix-logo-big.svg" alt="skey tix logo" className="mb-8" width={310} height={77} />

          {children}
        </Stack>
      </div>
    </div>
  );
};

export default NotFoundLayout;
