export const nftFaqQuestions = [
  {
    question: "nftFaq.questions.one.question",
    answer: "nftFaq.questions.one.answer",
    inititalVariant: "active"
  },
  {
    question: "nftFaq.questions.two.question",
    answer: "nftFaq.questions.two.answer"
  },
  {
    question: "nftFaq.questions.three.question",
    answer: "nftFaq.questions.three.answer"
  },
  {
    question: "nftFaq.questions.four.question",
    answer: {
      steps: {
        one: "nftFaq.questions.four.answer.steps.one",
        two: "nftFaq.questions.four.answer.steps.two",
        three: "nftFaq.questions.four.answer.steps.three",
        four: "nftFaq.questions.four.answer.steps.four",
        five: "nftFaq.questions.four.answer.steps.five",
        six: "nftFaq.questions.four.answer.steps.six"
      }
    }
  },
  {
    question: "nftFaq.questions.five.question",
    answer: {
      info: "nftFaq.questions.five.answer.info",
      steps: {
        one: "nftFaq.questions.five.answer.steps.one",
        two: "nftFaq.questions.five.answer.steps.two",
        three: "nftFaq.questions.five.answer.steps.three",
        four: "nftFaq.questions.five.answer.steps.four"
      }
    }
  }
];
