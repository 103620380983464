import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetData } from "@services/blockchain/blockchain.types";

import { useTransaction } from "@services/blockchain/hooks/useTransaction";
import { useWvs } from "@services/blockchain/Waves.context";
import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./TransferNft.scss";

interface TransferNftProps {
  nft: AssetData;
}

const TransferNft = ({ nft }: TransferNftProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [transactionError, setTransactionError] = useState("");

  const tx = useTransaction();
  const { services } = useWvs();
  const { removeAssetData } = useBrowseNfts();
  const { closeModal } = usePoapNftModals();

  const { t } = useTranslation(["poapNft", "apiResponse", "common"]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceiverAddress(e.target.value);
  };

  const handleTransfer = async () => {
    try {
      if (!receiverAddress) return;

      setTransactionError("");
      setSubmitting(true);

      const params = await services.txCreator.createTransferTxData(receiverAddress, 1, nft.assetId, undefined);

      await tx
        .send(params)
        .then(() => removeAssetData(nft.assetId))
        .then(closeModal);
    } catch (e: any) {
      if (e?.message === "User denied message") {
        setTransactionError(t("apiResponse:errors.bcTransactionRejected"));
      } else {
        setTransactionError(t("apiResponse:errors.bcTransactionFailed"));
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack rowGap={10} className="transfer-nft-modal">
            <h2>{t("poapNft:modals.transferNft.title")}</h2>

            <p className="p0">{t("poapNft:modals.transferNft.description")}</p>

            <Input
              name="receiver-address"
              label={t("poapNft:modals.transferNft.input.receiverAddress.label")}
              placeholder={t("poapNft:modals.transferNft.input.receiverAddress.placeholder")}
              onChange={handleInputChange}
              className="mt-2"
            />

            {transactionError && (
              <Group colGap={8} className="transaction-error">
                <img src="/images/icon-notification-error.svg" alt="error icon" />
                <p className="p0 danger-1 text-center">{transactionError}</p>
              </Group>
            )}

            <Group colGap={10} className="mt-2">
              <Button type="submit" variant="primary" onClick={handleTransfer} disabled={submitting}>
                {t("common:accept")}
              </Button>

              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default TransferNft;
