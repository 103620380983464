import { WVS_NETWORK_SERVER } from "@config/config";

import { BCFeeCalculator } from "./services/BCFeeCalculator";
import { BCKeeperService } from "./services/BCKeeperService";
import { BCReadService } from "./services/BCReadService";
import { BCTxCreator } from "./services/BCTxCreator";

export interface BCServices {
  readService: BCReadService;
  txCreator: BCTxCreator;
  feeCalculator: BCFeeCalculator;
  keeperService: BCKeeperService;
}

export class BCServiceManager {
  public readService = new BCReadService();
  public txCreator = new BCTxCreator();
  public feeCalculator = new BCFeeCalculator(this.readService);
  public keeperService = new BCKeeperService();

  public getServices(): BCServices {
    return {
      readService: this.readService,
      txCreator: this.txCreator,
      feeCalculator: this.feeCalculator,
      keeperService: this.keeperService
    };
  }

  public update(
    publicState?: WavesKeeper.IPublicStateResponse,
    setPublicState?: (state: WavesKeeper.IPublicStateResponse | null) => void
  ) {
    this.keeperService.update(publicState, setPublicState);
    this.readService.update(publicState?.network?.server || WVS_NETWORK_SERVER);
    this.feeCalculator.update(publicState?.account?.address);
  }
}
