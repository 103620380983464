import React from "react";

interface Icon {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const Discord = ({ width = 27, height = 31, color = "#5C44EC", className = "" }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 31"
      fill="none"
      className={className}
    >
      <path
        d="M3.64633 26.5747H20.7999L19.9823 23.8126C20.1063 23.9184 27.0084 30.089 27.0084 30.089V3.10293C26.9294 1.42805 25.5423 0 23.8224 0L3.65197 0.00587675C1.93774 0.00587675 0.544922 1.43393 0.544922 3.1088V23.4718C0.544922 25.2407 1.93774 26.5747 3.64633 26.5747ZM16.3395 7.12263L16.3001 7.14026L16.317 7.12263H16.3395ZM7.15938 8.71523C9.3642 7.04035 11.4055 7.12263 11.4055 7.12263L11.569 7.29305C8.86797 7.963 7.64996 9.22063 7.64996 9.22063C7.77402 9.19124 13.2212 5.91789 19.8244 9.3029C19.8244 9.3029 18.6007 8.12755 16.0689 7.37533L16.2944 7.14613C16.644 7.14613 18.4993 7.21665 20.4785 8.72698C20.4785 8.72698 22.6946 12.6762 22.6946 17.5304C22.6213 17.4363 21.3187 19.6166 17.9636 19.693C17.9636 19.693 17.394 19.0231 16.9937 18.4413C18.956 17.8536 19.6891 16.6782 19.6891 16.6782C15.8715 19.1817 12.5276 18.7939 8.524 17.1014C8.48452 17.1014 8.46761 17.0837 8.45069 17.0661V17.0602C8.43377 17.0426 8.41686 17.025 8.37738 17.025H8.30408C8.05597 16.8545 7.89244 16.7723 7.89244 16.7723C7.89244 16.7723 8.6255 17.9476 10.5089 18.5353C10.0127 19.123 9.52209 19.7929 9.52209 19.7929C6.17257 19.7106 4.94892 17.5304 4.94892 17.5304C4.95456 12.6585 7.15938 8.71523 7.15938 8.71523Z"
        fill={color}
      />
      <path
        d="M16.5546 16.007C17.4117 16.007 18.1053 15.2548 18.1053 14.3262C18.1053 13.4036 17.4117 12.6514 16.5546 12.6514V12.6572C15.7031 12.6572 15.0039 13.4095 15.0039 14.3321C15.0039 15.2548 15.6975 16.007 16.5546 16.007Z"
        fill={color}
      />
      <path
        d="M11.0009 16.007C11.858 16.007 12.5516 15.2548 12.5516 14.3262C12.5516 13.4036 11.858 12.6514 11.0065 12.6514L11.0009 12.6572C10.1438 12.6572 9.4502 13.4095 9.4502 14.3321C9.4502 15.2548 10.1438 16.007 11.0009 16.007Z"
        fill={color}
      />
    </svg>
  );
};

export default Discord;
