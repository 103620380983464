import React from "react";
import classNames from "classnames";
import "./BadgeStatus.scss";

export interface BadgeStatusProps {
  text: string;
  variant?: "started" | "ended";
}

const BadgeStatus = ({ text, variant }: BadgeStatusProps) => {
  const classes = classNames(`badge-status badge-status-${variant}`);

  return <div className={classes}>{text}</div>;
};

export default BadgeStatus;
