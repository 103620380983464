import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@router/paths";
import { validateWalletAddress } from "@services/blockchain/utils/validateWalletAddress";
import { useBrowseNfts } from "@app/poap-nft/contexts/BrowseNfts.context";

import Button from "@components/dataEntry/Buttons/Button/Button";
import Input from "@components/dataEntry/Input/Input";

import "./SearchWallet.scss";

const SearchWallet = () => {
  const { updateWalletAddress } = useBrowseNfts();
  const navigate = useNavigate();

  const [searchWalletAddress, setSearchWalletAddress] = useState("");
  const [walletAddressError, setWalletAddressError] = useState("");

  const { t } = useTranslation(["poapNft", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletAddressError("");
    setSearchWalletAddress(e.target.value);
  };

  const handleExplore = () => {
    const error = validateWalletAddress(errorsT, searchWalletAddress);

    if (!error) {
      updateWalletAddress(searchWalletAddress);
      navigate(PATHS.poapNftExplore.path);
    } else {
      setWalletAddressError(error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleExplore();
    }
  };

  const exploreBtnDisabled = !searchWalletAddress || !!walletAddressError;

  return (
    <div className="search-wallet-page">
      <div className="search-wallet-page-header">
        <h1 className="header-title">{t("poapNft:searchNfts.title")}</h1>
        <img src="/images/nft-hands-base.png" alt="nft image" className="header-image" />
      </div>

      <div className="search-wallet-page-content">
        <h2>{t("poapNft:browseNfts")}</h2>

        <Input
          label={t("poapNft:searchNfts.searchWallet.label")}
          placeholder={t("poapNft:searchNfts.searchWallet.placeholder")}
          name="wallet-address"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={searchWalletAddress}
          error={walletAddressError}
        />

        <Button size="big" onClick={handleExplore} disabled={exploreBtnDisabled}>
          {t("common:explore")}
        </Button>
      </div>
    </div>
  );
};

export default SearchWallet;
