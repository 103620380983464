import { TFunction } from "i18next";

export const accessPrivilegesOptions = (t: TFunction) => [
  {
    name: `${t("accessPrivilegesOptions.onlyRead")}`,
    value: "read"
  },
  {
    name: `${t("accessPrivilegesOptions.writeRead")}`,
    value: "write"
  }
];

export const validityTypeOptions = (t: TFunction) => [
  {
    name: `${t("validityType.indefinite")}`,
    value: "indefinite"
  },
  {
    name: `${t("validityType.definite")}`,
    value: "definite"
  }
];
