import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import DemoMark from "@components/common/DemoMark/DemoMark";

import "./Footer.scss";

const Footer = () => {
  const { t: layoutT } = useTranslation("layout");
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <Stack className="footer-content">
        <Group colGap={20} justifyContent="space-between" alignItems="flex-start" fullWidth className="mb-5">
          <DemoMark>
            <img src="/images/skey-tix-logo.svg" alt="skey tix logo" />
          </DemoMark>

          <Group colGap={40} alignItems="flex-start" className="footer-descriptions">
            <p className="p0 footer-description">{layoutT("footer.textOne")}</p>
            <p className="p0 footer-description">{layoutT("footer.textTwo")}</p>
          </Group>
        </Group>

        <hr className="mb-4" />

        <Group justifyContent="space-between" fullWidth>
          <p className="footer-copy p4">
            {layoutT("footer.copy")} {year}
          </p>
          <img src="/images/skey-network-logo-small-gray.svg" alt="skey tix logo" />
        </Group>
      </Stack>
    </footer>
  );
};

export default Footer;
