import React from "react";

const TextBold: React.FC<{ width?: number; height?: number; className?: string }> = ({
  width = 16,
  height = 16,
  className = ""
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z"></path>
      <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7"></path>
    </svg>
  );
};

export default TextBold;
