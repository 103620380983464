import { ApiKeyDto } from "@services/Api/api.dto";
import { modalsContextFactory } from "@contexts/ModalsContextFactory";

interface ApiKeyModal {
  type: "removeApiKey";
  data: ApiKeyDto;
}

interface WithoutObjectModal {
  type: "createApiKey" | "editOrganizationData";
  data?: null;
}

type SettingsModal = ApiKeyModal | WithoutObjectModal;

export const { ModalsProvider: SettingsModalsProvider, useModalsContext: useSettingsModals } =
  modalsContextFactory<SettingsModal>();
