import React from "react";
import { useTranslation } from "react-i18next";

import { ClaimingUserDto } from "@services/Api/api.dto";
import { useEventsModals } from "../../contexts/EventsModals.context";
import { useSelectedEvent } from "../../contexts/SelectedEvent.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Link from "@components/navigation/Link/Link";

import "./NftPreview.scss";

interface NftPreviewProps {
  claimingUser: ClaimingUserDto;
}

const NftPreview = ({ claimingUser }: NftPreviewProps) => {
  const { event } = useSelectedEvent();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation("eventsModals");
  const { t: commonT } = useTranslation("common");

  const nftImageUrl = event.occasionalNFT?.imageUrl || "/images/nft-image-placeholder.svg";

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="nft-preview-modal" justifyContent="space-between">
            <Stack className="nft-preview-modal-content">
              <h2 className="mb-4">{t("nftPreview.title")}</h2>

              <img src={nftImageUrl} alt="nft image preview" className="nft-preview-modal-image mb-3" />
              <h2 className="nft-preview-modal-title mb-2">{event.occasionalNFT?.name}</h2>

              {event.occasionalNFT?.description && <p className="p4 mb-2">{event.occasionalNFT?.description}</p>}

              <Stack rowGap={8}>
                <p className="link-hedaer">{t("nftPreview.viewInExplorer")}</p>
                <Link href={claimingUser.explorerUrl} target="_blank" title="Explorer link" className="link">
                  {claimingUser.blockchainAddress}
                </Link>
              </Stack>
            </Stack>

            <Button onClick={closeModal} className="mt-5">
              {commonT("close")}
            </Button>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default NftPreview;
