import React from "react";
import { useTranslation } from "react-i18next";

import Group from "@components/arrangement/Group/Group";
import InfoIcon from "src/assets/InfoIcon";

import "./StatusBadge.scss";

const StatusBadge = () => {
  const { t } = useTranslation("public");

  return (
    <Group colGap={18} alignItems="center" className="status-badge">
      <InfoIcon />
      <h2 className="white-1">{t("eventPage.header.endedStatusBadge")}</h2>
    </Group>
  );
};

export default StatusBadge;
