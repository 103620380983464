import React from "react";

const EmailIcon: React.FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.3 2.65V10.35C15.3 10.7876 15.1262 11.2073 14.8167 11.5167C14.5073 11.8262 14.0876 12 13.65 12H2.65C2.21239 12 1.79271 11.8262 1.48327 11.5167C1.17384 11.2073 1 10.7876 1 10.35V2.65M15.3 2.65C15.3 2.21239 15.1262 1.79271 14.8167 1.48327C14.5073 1.17384 14.0876 1 13.65 1H2.65C2.21239 1 1.79271 1.17384 1.48327 1.48327C1.17384 1.79271 1 2.21239 1 2.65M15.3 2.65V2.8282C15.3 3.10991 15.2279 3.38693 15.0906 3.63288C14.9532 3.87884 14.7552 4.08553 14.5153 4.23327L9.01533 7.6176C8.75515 7.77786 8.45558 7.86272 8.15 7.86272C7.84442 7.86272 7.54485 7.77786 7.28467 7.6176L1.78467 4.234C1.54481 4.08626 1.34677 3.87957 1.20942 3.63362C1.07206 3.38766 0.999969 3.11064 1 2.82893V2.65"
        stroke="#5C44EC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
