import React from "react";
import { AxiosError } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { OrganizationDto, UserDto } from "@services/Api/api.dto";
import { organizationMock } from "@mocks/organization.mock";

import { useApiClient } from "@services/Api/ApiClientContext";

export interface UserData {
  status: FetchStatus;
  user: UserDto;
  organization: OrganizationDto | null;
  error: Error | AxiosError | null;
}

interface ContextValue extends UserData {
  updateOrganizationData: (updatedOrganization: OrganizationDto) => void;
}

const initialCurrentUserData = (): UserDto => {
  return {
    id: "",
    email: "",
    organizations: [organizationMock]
  };
};

const initialUserData = (): UserData => ({
  status: "loading",
  user: initialCurrentUserData(),
  organization: null,
  error: null
});

const UserDataContext = React.createContext(null as any);

export const UserDataProvider = ({ children }: IContext) => {
  const [userData, setUserData] = React.useState(initialUserData());
  const { getMeData } = useApiClient();

  React.useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setUserData(initialUserData());

    try {
      const { data } = await getMeData();
      return setUserData((d) => ({
        ...d,
        user: data,
        organization: data.organizations?.[0] ?? null,
        status: "success"
      }));
    } catch (e: any) {
      setUserData((d) => ({ ...d, status: "failed", error: e }));
    }
  };

  const updateOrganizationData = (updatedOrganization: OrganizationDto) => {
    setUserData((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        organizations: prevState.user.organizations?.map((organization) => {
          if (organization.id === updatedOrganization.id) return updatedOrganization;

          return organization;
        })
      },
      organization: prevState.organization?.id === updatedOrganization.id ? updatedOrganization : prevState.organization
    }));
  };

  const contextValue: ContextValue = {
    ...userData,
    updateOrganizationData
  };

  return <UserDataContext.Provider value={contextValue}>{children}</UserDataContext.Provider>;
};

export const useUserData = (): ContextValue => React.useContext(UserDataContext);
