import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./SubmissionsSkeleton.scss";

const SubmissionsSkeleton = () => {
  return (
    <div className="submissions-skeleton">
      <ContentLoader width={720} height={400} viewBox="0 0 720 400">
        <rect x="40" y="0" rx="5" ry="5" width="300" height="32" />

        <rect x="0" y="50" rx="5" ry="5" width="720" height="32" />

        {/* 1 line */}
        <rect x="0" y="92" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="92" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="92" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="92" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="92" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="92" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="92" rx="5" ry="5" width="30" height="30" />
        {/* 2 line */}
        <rect x="40" y="132" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="132" rx="5" ry="5" width="130" height="30" />

        <rect x="0" y="172" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="172" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="172" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="172" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="172" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="172" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="172" rx="5" ry="5" width="30" height="30" />

        <rect x="40" y="212" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="212" rx="5" ry="5" width="130" height="30" />

        <rect x="0" y="252" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="252" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="252" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="252" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="252" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="252" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="252" rx="5" ry="5" width="30" height="30" />

        <rect x="40" y="292" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="292" rx="5" ry="5" width="130" height="30" />

        <rect x="0" y="332" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="332" rx="5" ry="5" width="310" height="30" />
        <rect x="360" y="332" rx="5" ry="5" width="100" height="30" />
        <rect x="470" y="332" rx="5" ry="5" width="130" height="30" />
        <rect x="610" y="332" rx="5" ry="5" width="30" height="30" />
        <rect x="650" y="332" rx="5" ry="5" width="30" height="30" />
        <rect x="690" y="332" rx="5" ry="5" width="30" height="30" />

        <rect x="40" y="372" rx="5" ry="5" width="310" height="30" />
        <rect x="470" y="372" rx="5" ry="5" width="130" height="30" />
      </ContentLoader>
    </div>
  );
};

export default SubmissionsSkeleton;
