import React from "react";
import { useTranslation } from "react-i18next";

import { AssetData } from "@services/blockchain/blockchain.types";
import { parseNftImageUrl } from "@services/blockchain/utils/nft-parsers";
import { usePoapNftModals } from "@app/poap-nft/contexts/EventPageModals.context";
import { useWvs } from "@services/blockchain/Waves.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Button from "@components/dataEntry/Buttons/Button/Button";

import "./PoapNftPreview.scss";

interface PoapNftPreviewProps {
  nft: AssetData;
}

const PoapNftPreview = ({ nft }: PoapNftPreviewProps) => {
  const { name, description } = nft;

  const { publicState } = useWvs();
  const { closeModal } = usePoapNftModals();
  const { t } = useTranslation(["poapNft", "common"]);

  const imageUrl =
    parseNftImageUrl(publicState?.account?.network || "testnet", description) || "/images/skey-logo-signet.png";
  const imageAlt = name || "Skey network logo";

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <div className="poap-nft-preview-modal">
            <div className="poap-nft-preview-modal-content">
              <img src={imageUrl} alt={imageAlt} loading="lazy" className="nft-image" />

              <div className="content-info">
                <h2>{name}</h2>
              </div>
            </div>

            <Button onClick={closeModal}>{t("common:close")}</Button>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default PoapNftPreview;
