import React from "react";

import { useNotification } from "@contexts/Notification.context";
import Notification from "@components/overlay/Notification/Notification";
import Portal from "@components/overlay/Portal/Portal";

const NotificationPanel = () => {
  const { notifications, removeNotification } = useNotification();

  if (notifications.length > 0) {
    return (
      <Portal type="notification">
        {notifications.map(({ id, variant, message }) => {
          return (
            <Notification key={id} id={id} variant={variant} message={message} close={() => removeNotification(id)} />
          );
        })}
      </Portal>
    );
  }

  return <></>;
};

export default NotificationPanel;
