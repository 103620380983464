import React from "react";
import ContentLoader from "@components/ux/ContentLoader/ContentLoader";
import "./EventsNavListSkeleton.scss";

const EventsNavListSkeleton = () => {
  return (
    <div className="events-nav-list-skeleton">
      <ContentLoader width={340} height={550} viewBox="0 0 340 550">
        <rect x="0" y="0" rx="10" ry="10" width="340" height="58.5" />
        <rect x="0" y="68.5" rx="10" ry="10" width="340" height="140" />
        <rect x="0" y="218.5" rx="10" ry="10" width="340" height="140" />
        <rect x="0" y="368.5" rx="10" ry="10" width="340" height="140" />
      </ContentLoader>
    </div>
  );
};

export default EventsNavListSkeleton;
