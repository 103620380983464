export const tableHeaders = [
  {
    label: "apiKeysTable.headers.nameKey",
    className: "cell-key-nameKey"
  },
  {
    label: "apiKeysTable.headers.key",
    className: "cell-key-hash"
  },
  {
    label: "apiKeysTable.headers.accessPrivileges",
    className: "cell-key-accessPrivileges"
  },
  {
    label: "apiKeysTable.headers.termOfValidity",
    className: "cell-key-termOfValidity"
  },
  {
    label: "",
    className: "cell-key-actions"
  }
];
