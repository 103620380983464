import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import classNames from "classnames";

import Stack from "@components/arrangement/Stack/Stack";

import * as nftGiftAnimation from "../../../../../../public/animations/nft-gift.json";
import "./ReceivedNftPreview.scss";

interface ReceivedNftPreviewProps {
  url?: string;
  isClaimed?: boolean;
}

const ReceivedNftPreview = ({ url = "", isClaimed }: ReceivedNftPreviewProps) => {
  const [showNft, setShowNft] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);

  const fireworksRef = useRef(null as any);

  useEffect(() => {
    if (isClaimed) {
      setTimeout(() => {
        setShowNft(true);
      }, 1800);

      setTimeout(() => {
        setShowFireworks(true);
        fireworksRef.current.play();
      }, 2350);
    }
  }, [isClaimed]);

  const imageNftClasses = classNames("nft-image", { "show-element": showNft });
  const fireworksClasses = classNames("received-nft-preview-fireworks", { "show-element": showFireworks });

  return (
    <>
      {isClaimed ? (
        <div className="received-nft-preview">
          <div className={fireworksClasses}>
            <Lottie ref={fireworksRef} animationData={nftGiftAnimation} speed={1.4} />
          </div>

          <object type="image/svg+xml" data="/animations/nft-hands.svg" className="received-nft-preview-bg" />

          <img src={url} alt="received nft preview" className={imageNftClasses} loading="lazy" />
        </div>
      ) : (
        <Stack alignItems="center" justifyContent="center" className="received-nft-preview-before">
          <object type="image/svg+xml" data="/animations/nft-hands.svg" className="received-nft-preview-bg" />
        </Stack>
      )}
    </>
  );
};

export default ReceivedNftPreview;
