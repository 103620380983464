import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { IChildren } from "@src/types/IChildren.types";
import { useEvents } from "@app/panel/Events/contexts/EventsData.context";
import { PATHS } from "src/router/paths";

const HasEventsGuard = ({ children }: IChildren) => {
  const { events, status } = useEvents();
  const { pathname } = useLocation();

  if (
    events.length <= 0 &&
    status === "success" &&
    pathname === PATHS.events.path &&
    pathname !== PATHS.noEvents.path
  ) {
    return <Navigate to={PATHS.noEvents.path} />;
  }

  return <>{children}</>;
};

export default HasEventsGuard;
