import React from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import classNames from "classnames";

import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Dropcursor from "@tiptap/extension-dropcursor";
import Image from "@tiptap/extension-image";
import Youtube from "@tiptap/extension-youtube";

import { TextEditorProps } from "./TextEditor.types";
import MenuUtilsBar from "./components/MenuUtilsBar/MenuUtilsBar";
import "./TextEditor.scss";

const TextEditor = ({ onChange, content, className }: TextEditorProps) => {
  const editor = useEditor({
    autofocus: true,
    extensions: [
      Underline,
      Highlight,
      Dropcursor,
      Image,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        }
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"]
      }),
      Link.configure({
        HTMLAttributes: {
          target: "_blank",
          rel: "noopener noreferrer nofollow"
        },
        openOnClick: false,
        validate: (href) => /^https?:\/\//.test(href)
      }),
      Youtube.configure({
        controls: false
      })
    ],
    content: content,
    enablePasteRules: true,
    onUpdate: ({ editor }) => {
      if (!onChange) return;
      const content = editor.getHTML().replace(/<p><\/p>/g, "<br>");
      onChange(content);
    }
  });

  const classes = classNames("text-editor", className);

  return (
    <div className={classes}>
      <MenuUtilsBar editor={editor} />
      <EditorContent editor={editor} className="text-editor-content" />
    </div>
  );
};

export default TextEditor;
